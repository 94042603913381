import { Outlet } from "react-router-dom";
import React, { Component } from "react";
import Header from "../Header";
import NavFooter from "../NavFooter";
import SideMenu from "../../map/SideMenu";

export class AdminLayout extends Component {
  render() {
    return (
      <div className="h-100 w-100 my-0">
        <Header />
        <Outlet/>
        <NavFooter />
      </div>
    );
  }
}
