import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import Button from "../shared/Button"
import { useState } from "react";
import httpClientPy from "../../utils/httpClientPy";
import { logEvent } from "../shared/Mixpanel";
import { toast, ToastContainer } from "react-toastify";


const LibraryPromptCreate = (props) => {

    const [promptValue, setPromptValue] = useState('');
    const [loading, setLoading] = useState(false);

    const handleConfirm = async () => {
        setLoading(true);
        try {
            let response = await httpClientPy.post(`/library/management/prompts/create`, 
                { 
                    prompt: promptValue,
                    library_id: props.library_id
                }
            );
            logEvent('Prompt Created', { prompt: promptValue });
            toast.success('Prompt created successfully.', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000});
            setPromptValue('');
            props.getPrompts();
            props.toggle();
        } catch (err) {
            let error_code = err.response.data.detail;
            if (error_code === 4) {
                toast.error('Document limit has been exceeded, contact your admin in order to check limit balance.', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
            } else if (error_code === 3) {
                toast.error('User is not part of enterprise account.', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
            } else if (error_code === 5) {
                toast.error('Prompt does not exist or user is not part of the enterprise.', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
            } else {
                toast.error('An error occurred while creating new prompt. Please contact info@civils.ai.', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
            }
        }
        setLoading(false);
    }

    return (
        <>
            <ToastContainer />
            <Modal isOpen={props.isOpen} toggle={props.toggle} size="lg" centered>
                <ModalHeader toggle={props.toggle}>Create New Prompt</ModalHeader>
                    <ModalBody>
                        <textarea type="text" className="form-control" value={promptValue} onChange={(e) => setPromptValue(e.target.value)} />
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            label='Create'
                            className="btn btn-primary"
                            onClick={handleConfirm}
                            loading={loading}
                        />
                        <Button
                            label='Cancel'
                            className="btn btn-secondary"
                            onClick={props.toggle}
                            loading={loading}
                        />
                    </ModalFooter>
            </Modal>
        </>
    )
}

export default LibraryPromptCreate