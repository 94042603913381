import React, { useCallback, useEffect, useState, useRef } from "react";
import { MapContainer, Marker, Polygon, Polyline, Tooltip } from "react-leaflet";
import L, { marker } from "leaflet";
import { useMap, GeoJSON, useMapEvents } from "react-leaflet";
import * as turf from "@turf/turf";
import Loader from "../shared/Loader";
import localStorageService from "../../utils/localStorageService";
import { library } from "@fortawesome/fontawesome-svg-core";
import 'leaflet/dist/leaflet.css'

import httpClientPy from "../../utils/httpClientPy";
import MapMarkerLabel from "./mapTab/mapMarkerLabel";

import { faLayerGroup, faCaretLeft, faExpand, faArrowUpRightFromSquare, faSatellite, faBook, faFileExcel} from "@fortawesome/free-solid-svg-icons";
library.add(faLayerGroup, faCaretLeft, faExpand, faArrowUpRightFromSquare, faSatellite, faBook);


const MapData = (props) => {
  //modal controls the display of the borehole preview modal
  //data is used to store the map marker data called from the backend
  const [data, setData] = useState([]);


  const [boreholeSelected, setBoreholeSelected] = useState([])

  // variable to store current user email address
  const [domain, setDomain] = useState("");
  const [markers, setMarkers] = useState([]);


  const zoom = useRef([]);
  const renders = useRef(1);



  useEffect(() => {
    httpClientPy.get(`/user`).then((response) => {
        let userList = response.data
        let domain = userList.email.split('@')[1]
        setDomain(domain)
    })
  }, []);
  

  // listen for cursor events on map and update state
  const map = useMapEvents({})

  // freeze map if virtual borehole is dropped
  const mapholder = useMap();
  

  mapholder.dragging.enable()
  mapholder.doubleClickZoom.enable()
  mapholder.touchZoom.enable()
  mapholder.scrollWheelZoom.enable()
  





  // This function check if there is any project detail sent from the project page and if there is, it will fly to the project location
  useEffect(() => {

    let lat = 0;
    let lng = 0;
    let zoomPolygon = 15;

    let polygon_object = getPolygonCoordinates(props.projectDetails.polygon);
    let distance = haversineDistance(polygon_object.minLat, polygon_object.minLng, polygon_object.maxLat, polygon_object.maxLng,)
    
    if (props.projectDetails) {
      lat = polygon_object.lat;
      lng = polygon_object.lng;
      zoomPolygon = calculateZoomLevel(distance);
    } 

    //wait 300 miliseconds before setting the map view to the project location
    setTimeout(() => {
      map.setView(new L.LatLng(lat, lng), zoomPolygon);
    }, 300);

    const newState = {
      location: [lat, lng],
      zoom: 18,
    };
    localStorageService.setItem("map_state", newState);

    
  }, [props.projectDetails]);



    // This function check if there is any project detail sent from the project page and if there is, it will fly to the project location
    useEffect(() => {

      setMarkers(props.boreholeLogs)
      setBoreholeSelected(props.selectedBoreholes)

    }, [props.boreholeLogs, props.selectedBoreholes]);
  

    useEffect(() => {

      setMarkers(props.boreholeLogs)
      setBoreholeSelected(props.selectedBoreholes)

    }, [props.boreholeLogs, props.selectedBoreholes]);


    useEffect(() => {
      
      if(props.clickedRecord && props.clickedRecord.lat && props.clickedRecord.lng){
        map.panTo(new L.LatLng(props.clickedRecord.lat, props.clickedRecord.lng), map.getZoom());

        const newState = {
          location: [props.clickedRecord.lat, props.clickedRecord.lng],
          zoom:  map.getZoom(),
        };
        localStorageService.setItem("map_state", newState);


      }
    }, [props.clickedRecord]);
    

  renders.current = parseFloat(renders.current) + 1;

  // initialise the polygons and icons for the map
  if (markers && markers.length > 0) {
    


    return (
      <>
        {markers.map((feature) => {
          const longitude = feature.position.lng;
          const latitude = feature.position.lat;
          const { id} = feature;
          let icon_holder = boreholeBlack
          let zIndex = 0;


          if (boreholeSelected.includes(id)){
            icon_holder = boreholeRed
            zIndex = 1000;
          }


          return (
            <React.Fragment key={`marker-fragment-${id}`}>
              <Marker
                key={`marker-${id}`}
                position={[latitude, longitude]}
                icon={
                  icon_holder
                }
                zIndexOffset={zIndex}
              >
              </Marker>
              
            </React.Fragment>
          );
        })}
        {props.projectDetails && (
          <>
            <Polyline
              pathOptions={{
                color: 'black',
                fill: props.projectDetails.project_type === 0 ? true : false,
                fillOpacity: 0.05,
                weight: props.projectDetails.project_type === 0 ? 2 : 0,
              }}
              positions={props.projectDetails.polygon}
            />
          </>
        )}
      </>
    );
  }
};

export default MapData;



function getPolygonCoordinates(polygonCoordinates) {
  if (polygonCoordinates.length === 0) {
    return null;
  }

  let centroidX = 0, centroidY = 0;
  let det = 0, tempDet = 0;
  let j = 0;
  let nVertices = polygonCoordinates.length;

  // Initialize bounding box values
  let maxLat = -90, minLat = 90;
  let maxLng = -180, minLng = 180;

  for (let i = 0; i < nVertices; i++) {
    // Update bounding box
    maxLat = Math.max(maxLat, polygonCoordinates[i][0]);
    minLat = Math.min(minLat, polygonCoordinates[i][0]);
    maxLng = Math.max(maxLng, polygonCoordinates[i][1]);
    minLng = Math.min(minLng, polygonCoordinates[i][1]);

    // The last vertex connects to the first
    j = (i + 1) % nVertices;

    // Calculate the determinant
    tempDet = polygonCoordinates[i][0] * polygonCoordinates[j][1] - polygonCoordinates[j][0] * polygonCoordinates[i][1];
    det += tempDet;

    centroidX += (polygonCoordinates[i][0] + polygonCoordinates[j][0]) * tempDet;
    centroidY += (polygonCoordinates[i][1] + polygonCoordinates[j][1]) * tempDet;
  }

  // The total determinant is divided by 2 because the formula for the area of the polygon is 1/2 the determinant
  det = det / 2;

  // Centroid is the sum of x (or y) coordinates times the determinant, divided by 6 times the area
  centroidX = centroidX / (6 * det);
  centroidY = centroidY / (6 * det);

  // Handling negative area for clockwise polygons
  // if (det < 0) {
  //   centroidX = -centroidX;
  //   centroidY = -centroidY;
  //   det = -det;
  // }

  return {
    'lng': centroidY, 
    'lat': centroidX,
    'maxLat': maxLat,
    'minLat': minLat,
    'maxLng': maxLng,
    'minLng': minLng,
  };
}



function haversineDistance(lat1, lon1, lat2, lon2) {
  const R = 6371; // Radius of the Earth in kilometers
  const dLat = (lat2 - lat1) * (Math.PI / 180);
  const dLon = (lon2 - lon1) * (Math.PI / 180);
  const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c; // Distance in kilometers
  return distance;
}

// Calculate appropriate zoom level based on distance
function calculateZoomLevel(distance) {
  let zoomLevel = Math.floor(16 - Math.log2(distance)); // Adjust the base value (16) as needed
  if(zoomLevel>18){
    zoomLevel = 18
  }
  return zoomLevel;
}


const fetchMapData = ({ bounds, zoom, project_id }) => {
  
  const query =
    zoom && bounds
      ? `projectId=${project_id}&zoom=${zoom}&northEast_lat=${bounds.northEast.lat}&northEast_lng=${bounds.northEast.lng}&northWest_lat=${bounds.northWest.lat}&northWest_lng=${bounds.northWest.lng}&southWest_lat=${bounds.southWest.lat}&southWest_lng=${bounds.southWest.lng}&southEast_lat=${bounds.southEast.lat}&southEast_lng=${bounds.southEast.lng}`
      : "";

  return httpClientPy.get(`/map/index?${query}`).then((response) => {
    return response.data;
  });
};


// load the borehole log icons
const boreholeBlack = new L.Icon({
  iconUrl: "assets/borehole-black.svg",
  iconSize: [32, 32],
});

const boreholeWhite = new L.Icon({
  iconUrl: "assets/borehole-white.svg",
  iconSize: [25, 25],
});

const boreholeWhiteSmall = new L.Icon({
  iconUrl: "assets/borehole-white.svg",
  iconSize: [20, 20],
});

const boreholeRed = new L.Icon({
  iconUrl: "assets/borehole-red.svg",
  iconSize: [32, 32],
});

const boreholeGrey = new L.Icon({
  iconUrl: "assets/borehole-grey.svg",
  iconSize: [24, 24],
});

const selectedBorehole = new L.Icon({
  iconUrl: "assets/selectedBorehole3.svg",
  iconSize: [60, 60],
});