import { useCallback, useEffect, useMemo, useState } from 'react';
import BaseCard from './BaseCard';
import Button from '../../shared/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { debounce } from 'lodash';
import { useReactFlow } from '@xyflow/react';
import { toast } from 'react-toastify';
 
const SearchBox = (props) => {

	const [searchTerms, setSearchTerms] = useState(props.data.input);
	const [areNodesRunning, setAreNodesRunning] = useState(false);

	const reactFlowInstance = useReactFlow();

	const debouncedUpdate = useMemo(
		() => debounce((updatedTerms) => {
			props.updateNodeData(props.id, { input: updatedTerms });
		}, 500),
		[props.id, props.updateNodeData]
	);

	const onChange = useCallback((evt, index) => {

		const value = evt.target.value;
		setSearchTerms(prevSearchTerms => {
            const updatedTerms = [...prevSearchTerms];
            updatedTerms[index] = value;
            debouncedUpdate(updatedTerms);
            return updatedTerms;
        });

	}, [debouncedUpdate]);

	const addSearchTermRow = () => {

		// find all the search nodes
		let searchNodes = reactFlowInstance.getNodes().filter(node => node.data.node === 'search');
		const total_search_terms = searchNodes.reduce((acc, node) => acc + node.data.input.length, 0) + 1;

		if (total_search_terms > 30) {
			toast.error('Maximum number of search terms reached', { position: toast.POSITION.TOP_RIGHT, autoClose: 5000 });
			return;
		}

		const tempSearchTerms = [...searchTerms];
		tempSearchTerms.push('');
		setSearchTerms(tempSearchTerms);
		props.updateNodeData(props.id, { input: tempSearchTerms });
	}

	const removeSearchTermRow = (index) => {
		const tempSearchTerms = [...searchTerms];
		tempSearchTerms.splice(index, 1);
		if (tempSearchTerms.length === 0) {
			tempSearchTerms.push('');
		}
		setSearchTerms(tempSearchTerms);
		props.updateNodeData(props.id, { input: tempSearchTerms });
	}

	useEffect(() => {
		return () => {
			debouncedUpdate.cancel();
		}
	}, [debouncedUpdate]);
	
	return (
		<>	
			<BaseCard {...props} areNodesRunning={areNodesRunning} setAreNodesRunning={setAreNodesRunning}>
				{searchTerms.map((term, index) => (
					<div key={index} className="d-flex justify-content-between align-items-center">
						<textarea
							id="text"
							name="searchValues"
							placeholder='Enter search term here'
							onChange={(evt) => onChange(evt, index)}
							className="nodrag nowheel form-control mt-2"
							style={{ width: '100%' }}
							value={term}
							rows={term.split('\n').length}
							disabled={props.data.is_running || props.isDigitsationWf || areNodesRunning}
						></textarea>
						<Button className='btn btn-sm btn-outline-danger ms-1'
								label={<FontAwesomeIcon icon={faMinus}></FontAwesomeIcon>}
								onClick={() => removeSearchTermRow(index)}
								disabled={props.data.is_running || props.isDigitsationWf || areNodesRunning}
						/>
					</div>
				))}
				<div className="d-flex justify-content-center mt-2">
					<Button onClick={() => addSearchTermRow()}
							className='btn btn-sm btn-outline-primary' 
							label={<FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>}
							disabled={props.data.is_running || props.isDigitsationWf || areNodesRunning} />
				</div>
			</BaseCard>
		</>
	);
}

export default SearchBox;