import React, { useState, useEffect, useRef } from "react";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare, faCaretDown, faDrawPolygon, faEllipsis, faPenToSquare } from '@fortawesome/free-solid-svg-icons';

const EditProjectDropdown = (props) => {

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => setDropdownOpen(prevState => !prevState);




  return (
    <>
    <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} className="d-inline">
      <DropdownToggle className="btn border-secondary btn-sm overview_button title-bold" tag="btn">
          <span>Edit</span>
          <span><FontAwesomeIcon className="ms-2" icon={faCaretDown}/></span>
      </DropdownToggle>
      <DropdownMenu>
          <DropdownItem onClick={()=>props.editProjectName()}><FontAwesomeIcon className="me-2" icon={faPenToSquare}/>Edit project name</DropdownItem>
          <DropdownItem onClick={()=>props.editProjectLocation()}><FontAwesomeIcon className="me-2" icon={faDrawPolygon}/>Edit project location</DropdownItem>
      </DropdownMenu>
    </Dropdown>
    </>
  );
};

export default EditProjectDropdown;
