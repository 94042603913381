import { useEffect, useState } from "react"
import httpClientPy from "../../utils/httpClientPy";
import { useNavigate } from "react-router-dom";
import Button from "../shared/Button";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { marked } from 'marked';

const AdminVectorUpdate = () => {

    const navigate = useNavigate();

    const [userInfo, setUserInfo] = useState([])
    const [loading, setLoading] = useState(false)
    const [recordID, setRecordID] = useState('')
    const [vectorList, setVectorList] = useState([])
    const [editModal, setEditModal] = useState(false)
    const [vectorText, setVectorText] = useState('')
    const [vectorID, setVectorID] = useState('')


    useEffect(() => {
        httpClientPy.get(`/user`).then((response) => {
            let userList = response.data
            setUserInfo(userList)
            if (!userList.admin) {
                navigate('/')
            }
        })
    }, [])

    const handleRecordIDChange = (e) => {
        setRecordID(e.target.value)
    }

    const handleRecordIDSubmit = (e) => {
        e.preventDefault()
        setLoading(true)
        httpClientPy.get(`/vector?record_id=${recordID}`).then((response) => {
            setLoading(false)
            setVectorList(response.data.data)
        }
        ).catch((error) => {
            setLoading(false)
            console.log(error)
        })
    }

    const handleEditClicked = (id, text) => {
        setVectorID(id)
        setVectorText(text)
        setEditModal(true)
    }

    const toggleEditModal = () => {
        setEditModal(!editModal)
        setVectorID('')
        setVectorText('')
    }

    const handleVectorTextChange = (e) => {
        setVectorText(e.target.value)
    }

    const handleVectorTextUpdated = () => {
        setLoading(true)
        httpClientPy.post(`/vector/update`, { new_text: vectorText, vector_uuid: vectorID }).then((response) => {
            let vector_list = vectorList
            let index = vector_list.findIndex((vector) => vector.id === vectorID)
            vector_list[index].text = vectorText
            setLoading(false)
            setVectorList(vector_list)
            setEditModal(false)
            setVectorID('')
            setVectorText('')
        }).catch((error) => {
            setLoading(false)
            console.log(error)
        })
    }

    return (
        <>
        <div className="container-flex bg-white min-vh-100">
            <div className="col-12 text-center">
                {vectorList.length < 1 ? (
                    <>
                    <h3>Fetch a record from the database</h3>
                    <div className="digitiser-form-details mx-auto my-auto">
                        <form onSubmit={handleRecordIDSubmit}>
                            <div className="form-floating mb-2">
                            <input
                                name="recordID"
                                type="number"
                                min="1"
                                className="form-control"
                                id="recordIDInput"
                                value={recordID}
                                onChange={handleRecordIDChange}
                                autoComplete="off"
                            />
                            <label htmlFor="recordIDInput">Record ID</label>
                            </div>

                            <div className="vstack gap-2">
                            <Button
                                type="submit"
                                label="Fetch record"
                                className="w-100 btn btn-primary"
                                loading={loading}
                            />
                            </div>
                        </form>
                    </div>
                    </>
                ) : (
                    <>
                        <div className="col-9 mx-auto mt-3">
                            <table className="table table-striped table-hover">
                                <thead>
                                    <tr>
                                        <th scope="col">ID</th>
                                        <th scope="col">Text</th>
                                        <th scope="col">Page</th>
                                        <th scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {vectorList.map((vector) => (
                                        <tr key={vector.id}>
                                            <td>{vector.id}</td>
                                            <td>{vector.text.slice(0, 150)}</td>
                                            <td>{vector.page}</td>
                                            <td>
                                                <Button className="btn btn-primary mb-2" 
                                                        label="Edit" 
                                                        loading={loading} 
                                                        onClick = {() => handleEditClicked(vector.id, vector.text)}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </>
                )}
            </div>
        </div>        
        <Modal isOpen={editModal} toggle={toggleEditModal} size="xl" className="modal-dialog-centered mt-5" fullscreen={true}>
            <ModalHeader className='title-bold border-0 pb-0 mb-0' toggle={toggleEditModal}>Edit Text</ModalHeader>
            <ModalBody>
                <form>
                    <div className="form-group">
                        <label htmlFor="vectorText">Text</label>
                        <textarea
                            className="form-control"
                            id="vectorText"
                            rows="15"
                            value={vectorText}
                            onChange={handleVectorTextChange}
                        ></textarea>
                    </div>
                    <div className="d-flex justify-content-end mt-2">
                        <Button className="btn btn-primary me-2" label="Save" loading={loading} onClick={() => handleVectorTextUpdated()} />
                        <button type="button" className="btn btn-danger" onClick={toggleEditModal}>Cancel</button>
                    </div>
                </form>
                <div className="mt-4">
                    <h5>Preview</h5>
                    <div
                        className="border p-3"
                        dangerouslySetInnerHTML={{ __html: marked(vectorText) }}
                    ></div>
                </div>
            </ModalBody>
        </Modal>
        
        </>
    )
}

export default AdminVectorUpdate;