import { useState } from "react";
import { ModalHeader, ModalBody } from "react-bootstrap";
import Button from "../shared/Button";
import httpClientPy from "../../utils/httpClientPy";
import AdminUploader from "./AdminUploader";

const AdminUploadRecord = ({ ...props }) => {

    const [geoRecordId, setGeoRecordId] = useState('');
    const [loading, setLoading] = useState(false);

    const updateValues = (event, field) => {
        if (field === 'geo_record_id') {
            setGeoRecordId(event.target.value);
        }
    }

    const closeModal = () => {
        props.toggleModal()
    }

    const uploadComplete = () => {
        props.uploadDone();
        closeModal();
    };
    
    return (
        <>  
            <ModalHeader className="border-0 mb-0 pb-0" toggle={()=>props.toggleModal()}> {props.displayName && props.displayName}</ModalHeader>
            <ModalBody>
                <div className="container-fluid w-100 pb-2 mb-1">
                    <div>This form is for VA to upload splitted records with the uploads automatically assigned to original user and it 
                        will delete the original file
                    </div>
                </div>
                <div className="form-floating mt-2">
                    <input
                        name="geo_record_id"
                        type="text"
                        className="form-control"
                        id="geo_record_id"
                        value={geoRecordId}
                        onChange={(event) => updateValues(event, 'geo_record_id')}
                        autoComplete="off"
                    />
                    <label htmlFor="geo_record_id">Original Record ID</label>
                </div>
                {geoRecordId &&
                    <div className="px-2 py-3">
                        <AdminUploader
                            uploadUrl="/geo/record/upload"
                            userInfo={props.userInfo}
                            onComplete={uploadComplete}
                            geoRecordId={geoRecordId}
                            geotech={true}
                        />
                    </div>
                }
            </ModalBody>
        </>
    );
};

export default AdminUploadRecord;
