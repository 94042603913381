import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { distanceInKmBetweenEarthCoordinates } from "../../shared/algorithms/geoRecordAlgorithms.js"
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry'
import { CSS2DRenderer, CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer.js';

const Model = (props) => {
  //this is all used for generating the threejs model of the underground geology
  const mount = useRef();
  const frameId = useRef();
  const scene = useRef();
  const camera = useRef();
  const renderer = useRef();
  const points = useRef();
  const controls = useRef();
  const labelRenderer = useRef();


  let max_ground_level = -100000

  if(props.boreholeData.virtual && props.boreholeData.virtual.strata && props.boreholeData.virtual.strata.length > 0){

    for (let borehole of props.boreholeData.boreholes){
      if(borehole.data.groundlevel > max_ground_level){
        max_ground_level = borehole.data.groundlevel
      }
    }
    if (props.boreholeData.rerender === true){
      props.boreholeData.rerender = false
      render_scene()
    }
  }




  useEffect(() => {
      render_scene()
    }, [props.boreholeData, props.fixVirtualBorehole, props.scale, props.virtualBoreholeDisplay, props.displayDimensions, props.boreholeName, props.groundwater, props.displayInterpolation]);

    useEffect(() => {
      window.addEventListener("resize", handleWindowResize);
      return () => window.removeEventListener("resize", handleWindowResize);
    }, []);



  function render_scene(){
    //before the virtual borehole is loaded initialise a spinning empty ground layer
    let width = 10;
    let height = 10;
    // Get the new width and height of the window
    if(mount.current){
      width = mount.current.clientWidth;
      height = mount.current.clientHeight;
  }


    // Add the event listener to the window
    window.addEventListener("resize", handleWindowResize);

    // if rendering canvas with borehole data
    if(props.boreholeData.virtual && props.boreholeData.virtual.strata && props.boreholeData.virtual.strata.length > 0){

      cancelAnimationFrame(frameId.current);

        // remove all objects from the scene
        if(scene.current){
          if(scene.current.children){
            while (scene.current.children.length > 0){ 
              scene.current.remove(scene.current.children[0]); 
            }
          }
        }
        
        var vertices = [];
        var colors = [];
        var color




        if(props.boreholeData.topography){
          for ( let i = -50; i < 50; i+=5 ) {
            for ( let l = -50; l < 50; l+=5 ) {

                vertices.push( -i, (props.boreholeData.virtual.surface_pointcloud[(l+50)/5][(i+50)/5].elevation-max_ground_level)*props.scale, l );
            
            }
          }
        }else{
          vertices = props.boreholeData.virtual.surface_pointcloud
        }
        
        var geometry = new THREE.BufferGeometry();
        geometry.setAttribute( 'position', new THREE.Float32BufferAttribute( vertices, 3 ) );
        
        var material = new THREE.PointsMaterial( {color: 0xc0c0c0, size:1.5} );
        
        points.current = new THREE.Points( geometry, material );
        
        scene.current.add( points.current );

        //create the soil layers
        //iterate through all the boreholedata array and render the layer as an array of points
        if(!props.groundwater){
          
          for (let borehole of props.boreholeData.virtual.strata){
            
              var boreholeHolderColor = null
              
              vertices =[]
              colors=[]
              boreholeHolderColor = borehole.color
              if(borehole.material !== '-' && borehole.visible !== false && props.displayInterpolation){
                for ( let i = -50; i < 50; i+=5 ) {
                    for ( let l = -50; l < 50; l+=5 ) {

                      if(props.boreholeData.topography){
                        vertices.push( -i, (borehole.pointcloud[(l+50)/5][(i+50)/5].top-max_ground_level)*props.scale, l );
                        vertices.push( -i, (borehole.pointcloud[(l+50)/5][(i+50)/5].base-max_ground_level)*props.scale, l );
                      }else{
                        vertices.push( -i, -borehole.pointcloud[(l+50)/5][(i+50)/5].top*props.scale, l );
                        vertices.push( -i, -borehole.pointcloud[(l+50)/5][(i+50)/5].base*props.scale, l );
                      }

                        color = new THREE.Color();
                        const vx = boreholeHolderColor[0]/250;
                        const vy = boreholeHolderColor[1]/250;
                        const vz = boreholeHolderColor[2]/250;
                    
                        color.setRGB( vx, vy, vz );
                        colors.push( color.r, color.g, color.b );
                        colors.push( color.r, color.g, color.b );

                    }

                    geometry = new THREE.BufferGeometry();
                    geometry.setAttribute( 'position', new THREE.Float32BufferAttribute( vertices, 3 ) );
                    geometry.setAttribute( 'color', new THREE.Float32BufferAttribute( colors, 3 ) );

                    material = new THREE.PointsMaterial( { vertexColors: true, size:1.5 } );
                    points.current = new THREE.Points( geometry, material );
                    
                    scene.current.add( points.current );
                }
              }
          }
        }
        else if (props.displayInterpolation){
          let vertices_water = []      
          for ( let i = -50; i < 50; i+=5 ) {
            for ( let l = -50; l < 50; l+=5 ) {
              if(props.boreholeData.topography){
              vertices_water.push( -i, (-props.boreholeData.virtual.water_pointcloud[(l+50)/5][(i+50)/5].water_level-max_ground_level)*props.scale, l );
              }else{
                vertices_water.push( -i, (-props.boreholeData.virtual.water_pointcloud[(l+50)/5][(i+50)/5].water_level)*props.scale, l );
              }
            }
          }

          var geometry_water = new THREE.BufferGeometry();
          geometry_water.setAttribute( 'position', new THREE.Float32BufferAttribute( vertices_water, 3 ) );
          
          var material_water = new THREE.PointsMaterial( {color: 0x00FFFF, size:1.5} );
          
          points.current = new THREE.Points( geometry_water, material_water );
          
          scene.current.add( points.current );


        }

        const selectionRadius = Math.abs((((props.selectionRadiusSlider/400)*76000) / Math.pow(2, props.zoom - 3) * Math.cos(props.boreholeData.virtual.location.lat * Math.PI / 180))*0.05196);
        let bh_id = 1
        for(let borehole of props.boreholeData.boreholes){
          if(borehole.data){
            let scale_factor_lat = 1
            let scale_factor_lng = 1
        
            let zholder = distanceInKmBetweenEarthCoordinates(borehole.data.coordinates.lat, props.boreholeData.virtual.min_lng, props.boreholeData.virtual.min_lat, props.boreholeData.virtual.min_lng)
            let xholder = distanceInKmBetweenEarthCoordinates(props.boreholeData.virtual.min_lat, borehole.data.coordinates.lng, props.boreholeData.virtual.min_lat, props.boreholeData.virtual.min_lng)
        
            let scaledZPosition = -50+(zholder / (selectionRadius))*50
            let scaledXPosition = -50+(xholder / (selectionRadius))*50

            create3DBoreholeLog(scene, borehole, -scaledXPosition, scaledZPosition, props.scale, props.boreholeData.topography, max_ground_level, props.groundwater)
            if(props.boreholeName){
              createBoreholeLabel(borehole.data.borehole_name, '', labelRenderer, scene, -scaledXPosition, -5, scaledZPosition, width, height, props.scale)
            } else{
              createBoreholeLabel(bh_id, '', labelRenderer, scene, -scaledXPosition, -5, scaledZPosition, width, height, props.scale)
            }
        
            //incremenet bh_id
            bh_id+=1
        }
        
        }

        if(props.virtualBoreholeDisplay==true){

          let virtual_lat_holder = props.boreholeData.virtual.location.lat
          let virtual_lng_holder = props.boreholeData.virtual.location.lng
          if(virtual_lat_holder<props.boreholeData.virtual.min_lat){
            virtual_lat_holder = props.boreholeData.virtual.min_lat
          }
          if(virtual_lat_holder>props.boreholeData.virtual.max_lat){
            virtual_lat_holder = props.boreholeData.virtual.max_lat
          }
          if(virtual_lng_holder<props.boreholeData.virtual.min_lng){
            virtual_lng_holder = props.boreholeData.virtual.min_lng
          }
          if(virtual_lng_holder>props.boreholeData.virtual.max_lng){
            virtual_lng_holder = props.boreholeData.virtual.max_lng
          }

          let zholder = distanceInKmBetweenEarthCoordinates(virtual_lat_holder, props.boreholeData.virtual.min_lng, props.boreholeData.virtual.min_lat, props.boreholeData.virtual.min_lng)
          let xholder = distanceInKmBetweenEarthCoordinates(props.boreholeData.virtual.min_lat, virtual_lng_holder, props.boreholeData.virtual.min_lat, props.boreholeData.virtual.min_lng)
      
          let scaledZPosition = -50+(zholder / (selectionRadius))*50
          let scaledXPosition = -50+(xholder / (selectionRadius))*50
          create3DBoreholeLog(scene, props.boreholeData.virtual, -scaledXPosition, scaledZPosition, props.scale, props.boreholeData.topography, max_ground_level, props.groundwater)
          createBoreholeLabel('Virtual', 'BH', labelRenderer, scene, -scaledXPosition, -5, scaledZPosition, width, height, props.scale)
        }

        //add a red arrow pointing in z direction with the label 'N'
        const arrowHelper = new THREE.ArrowHelper( new THREE.Vector3( 0, 0, 1 ), new THREE.Vector3( 0, 0, 0 ), 10, 0xff0000 );
        scene.current.add( arrowHelper );
        //position arrow at edge of area
        arrowHelper.position.set(0, 0, 55)
        createBoreholeLabel('N', '', labelRenderer, scene, 0, -5, 65, width, height, props.scale)

        let borehole_length = props.boreholeData.virtual.max_depth
        let depth_labels = []


        if(props.displayDimensions){
          if(borehole_length){

            if(props.boreholeData.topography){
              for (let i = max_ground_level; i > (max_ground_level-borehole_length); i-=10) {
                let depthlabelDiv = create_label(Math.round(i*100)/100, -57, (i-max_ground_level)*props.scale, 0,)
                scene.current.add(depthlabelDiv)
                let position_marker = create_marker(-55, (i-max_ground_level)*props.scale, 0,)
                scene.current.add( position_marker );
              }
            }
            else{
              for (let i = 0; i < borehole_length; i+=10) {
                let depthlabelDiv = create_label(i, -57, -i*props.scale, 0,)
                scene.current.add(depthlabelDiv)
                let position_marker = create_marker(-55, -i*props.scale, 0,)
                scene.current.add( position_marker );
              }
            }

            let final_label_text = Math.round(borehole_length*100)/100
            if(props.boreholeData.topography){
              final_label_text = Math.round((max_ground_level-borehole_length)*100)/100
            }

            let finaldepthlabelDiv = create_label(final_label_text, -55, -(borehole_length*props.scale)-3, 0,)
            scene.current.add(finaldepthlabelDiv)
            let position_marker = create_marker(-55, -(borehole_length*props.scale), 0,)
            scene.current.add( position_marker );
          }
        }

        if(props.virtualBoreholeDisplay==true){
          controls.current.autoRotate = false;

        }
        else{
          controls.current.autoRotate = true;
        }
        controls.current.update();


    }

    // if there is not any borehole data provided
    else if (!scene.current) {
      scene.current = new THREE.Scene();

      camera.current = new THREE.PerspectiveCamera(
        45,
        width / height,
        0.1,
        200000
      );
      camera.current.position.set(200, 100, 0 );
      renderer.current = new THREE.WebGLRenderer({ antialias: true });

      const vertices = [];

      for ( let i = -50; i < 50; i+=5 ) {
          for ( let l = -50; l < 50; l+=5 ) {

              const x = parseFloat(i);
              const z = parseFloat(l);

              vertices.push( x, 0, z );
          }
      }
      
      var geometry = new THREE.BufferGeometry();
      geometry.setAttribute( 'position', new THREE.Float32BufferAttribute( vertices, 3 ) );
      
      var material = new THREE.PointsMaterial( { color: 0x888888, size:1.5 } );
      
      points.current = new THREE.Points( geometry, material );
      
      scene.current.add( points.current );
    


    // animations and controls for both cases
    camera.current.position.z = 4;

    // Add an instance of OrbitControls to the scene
    controls.current = new OrbitControls(camera.current, renderer.current.domElement);

    controls.current.autoRotate = true;
      controls.current.autoRotateSpeed = 0.2; 
    
    controls.current.update();

    renderer.current.setClearColor('#28242c');
    renderer.current.setSize(width, height);


    }
    
    const start = () => {
        frameId.current = requestAnimationFrame(animate);      
    };

    const stop = () => {
      cancelAnimationFrame(frameId.current);
    };

    const animate = () => {
      renderScene();

      frameId.current = window.requestAnimationFrame(animate);
      controls.current.update();
    };

    const renderScene = () => {
      renderer.current.render(scene.current, camera.current);
      if(props.boreholeData.virtual && props.boreholeData.virtual.strata && props.boreholeData.virtual.strata.length > 0){
        labelRenderer.current.render(scene.current, camera.current);
      }
    };

    mount.current.appendChild(renderer.current.domElement);
    if(props.boreholeData.virtual && props.boreholeData.virtual.strata && props.boreholeData.virtual.strata.length > 0){
      mount.current.appendChild(labelRenderer.current.domElement);
    }
    start();

    return () => {
      stop();
      scene.current.remove( points.current );
    };

  }

  function handleWindowResize() {

    let width = 10;
    let height = 10;
    // Get the new width and height of the window
    if(mount.current){
      width = mount.current.clientWidth;
      height = mount.current.clientHeight;
    }
    // Update the renderer and canvas size
    renderer.current.setSize(width, height);

    // Update the camera aspect ratio
    camera.current.aspect = width / height;
    camera.current.updateProjectionMatrix();


    // Update the label renderer and canvas size
    labelRenderer.current.setSize(width, height);

}



  return (
    <>
    <div className='modelRender' ref={mount} />
    </>
  );
}

export default Model;


function create3DBoreholeLog(scene, selectedBorehole, scaledXPosition, scaledZPosition, vertical_scale, topography, max_ground_level, groundwater){
  let color
    if(selectedBorehole.data && selectedBorehole.data.water_level){
        if(groundwater){
        let waterStrike = selectedBorehole.data.water_level

        var geometry = new THREE.PlaneGeometry(10,10);
        
        var material = new THREE.MeshBasicMaterial( {color: 0x8bcaf1, side: THREE.DoubleSide,transparent: true,opacity:0.5} );          
        let water_mesh = new THREE.Mesh( geometry, material );

        water_mesh.rotation.x = Math.PI / 2;
        water_mesh.position.y = -waterStrike*vertical_scale

        if(topography){
          water_mesh.position.y = (-max_ground_level-waterStrike)*vertical_scale
        }



        water_mesh.position.x = scaledXPosition
        water_mesh.position.z = scaledZPosition
        scene.current.add( water_mesh );
      }
    }


  for (let boreholeHolder of selectedBorehole.strata){
    if (boreholeHolder.visible !== false){
      var boreholeHolderColor = null

      boreholeHolderColor = boreholeHolder.color

      color = new THREE.Color();
      const vx = boreholeHolderColor[0]/250;
      const vy = boreholeHolderColor[1]/250;
      const vz = boreholeHolderColor[2]/250;
      color.setRGB( vx, vy, vz );

      let strataLength = (boreholeHolder.base - boreholeHolder.top)*vertical_scale
      const geometryVirtual = new THREE.CylinderGeometry( 2, 2, strataLength, 32 );
      const materialVirtual = new THREE.MeshBasicMaterial( {color: color} );
      const virtualBorehole = new THREE.Mesh( geometryVirtual, materialVirtual );
      if(topography){
        virtualBorehole.position.y = (boreholeHolder.top-(boreholeHolder.top - boreholeHolder.base)/2-max_ground_level)*vertical_scale
      }
      else {
        virtualBorehole.position.y = (-boreholeHolder.top-(boreholeHolder.base - boreholeHolder.top)/2)*vertical_scale
      }
      

      virtualBorehole.position.x = scaledXPosition
      virtualBorehole.position.z = scaledZPosition


      scene.current.add( virtualBorehole );

      const edges = new THREE.EdgesGeometry( geometryVirtual, 20 );
      const line = new THREE.LineSegments( edges, new THREE.LineBasicMaterial( { color: 0x000000 } ) );

      if(topography){
        line.position.y = (boreholeHolder.top-(boreholeHolder.top - boreholeHolder.base)/2-max_ground_level)*vertical_scale
      }
      else {
        line.position.y = (-boreholeHolder.top-(boreholeHolder.base - boreholeHolder.top)/2)*vertical_scale
      }

      line.position.x = scaledXPosition
      line.position.z = scaledZPosition
      
      scene.current.add( line );
    }
  }
}


function createBoreholeLabel(label_top, label_bottom, labelRenderer, scene, x, y, z, width, height, vertical_scale) {
  // Setup labels
  labelRenderer.current = new CSS2DRenderer();

  labelRenderer.current.setSize(width, height);
  labelRenderer.current.domElement.style.position = 'absolute';
  labelRenderer.current.domElement.style.top = '0px';
  labelRenderer.current.domElement.style.zIndex = '4000';
  labelRenderer.current.domElement.style.pointerEvents = 'none';
  labelRenderer.current.domElement.style.color = '#FFFFFF'
  labelRenderer.current.domElement.style.fontSize = 'x-small'


  const labelDiv = document.createElement('div');
  labelDiv.setAttribute('style', 'white-space: pre;');
  if(label_top === 'Virtual'){
    labelDiv.className = 'label modelLabel modellingTextVirtualBH text-center ';
  }
  else{
    labelDiv.className = 'label modelLabel text-white text-center ';
  }
  labelDiv.textContent = `${label_top}\r\n`
  labelDiv.textContent += `${label_bottom}`
  labelDiv.style.fontSize = 'small'
  labelDiv.style.marginTop = '-1em';
  labelDiv.style.zIndex = '9000';

  const label = new CSS2DObject(labelDiv);
  label.visible = true;
  label.position.set(x, y+5, z );


  scene.current.add(label);
}

function create_label(text, x, y, z){
  let depthlabelDiv = document.createElement('div');
  depthlabelDiv.setAttribute('style', 'white-space: pre;');
  depthlabelDiv.className = 'label modelLabel text-center ';
  depthlabelDiv.style.color = '#D3D3D3'
  depthlabelDiv.textContent = `${text}m`
  depthlabelDiv.style.zIndex = '9000';
  const depthlabel = new CSS2DObject(depthlabelDiv);
  depthlabel.visible = true;
  depthlabel.position.set(x, y, z );
  return depthlabel
}

function create_marker(x, y, z){
  let geo_geometry = new THREE.BoxGeometry(10,1,0.1);
          
  let material_levels = new THREE.MeshBasicMaterial( {color: 0xffffff, side: THREE.DoubleSide,transparent: true,opacity:0.2} );          
  let levels = new THREE.Mesh( geo_geometry, material_levels );

  levels.rotation.x = Math.PI / 2;
  levels.position.y = y
  levels.position.x = x
  levels.position.z = z
  return levels
}


