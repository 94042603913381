/* eslint-disable react/jsx-no-target-blank */
import { setUserID, logEvent } from "../shared/amplitude";
import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import authenticationHandler from "../../utils/authenticationHandler";
import httpClientPy from "../../utils/httpClientPy";
import GoogleSignIn from "../googleSignIn/GoogleSignIn";
import Button from "../shared/Button";
import ValidationSummary from "../shared/ValidationSummary";
import AuthFormHeader from "../shared/AuthFormHeader";
import { UncontrolledAlert } from "reactstrap";
import Auth from "../sso/Auth";

const VerifyPage = () => {
  
  const navigate = useNavigate();

  useEffect(() => {
    // get the token from the url if there is one
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    if (token) {
        httpClientPy
        .post(`/user/verify`, { token: token })
        .then((result) => {
            localStorage.setItem("auth_token", result.data.token);
            localStorage.setItem("onboarded", true);
        })
        .catch((errorResponse) => {
            navigate("/log-in");
        })
        .finally(() => {
            navigate("/projects");
        });
        
    }
  }, []);

  return (
    null
  );
};

export default VerifyPage;
