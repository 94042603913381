
export function findBoreholeColor(boreholeData) {

  // if number of colors required exceed the number in this we randomly generate colors to be used
  var colorsHolder = [
    [220, 200, 160],
    [224,159,167],
    [183,171,123],
    [216,168,136],
    [219,187,240],
    [232,214,153],
    [186,172,97],
    [235,145,191],
    [238,137,133],
    [233,155,111],
    [198,154,228],
    [223,173,99],
    [231,221,131],
    [195,175,81],
    [224,159,167],
    {name: 'Unknown',color:[255,255,255]},
    {name: '-',color:[255,255,255]},
    {name: 'Clay (CH/CL)',color:[177,161,205]},
    {name: 'Sand (SW/SP/SM)',color:[232,212,189]},
    {name: 'Gravel (GW/GP)',color:[232,201,219]},
    {name: 'Silt (MH/ML)',color:[191,162,181]},
    {name: 'Rock',color:[140,140,140]},
    {name: 'Fill',color:[188,167,153]},
    {name: 'pending',color:[255,255,255]},
  ]



    // if rendering canvas with borehole data
    if(boreholeData && boreholeData.length > 0){
        for (let borehole of boreholeData){


          var boreholeHolderColor = null
          var foundMaterial = false
      
          //associate a color from colorsHolder to the boreholeHolder material, 
          //if a color is already associated to this material use this color if not use the next available color and associate this color with the material
          for (let material of colorsHolder){
            if (material.name === borehole.material){
              boreholeHolderColor = material.color
              foundMaterial = true
              break
            }
          }
          if (!foundMaterial){
            boreholeHolderColor = colorsHolder[0]
            colorsHolder.shift()
            colorsHolder.push({name: borehole.material, color: boreholeHolderColor})
          }

          borehole.color = boreholeHolderColor
        }
    }

  return (
    boreholeData
  );
}

function getRandomRgb() {
  var num = Math.round(0xffffff * Math.random());
  var red = num >> 16;
  var green = num >> 8 & 255;
  var blue = num & 255;
  return [
    red,
    green,
    blue
  ]
}
