import { faTimes, faSpinner,faCircleChevronRight, faX, faXmark, faPlus, faMinus, faExpand, faArrowRight, faArrowLeft, faRobot, faPaperPlane, faWarning, faMagnifyingGlass, faCheck, faCopy, faDownload, faDownLeftAndUpRightToCenter, faWindowMinimize, faWindowMaximize, faThumbsUp, faThumbsDown, faClockRotateLeft, faListCheck, faMagnifyingGlassArrowRight, faCity, faTriangleExclamation, faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPlay } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import {useNavigate} from "react-router-dom";
import { Card, CardBody, CardHeader, Spinner, Button, FormGroup, Input, Tooltip } from 'reactstrap';
import PdfViewer from './PdfViewer';
import { marked } from 'marked';
import { logEvent } from '../../shared/Mixpanel';
import  SearchAll from './components/SearchAll';
import  SearchDoc from './components/SearchDoc';
import RegChecker from './components/RegChecker';

marked.setOptions({
    gfm: true,
    tables: true,
  });


const SearchResult = (props) => {

    const [highlightedAreas, setHighlightedAreas] = useState([]);
    const [fileUrl, setFileUrl] = useState((props.selectedData && props.selectedData.pdf_url) ? props.selectedData.pdf_url : null );
    const [currentFileName, setCurrentFileName] = useState((props.selectedData && props.selectedData.original_file_name) ? props.selectedData.original_file_name : null );
    const [pageNumberInput, setPageNumberInput] = useState(1);
    const [openPreview, setOpenPreview] = useState(false);
    const [initialPage, setInitialPage] = useState(1);
    const [clickedMsgId, setClickedMsgId] = useState(null);
    const pdfview = useRef(null);
    const [fileType, setFileType] = useState((props.selectedData && props.selectedData.file_type !== null) ? props.selectedData.file_type : null );
    const [selectedId, setSelectedId] = useState((props.selectedData && props.selectedData.id) ? props.selectedData.id : null );
    const [copyStatus, setCopyStatus] = useState(false);
    const [base64img, setBase64img] = useState(null);
    const [task, setTask] = useState(props.task ? props.task : 1);
    const [openCheckHistory, setOpenCheckHistory] = useState(false);
    const [showMasterlist, setShowMasterlist] = useState(true);
    const [masterListComments, setMasterListComments] = useState(null);
    const [tooltipOpen1, setTooltipOpen1] = useState(false);
    const [tooltipOpen2, setTooltipOpen2] = useState(false);
    const [tooltipOpen3, setTooltipOpen3] = useState(false);

    const toggle1 = () => setTooltipOpen1(!tooltipOpen1);
    const toggle2 = () => setTooltipOpen2(!tooltipOpen2);
    const toggle3 = () => setTooltipOpen3(!tooltipOpen3);
    
    const [chatInput, setChatInput] = useState('');
    const navigate = useNavigate();

    const handleChangeQuery = (e) => {
        setChatInput(e.target.value);
    }

    const navigateToProjects = () => {
        navigate(`/projects`);
    }

    const [tooltipOpen, setTooltipOpen] = useState({
        masterList: false,
        checkHistory: false
      });
    
      const toggle = (tooltip) => {
        setTooltipOpen(prevState => ({
          ...prevState,
          [tooltip]: !prevState[tooltip]
        }));
      };


    const handleCopy = () => {
        const copyText = document.getElementById('copy_this').innerText;
        navigator.clipboard.writeText(copyText).then(() => {
            setCopyStatus(true)
            // set a timeout before setting the copy status back to false
            setTimeout(() => {
                setCopyStatus(false)
            }
            , 3000);
        }).catch(err => {
            setCopyStatus(false)
        });
      };

    const handleDownload = () => {
        if (fileUrl) {
            // Create an anchor element dynamically
            const link = document.createElement('a');
            link.href = fileUrl;
            link.target = '_blank'; // Open in a new tab or window
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    useEffect(() => {
        
        if (fileType === null || fileType === undefined) {
            setFileType(props.fileType)
        }
    }, []);

    return (
        <>
            <Card 
                className={`chat-card chat-card-open shadow`}
                style={{ height: '100vh' }}
            >
                <CardBody className={`py-0 bg-white w-100`}>
                    <div className='row h-100'>
                        <div id='pdfview' ref={pdfview} className={`border-end col-6 px-0 d-none d-sm-block`} style={{height: '90vh' }}>
                                <PdfViewer
                                    project={props.project} 
                                    fileUrl={fileUrl} 
                                    task={task}
                                    pageNumberInput={pageNumberInput}
                                    initialPage={initialPage}
                                    fileType={fileType}
                                    setInitialPage={(page)=>setInitialPage(page)}
                                    highlightArea = {highlightedAreas} 
                                    updateTask={(id)=>setTask(id)}
                                    currentFileName={currentFileName}
                                    updatePageNumber={(page)=>setPageNumberInput(page)}
                                    updateBase64img={(img)=>setBase64img(img)}
                                />
                        </div>
                        <div className={`d-flex col-12 col-sm-6 shadow bg-offwhite px-0 flex-column  chatList-open d-flex`}>
                            <div className="d-flex justify-content-between align-items-center border-bottom bg-light">
                                    <div className="px-3" style={{ margin: 0 }}>

                                        <div class="btn-group py-2 pe-2 d-none d-sm-inline-block" role="group" aria-label="Basic example">

                                            <div id="tooltip1" className={`btn btn-light border  ${task === 1 && 'active'}`} onClick={()=>setTask(1)}>
                                                <FontAwesomeIcon icon={faCity} width={'1rem'} />
                                            </div>
                                                <Tooltip isOpen={tooltipOpen1} placement={'bottom'} target="tooltip1" toggle={toggle1}>
                                                    Search all project
                                                </Tooltip>
                                            <div id="tooltip2" className={`${fileType === 2 ? 'btn btn-light  border grey' : `btn btn-light  border ${task === 2 && 'active'}`}`} onClick={fileType === 2 ? null : ()=>setTask(2)}>
                                                <FontAwesomeIcon icon={faLightbulb} />
                                            </div>
                                            <Tooltip isOpen={tooltipOpen2} placement={'bottom'} target="tooltip2" toggle={toggle2}>
                                                {fileType === 2 ? 'Searching the open document is not available for CAD right now': 'Search open document'}
                                            </Tooltip>
                                            <div id="tooltip3" className={`${fileType !== 0 ? 'btn btn-light border btn-disabled' : `btn btn-light  border ${task === 3 && 'active'}`}`} onClick={fileType !== 0 ? null : ()=>setTask(3)}>
                                                <FontAwesomeIcon icon={faTriangleExclamation} />
                                            </div>
                                            <Tooltip isOpen={tooltipOpen3} placement={'bottom'} target="tooltip3" toggle={toggle3}>
                                                {fileType !== 0 ? 'Finding issues and optimisations is only available for `proposal` documents': 'Comments & Issues'}
                                            </Tooltip>
                                        </div>

                                        <h5 className='d-inline py-0 my-0'><b>{task === 1 ? 'Search all project' : task === 2 ? <>Search this document</> : <>Comments and issues <span className="beta-badge">BETA</span></>}</b></h5>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        {task === 3 &&
                                            <>
                                            {masterListComments && masterListComments.length > 0 && !showMasterlist && (
                                                <div>
                                                <button id="masterList" className="btn btn-link text-dark me-2 btn-sm d-flex align-items-center ms-2" onClick={() => setShowMasterlist(true)}>
                                                    <FontAwesomeIcon icon={faListCheck} />
                                                </button>
                                                <Tooltip target="masterList" isOpen={tooltipOpen.masterList} toggle={() => toggle('masterList')} placement="bottom">
                                                    View full comments list
                                                </Tooltip>
                                                </div>
                                            )}
                                            <div>
                                                <a id="checkHistory" className="btn btn-link text-dark btn-sm me-2" onClick={() => setOpenCheckHistory(prev => !prev)}>
                                                <FontAwesomeIcon icon={faClockRotateLeft} />
                                                </a>
                                                <Tooltip target="checkHistory" isOpen={tooltipOpen.checkHistory} toggle={() => toggle('checkHistory')} placement="bottom">
                                                View check history
                                                </Tooltip>
                                            </div>
                                            </>
                                        }
                                        <a className="btn btn-link text-dark d-none d-sm-inline" type="button" onClick={() => props.handleChatClose()}>
                                            <FontAwesomeIcon icon={faTimes} />
                                        </a>
                                        <a className="btn btn-icon text-dark d-inline d-sm-none" type="button" onClick={() => navigateToProjects()}>
                                            <FontAwesomeIcon icon={faTimes} />
                                        </a>
                                    </div>
                            </div>
                            <div className={`${task!==1 && 'd-none'}`}>
                                <SearchAll
                                    project={props.project}
                                    initMessage={props.initMessage}
                                    lastId={props.lastId}
                                    historicalMsg={props.historicalMsg}
                                    updateFileUrl={(url)=>setFileUrl(url)}
                                    updateFileName={(name)=>setCurrentFileName(name)}
                                    updatePageNumber={(page)=>setPageNumberInput(page)}
                                    updateInitialPage={(page)=>setInitialPage(page)}
                                    updateHighlights={(id)=>setHighlightedAreas(id)}
                                    updateClickedMsgID={(id)=>setClickedMsgId(id)}
                                    updateOpenPreview={(status)=>setOpenPreview(status)}
                                    updateRecordId={(id)=>setSelectedId(id)}
                                    updateFileType={(type)=>setFileType(type)}
                                    openPreview={openPreview}
                                    initialPage={initialPage}
                                    highlightedAreas={highlightedAreas}
                                    currentFileName={currentFileName}
                                    fileType={fileType}
                                    fileUrl={fileUrl}
                                    task={task}
                                />
                            </div>
                            <div className={`${task!==2 && 'd-none'}`}>
                                {selectedId && props.project && task === 2 &&
                                    <SearchDoc
                                        project={props.project}
                                        initMessage={props.initMessage}
                                        lastId={props.lastId}
                                        historicalMsg={props.historicalMsg}
                                        updatePageNumber={(page)=>setPageNumberInput(page)}
                                        updateInitialPage={(page)=>setInitialPage(page)}
                                        updateHighlights={(id)=>setHighlightedAreas(id)}
                                        updateClickedMsgID={(id)=>setClickedMsgId(id)}
                                        updateOpenPreview={(status)=>setOpenPreview(status)}
                                        selectedId={selectedId}
                                        currentFileName={currentFileName}
                                    />
                                }
                            </div>
                            <div className={`${task!==3 && 'd-none'}`}>
                                {selectedId && props.project && task === 3 &&
                                    <RegChecker
                                        project={props.project}
                                        initMessage={props.initMessage}
                                        lastId={props.lastId}
                                        historicalMsg={props.historicalMsg}
                                        updateFileUrl={(url)=>setFileUrl(url)}
                                        updateRecordId={(id)=>setSelectedId(id)}
                                        updateFileName={(name)=>setCurrentFileName(name)}
                                        updatePageNumber={(page)=>setPageNumberInput(page)}
                                        updateInitialPage={(page)=>setInitialPage(page)}
                                        updateHighlights={(id)=>setHighlightedAreas(id)}
                                        updateClickedMsgID={(id)=>setClickedMsgId(id)}
                                        updateOpenPreview={(status)=>setOpenPreview(status)}
                                        updateFileType={(type)=>setFileType(type)}
                                        selectedId={selectedId}
                                        currentFileName={currentFileName}
                                        base64img={base64img}
                                        updateBase64img={()=>setBase64img(null)}
                                        fileType={fileType}
                                        openCheckHistory={openCheckHistory}
                                        setOpenCheckHistory={(status)=>setOpenCheckHistory(status)}
                                        toggleShowMasterlist={(status)=>setShowMasterlist(status)}
                                        showMasterlist={showMasterlist}
                                        masterListComments={masterListComments}
                                        updateMasterListComments={(list)=>setMasterListComments(list)}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </>
    )

}

export default SearchResult;