import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Card, CardBody, CardTitle, CardFooter, CardText, CardSubtitle, CardHeader } from 'reactstrap';
import Button from './Button';  
import Uploader from './Uploader';

const PricingModal = (props) => { 

    return(
        <Modal isOpen={props.isOpen} className='pricingModal' toggle={props.toggle} size='xl'>
            <ModalHeader className='title-bold border-0' toggle={props.toggle}>Upload more documents to your project library</ModalHeader>
            <ModalBody>
                {props.project && props.project.id &&
                    <Uploader
                        userInfo = {props.userInfo}
                        className="mx-auto"
                        uploadUrl="/geo/record/upload"
                        allDocs={true}
                        projectId={props.project.id}
                        onComplete={() => props.onComplete()}
                        extraDetails={props.extraDetails}
                        project={props.project}
                        initialUploadType={props.initialUploadType}
                    />
                }
            </ModalBody>
        </Modal>
    )

 }; export default PricingModal;