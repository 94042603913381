import { useState } from "react";
import { logEvent } from "../shared/amplitude";
import Button from "../shared/Button";
import httpClientPy from "../../utils/httpClientPy.js";

const GeoRecordDownloadButton = (props) => {
  const [loading, setLoading] = useState(false);

  const handleDownload = (id, format, project_id) => {
    setLoading(true);

    httpClientPy.post('/geo/records/export', {
      geo_record_ids: [id],
      project_id: project_id,
      export_type: 0 
    }).then((response) => {
      if (response.data.ags_str) {
        
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(response.data.ags_str));

        element.setAttribute('download', `${response.data.file_name}.ags`)

        element.click();
      }
    }).catch((err) => {
      console.log(err)
    }).finally(() => {
      setLoading(false);
      logEvent("download-geological-records", { format: "ags" });
    })
  };

  return (
    <>
      <Button
        onClick={() => {
          handleDownload(props.geoRecord.id, "json", props.projectId);
        }}
        label={props.label}
        className={props.className}
        disabled={loading || props.disabled}
      ></Button>
    </>
  );
};

export default GeoRecordDownloadButton;
