import React from "react";
import { NavMenu } from "./NavMenu";
import authenticationHandler from "../../utils/authenticationHandler";
import AuthenticatedNavMenu from "./AuthenticatedNavMenu";
const Header = () => {
  return authenticationHandler.isAuthenticated() === true ? (
    <AuthenticatedNavMenu />
  ) : (
    <NavMenu />
  );
};

export default Header;
