import React, { useState } from "react";
import {
  Collapse,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import authenticationHandler from "../../utils/authenticationHandler";

const AuthenticatedNavMenu = () => {
  const navigate = useNavigate();

  const [collapsed, setCollapsed] = useState(true);
  
  const handleLogout = () => {
    authenticationHandler.logout();
    navigate("/log-in");
  };

  const openHelp = () => {
    window.open("https://civils-corp.notion.site/Civils-ai-Documentation-accf65b2896f4ab89e7b977e8e1626ca", "_blank");
  };

  return (
    <header>
      <Navbar
        className="navbar navbar-dark navBarTop bg-dark navbar-expand-sm navbar-toggleable-sm ng-white box-shadow"
        light
      >
        <NavbarBrand href="https://civils.ai/" className="logo">
          <img src="assets/logo_white.png" className="mediumImage" alt="Civils.ai" />
          <span className="navBarTitle">Civils.ai</span>
        </NavbarBrand>
        <NavbarToggler
          onClick={() => setCollapsed(!collapsed)}
          className="mr-2"
        />
        <Collapse
          className="d-sm-inline-flex flex-sm-row-reverse"
          isOpen={!collapsed}
          navbar
        >
          <ul className="navbar-nav flex-grow">
            <NavItem>
              <button
                type="button"
                className="btn btn-link nav-link"
                onClick={()=>openHelp()}
              >
                Help
              </button>
            </NavItem>
            <NavItem>
              <button
                type="button"
                className="btn btn-link nav-link"
                onClick={handleLogout}
              >
                Logout
              </button>
            </NavItem>
          </ul>
        </Collapse>
      </Navbar>
    </header>
  );
};

export default AuthenticatedNavMenu;
