import { Outlet } from "react-router-dom";
import React, { Component } from "react";
import { Container } from "reactstrap";
import Header from "../Header";

export class DefaultLayout extends Component {
  render() {
    return (
      <div>
        <Header />
        <Container className="mt-5">
          <Outlet />
        </Container>
      </div>
    );
  }
}
