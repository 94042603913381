import React, { useState, useRef, useEffect } from 'react';
import PdfViewer from "../../project/detailComponents/PdfViewer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, CardBody, CardHeader, Modal} from 'reactstrap';
import { faTimes, faPlus, faMinus, faExpand, faArrowRight, faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import Select from 'react-select';
import httpClientPy from '../../../utils/httpClientPy';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import SaveConfirmModal from '../../shared/layouts/SaveConfirmModal';
import { index } from 'd3';

const EditCadForm = (props) => {

    const [openPreview, setOpenPreview] = useState(true);
    const [fileUrl, setFileUrl] = useState(null);
    const [pageNumberInput, setPageNumberInput] = useState(1);
    const [totalPages, setTotalPages] = useState(null);
    const [initialPage, setInitialPage] = useState(1);
    const [scale, setScale] = useState(1);
    const [drawingInfo, setDrawingInfo] = useState(null);
    const [categories, setCategories] = useState([])
    const [isConfirming, setIsConfirming] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    const [loading, setLoading] = useState(false);
    const pdfview = useRef(null);
    const pageNavigationPluginInstance = pageNavigationPlugin();
    const zoomPluginInstance = zoomPlugin();

    const { zoomTo } = zoomPluginInstance;

    const highlightedAreas = [];

    const changePage = (offset) => {
        if(pageNumberInput+offset < 1 || pageNumberInput+offset > totalPages) return;
        setPageNumberInput(parseInt(pageNumberInput)+offset); 
    }

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setFileUrl(props.fileUrl)
        }, 500)

        httpClientPy.get(`/geo/record/cad/info?record_id=${props.id}`)
        .then((response) => {
            setDrawingInfo(response.data.info);
            let categories = [];
            response.data.info.forEach((row) => {
                if(!categories.includes(row.category)) {
                    categories.push(row.category);
                }
            });
            setCategories(categories.map((category) => ({value: category, label: category})));
        })
        .catch((error) => {
            console.log(error);
        });
        setLoading(false);
    }, []);

    useEffect(() => {
        if(scale) {
            zoomTo(scale);
        }
    }, [scale]);

    const updateTestValue = (event, id, field) => {

        event.target.style.height = `${event.target.scrollHeight}px`;

        const newDrawingInfo = drawingInfo.map((row) => {
            if(row.id === id) {
                return {...row, [field]: event.target.value}
            }
            return row;
        });
        
        // check if event.target.value is not in categories
        if(!categories.map((category) => category.value).includes(event.target.value) && field === 'category') {
            setCategories([...categories, {value: event.target.value, label: event.target.value}]);
        }

        setDrawingInfo(newDrawingInfo);
    }

    const removeInfo = (id) => {
        const newDrawingInfo = drawingInfo.filter((row) => row.id !== id);
        setDrawingInfo(newDrawingInfo);
    }

    const addNewDimRow = () => {
        setDrawingInfo([...drawingInfo, {id: Math.max(...drawingInfo.map((row) => row.id)) + 1, category: 'dimensions', key: '', value: ''}]);
    }

    const addNewMetadataRow = () => {
        setDrawingInfo([...drawingInfo, {id: Math.max(...drawingInfo.map((row) => row.id)) + 1, category: 'metadata', key: '', value: ''}]);
    }

    const triggerConfirmModal = () => {
        setIsConfirming(true);
    }

    const toggleSaveConfirmModal = () => {
        setIsConfirming(!isConfirming);
    }

    const adjustAllTextareasHeight = () => {
        const textareas = document.querySelectorAll('.CADtextarea');
        textareas.forEach((textarea) => {
            textarea.style.height = 'auto';
            textarea.style.height = textarea.scrollHeight+3 + 'px';
        });
    };

    useEffect(() => {
        adjustAllTextareasHeight();
    }, [drawingInfo]);

    const submitForm = () => {
        setIsEditing(false)
        httpClientPy.post(`/geo/record/cad/info/update`, 
            {
                info: drawingInfo,
                record_id: props.id
            })
        .then((response) => {
            setDrawingInfo(response.data.info);
            let categories = [];
            response.data.info.forEach((row) => {
                if(!categories.includes(row.category)) {
                    categories.push(row.category);
                }
            });
            setCategories(categories.map((category) => ({value: category, label: category})));
            setLoading(false);
        })
        .catch((error) => {
            console.log(error);
        });
    }

    return (
        <>
            <Card 
                className={`chat-card chat-card-open shadow`}
                style={{ height: '100vh' }}
            >
                <CardBody className={`py-0 bg-white w-100`}>
                    <div className='row h-100'>

                    <div id='pdfview' ref={pdfview} className={`col-6 border-end ${!openPreview && 'd-none'}`}>
                        <PdfViewer  fileUrl={fileUrl}
                                    updateTotalPages={(pages)=>setTotalPages(parseInt(pages))}
                                    updatePageNumber={(pageNumber)=>setPageNumberInput(parseInt(pageNumber))}
                                    initialPage={initialPage}
                                    setInitialPage={()=>setInitialPage(null)}
                                    pageNavigationPluginInstance={pageNavigationPluginInstance}
                                    zoomPluginInstance={zoomPluginInstance}
                                    highlightArea = {highlightedAreas} 
                                    task={0}/>
                    </div>
                    <div className={`col-6 px-2 pb-2 bg-light vh-100-true overflow-auto`}>
                        <div className="container py-2 d-flex justify-content-between align-items-center">
                            <div>
                                <h5 className='title-bold d-inline py-0'>Extracted CAD Drawing Data</h5>
                            </div>
                            <div className='d-flex'>
                                {isEditing ? 
                                     <a className="btn text-dark border" type="button" onClick={() => setIsEditing(false)}>
                                     <span>Cancel</span>
                                 </a>
                                 :                           
                                <a className="btn text-dark border" type="button" onClick={() => setIsEditing(true)}>
                                    <span>Edit</span>
                                </a>
                                }
                                <a className="btn btn-link text-dark ms-2" type="button" onClick={() => props.toggleModal()}>
                                    <FontAwesomeIcon icon={faTimes} />
                                </a>
                            </div>

                        </div>
                        <div className={`py-0 w-100`}>
                                {drawingInfo &&
                                <>
                                    <div className="row mx-1 py-2">
                                    {isEditing ? 
                                        <>
                                        <table className="table no-bordered">
                                            <thead>
                                            <tr>
                                                <th className="col-3">Metadata</th>
                                                <th className="col-8">Information</th>
                                                <th className="col-1"></th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                                {drawingInfo.map((row, index) => (
                                                    <>
                                                    {row.category === 'metadata' &&
                                                        <tr key={row.id}>
                                                            <td>
                                                                <div className="input-group">
                                                                <input
                                                                    className="form-control"
                                                                    required
                                                                    disabled={row.key === 'Drawing Title' || row.key === 'Drawing Number' || row.key === 'Project Title'}
                                                                    value={row.key}
                                                                    onChange={(event) => updateTestValue({target: {value: event.target.value}}, row.id, 'key')}
                                                                />
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="input-group">
                                                                    <input
                                                                        className="form-control"
                                                                        required
                                                                        value={row.value}
                                                                        onChange={(event) => updateTestValue(event, row.id, 'value')}
                                                                    />
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {row.key !== 'Drawing Title' && row.key !== 'Drawing Number' && row.key !== 'Project Title' &&
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-danger"
                                                                        onClick={() => removeInfo(row.id)}
                                                                    >
                                                                    <FontAwesomeIcon icon={faMinus} />
                                                                    </button>
                                                                }
                                                            </td>
                                                        </tr>
                                                    }
                                                    </>
                                                ))}
                                            </tbody>
                                        </table>
                                        {!loading &&
                                            <div className="bg-none mx-2  text-center">
                                                <button type="button" className="btn btn-success" onClick={addNewMetadataRow}>
                                                    Add Metadata
                                                </button>
                                            </div>
                                        }
                                        </>
                                        :
                                        <div className='px-0'>
                                            <table className="table">
                                            <tbody>
                                            {drawingInfo.map((row, index) => (
                                                <>
                                                {row.category === 'metadata' &&
                                                    <tr>
                                                        <th>{row.key}</th>
                                                        <td>{row.value}</td>
                                                    </tr>
                                                }
                                                </>
                                            ))}
                                            </tbody>
                                            </table>
                                        </div>
                                        }
                                        <div className="py-3">
                                            <h6 className='title-bold d-inline py-0 mb-2'>Description</h6>
                                            <textarea   
                                                className="form-control CADtextarea bg-white"
                                                required
                                                disabled
                                                value={drawingInfo.filter((row) => row.category === 'description')[0].value}
                                                onChange={(event) => updateTestValue(event, drawingInfo.filter((row) => row.category === 'description')[0].id, 'value')}
                                                cols="10"
                                            />
                                        </div>
                                    </div>
                                    <div className="row mx-1 py-2">
                                        <div className="col-6">
                                            <h6 className='title-bold d-inline py-0 mb-2'>Notes</h6>
                                            <textarea
                                                className="form-control CADtextarea bg-white"
                                                required
                                                disabled={!isEditing}
                                                value={drawingInfo.filter((row) => row.category === 'notes')[0].value}
                                                onChange={(event) => updateTestValue(event, drawingInfo.filter((row) => row.category === 'notes')[0].id, 'value')}
                                                cols="10"
                                                rows="5"
                                            />
                                        </div>
                                        <div className="col-6">
                                            <h6 className='title-bold d-inline py-0 mb-2'>Risks</h6>
                                            <textarea
                                                className="form-control CADtextarea bg-white"
                                                required
                                                disabled={!isEditing}
                                                value={drawingInfo.filter((row) => row.category === 'risks')[0].value}
                                                onChange={(event) => updateTestValue(event, drawingInfo.filter((row) => row.category === 'risks')[0].id, 'value')}
                                                cols="10"
                                                rows="5"
                                            />
                                        </div>
                                    </div>
                                    <div className="row mx-1 mt-2">
                                        <h6 className='title-bold d-inline mt-2 mb-0'>Dimensions</h6>
                                        <table className="table">
                                            <tbody>
                                                {drawingInfo.map((row) => (
                                                    <>
                                                    {row.category === 'dimensions' &&
                                                        <tr key={row.id}>
                                                            <td className="col">
                                                                <div className="input-group">
                                                                <input
                                                                    className="form-control  bg-white"
                                                                    required
                                                                    disabled={!isEditing}
                                                                    value={row.key}
                                                                    onChange={(event) => updateTestValue(event, row.id, 'key')}
                                                                />
                                                                </div>
                                                            </td>
                                                            <td className="col-7">
                                                                <div className="input-group">
                                                                <input
                                                                    type="text"
                                                                    className="form-control  bg-white"
                                                                    disabled={!isEditing}
                                                                    required
                                                                    value={row.value}
                                                                    onChange={(event) => updateTestValue(event, row.id, 'value')}
                                                                />
                                                                </div>
                                                            </td>
                                                            {row.key !== 'Drawing Title' && isEditing &&
                                                                <td className="col-1">
                                                                    <button
                                                                    type="button"
                                                                    className="btn btn-danger"
                                                                    onClick={() => removeInfo(row.id)}
                                                                    >
                                                                    Remove
                                                                    </button>
                                                                </td>
                                                            }
                                                        </tr>
                                                    }
                                                    </>
                                                ))}
                                            </tbody>
                                        </table>
                                        {!loading && isEditing &&
                                            <div className="bg-none mx-2  text-center">
                                                <button type="button" className="btn btn-success" onClick={addNewDimRow}>
                                                    Add Dimension
                                                </button>
                                            </div>
                                        }
                                    </div>
                                </>
                                }
                        </div>
                        {loading ?
                                <div className="spinner-container">
                                    <div className="spinner-border text-primary" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            :
                            isEditing &&
                                <div className='row bg-light mx-1 py-2 '>
                                    <button className="btn btn-primary border-0 btn-lg" type="button" onClick={() => triggerConfirmModal()}>
                                        Save Changes
                                    </button> 
                                </div>
                            }
                        
                    </div>
                    </div>
                </CardBody>
            </Card>
            <Modal id="saveConfirm" size="sm" isOpen={isConfirming} toggle={toggleSaveConfirmModal} centered={true}>
                <SaveConfirmModal 
                    displayName={`Save Updated CAD Info`} 
                    text = "Are you sure you want to save the updates? This action cannot be undone."
                    toggleModal={toggleSaveConfirmModal}
                    saveConfirmed = {(status) => {submitForm()}}
                />
            </Modal>
        </>
    )
}

export default EditCadForm;