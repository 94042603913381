import {init, setDeviceId, setUserId, identify, Identify, track} from '@amplitude/analytics-browser';

export const initAmplitude = () => {
  if (process.env.REACT_APP_ENVIRONMENT === "production") {
    init(process.env.AMPLITUDE, null, {minIdLength: 1});
  }
};

export const setDeviceID = (installationToken) => {
  if (process.env.REACT_APP_ENVIRONMENT === "production") {
    setDeviceId(installationToken);
  }
};

export const setUserID = (userId) => {
  if (process.env.REACT_APP_ENVIRONMENT === "production") {
    setUserId(userId);
  }
};

export const setUserProperties = (properties) => {
  if (process.env.REACT_APP_ENVIRONMENT === "production") {
    const identifyObj = new Identify();
    identify(identifyObj);
  }
};

export const logEvent = (eventType, eventProperties) => {
  if (process.env.REACT_APP_ENVIRONMENT === "production") {
    track(eventType, eventProperties);
  }
};
