import Loader from "../../shared/Loader";
import DeleteConfirmation from "../../shared/DeleteConfirmation";
import moment, { max } from "moment";
import { useEffect, useState, useRef } from "react";
import GeoRecordDetailsButton from "../../geoRecords/geoRecordDetailsButton";

import Button from "../../shared/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { Modal, Card, CardBody, Alert, Table, Input, FormGroup, Tooltip, CardHeader } from "reactstrap";
import { faFileExcel, faLocationDot, faMapPin, faFilePdf, faFileCode, faEye, faMapLocation, 
        faDatabase, faList, faClock, faPenToSquare, faLock, faLockOpen, faLocationCrosshairs, faTriangleExclamation, faCloudArrowUp, faTrash, faSquarePollHorizontal, faTag, faBook, faMap, faMagnifyingGlass, faPieChart, faMagnifyingGlassChart, 
        faAngleLeft,
        faAngleRight} from "@fortawesome/free-solid-svg-icons";
import DataTable from 'react-data-table-component';
import httpClientPy from "../../../utils/httpClientPy";
import { logEvent } from "../../shared/Mixpanel";

library.add(faFileExcel, faMapPin, faLocationDot, faFilePdf, faMapLocation, faFileCode, faEye, faDatabase,faTriangleExclamation, faList, faClock, faCloudArrowUp);

const SearcHistory = (props) => {

    const [history, setHistory] = useState([]);
    const [totalHistoryPages, setTotalHistoryPages] = useState(0);
    const [loading, setLoading] = useState(false);
    const [currentPageNumber, setCurrentPageNumber] = useState(1);

    const paginate = (pageNumber) => setCurrentPageNumber(pageNumber);

    const searchHistoryRetrieval = async () => {
        setLoading(true);
        try {
            let response = await httpClientPy.get(`/search/history?project_id=${props.project_id}&limit=${10}&offset=${(currentPageNumber - 1) * 10}`);
            let history = response.data.messages;
            let totalHistoryPages = response.data.total_pages;
            logEvent('Search History');
            setHistory(history);
            setTotalHistoryPages(totalHistoryPages);
            setLoading(false);
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        searchHistoryRetrieval();
    }, [currentPageNumber]);

    const handleRowClick = (id) => {
        props.handleSearchHistoryClicked(id);
        setLoading(true)
    }

    return (
        <div>
            <div className="">
            <Card className="h-100 border-0 ">
            <CardHeader>
                <h3 className='title-bold pb-0 mb-0 text-center'>Search History</h3>
            </CardHeader>
            {loading ?
                <div className="my-5">
                    <Loader loading={loading} />
                </div>
                :
                <CardBody>
                        {history && history.length > 0 && (
                            <>    
                                <table class="table table table-borderless">
                                    <thead>
                                        <tr>
                                        <td className="text-center"><b>Search Query</b></td>
                                        <td className="text-center"><b>Date Searched</b></td>
                                        <td className="text-center"><b>Results Found</b></td>
                                        <td className="text-center"><b>Reports Found</b></td>
                                        <td className="text-center"><b>Reg Found</b></td>
                                        <td className="text-center"><b>CAD Found</b></td>
                                        </tr>
                                    </thead>
                                    <tbody className='small'>
                                        {history.map((row, index) => (
                                            <tr key={index} className='bg-white'>
                                                <td className="text-center" onClick={() => handleRowClick(row.id)} style={{cursor: 'pointer'}}>
                                                    {row.message}
                                                </td>
                                                <td className="text-center" onClick={() => handleRowClick(row.id)} style={{cursor: 'pointer'}}>
                                                    {row.created_at}
                                                </td>
                                                <td className="text-center" onClick={() => handleRowClick(row.id)} style={{cursor: 'pointer'}}>
                                                    {row.num_of_ref_files > 0 ? (
                                                        <span className="badge rounded-pill green">{row.num_of_ref_files}</span>
                                                    ) : (
                                                        <span className="badge rounded-pill red">{row.num_of_ref_files}</span>
                                                    )}
                                                </td>
                                                <td className="text-center" onClick={() => handleRowClick(row.id)} style={{cursor: 'pointer'}}>
                                                    {row.is_ref_design_doc === true ? (
                                                        <span className="badge rounded-pill green">{"yes"}</span>
                                                    ) : (
                                                        <span className="badge rounded-pill red">{"no"}</span>
                                                    )}
                                                </td>
                                                <td className="text-center" onClick={() => handleRowClick(row.id)} style={{cursor: 'pointer'}}>
                                                    {row.is_ref_reg === true ? (
                                                        <span className="badge rounded-pill green">{"yes"}</span>
                                                    ) : (
                                                        <span className="badge rounded-pill red">{"no"}</span>
                                                    )}
                                                </td>
                                                <td className="text-center" onClick={() => handleRowClick(row.id)} style={{cursor: 'pointer'}}>
                                                    {row.is_ref_cad === true ? (
                                                        <span className="badge rounded-pill green">{"yes"}</span>
                                                    ) : (
                                                        <span className="badge rounded-pill red">{"no"}</span>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <div className="d-flex justify-content-end">
                                    {currentPageNumber > 1 && (
                                        <button className="btn btn-primary btn-sm me-2" onClick={() => paginate(currentPageNumber - 1)}>
                                            <FontAwesomeIcon icon={faAngleLeft} />
                                        </button>
                                    )}
                                    {currentPageNumber} / {totalHistoryPages}
                                    {currentPageNumber < totalHistoryPages && (
                                        <button className="btn btn-primary btn-sm ms-2" onClick={() => paginate(currentPageNumber + 1)}>
                                            <FontAwesomeIcon icon={faAngleRight} />
                                        </button>
                                    )}
                                </div>
                            </>
                        )}
                        {(!history || history.length === 0) && !loading && (
                            <>
                            <h6 className="text-muted text-center pt-3">
                                No search history found.
                            </h6>
                            </>
                        )}
                    </CardBody>
                }
            </Card>
            </div>
        </div>
    );
};

export default SearcHistory;
