import { useState } from "react";
import { logEvent } from "../../shared/amplitude";
import Uploader from "../../shared/Uploader";
import { Modal, ModalBody, ModalHeader, ModalFooter, Table, Dropdown } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCopy, faCheck, faArrowsRotate, faLock, faBookOpen} from "@fortawesome/free-solid-svg-icons";
import AgsUploaderVerify from "./AgsUploaderVerify";

library.add(faCopy, faCheck, faBookOpen);

const PdfDigitiser = (props) => {
  const [modalDigitiseBorehole, setModalDigitiseBorehole] = useState(true);
  const [agsInfo, setAgsInfo] = useState([]);

  const toggleDigitiseBorehole = () => {
    if(modalDigitiseBorehole){
      props.toggleModal(!modalDigitiseBorehole)
    }
    setModalDigitiseBorehole(!modalDigitiseBorehole);
  }

  const uploadComplete = () => {
    logEvent("upload-geological-records");
  };

  const updateAgsInfo = (data) => {
    setAgsInfo(data);
  };

  const onToggleAgsVerifier = () => {
    props.onSearchGeoRecord(agsInfo[0].lat, agsInfo[0].lon, agsInfo[0].geo_record_id, 'search')
    setAgsInfo([]);
  }

  return (
    <>
    <Modal id="digitiseBoreholeModal" size="l" isOpen={modalDigitiseBorehole} toggle={toggleDigitiseBorehole} centered={true} keyboard={false}>
      <ModalHeader className="d-flex pb-0 align-items-center title-civils border border-top border-bottom-0 digitiserModal" toggle={toggleDigitiseBorehole}>                        
        <h4 className="d-inline-flex text-title align-items-center mt-2 mb-0">
        <img
            src="/assets/george_icon.svg"
            alt="Digitise your geological records automatically"
            className="d-inline-block georgeIcon primary"
          />
            <span>&nbsp;Automatic borehole digitiser</span></h4>
      </ModalHeader>
      <ModalBody className="border border-top-0 border-bottom-0">
          {/* <div className="mb-2 px-2">Extract location, geological descriptions, strata thicknesses and test data from your borehole log PDFs</div> */}
          <div className="px-2  py-3">
            <Uploader
              uploadUrl="/geo/record/upload"
              updateAgsInfo={(data) => updateAgsInfo(data)}
              onComplete={uploadComplete}
            />
          </div>
          <div className="mt-3 px-2 px-3">
            <FontAwesomeIcon className="icon-sideMenu" icon={faLock}/>
            &nbsp; All your records uploaded are kept private and confidential
          </div>
          <div className="mt-3 px-2 px-3">
            <FontAwesomeIcon className="icon-sideMenu" icon={faBookOpen}/>
            &nbsp; Extract geology layers / tests / coords / water / ground level
          </div>
          <div className="mt-3 px-2 px-3">
            <FontAwesomeIcon className="icon-sideMenu" icon={faArrowsRotate}/>
            &nbsp; Convert your PDF files into AGS and Excel automatically
          </div>
          {/* <div className="mt-3 px-2 text-secondary px-3">
            <FontAwesomeIcon className="icon-sideMenu" icon={faWandMagicSparkles}/>
            &nbsp; We achieve approximately 95% accuracy with records produced after 1970.
          </div> */}
      </ModalBody>
      <ModalFooter className="border border-top-0"></ModalFooter>
    </Modal>
    { agsInfo.length > 0 && 
      <AgsUploaderVerify
        agsInfo={agsInfo}
        toggleModal={()=>onToggleAgsVerifier()}>
      </AgsUploaderVerify>
    }
    </>
  );
};

export default PdfDigitiser;
