import { useState, useEffect } from 'react';
import { Card, CardBody, CardTitle,  Modal, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Spinner } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import httpClientPy from '../../utils/httpClientPy';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faBook, faCancel, faClock, faComment, faDrawPolygon, faEdit, faEllipsis, faGear, faLock, faMessage, faPenToSquare, faPlus, faTag, faTrash, faUpload } from "@fortawesome/free-solid-svg-icons";
import Select, { components } from 'react-select'
import Loader from '../shared/Loader';
import LoadingCivils from '../shared/LoadingCivils';
import PricingModal from '../shared/payments/Pricing';
import SideBar from '../project/SideBar';
import NewLibrary from './NewLibrary';
import CustomSelectCategory from './CustomSelectCategory';
import LibrarySettings from './LibrarySettings';
import Button from '../shared/Button';
import LibraryDeleteConfirm from './LibraryDeleteConfirm';
import EditLibrary from './EditLibrary';
import LibraryDetailsModal from './LibraryDetailsModal';
import LibraryPromptMngmt from './LibraryPromptMngmt';

const LibraryList = (props) => {

    // variable to handle if the api has returned error
    const [error, setError] = useState(false);
    // variable to store the error message
    const [errorMessage, setErrorMessage] = useState(null);
    // variable to handle loading
    const [loading, setLoading] = useState(true);
    const [loadingUser, setLoadingUser] = useState(true);
    const [userInfo, setUserInfo] = useState(null);
    const [admin, setAdmin] = useState(false);
    const [modalPricingOpen, setModalPricingOpen] = useState(false);
    const [toggleNewLibraryModal, setToggleNewLibraryModal] = useState(false);
    const [toggleSettingsModal, setToggleSettingsModal] = useState(false);
    const [toggleDeleteLibraryModal, setToggleDeleteLibraryModal] = useState(false);
    const [toggleEditLibraryModal, setToggleEditLibraryModal] = useState(false);
    const [toggleLibraryDetailsModal, setToggleLibraryDetailsModal] = useState(false);
    const [togglePromptMngmtModal, setTogglePromptMngmtModal] = useState(false);

    const [libraries, setLibraries] = useState([]);
    const [librariesNames, setLibrariesNames] = useState([]);
    const [categories, setCategories] = useState([]);
    const [namesFilter, setNamesFilter] = useState([]);
    const [categoriesFilter, setCategoriesFilter] = useState([]);

    // this part of states is to handle update and detele
    const [selectedLibrary, setSelectedLibrary] = useState(null);
    const [libraryAdded, setLibraryAdded] = useState(false);
    const [libraryDeleted, setLibraryDeleted] = useState(false);

    const [editingProjects, setEditingProjects] = useState(false);


    useEffect(() => {
        fetchInitialData();
    }, []);

    useEffect(() => {

        if (libraryAdded) {
         
            fetchLibrariesMetaData();
            return setLibraryAdded(false);
        }

        if (libraryDeleted) {
            fetchLibrariesMetaData();
            return setLibraryDeleted(false);
        }

        fetchLibrariesMetaData();

    }, [libraryAdded, libraryDeleted]);

    useEffect(() => {
        fetchLibrariesData(namesFilter, categoriesFilter);
    }, [namesFilter, categoriesFilter, librariesNames]);

    const togglePricingModal = () => setModalPricingOpen(!modalPricingOpen);

    const toggleNewLibrary = () => {
        setToggleNewLibraryModal(!toggleNewLibraryModal);
    }

    const toggleSettings = () => {
        setToggleSettingsModal(!toggleSettingsModal);
    }

    const toggleDeleteLibrary = (library_id) => {
        setSelectedLibrary(library_id);
        setToggleDeleteLibraryModal(!toggleDeleteLibraryModal);
    }

    const toggleEditLibrary = (library_id) => {
        setSelectedLibrary(library_id);
        setToggleEditLibraryModal(!toggleEditLibraryModal);
    }

    const togglePromptMngmt = (library_id) => {
        setSelectedLibrary(library_id);
        setTogglePromptMngmtModal(!togglePromptMngmtModal);
    }

    const toggleLibraryDetails = (library_id) => {
        let ready_count = libraries.filter(library => library.id === library_id)[0].ready_count;
        if (ready_count < 1) {
            return;
        }
        setSelectedLibrary(library_id);
        setToggleLibraryDetailsModal(!toggleLibraryDetailsModal);
    }

    const fetchInitialData = async () => {
        setLoading(true);
        setError(false);
        setErrorMessage(null);
        
        try {
            // Make both API calls in parallel
            const [userResponse] = await Promise.all([
                httpClientPy.get('/user')
            ]);
            
            // Handle user data
            const userData = userResponse.data;
            const processedUserData = {
                ...userData,
                allow_digitise: userData.is_enterprise 
                    ? userData.enteprise_is_digitiser 
                    : (userData.subscription_details?.is_digitise || false),
                allow_civilsgpt: userData.is_enterprise 
                    ? userData.enterprise_is_civilsgpt 
                    : (userData.subscription_details?.is_civilsgpt || false)
            };
            
            setUserInfo(processedUserData);
            setAdmin(userData.enterprise_admin);
        } catch (error) {
            setError(true);
            setErrorMessage(error.response?.data?.detail || 'An error occurred');
        } finally {
            setLoading(false);
            setLoadingUser(false);
        }
    };

    const fetchLibrariesMetaData = async () => {
        setLoading(true);
        setError(false);
        setErrorMessage(null);
        
        try {
            const response = await httpClientPy.get('/library/meta');
            let libraries_metadata = response.data.libraries_metadata;
            let categories_metadata = response.data.categories_metadata;
            let namesForSelect = libraries_metadata.map((meta) => {
                return {
                    value: meta.name,
                    label: meta.name
                }
            })
            let categoriesForSelect = categories_metadata.map((meta) => {
                return {
                    value: meta.id,
                    label: meta.name,
                    color: meta.color
                }
            })
            setLibrariesNames(namesForSelect);
            setCategories(categoriesForSelect);
        } catch (error) {
            setError(true);
            setErrorMessage(error.response?.data?.detail || 'An error occurred');
        } finally {
            setLoading(false);
        }
    }

    const fetchLibrariesData = async (namesFilter, categoriesFilter) => {
        // setLoading(true);
        setError(false);
        setErrorMessage(null);
        
        try {
            const response = await httpClientPy.post('/library/index', {
                name_filters: namesFilter,
                category_filters: categoriesFilter
            });
            let libraries = response.data.libraries;
            setLibraries(libraries);
        } catch (error) {
            setError(true);
            setErrorMessage(error.response?.data?.detail || 'An error occurred');
        } finally {
            setLoading(false);
        }
    }

    const handleNameFilterSelected = (selected) => {
        let names = selected.map((name) => name.value);
        setNamesFilter(names);
    }

    const handleCategoryFilterSelected = (selected) => {
        let categories = selected.map((category) => category.value);
        setCategoriesFilter(categories);
    }
    
    return (
        <>
        <div className="d-flex">
            <div id="sidebarParent" className=' d-none d-md-block' style={{minWidth:'190px'}}>
                <SideBar 
                    page={'list'} 
                    loading={loading} 
                    admin={admin}
                    userInfo={userInfo}
                    togglePricingModal = {()=> togglePricingModal()}
                />
            </div> 
            {!loading && !loadingUser ? (
                <>  
                    <div  className="flex-fill px-3 pe-lg-0 py-3 row" style={{maxWidth:'100vw'}}>
                        {!error && libraries !== null ? (
                            <>
                                <div>
                                    <div className='container mt-2' > 
                                        <>
                                            {toggleLibraryDetailsModal ? (

                                                <LibraryDetailsModal handleClose = {() => setToggleLibraryDetailsModal(!toggleLibraryDetailsModal)} 
                                                                        selectedLibrary = {selectedLibrary} />

                                            ) : (
                                                <>
                                                    <h3 className='title-bold'>My Codes Library</h3>
                                                    <div className='row my-3'>
                                                    <div className='col-auto'>
                                                        <div className='d-flex flex-column justify-content-end h-100'>
                                                            <Select
                                                                className="basic-multi-select filter-record-select-sm text-center"
                                                                classNamePrefix="select"
                                                                isSearchable={true}
                                                                name="filterCode"
                                                                isMulti={true}
                                                                placeholder={"Filter Code Name"}
                                                                components={{ ValueContainer }}
                                                                closeMenuOnSelect={false}
                                                                isRtl={false}
                                                                options={librariesNames}
                                                                onChange={(selected) => handleNameFilterSelected(selected)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='col-3'>
                                                        <div className='d-flex flex-column justify-content-end h-100'>
                                                            <CustomSelectCategory 
                                                                className="basic-multi-select filter-record-select-w-pos text-center"
                                                                isMulti={true}
                                                                placeholder="Filter Category"
                                                                categoriesUpdated={toggleSettingsModal}
                                                                onChange={(selected) => handleCategoryFilterSelected(selected)}
                                                                categories={categories}
                                                            />
                                                        </div>
                                                    </div>
                                                        {admin && 
                                                        <>
                                                        <div className='col'></div>
                                                        <div className='col-auto text-center align-self-end border py-2 rounded'> {/* Added align-self-end here */}
                                                            <div><small>Admin controls</small></div>
                                                            <div className='row'>
                                                                <div className='col-auto'>
                                                                    <a className="btn btn-sm btn-outline-dark" onClick={() => toggleNewLibrary()}>
                                                                        <span><FontAwesomeIcon className="me-2" icon={faAdd}/></span>
                                                                        <span className="">New library</span>
                                                                    </a>
                                                                </div>
                                                                <div className='col-auto'>
                                                                    <a className="btn btn-sm btn-outline-dark" onClick={() => toggleSettings()}>
                                                                        <span><FontAwesomeIcon className="me-2" icon={faTag}/></span>
                                                                        <span>Edit categories</span>
                                                                    </a>
                                                                </div>
                                                                <div className='col-auto'>
                                                                    <a className="btn btn-sm btn-outline-dark" onClick={() => setEditingProjects(!editingProjects)}>
                                                                        <span>
                                                                            {editingProjects ? 
                                                                                <span><FontAwesomeIcon className="me-2" icon={faCancel}/> Cancel edit project</span> 
                                                                            : 
                                                                                <span><FontAwesomeIcon className="me-2" icon={faPenToSquare}/> Edit projects</span> 
                                                                            }
                                                                        </span>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        </>
                                                        }
                                                    </div>

                                                    {libraries.length !== 0 ? (
                                                    <>
                                                        <div id="datalist" className='row' style={{ zIndex: 0 }}>
                                                            {libraries.map((library) => (
                                                                <div className='col-sm-6 col-md-3 col-lg-3 pt-4 d-flex' key={library.id}>
                                                                    <Card
                                                                        className='project-list-card rounded flex-grow-1 d-flex flex-column overflow-hidden'
                                                                        style={{ minHeight: '100%' }}
                                                                    >
                                                                        <div className="top-left-square" style={{ backgroundColor: library.color }}></div>
                                                                        <CardBody
                                                                            className="project-list-card-img-background"
                                                                            onClick={() => toggleLibraryDetails(library.id)}
                                                                        >
                                                                            {/* Overlay for text readability */}
                                                                            <div className="project-list-card-content">
                                                                                <CardTitle tag="h6">
                                                                                    <span style={{ maxWidth: '100%' }} className="d-inline-block title-bold text-truncate pt-2">{library.name}</span>
                                                                                </CardTitle>

                                                                                <CardBody className="mt-2 p-0">
                                                                                    <div>
                                                                                        <small><FontAwesomeIcon icon={faClock}></FontAwesomeIcon></small>
                                                                                        <small>&nbsp; {library.updated_at}</small>
                                                                                    </div>
                                                                                    <div className="d-flex justify-content-between">
                                                                                        <div>
                                                                                            <small><FontAwesomeIcon icon={faComment}></FontAwesomeIcon></small>
                                                                                            <small>&nbsp; {library.total_prompts ? library.total_prompts : 0}</small>
                                                                                        </div>
                                                                                        {library.ready_count < 1 && !editingProjects &&
                                                                                            <div>
                                                                                                <Spinner size="sm" color="primary" />
                                                                                            </div>
                                                                                        }
                                                                                        {admin && editingProjects &&
                                                                                        <div>
                                                                                            <Button className="btn btn-sm btn-outline-primary me-2" 
                                                                                                    label={<FontAwesomeIcon icon={faEdit}/>}
                                                                                                    onClick={(e) => {
                                                                                                        e.stopPropagation();
                                                                                                        toggleEditLibrary(library.id)
                                                                                                    }} />
                                                                                            {library.ready_count > 0 &&
                                                                                                <Button className="btn btn-sm btn-outline-dark me-2" 
                                                                                                        label={<FontAwesomeIcon icon={faBook}/>}
                                                                                                        onClick={(e) => {
                                                                                                            e.stopPropagation();
                                                                                                            togglePromptMngmt(library.id)
                                                                                                        }} />
                                                                                            }
                                                                                            <Button className="btn btn-sm btn-outline-danger" 
                                                                                                label={<FontAwesomeIcon icon={faTrash}/>}
                                                                                                onClick={(e) => {
                                                                                                    e.stopPropagation();
                                                                                                    toggleDeleteLibrary(library.id)
                                                                                                }} />
                                                                                        </div>
                                                                                        }
                                                                                    </div>
                                                                                </CardBody>
                                                                            </div>
                                                                        </CardBody>
                                                                    </Card>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </>
                                                    ) : (
                                                        <div className='col-md-12 d-flex justify-content-center flex-column align-items-center' style={{ height: '70vh' }}>
                                                            <h6 className="text-muted text-center pt-3">
                                                                <span className="text-muted">No libraries found</span>
                                                                <br />
                                                                {userInfo.admin ? (
                                                                    <span className="text-muted">Click on the <b>create library</b> button to add new library</span>
                                                                ) : (
                                                                    <span className="text-muted">Please reach to your admin to add libraries</span>
                                                                )}
                                                            </h6>
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className='col-md-12 d-flex justify-content-center flex-column align-items-center' style={{ height: '70vh' }}>
                                    <img src="assets/build_img.png" alt="Civils.ai" />
                                    <h2><b>Oops!</b></h2>
                                    <h4>{errorMessage}</h4>
                                </div>
                            </>
                        )}

                    </div>
                </>
            ) : (
                <>
                    <div className="flex-fill px-3 pe-lg-0 py-4 row text-center d-flex justify-content-center align-items-center" style={{ maxWidth: '100vw', height: '70vh' }}>
                        <span>
                            <span
                                className="spinner-border spinner-border-md mx-auto"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            <span className="visually-hidden">Loading...</span>
                        </span>
                    </div>
                </>
            )}
        </div>

        <NewLibrary isOpen={toggleNewLibraryModal} 
                    toggle={()=>toggleNewLibrary()} 
                    categoriesUpdated = {toggleSettingsModal}
                    libraryAdded = {()=>setLibraryAdded(true)} />

        <EditLibrary isOpen={toggleEditLibraryModal}
                     toggle={()=>setToggleEditLibraryModal(!toggleEditLibraryModal)}
                     libraryUpdated = {()=>setLibraryAdded(true)}
                     categoriesUpdated = {toggleSettingsModal}
                     selectedLibrary={selectedLibrary} />

        <LibrarySettings isOpen={toggleSettingsModal} 
                         toggle={()=>toggleSettings()} />

        <LibraryDeleteConfirm isOpen={toggleDeleteLibraryModal} 
                              toggle={()=>setToggleDeleteLibraryModal(!toggleDeleteLibraryModal)}
                              item={"library"}
                              selectedLibrary={selectedLibrary}
                              libraryDeleted = {()=>setLibraryDeleted(true)} />

        <LibraryPromptMngmt isOpen={togglePromptMngmtModal}
                            toggle={()=>setTogglePromptMngmtModal(!togglePromptMngmtModal)}
                            selectedLibrary={selectedLibrary} />

        </>
    )
}

export default LibraryList;

const ValueContainer = ({ children, ...props }) => {
    if (children[0] !== null) {
      const length = children[0].length
      let tmpChildren = [...children];
      if(length >= 2){
        tmpChildren[0] = `${length} options selected`
      }
  
      return (
        <components.ValueContainer {...props}>{tmpChildren}</components.ValueContainer>
      );
    } else {
      return (
        <components.ValueContainer {...props}>{children}</components.ValueContainer>
      );
    }
  };