import httpClient from "../../utils/httpClient";
import { useState } from "react";
import { logEvent } from "../shared/amplitude";
import Button from "../shared/Button";

const GeoRecordDetailsButton = (props) => {

  return (
    <>
      <Button
        onClick={() => {props.onClick()}}
        label={props.label}
        className={props.className}
      ></Button>
    </>
  );
};

export default GeoRecordDetailsButton;
