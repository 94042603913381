import React, { useState, useEffect, useRef, useMemo } from "react";
import httpClientPy from "../../utils/httpClientPy";
import Button from "../shared/Button";
import ValidationSummary from "../shared/ValidationSummary";
import AdminUpdateRecord from "./AdminUpdateRecord";
import { Modal } from "reactstrap";
import AdminUploadRecord from "./AdminUploadRecord";
import { FileUploader } from "react-drag-drop-files";

const Admin = () => {

    const [loading, setLoading] = useState(false);

    const [errors, setErrors] = useState([]);
    const [recordID, setRecordID] = useState();
    const [filetype, setFiletype] = useState();
    const [groundlevel, setGroundLevel] = useState(0);
    const [waterlevel, setWaterLevel] = useState(null);
    const [files, setFiles] = useState([]);

    const [groundLevelGiven, setGroundLevelGiven] = useState(false);
    const [weatheringGiven, setWeatheringGiven] = useState(false);

    const [testsGiven, setTestsGiven] = useState(false);
    const [waterGiven, setWaterGiven] = useState(false);
    const [otherFilesUpload, setOtherFilesUpload] = useState(false);

    const [oustandingLogsUser, setOustandingLogsUser] = useState([]);
    const [oustandingLogsVa, setOustandingLogsVa] = useState([]);
    const [showUserList, setShowUserList] = useState(false);

    const [epsgList, setEpsgList] = useState([]);
    const [userInfo, setUserInfo] = useState([]);
    const [superAdmin, setSuperAdmin] = useState(false);

    const [startPage, setStartPage] = useState(true);
    const [detailsPage, setDetailsPage] = useState(false);
    const [descriptionPage, setDescriptionPage] = useState(false);
    const [testPage, setTestPage] = useState(false);
    const [completePage, setCompletePage] = useState(false);
    const [readable, setReadable] = useState(false);
    const [selectedUser, setSelectedUser] = useState();
    const [inUk, setInUk] = useState(true);
    const [inDegree, setInDegree] = useState(false);

    const [selectedDataItem, setSelectedDataItem] = useState([]);
    const link = useRef();
    const [useFeet, setUseFeet] = useState(false);
    const [useDepth, setUseDepth] = useState(false);
    const [displayEmailFail, setDisplayEmailFail] = useState(false);
    const [displayThicknessFail, setDisplayThicknessFail] = useState(false);
    const [displayProjectNameFail, setDisplayProjectNameFail] = useState(false);
    const [strataCompleteFail, setStrataCompleteFail] = useState(false);

    // and a function to update the rows.
    const [testRows, setTestRows] = useState([]);

    const [idCounter, setIdCounter] = useState(1);
    const [idTestCounter, setIdTestCounter] = useState(1);

    // and a function to update the rows.
    const [descriptionRows, setDescriptionRows] = useState([{ id: 1, weathering: '', classification: '', name: '', thickness: '' },]);

    const [recordDetails, setRecordDetails] = useState({projectName: "", easting: "", northing: ""});

    const [updateModal, setUpdateModal] = useState(false);
    const [uploadModal, setUploadModal] = useState(false);

    const [uploadDone, setUploadDone] = useState(false);
    const [step, setStep] = useState(0);
    const [scrGiven, setScrGiven] = useState(false);

    useEffect(() => {
        httpClientPy.get(`/user`).then((response) => {
            let userList = response.data
            setUserInfo(userList)
            setSuperAdmin(true)
        })
    }, [])


    // timer for copied table icon animation
    useEffect(() => {
        if((startPage || uploadDone) && userInfo.george_digitiser===true){
            setLoading(true)
            httpClientPy.get(`/geo/records/outstanding`).then((response) => {
                let boreholeList = response.data
                setOustandingLogsUser(boreholeList[0].user_uploaded)
                setOustandingLogsVa(boreholeList[0].va_uploaded)
                setShowUserList(true)
                setRecordID()
                // setReadable(true)
            })
            httpClientPy.get(`/epsg`).then((response) => {
                let epsgList = response.data
                setEpsgList(epsgList)
            })
            setLoading(false)
            if (uploadDone){
                setUploadDone(false)
            }
        }
    }, [startPage, uploadDone, userInfo]);

    const memoizedOptions = useMemo(() => {
        return epsgList.map((epsg) => (
          <option key={epsg.epsg} value={epsg.epsg}></option>
        ));
    }, [epsgList]);

    // This function adds a new row to the table.
    const addDescriptionRow = () => {

        const newDescriptionRow = {
            id: idCounter + 1,
            weathering: '',
            classification: '',
            name: '',
            thickness: '',
            };
        
        // Increment the ID counter.
        setIdCounter(idCounter + 1);
        
        // Use the setRows function to add the new row to the state.
        setDescriptionRows([...descriptionRows, newDescriptionRow]);
    };


    const addDescriptionRowFive = () => {
        // Create an array of 5 new description rows using a loop.
        const newDescriptionRows = Array.from({ length: 5 }, (_, index) => ({
          id: idCounter + index + 1,
          weathering: '',
          classification: '',
          name: '',
          thickness: '',
        }));
      
        // Increment the ID counter.
        setIdCounter(idCounter + 5);
      
        // Use the setRows function to add the new rows to the state.
        setDescriptionRows([...descriptionRows, ...newDescriptionRows]);
      };
      


    // This function removes a row from the table.
    const removeDescriptionRow = (id) => {
        // Use the setRows function to filter out the row with the specified ID.
        setDescriptionRows(descriptionRows.filter((row) => row.id !== id));
    };

    const addDescriptionRowAbove = (id) => {
        // Create a new row object with a unique ID and blank name and age values.
        const newDescriptionRow = {
        id: idCounter + 1,
        weathering: '',
        classification: '',
        name: '',
        thickness: '',
        };

        // Increment the ID counter.
        setIdCounter(idCounter + 1);

        // Use the setRows function to add the new row to the state.
        setDescriptionRows([
        ...descriptionRows.slice(0, descriptionRows.findIndex((row) => row.id === id)),
        newDescriptionRow,
        ...descriptionRows.slice(descriptionRows.findIndex((row) => row.id === id)),
        ]);
    };

    const addDescriptionRowBelow = (id) => {
        // Create a new row object with a unique ID and blank name and age values.
        const newDescriptionRow = {
        id: idCounter + 1,
        weathering: '',
        classification: '',
        name: '',
        thickness: '',
        };

        // Increment the ID counter.
        setIdCounter(idCounter + 1);

        // Use the setRows function to add the new row to the state.
        setDescriptionRows([
        ...descriptionRows.slice(0, descriptionRows.findIndex((row) => row.id === id) + 1),
        newDescriptionRow,
        ...descriptionRows.slice(descriptionRows.findIndex((row) => row.id === id) + 1),
        ]);
    };

    const updateDescriptionValue = (event, id, field) => {
        // Use the setRows function to create a new array of rows with the updated value.
        let inputValue = event.target.value

        if (field === 'name') {
            event.target.style.height = `${event.target.scrollHeight}px`;
        }

        setDescriptionRows(
            descriptionRows.map((row) => {
            if (row.id === id) {
            return {
                ...row,
                [field]: inputValue,
            };
            }
            return row;
        })
        );
    };

    const addTestRow = () => {

        // if 1 row or more get name of last row
        let name = 'N'
        let depth = ''
        let result = ''
        if(testRows.length>0){
            name = testRows[testRows.length-1].name
            depth = parseFloat(testRows[testRows.length-1].depth)+parseFloat(step)
            result = testRows[testRows.length-1].result
        }



        // Create a new row object with a unique ID and blank name and age values.
        const newTestRow = {
        id: idTestCounter,
        name: name,
        depth: depth,
        result: '',
        };
    
        // Increment the ID counter.
        setIdTestCounter(idTestCounter + 1);
    
        // Use the setRows function to add the new row to the state.
        setTestRows([...testRows, newTestRow]);
    };

    const addTestRowFive = () => {
        
        // if 1 row or more get name of last row
        let name = 'N'
        let depth = ''
        let result = ''
        if(testRows.length>0){
            name = testRows[testRows.length-1].name
            depth = parseFloat(testRows[testRows.length-1].depth)
            result = testRows[testRows.length-1].result
        }
        // Create an array of 5 new test rows using a loop.
        const newTestRows = Array.from({ length: 5 }, (_, index) => ({
        id: idTestCounter + index,
        name: name,
        depth: depth+parseFloat(step)*(index+1),
        result: '',
        }));

        // Increment the ID counter.
        setIdTestCounter(idTestCounter + 5);

        // Use the setRows function to add the new rows to the state.
        setTestRows([...testRows, ...newTestRows]);
    };

    
    // This function removes a row from the table.
    const removeTestRow = (id) => {
        // Use the setRows function to filter out the row with the specified ID.
        setTestRows(testRows.filter((row) => row.id !== id));
    };

    // This function updates the name or age value for a row.
    const updateTestValue = (event, id, field) => {
        // Use the setRows function to create a new array of rows with the updated value.
        let inputValue = event.target.value

        if(field==='name'){
            setTestRows(
                testRows.map((row) => {
                if (row.id === id) {
                return {
                    ...row,
                    [field]: inputValue,
                    scr: null,
                    tcr: null,
                };
                }
                return row;
            })
            );
        }
        else{
            setTestRows(
                testRows.map((row) => {
                if (row.id === id) {
                return {
                    ...row,
                    [field]: inputValue,
                };
                }
                return row;
            })
            );
        }
    };

    const handleRecordIDChange = (e) => {
        setRecordID(e.target.value);
    };

    const handleGroundLevelChange = (e) => {
        let inputValue = e.target.value
        setGroundLevel(inputValue);
    };

    const handleWaterLevelChange = (e) => {
        let inputValue = e.target.value
        setWaterLevel(inputValue);
    };

    const handleScrGiven = (e) => {
        setScrGiven(e.target.checked)
    };

    const handleStepChange = (e) => {
        let inputValue = e.target.value
        setStep(inputValue);
    };
    
    
    const handleRecordIDSubmit = (event) => {
        if(userInfo.george_digitiser===true){
            setLoading(true);
            event.preventDefault();
            httpClientPy.get(`/geo/record?geo_record_id=${recordID}&project_id=${0}&admin=${1}`).then((response) => {
                var feet_holder = false
                
                if(response.data.status && response.data.status === "fail"){
                    setReadable(false)
                } else {
                    setReadable(true)
                }

                if(response.data.link){
                    setSelectedDataItem(response.data)
                    setLoading(false);
                    setDetailsPage(false)
                    setDescriptionPage(true)
                    setStartPage(false)
                    if(response.data.imperial){
                        if(response.data.imperial == 1){
                            feet_holder = true
                        }
                    }
                    setUseFeet(feet_holder)

                }
                else{
                    handleDownload(response.data.file_path, "pdf");
                    setFiletype('private')
                    setDetailsPage(true)
                    setStartPage(false)
                    if(response.data.imperial){
                        if(response.data.imperial == 1){
                            feet_holder = true
                        }
                    }
                    setUseFeet(feet_holder)

                }

                try{
                    setSelectedUser(response.data.user_profile_id)
                }
                catch{
                    setSelectedUser(0)
                }

                let feetConversion = 1
                if(feet_holder==true){
                    feetConversion = 0.3048
                }
                
                setRecordDetails({
                    boreholeName: response.data.borehole_name,
                    projectName: response.data.description,
                    coordinateSystemInput: response.data.geo_coordinate_system,
                    easting: String(response.data.easting),
                    northing: String(response.data.northing),
                    logDate: response.data.log_date,
                    logStartDate: response.data.log_start_date,
                    degree1: response.data.degree_1,
                    degree2: response.data.degree_2,
                    minutes1: response.data.minute_1,
                    minutes2: response.data.minute_2,
                    seconds1: response.data.second_1,
                    seconds2: response.data.second_2,
                    orientation1: response.data.orientation1,
                    orientation2: response.data.orientation2,
                })

                // if degree1 has a value, then in degree is true
                if(response.data.degree_1){
                    setInDegree(true)
                }

                if(response.data.geo_coordinate_system != 'EPSG:27700') {
                    setInUk(false);
                }

                let files = []

                for (let file of response.data.files){
                    files.push({name: file.file_name, size: 0, path: file.file_path})
                }

                setFiles(files)
                if (files.length > 0){
                    setOtherFilesUpload(true)
                }

                let strataData = response.data.geo_record_strata

                let testData = response.data.geo_record_test
                let strata_holder = []
                let test_holder = []

                //sort testdata by depth
                testData.sort(function(a, b) {
                    return parseFloat(a.depth) - parseFloat(b.depth);
                });

                // deduplicate testdata
                testData = testData.filter((test, index, self) =>
                    index === self.findIndex((t) => (
                        t.test === test.test && t.depth === test.depth
                    ))
                )
                
                if(strataData[0].top===0){
                    setGroundLevelGiven(false)

                    setGroundLevel(Math.round((strataData[0].top/feetConversion)*100)/100)
                    for(var i = 0; i < strataData.length; i++){
                        let thickness = Math.round(((strataData[i].base - strataData[i].top)/feetConversion)*100)/100
                        if (thickness < 0) {
                            thickness = Math.abs(thickness);
                        }
                        strata_holder.push({ id: i+1, weathering: strataData[i].weathering, classification: strataData[i].classification, 
                                            name: strataData[i].description, 
                                            thickness: String(thickness)})

                        if (!weatheringGiven){
                            if(strataData[i].weathering && strataData[i].weathering.length > 0){
                                setWeatheringGiven(true)
                            }
                        }
                        
                    }
                }   
                else{
                    setGroundLevelGiven(true)
                    // order by top reverse
                    strataData.sort(function(a, b) {
                        return parseFloat(b.top) - parseFloat(a.top);
                    });
                    setGroundLevel(Math.round((strataData[0].top/feetConversion)*100)/100)

                    for(var j = 0; j < strataData.length; j++){
                        let thickness = Math.round(((strataData[j].top - strataData[j].base)/feetConversion)*100)/100
                        if (thickness < 0) {
                            thickness = Math.abs(thickness);
                        }
                        strata_holder.push({ id: j+1, weathering: strataData[j].weathering, classification: strataData[j].classification,
                                            name: strataData[j].description, 
                                            thickness: String(thickness)})

                        if (!weatheringGiven){
                            if(strataData[j].weathering && strataData[j].weathering.length > 0){
                                setWeatheringGiven(true)
                            }
                        }

                    }
                }

                setDescriptionRows(strata_holder)

                setIdCounter(strata_holder.length + 1);
                for(var j = 0; j < testData.length; j++){

                    if(testData[j].test==="SCR" || testData[j].test==="TCR") {
                        continue
                    }

                    if(testData[j].test==="RQD"){
                        setScrGiven(true)

                        // get all the test at the same depth that is SCR, TCR and RQD
                        let scr = testData.filter((test) => test.depth === testData[j].depth && test.test === "SCR")
                        let tcr = testData.filter((test) => test.depth === testData[j].depth && test.test === "TCR")

                        if (scr.length === 0) {
                            scr = { result: null }
                        } else {
                            scr = scr[0]
                        }

                        if (tcr.length === 0) {
                            tcr = { result: null }
                        } else {
                            tcr = tcr[0]
                        }
                        
                        let scr_result = scr.result ? parseFloat(scr.result) : scr.report ? scr.report : ''
                        let tcr_result = tcr.result ? parseFloat(tcr.result) : tcr.report ? tcr.report : ''
                        let rqd_result = testData[j].result ? parseFloat(testData[j].result) : testData[j].report ? testData[j].report : ''

                        test_holder.push({ id: j+1, name: testData[j].test, depth: String(Math.round(((testData[j].depth)/feetConversion)*100)/100), 
                            result: rqd_result, scr: scr_result, tcr: tcr_result})                   
                        
                    }
                    else if(testData[j].test==="water_strike"){
                        setWaterGiven(true)
                        setWaterLevel(Math.round((testData[j].depth/feetConversion)*100)/100)
                    }
                    else{
                        let res = testData[j].report ? testData[j].report : testData[j].result
                        test_holder.push({ id: j+1, name: testData[j].test, depth: String(Math.round(((testData[j].depth)/feetConversion)*100)/100), result: res})
                    }
                } 

                if(test_holder.length===0){
                    setTestsGiven(false)
                }
                else{
                    setTestsGiven(true)
                }

                setTestRows(test_holder)
                
                let max_id = Math.max(...test_holder.map(test => test.id), 0) + 1;
                setIdTestCounter(max_id)
                setLoading(false);
            })
            .then((result) => {
                // console.log(result)
            })
            .catch((errorResponse) => {
                setErrors(errorResponse);
                setLoading(false);
                throw errorResponse;
            })
        }
    };

    const handleDetailsChange = (e) => {
        if(e.target.name=='boreholeName' && e.target.value.indexOf(' ') >= 0){
            setDisplayProjectNameFail(true)
        }
        else{
            setDisplayProjectNameFail(false)
            setRecordDetails({
            ...recordDetails,
            [e.target.name]: e.target.value,
            });
        }
    };

    const handleGroundLevelGiven = (e) => {
        setGroundLevelGiven(e.target.checked)
        if(!e.target.checked){
            setGroundLevel(0)
        }
    };


    const handleWeatheringGiven = (e) => {
        setWeatheringGiven(e.target.checked)
    };

    const handleTestsGiven = (e) => {
        setTestsGiven(e.target.checked)
    };

    const handleWaterGiven = (e) => {
        setWaterGiven(e.target.checked)
    };

    const handleOtherFiles = (e) => {
        setOtherFilesUpload(e.target.checked)
    };

    const handleReadable = (e) => {
        setReadable(!e.target.checked)
    };

    const handleInUk = (e) => {
        setInUk(e.target.checked)
    };

    const handleInDegree = (e) => {
        setInDegree(e.target.checked)
    };

    const handleUseFeet = (e) => {
        setUseFeet(e.target.checked)
    };

    const handleUseDepth = (e) => {
        setUseDepth(e.target.checked)
    };

    const handleFileChange = (files) => {
        let newFiles = []
        for (var i = 0; i < files.length; i++) {
            newFiles.push(files[i])
        }
        setFiles(newFiles)
    };

    const handleRemoveFile = (index) => {
        let newFiles = [...files]
        newFiles.splice(index, 1)
        setFiles(newFiles)
    };


    const extractData = (id) => {
        httpClientPy
        .post('/geo/record/digitise/auto', {
            geo_record_id: id,
        })
        .then((response) => {
            const updatedUserLogs = oustandingLogsUser.map((log) => {
                return log[0] === id ? { ...log, 3: "pending" } : log;
            });
            const updatedVaLogs = oustandingLogsVa.map((log) => {
                return log[0] === id ? { ...log, 3: "pending" } : log;
            });
        
            setOustandingLogsUser(updatedUserLogs);
            setOustandingLogsVa(updatedVaLogs);
        })
    }

    useEffect(() => {
        // console.log(oustandingLogsUser, oustandingLogsVa)
    }, [oustandingLogsUser, oustandingLogsVa]); // Dependency array triggers on state changes
      
    
    const handleDetailsSubmit = (event) => {
        if(recordDetails.boreholeName && (recordDetails.boreholeName === "" || recordDetails.boreholeName.indexOf(' ') >= 0) && readable){
            setDisplayProjectNameFail(true)
        }
        else{
            setDisplayProjectNameFail(false)
            setLoading(true)
            let recordId = parseInt(recordID)

            let data_record = {
                geo_coordinate_system: inUk ? "EPSG:27700": recordDetails.coordinateSystemInput,
                northing: recordDetails.northing != 'null' && recordDetails.northing != '' ? recordDetails.northing : 0.0,
                easting: recordDetails.easting != 'null' && recordDetails.easting != '' ? recordDetails.easting : 0.0,
                description: readable ? recordDetails.projectName : "not readable",
                borehole_name: readable ? recordDetails.boreholeName : "not readable",
                log_date: recordDetails.logDate,
                log_start_date: recordDetails.logStartDate,
                orientation1: recordDetails.orientation1,
                orientation2: recordDetails.orientation2,
            }
            
            let deg_data1 = null
            let deg_data2 = null
            if (inDegree) {
                // remove northings and eastings from data_record 
                delete data_record.northing
                delete data_record.easting
                deg_data1 = {
                    degrees: recordDetails.degree1,
                    minutes: recordDetails.minutes1,
                    seconds: recordDetails.seconds1,
                    direction: recordDetails.direction1 == undefined ? 'E' : recordDetails.direction1
                }

                deg_data2 = {
                    degrees: recordDetails.degree2,
                    minutes: recordDetails.minutes2,
                    seconds: recordDetails.seconds2,
                    direction: recordDetails.direction2 == undefined ? 'N' : recordDetails.direction2
                }
            }

            return httpClientPy
            .put(`/geo/record/update`, {
                id: recordId,
                update_mode: "updateRecord",
                is_degree: inDegree,
                data_record: data_record,
                deg_data1: deg_data1,
                deg_data2: deg_data2,
                readable: readable,
            })
            .then((response) => {
                setLoading(false);
                setDetailsPage(false)
                setDescriptionPage(true)
            })
        }
    };

    const handleDescriptionSubmit = () => {
        setLoading(true)
        let recordId = parseInt(recordID)

        let feetConversion = 1
        if(useFeet){
            feetConversion = 0.3048
        }

        let groundLevelHolder = parseFloat(groundlevel)

        let top_holder = groundLevelHolder

        var inputError = false
        var isRowHasEmpty = false

        if(useDepth){
            if(groundLevelGiven===false){
                for (let description of descriptionRows){
                    let geol_top = parseFloat(top_holder)*feetConversion*100/100
                    let geol_base = Math.abs(((groundLevelHolder+parseFloat(description.thickness))*feetConversion)*100)/100
                    description.geol_top = String(geol_top)
                    description.geol_base = String(geol_base)
                    top_holder = parseFloat(description.thickness)
                    if(geol_top>=geol_base) {
                        inputError = true
                    }
                    if(description.thickness === "" || description.name === "" || description.description === "") {
                        isRowHasEmpty = true
                    }
                }
            }
            else{
                for (let description of descriptionRows){
                    let geol_top = parseFloat(top_holder)*feetConversion*100/100
                    let geol_base = ((groundLevelHolder-parseFloat(description.thickness))*feetConversion)*100/100
                    description.geol_top = String(geol_top)
                    description.geol_base = String(geol_base)
                    top_holder = groundLevelHolder-parseFloat(description.thickness)
                    if(geol_top<=geol_base) {
                        inputError = true
                    }
                    if(description.thickness === "" || description.name === "" || description.description === "") {
                        isRowHasEmpty = true
                    }
                }
            }
        }
        else{
            if(groundLevelGiven===false){
                for (let description of descriptionRows){
                    description.geol_top = String(parseFloat(top_holder)*feetConversion*100)/100
                    description.geol_base = String(Math.abs(((top_holder + parseFloat(description.thickness)) * feetConversion).toFixed(20)))
                    top_holder = top_holder+parseFloat(description.thickness)
                    if(description.thickness === "" || description.name === "" || description.description === "") {
                        isRowHasEmpty = true
                    }
                }
            }
            else{
                for (let description of descriptionRows){
                    description.geol_top = String(parseFloat(top_holder)*feetConversion*100)/100
                    description.geol_base = String(((top_holder - parseFloat(description.thickness)) * feetConversion).toFixed(20))
                    top_holder = top_holder-parseFloat(description.thickness)
                    if(description.thickness === "" || description.name === "" || description.description === "") {
                        isRowHasEmpty = true
                    }
                }
            }
        }


        let data_strata = []
        
        descriptionRows.map((description, index) => {
            let top = description.geol_top
            let base = description.geol_base
            let classification = description.classification
            let name = description.name
            let weathering = description.weathering
            
            // append to data_strata
            data_strata.push({
                id: description.id,
                top: top,
                base: base,
                classification: classification,
                name: name,
                weathering: weathering
            })
        })

        if(!inputError && !isRowHasEmpty){
            setDisplayThicknessFail(false)
            setStrataCompleteFail(false)
            return httpClientPy
            .put(`/geo/record/update`, {
                id: recordId,
                update_mode: "updateStrata",
                data_strata: readable ? data_strata : [],
                readable: readable,
                imperial: useFeet,
            })
            .then((response) => {
                setLoading(false);
                setDescriptionPage(false)
                setTestPage(true)
            })
        }
        else{
            if(!readable) {
                return httpClientPy
                .put(`/geo/record/update`, {
                    id: recordId,
                    update_mode: "updateStrata",
                    data_strata: readable ? data_strata : [],
                    readable: readable,
                    imperial: useFeet,
                })
                .then((response) => {
                    setLoading(false);
                    setDescriptionPage(false)
                    setTestPage(true)
                })
            } else {
                setLoading(false)
                if(isRowHasEmpty){
                    setDisplayThicknessFail(false)
                    setStrataCompleteFail(true)
                } else {
                    setDisplayThicknessFail(true)
                    setStrataCompleteFail(false)
                }
            }
        }
    };

    const handleTestSubmit = () => {

        let feetConversion = 1
        if(useFeet){
            feetConversion = 0.3048
        }

        let id_last = testRows.length
        if(waterGiven && waterlevel!==null){
            testRows.push({'id':id_last+1, 'name': "water_strike", 'depth': waterlevel, 'result': '0'})
        }

        for (let test of testRows){
            test.depth = String(parseFloat(test.depth)*feetConversion*100)/100
        }

        let data_test = []
        testRows.map((info, index) => {
            let test = info.name
            let depth = info.depth
            let result = info.result

            if (result !== null){
                // append to data_test
                data_test.push({
                    id: info.id,
                    test: test,
                    depth: depth,
                    result: result
                })
            }

            if(info.scr >= 0){
                data_test.push({
                    id: info.id,
                    test: "SCR",
                    depth: depth,
                    result: info.scr
                })
            }

            if(info.tcr >= 0){
                data_test.push({
                    id: info.id,
                    test: "TCR",
                    depth: depth,
                    result: info.tcr
                })
            }
        })

        setLoading(true)
        let recordId = parseInt(recordID)
        return httpClientPy
        .put(`/geo/record/update`, {
            id: recordId,
            update_mode: "updateTest",
            data_test: data_test,
        })
        .then((response) => {
            if (otherFilesUpload) {
                const formData = new FormData();
                for (var i = 0; i < files.length; i++) {
                    formData.append("file_obj", files[i]);
                }
                httpClientPy.post(`/geo/record/upload/extra?geo_record_id=${recordID}`, formData).then((response) => {
                    setLoading(false);
                    setTestPage(false)
                    setCompletePage(true)
                }).catch((errorResponse) => {
                    console.log(errorResponse)
                })
            } else {
                setLoading(false);
                setTestPage(false)
                setCompletePage(true)
            }
        })
    };

    const handleDownload = (file_path, format) => {
        
        link.current = document.createElement("a");
        httpClientPy.get(`/geo/record/fetch/pdf?record_id=${recordID}&project_id=${0}`, {
          responseType: 'blob'  // Important: Set the response type to 'blob'
        }).then(response => {
          const file = new Blob(
            [response.data], 
            { type: 'application/pdf' }  // Specify the file type
          );
          let url = URL.createObjectURL(file);
          link.current.href = url;
          link.current.download = url.split("/").pop();
          link.current.click();
        })
        .catch((errorResponse) => {
            console.log(errorResponse)
            throw errorResponse;
        }
        )
    };


    const handleBackButton = (state) => {
        if(state==="strata") {
            setDescriptionPage(false)
            setDetailsPage(true)
        } else if(state==="test") {
            setTestPage(false)
            setDescriptionPage(true)
        }
    };

    const handleChangeList = () => {
        setShowUserList(!showUserList)
    };

    const toggleUpdateModal = () => {
        setUpdateModal(!updateModal)
    };

    const toggleUploadModal = () => {
        setUploadModal(!uploadModal)
    };

    return (
        <div className="container-flex bg-white min-vh-100">
        {userInfo.george_digitiser == true ? (
            <>
                <main className="container m-auto py-5">
                    {!startPage &&
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="/admin/records">Home</a></li>
                        <li className="breadcrumb-item active" aria-current="page">Update record {recordID}</li>
                        </ol>
                    </nav>
                    }
                    {startPage &&
                        <>
                            <div className="row">
                                <div className="col-12 col-lg-6">
                                    <div className="hstack">
                                        <Button
                                            type="button"
                                            onClick={()=>handleChangeList()}
                                            label= {!showUserList ? "User uploaded records": "VA uploaded records"}
                                            className="w-20 btn btn-primary"
                                            loading={loading}
                                        />
                                        { superAdmin && (
                                            <>
                                            {/* <Button
                                                type="button"
                                                onClick={()=>toggleUpdateModal()}
                                                label= "Update Records"
                                                className="w-20 btn btn-primary m-2"
                                                loading={loading}
                                            /> */}
                                            <Button
                                                type="button"
                                                onClick={()=>toggleUploadModal()}
                                                label= "Upload Records"
                                                className="w-20 btn btn-primary m-2"
                                                loading={loading}
                                            />
                                            </>
                                        )}
                                    </div>
                                    <h3>Team priority list:</h3>
                                    {showUserList ? (
                                        <>
                                        { oustandingLogsUser.length>0 ? (
                                        <>
                                            <div className="mb-2">This is a list of user uploaded records 1 is the highest priority.</div>
                                            {loading ? (
                                                <span>
                                                <span
                                                    className="spinner-border spinner-border-sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                ></span>
                                                <span className="visually-hidden">Loading...</span>
                                                </span>
                                            ) : (
                                                <table className="table">
                                                    <thead>
                                                    <tr>
                                                        <th className="col-1">Priority</th>
                                                        <th className="col-2">Geo Record Id</th>
                                                        <th className="col-2">User ID</th>
                                                        <th className="col-2">Original Record</th>
                                                        <th className="col-2">Org Name</th>
                                                        <th className="col-2">Quota Used</th>
                                                        <th className="col-2"></th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                        { 
                                                            oustandingLogsUser.map((log) => (
                                                                <tr>
                                                                    <td>{oustandingLogsUser.indexOf(log)+1}</td>
                                                                    <td>{log[0]}</td>
                                                                    <td>{log[1]}</td>
                                                                    <td>{log[2] !== 0 ? log[2] : '-'}</td>
                                                                    <td>{log[6]}</td>
                                                                    <td>{log[4]}/{log[5]}</td>
                                                                    <td>
                                                                        {log[3]==="pre-pending" ?
                                                                            <Button
                                                                                type="button"
                                                                                onClick={()=>extractData(log[0])}
                                                                                label= "Extract"
                                                                                className="w-100 btn btn-sm btn-primary"
                                                                                loading={loading}
                                                                            />
                                                                        : log[3]==="pending" ?
                                                                            <span class="badge bg-warning text-dark">Processing</span>
                                                                        :
                                                                            <span class="badge bg-warning text-dark">Ready for check</span>
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }    
                                                    </tbody>
                                                </table>
                                            )}
                                        </>
                                        ) : (
                                            <div className="text-success">✅ No priority records</div>
                                        )}
                                        </>
                                    ) : (
                                        <>
                                        { oustandingLogsVa.length>0 ? (
                                        <>
                                            <div className="mb-2">This is a list of VA uploaded records 1 is the highest priority.</div>
                                            {loading ? (
                                                <span>
                                                <span
                                                    className="spinner-border spinner-border-sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                ></span>
                                                <span className="visually-hidden">Loading...</span>
                                                </span>
                                            ) : (
                                                <table className="table">
                                                    <thead>
                                                    <tr>
                                                        <th className="col-1">Priority</th>
                                                        <th className="col-2">Geo Record Id</th>
                                                        <th className="col-2">User ID</th>
                                                        <th className="col-2">Original Record</th>
                                                        <th className="col-2">Org Name</th>
                                                        <th className="col-2">Quota Used</th>
                                                        <th className="col-2"></th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                        {   
                                                            oustandingLogsVa.map((log) => (
                                                                <tr>
                                                                    <td>{oustandingLogsVa.indexOf(log)+1}</td>
                                                                    <td>{log[0]}</td>
                                                                    <td>{log[1]}</td>
                                                                    <td>{log[2] !== 0 ? log[2] : '-'}</td>
                                                                    <td>{log[6]}</td>
                                                                    <td>{log[4]}/{log[5]}</td>
                                                                    <td>
                                                                        {log[3]==="pre-pending" ?
                                                                            <Button
                                                                                type="button"
                                                                                onClick={()=>extractData(log[0])}
                                                                                label= "Extract"
                                                                                className="w-100 btn btn-sm btn-primary"
                                                                                loading={loading}
                                                                            />
                                                                        : log[3]==="pending" ?
                                                                            <span class="badge bg-warning text-dark">Processing</span>
                                                                        :
                                                                            <span class="badge bg-warning text-dark">Ready for check</span>
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </table>
                                            )}
                                        </>
                                        ) : (
                                            <div className="text-success">✅ No priority records</div>
                                        )}
                                        </>
                                    )}
                                </div>
                                <div className="col-12 col-lg-6 text-center">
                                    <h3>Fetch a record from the database</h3>
                                    <div className="digitiser-form-details mx-auto my-3">
                                        <ValidationSummary errors={errors} />
                                        <form onSubmit={handleRecordIDSubmit}>
                                            <div className="form-floating mb-2">
                                            <input
                                                name="recordID"
                                                type="number"
                                                min="1"
                                                className="form-control"
                                                id="recordIDInput"
                                                value={recordID}
                                                onChange={handleRecordIDChange}
                                                autoComplete="off"
                                            />
                                            <label htmlFor="recordIDInput">Record ID</label>
                                            </div>

                                            <div className="vstack gap-2">
                                            <Button
                                                type="submit"
                                                label="Fetch record"
                                                className="w-100 btn btn-primary"
                                                loading={loading}
                                            />

                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <Modal id="updateRecordModal" size="lg" isOpen={updateModal} toggle={()=>setUpdateModal(!updateModal)} centered={true}>
                            <AdminUpdateRecord displayName = "Reassign Record" 
                                               toggleModal = {()=>setUpdateModal(!updateModal)} />
                            </Modal>
                            <Modal id="uploadRecordModal" size="lg" isOpen={uploadModal} toggle={()=>setUploadModal(!uploadModal)} centered={true}>
                            <AdminUploadRecord displayName = "Reassign Record" 
                                               userInfo = {userInfo}
                                               toggleModal = {()=>setUploadModal(!uploadModal)}
                                               uploadDone = {()=>setUploadDone(true)} />
                            </Modal>
                        </>
                    }

                    {!startPage &&
                    <>
                        <div className="text-center">
                            <h2>
                                {
                                    filetype === 'private' ?
                                        `Update record ${recordID} (Private record)`
                                    :
                                        `Update record ${recordID} (Public record)`
                                }
                            </h2>
                            {loading && 
                            <div className="py-2">
                                {loading && (
                                    <span>
                                    <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                    ></span>
                                    <span className="visually-hidden">Loading...</span>
                                    </span>
                                )}
                                <span> Please wait...</span></div>
                            }
                        </div>
                    
                    {!completePage &&
                        <div className="mt-5 mb-3">
                            <div className="row">

                            {filetype === 'private' ?
                                <div className="col-12 col-lg-12 text-center">
                                
                                <a
                                    type="button"
                                    onClick={()=>{link.current.click()}}
                                    target="_blank"
                                    className="d-inline-block text-truncate fw-semibold fs-5 text"
                                    rel="noreferrer"
                                >
                                    Download PDF
                                </a>
                                </div>
                                :
                                <div className="col-12 col-lg-12 text-center">

                                <a
                                    href={selectedDataItem.link}
                                    target="_blank"
                                    className="d-inline-block text-truncate fw-semibold fs-5 text"
                                    rel="noreferrer"
                                >
                                    Open fullscreen
                                </a>
                                {/* {<div className="previewContainerAdmin w-100 mx-auto">
                                    <iframe
                                    src={selectedDataItem.boreHoleLog.externalLink.replace("http:","https:")}
                                    className="boreholePreviewAdmin"
                                    ></iframe>
                                </div>} */}
                                </div>
                                                
                            }
                                <div className="col-12 col-lg-12">

                                    { filetype === 'private' && detailsPage &&
                                        <form>
                                            <div className="form-check mt-4 mb-2">
                                                <input className="form-check-input" checked={!readable} onChange={handleReadable} type="checkbox" id="flexCheckDefault"/>
                                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                                    Record not readable
                                                </label>
                                            </div>
                                            {readable ?
                                            <>
                                                <div className="form-check mb-2">
                                                    <input className="form-check-input" checked={inUk} onChange={handleInUk} type="checkbox" id="flexCheckDefault"/>
                                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                                        This record is in UK
                                                    </label>
                                                </div>
                                                <div className="form-check mb-4">
                                                    <input className="form-check-input" checked={inDegree} onChange={handleInDegree} type="checkbox" id="flexCheckDefault"/>
                                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                                        Coordinates are in degrees, minutes & seconds (e.g. 12° 30' 00”)
                                                    </label>
                                                </div>
                                                <div className="form-floating mb-2">
                                                <input
                                                    name="projectName"
                                                    type="text"
                                                    className="form-control"
                                                    id="projectName"
                                                    value={recordDetails.projectName}
                                                    onChange={handleDetailsChange}
                                                    required
                                                    autoComplete="off"
                                                />
                                                <label htmlFor="projectName">Report title</label>
                                                </div>
                                                <div className="form-floating mb-2">
                                                <input
                                                    name="boreholeName"
                                                    type="text"
                                                    className="form-control"
                                                    id="boreholeName"
                                                    value={recordDetails.boreholeName}
                                                    onChange={handleDetailsChange}
                                                    required
                                                    autoComplete="off"
                                                />
                                                <label htmlFor="projectName">Borehole number</label>
                                                </div>
                                                { displayProjectNameFail &&
                                                    <div className="text-danger text-center mb-2">Enter a borehole name and ensure there are no spaces</div>
                                                }
                                                <div className="form-floating mb-2">
                                                <input
                                                    name="logStartDate"
                                                    type="text"
                                                    className="form-control"
                                                    id="logStartDate"
                                                    value={recordDetails.logStartDate ? recordDetails.logStartDate.split('T')[0] : ''}
                                                    onChange={handleDetailsChange}
                                                    required
                                                    autoComplete="off"
                                                />
                                                <label htmlFor="logStartDate">From Date</label>
                                                </div>
                                                <div className="form-floating mb-2">
                                                <input
                                                    name="logDate"
                                                    type="text"
                                                    className="form-control"
                                                    id="logDate"
                                                    value={recordDetails.logDate ? recordDetails.logDate.split('T')[0] : ''}
                                                    onChange={handleDetailsChange}
                                                    required
                                                    autoComplete="off"
                                                />
                                                <label htmlFor="projectName">To Date</label>
                                                </div>
                                                <div className="form-floating mb-2">
                                                    <input
                                                        name="orientation1"
                                                        type="text"
                                                        className="form-control"
                                                        id="orientation1"
                                                        value={recordDetails.orientation1 ? recordDetails.orientation1 : ''}
                                                        onChange={handleDetailsChange}
                                                        required
                                                        autoComplete="off"
                                                    />
                                                    <label htmlFor="orientation1">Orientation</label>
                                                </div>
                                                <div className="form-floating mb-2">
                                                    <input
                                                        name="orientation2"
                                                        type="text"
                                                        className="form-control"
                                                        id="orientation2"
                                                        value={recordDetails.orientation2 ? recordDetails.orientation2 : ''}
                                                        onChange={handleDetailsChange}
                                                        required
                                                        autoComplete="off"
                                                    />
                                                    <label htmlFor="orientation2">Inclination</label>
                                                </div>
                                                { !inDegree ?
                                                <>
                                                    <div className="form-floating mb-2">
                                                    <input
                                                        name="easting"
                                                        type="number"
                                                        className="form-control"
                                                        id="easting"
                                                        value={recordDetails.easting}
                                                        onChange={handleDetailsChange}
                                                        autoComplete="off"
                                                    />
                                                    <label htmlFor="easting">Easting coordinate</label>
                                                    </div>

                                                    <div className="form-floating mb-2">
                                                    <input
                                                        name="northing"
                                                        type="number"
                                                        className="form-control"
                                                        id="northing"
                                                        value={recordDetails.northing}
                                                        onChange={handleDetailsChange}
                                                        autoComplete="off"
                                                    />
                                                    <label htmlFor="northing">Northing coordinate</label>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <div className="form-floating mb-2">
                                                        <table className="table">
                                                            <thead>
                                                                <tr>
                                                                    <th className="col-3">Degree</th>
                                                                    <th className="col-3">Minutes</th>
                                                                    <th className="col-3">Seconds</th>
                                                                    <th className="col-3">Direction</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <div className="input-group">
                                                                            <input
                                                                                name="degree1"
                                                                                type="number"
                                                                                className="form-control"
                                                                                id="degree1"
                                                                                value={recordDetails.degree1}
                                                                                onChange={handleDetailsChange}
                                                                                autoComplete="off"
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="input-group">
                                                                            <input
                                                                                name="minutes1"
                                                                                type="number"
                                                                                className="form-control"
                                                                                id="minutes1"
                                                                                value={recordDetails.minutes1}
                                                                                onChange={handleDetailsChange}
                                                                                autoComplete="off"
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="input-group">
                                                                            <input
                                                                                name="seconds1"
                                                                                type="number"
                                                                                className="form-control"
                                                                                id="seconds1"
                                                                                value={recordDetails.seconds1}
                                                                                onChange={handleDetailsChange}
                                                                                autoComplete="off"
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="input-group">
                                                                            <select disabled={true} className="form-select" name="direction1" id="direction1" onChange={handleDetailsChange}>
                                                                                <option value="N">N</option>
                                                                                <option selected value="E">E</option>
                                                                            </select>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <div className="input-group">
                                                                            <input
                                                                                name="degree2"
                                                                                type="number"
                                                                                className="form-control"
                                                                                id="degree2"
                                                                                value={recordDetails.degree2}
                                                                                onChange={handleDetailsChange}
                                                                                autoComplete="off"
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="input-group">
                                                                            <input
                                                                                name="minutes2"
                                                                                type="number"
                                                                                className="form-control"
                                                                                id="minutes2"
                                                                                value={recordDetails.minutes2}
                                                                                onChange={handleDetailsChange}
                                                                                autoComplete="off"
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="input-group">
                                                                            <input
                                                                                name="seconds2"
                                                                                type="number"
                                                                                className="form-control"
                                                                                id="seconds2"
                                                                                value={recordDetails.seconds2}
                                                                                onChange={handleDetailsChange}
                                                                                autoComplete="off"
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="input-group">
                                                                            <select disabled={true} className="form-select" name="direction2" id="direction2" onChange={handleDetailsChange}>
                                                                                <option selected value="N">N</option>
                                                                                <option value="E">E</option>
                                                                            </select>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </>
                                                }

                                                { !inUk ?
                                                <>
                                                    <div className="form-floating mb-2">
                                                        <input 
                                                            list="coordinateSystemInputList" 
                                                            id="coordinateSystemInput" 
                                                            name="coordinateSystemInput"
                                                            value={recordDetails.coordinateSystemInput}
                                                            onChange={handleDetailsChange} 
                                                            className="form-control" 
                                                        />
                                                        <label htmlFor="coordinateSystemInput">Coordinate system</label>
                                                        <datalist id="coordinateSystemInputList">
                                                            {memoizedOptions}
                                                        </datalist>
                                                    </div>
                                                </>
                                                : 
                                                <>
                                                    <div className="form-floating mb-2">
                                                    <input
                                                        name="coordinateSystemInput"
                                                        type="text"
                                                        className="form-control"
                                                        id="coordinateSystemInput"
                                                        value="EPSG:27700"
                                                        onChange={handleDetailsChange}
                                                        required
                                                        autoComplete="off"
                                                        disabled
                                                    />
                                                    <label htmlFor="coordinateSystemInput">Coordinate system</label>
                                                    </div>
                                                </>
                                                }

                                                <div className="vstack gap-2">
                                                    <Button
                                                        type="button"
                                                        onClick={()=>handleDetailsSubmit()}
                                                        label="Next"
                                                        className="w-100 btn btn-primary"
                                                        loading={loading}
                                                    />
                                                </div>
                                            </>
                                            :
                                                <div className="vstack gap-2">
                                                    <Button
                                                        type="button"
                                                        onClick={()=>handleDetailsSubmit()}
                                                        label="Next"
                                                        className="w-100 btn btn-primary"
                                                        loading={loading}
                                                    />
                                                </div>
                                            }
                                        </form>
                    }

                    {descriptionPage &&

                                <div className="digitiser-form-description my-5">
                                <h4 className="text-start">Geology description</h4>

                                    <div className="form-check mt-4 mb-2">
                                        <input className="form-check-input" checked={!readable} onChange={handleReadable} type="checkbox" id="flexCheckDefault"/>
                                        <label className="form-check-label" htmlFor="flexCheckDefault">
                                            Record not readable
                                        </label>
                                    </div>

                                    {readable ?
                                        
                                        <>

                                            <div className="form-check mb-2">
                                                <input className="form-check-input" checked={useFeet} onChange={handleUseFeet} type="checkbox" id="flexCheckDefault"/>
                                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                                    Convert feet to metres
                                                </label>
                                            </div>

                                            <div className="form-check mb-2">
                                                <input className="form-check-input" checked={useDepth} onChange={handleUseDepth} type="checkbox" id="flexCheckDefault"/>
                                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                                    Use depth instead of thickness
                                                </label>
                                            </div>

                                            <div className="form-check mb-2">
                                                <input className="form-check-input" checked={groundLevelGiven} onChange={handleGroundLevelGiven} type="checkbox" id="flexCheckDefault"/>
                                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                                    Ground level given
                                                </label>
                                            </div>

                                            <div className="form-check mb-4">
                                                <input className="form-check-input" checked={weatheringGiven} onChange={handleWeatheringGiven} type="checkbox" id="flexCheckDefault"/>
                                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                                    Weathering given
                                                </label>
                                            </div>

                                            <form>

                                                { groundLevelGiven &&
                                                <div className="form-floating mb-2">
                                                    <input
                                                        name="groundLevel"
                                                        type="number"
                                                        className="form-control"
                                                        id="groundLevel"
                                                        value={groundlevel}
                                                        onChange={handleGroundLevelChange}
                                                        autoComplete="off"
                                                        required
                                                    />
                                                    <label htmlFor="groundLevel">Ground level {useFeet ? '(feet)' : '(m)'}</label>
                                                    </div>
                                                }

                                                { strataCompleteFail &&
                                                    <div className="text-danger text-center mb-2">Please make sure all fields for Geo Strata is filled.</div>
                                                }
                                                    <table className="table">
                                                    <thead>
                                                    <tr>
                                                        {weatheringGiven &&
                                                            <th className="col-1">Weathering</th>
                                                        }
                                                        <th className="col-2">Geo classification</th>
                                                        <th className="col-6">Geo description</th>
                                                        <th className="col-2">{useDepth ? 'Depth' : 'Thickness'} {useFeet ? '(feet)' : '(m)'}</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {descriptionRows.map((row) => (
                                                        <tr key={row.id}>
                                                        {weatheringGiven &&
                                                            <td>
                                                                <div className="input-group">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    required
                                                                    value={row.weathering}
                                                                    onChange={(event) => updateDescriptionValue(event, row.id, 'weathering')}
                                                                />
                                                                </div>
                                                            </td>
                                                        }
                                                        <td>
                                                            <div className="input-group">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                required
                                                                value={row.classification}
                                                                onChange={(event) => updateDescriptionValue(event, row.id, 'classification')}
                                                            />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="input-group">
                                                            <textarea
                                                                id="description"
                                                                rows={row.name.split("\n").length}
                                                                type="text"
                                                                className="form-control"
                                                                required
                                                                value={row.name}
                                                                onChange={(event) => updateDescriptionValue(event, row.id, 'name')}
                                                            />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="input-group">
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                required
                                                                value={row.thickness}
                                                                min="0.001"
                                                                step="0.001"
                                                                onChange={(event) => updateDescriptionValue(event, row.id, 'thickness')}
                                                            />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <button
                                                            type="button"
                                                            className="btn btn-danger"
                                                            onClick={() => removeDescriptionRow(row.id)}
                                                            >
                                                            -
                                                            </button>
                                                        </td>
                                                        <td>
                                                            <button
                                                            type="button"
                                                            className="btn btn-success"
                                                            onClick={() => addDescriptionRowAbove(row.id)}
                                                            >
                                                            + Up
                                                            </button>
                                                        </td>
                                                        <td>
                                                            <button
                                                            type="button"
                                                            className="btn btn-success"
                                                            onClick={() => addDescriptionRowBelow(row.id)}
                                                            >
                                                            + Down
                                                            </button>
                                                        </td>
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </table>
                                            <button type="button" className="btn btn-success" onClick={addDescriptionRow}>
                                                Add
                                            </button>
                                            <button type="button" className="btn btn-success ms-2" onClick={addDescriptionRowFive}>
                                                Add 5
                                            </button> 

                                            <div className="hstack gap-2 mt-2">
                                                { filetype === 'private' &&
                                                    <Button
                                                        type="button"
                                                        onClick={()=>handleBackButton('strata')}
                                                        label="Back"
                                                        className="w-100 btn btn-warning"
                                                        loading={loading}
                                                    />
                                                }
                                                <Button
                                                    type="button"
                                                    onClick={()=>handleDescriptionSubmit()}
                                                    label="Next"
                                                    className="w-100 btn btn-primary col-10"
                                                    loading={loading}
                                                />
                                            </div>
                                        </form>
                                    </>
                                    :
                                    <div className="vstack gap-2 mt-2">
                                        <a
                                            type="button"
                                            className="w-100 btn btn-primary"
                                            loading={loading}
                                            onClick={()=>handleDescriptionSubmit()}
                                        >Next</a>
                                    </div>

                                }
                                { displayThicknessFail &&
                                    <div className="text-danger text-center">Error with form, are you using thickness instead of depth?</div>
                                }
                                </div>
                    }

                    {testPage &&
                        <div className="digitiser-form-description my-5">
                                <h4 className="text-start">Geology Testing Results</h4>
                            <div className="form-check mt-4 mb-2">
                                <input className="form-check-input" checked={testsGiven} onChange={handleTestsGiven} type="checkbox" id="geoTestsInput"/>
                                <label className="form-check-label" htmlFor="geoTestsInput">
                                    Geological tests given
                                </label>
                            </div>
                            <div className="form-check mt-2 mb-2">
                                <input className="form-check-input" checked={waterGiven} onChange={handleWaterGiven} type="checkbox" id="geoWaterInput"/>
                                <label className="form-check-label" htmlFor="geoWaterInput">
                                    Water level given
                                </label>
                            </div>
                            <div className="form-check mt-2 mb-2">
                                <input className="form-check-input" checked={otherFilesUpload} onChange={handleOtherFiles} type="checkbox" id="otherFileUploadInput"/>
                                <label className="form-check-label" htmlFor="otherFileUploadInput">
                                    Other files upload
                                </label>
                            </div>

                            {otherFilesUpload &&
                                <FileUploader
                                    classes="drag-drop-uploader w-100 mb-2"
                                    handleChange={handleFileChange}
                                    files={files}
                                    accept=".pdf"
                                    multiple={true}
                                />
                            }
                            { files && files.length > 0 &&
                                // list the files using card with a remove button
                                <div className="row">
                                    {files.map((file, index) => (
                                        <div className="col-12 col-md-6 col-lg-4" key={index}>
                                            <div className="card mb-2">
                                                <div className="card-body">
                                                    <h5 className="card-title">{file.name}</h5>
                                                    <button
                                                        type="button"
                                                        className="btn btn-danger"
                                                        onClick={() => handleRemoveFile(index)}
                                                    >
                                                        Remove
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            }
                            { waterGiven &&
                                <div className="form-floating mb-2">
                                    <input
                                        name="waterLevel"
                                        type="number"
                                        className="form-control"
                                        id="waterLevel"
                                        value={waterlevel}
                                        onChange={handleWaterLevelChange}
                                        autoComplete="off"
                                        required
                                    />
                                    <label htmlFor="waterLevel">Water level depth {useFeet ? '(feet)' : '(m)'}</label>
                                    </div>
                                }


                            {testsGiven &&
                                <>
                                    <div className="form-check mt-2 mb-2">
                                        <input className="form-check-input" checked={scrGiven} onChange={handleScrGiven} type="checkbox" id="scrInput"/>
                                        <label className="form-check-label" htmlFor="scrInput">
                                            TCR & SCR given
                                        </label>
                                    </div>

                                    <div className="form-floating mb-2">
                                    <input
                                        name="depthStep"
                                        type="number"
                                        className="form-control"
                                        id="depthStep"
                                        value={step}
                                        onChange={handleStepChange}
                                        autoComplete="off"
                                        required
                                    />
                                    <label htmlFor="depthStep">Depth step (m)</label>
                                    </div>
                                </>
                            }



                            {testsGiven && 
                                <>
                                <table className="table">
                                <thead>
                                <tr>
                                    <th className="col-4">Test type</th>
                                    <th className="col-4">Depth {useFeet ? '(feet)' : '(m)'}</th>
                                    <th className="col-4">Result</th>
                                </tr>
                                </thead>
                                <tbody>
                                {testRows.map((row) => (
                                    <>
                                    <tr key={row.id}>
                                        <td>
                                            <div className="input-group">
                                            <select className="form-select" aria-label="Default select example" value={row.name === 'TCR' || row.name === 'SCR' ? 'RQD' : row.name}  onChange={(event) => updateTestValue(event, row.id, 'name')}>
                                                <option value="N">N</option>
                                                <option value="RQD">RQD</option>
                                                <option value="VANE">Vane Shear Test</option>
                                                <option value="PEN">Penetrometer</option>
                                            </select>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="input-group">
                                            <input
                                                type="number"
                                                className="form-control"
                                                required
                                                value={row.depth}
                                                onChange={(event) => updateTestValue(event, row.id, 'depth')}
                                            />
                                            </div>
                                        </td>
                                        <td>
                                            <div class="row g-1">
                                                { (row.name==="RQD" || row.name==="TCR" || row.name==="SCR") && scrGiven ? 
                                                    <>
                                                        <div className="col">
                                                            <div className="form-floating">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="tcr_input1"
                                                                    name="tcr_input1"
                                                                    value={row.tcr}
                                                                    onChange={(event) => updateTestValue(event, row.id, 'tcr')}
                                                                />
                                                                <label htmlFor="tcr_input1">TCR</label>

                                                            </div> 
                                                        </div>       
                                                        <div className="col">
                                                            <div className="form-floating">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="scr_input"
                                                                    name="scr_input"
                                                                    value={row.scr}
                                                                    onChange={(event) => updateTestValue(event, row.id, 'scr')}
                                                                />
                                                                <label htmlFor="scr_input">SCR</label>

                                                            </div> 
                                                        </div>
                                                        <div className="col">
                                                            <div className="form-floating">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="rqd_input1"
                                                                    name="rqd_input1"
                                                                    value={row.result}
                                                                    onChange={(event) => updateTestValue(event, row.id, 'result')}
                                                                />
                                                                <label htmlFor="rqd_input1">RQD</label>

                                                            </div> 
                                                        </div>      
                                                    </>    
                                                    :
                                                    <div class="col">
                                                        <div className="input-group">
                                                        <input
                                                            type="text"
                                                            id="rqd_input"
                                                            name="tcr_input"
                                                            className="form-control"
                                                            value={row.result}
                                                            onChange={(event) => updateTestValue(event, row.id, 'result')}
                                                        />
                                                        </div>
                                                    </div>                                        
                                                }
                                            </div>
                                        </td>
                                        <td>
                                            <button
                                            type="button"
                                            className="btn btn-danger"
                                            onClick={() => removeTestRow(row.id)}
                                            >
                                            Remove
                                            </button>
                                        </td>
                                    </tr>
                                    </>
                                ))}
                                </tbody>
                            </table>
                            <button type="button" className="btn btn-success" onClick={addTestRow}>
                                Add
                            </button>
                            <button type="button" className="btn btn-success ms-2" onClick={addTestRowFive}>
                                Add 5
                            </button>
                            </>
                            }
                            <div className="hstack gap-2 mt-2">
                                <Button
                                    type="button"
                                    onClick={()=>handleBackButton('test')}
                                    label="Back"
                                    className="w-100 btn btn-warning"
                                    loading={loading}
                                />
                                <a
                                    type="button"
                                    className="w-100 btn btn-primary col-10"
                                    loading={loading}
                                    onClick={()=>handleTestSubmit()}
                                >Finish</a>
                            </div>
                    
                        </div>
                    }
                        </div>
                    </div>  
                </div>
                    }
                    {completePage &&
                        <div className="digitiser-form-description my-3 text-center mx-auto">
                            {!loading &&
                            <>
                                <h2 className="text-success">Record added successfully!</h2>
                                {displayEmailFail && <div>Note: Email not sent to user</div>}
                                <div className="vstack gap-2 mt-2">
                                    <a
                                        type="submit"
                                        className="w-100 btn btn-primary"
                                        loading={loading}
                                        onClick={()=>{
                                            window.location.reload();
                                        }}
                                    >Start again</a>
                                </div>
                            </>
                            }  
                        </div>
                    }
                    </>
                    }
                </main>
            </>
    ) : (
        <>Please sign-in to an admin account</>
    )
    }   
    </div>

    );
  };
  
  export default Admin;




  const b64toBlob = (dataUri, contentType) => {
    var byteString = window.atob(dataUri.split(",")[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: contentType });
  };