import { useState } from "react";
import {
  Collapse,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
} from "reactstrap";

const NavFooter = (props) => {

    return (
      <header>
      <Navbar
        className="navbar navbar-dark navBarTop d-block d-md-none bg-dark text-light fixed-bottom text-center box-shadow"
        light
        style={{zIndex:"1"}}
      >
          <div className="text-center w-100">Civils.ai is optimised for desktop</div>
        </Navbar>
      </header>
    );
  }
  export default NavFooter;

