import React, { useState,useEffect } from "react";
import { Alert, Modal, ModalHeader, ModalBody, ModalFooter, Progress, Card, CardBody, CardTitle, CardFooter, CardText, CardSubtitle, CardHeader, Carousel, CarouselItem, CarouselControl, CarouselIndicators, } from 'reactstrap';
import authenticationHandler from '../../utils/authenticationHandler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare, faClock, faLock, faPersonChalkboard, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from 'react-router-dom';

const LoadingCivils = (props) => { 
    const [modalDisplay, setModalDisplay] = useState(true);
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);
    const navigate = useNavigate();


    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === modules.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
      };
    
      const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? modules.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
      };
    
      const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
      };

    const toggleModalDisplay = () => {
        setModalDisplay(!modalDisplay);
        props.toggleModal();
    }

    const toggleCancel = () => {
      setModalDisplay(!modalDisplay);
      props.toggleCancel();
    }

    const navigateToTraining = () => {
      navigate(`/training`);
    }



    const modules = [
        {
          title: "Module 1 - Course Introduction",
          description: "We kick off the course by explaining exactly how the course works",
          imgSrc: "https://d122f4822rob2u.cloudfront.net/lesson-1-2.png",
          duration: "10 mins",
          type: "Theory",
          learnings: [
            "Structure of the course with theory & practical",
            "Introduction to your instructor",
            "Course resources"
          ]
        },
        {
          title: "Module 2 - History and foundations of AI",
          description: "Get a fundamental understanding of foundational AI technologies and how AI applications work.",
          imgSrc: "https://d122f4822rob2u.cloudfront.net/lesson-2.png",
          duration: "30 mins",
          type: "Theory",
          learnings: [
            "The history of AI going back to the 1800's",
            "Demystifying definitions and terminology",
            "An overview of foundational AI technologies",
            "How AI models are trained and deployed"
          ]
        },
        {
          title: "Module 3 - Implementing AI with No-Code solutions",
          description: "We show you how to use AI (and customize it) to boost your productivity on construction projects with no coding required.",
          imgSrc: "https://d122f4822rob2u.cloudfront.net/lesson-3.png",
          duration: "45 mins",
          type: "Practical",
          learnings: [
            "What are AI Agents?",
            "How can we use AI Agents in construction?",
            "Implement our own AI Agents for construction tasks: Create an AI Agent for analyzing construction contracts, site photographs, etc."
          ]
        },
        {
            title: "Module 4 - Implementing Construction AI with Python",
            description: "We guide you in how to start basic Python scripting and how to interact with Large Language Models with just a few lines of code.",
            imgSrc: "https://d122f4822rob2u.cloudfront.net/lesson-4.png",
            duration: "60 mins",
            type: "Practical",
            learnings: [
              "Get started using Python (beginner-friendly crash course)",
              "Scripts and algorithms",
              "Data structures",
              "Using open-source libraries",
              "APIs and AI model inference",
              "Fetching real-time information",
              "Interacting with Large Language Models",
              "Coding your own Autonomous AI agents",
              "Build your own AI for estimating quantities from PDF CAD Drawings"
            ]
          },
          {
            title: "Module 5 - Publishing your AI applications",
            description: "Publish your AI application so that others in your team or company can use the solution you've built.",
            imgSrc: "https://d122f4822rob2u.cloudfront.net/lesson-5.png",
            duration: "50 mins",
            type: "Practical",
            learnings: [
              "Introduction to hosting web applications",
              "Developing AI apps with Streamlit",
              "Storing code on GitHub",
              "Creating our AI web application",
              "Publishing our AI for estimating quantities from PDF CAD Drawings",
              "Controlling access to our application"
            ]
          },
          {
            title: "Module 6 - Fine-tuning AI for Construction",
            description: "In this final practical session, we will bring together everything we've learned and build four complete apps for different construction use cases.",
            imgSrc: "https://d122f4822rob2u.cloudfront.net/lesson-6.png",
            duration: "60 mins",
            type: "Practical",
            learnings: [
              "AI for CAD: We will finish off our AI for estimating quantities from PDF CAD Drawings, generating a downloadable Excel file.",
              "AI for Civil Engineering: AI for analyzing site photographs and writing a draft inspection report as a downloadable Word file.",
              "AI for Contractors: AI for analyzing construction contracts and creating a table of the key risks, including page numbers, along with a renegotiation email.",
              "AI for Architects: AI for restyling building interiors and exteriors using photographs you've uploaded and a prompt."
            ]
          },
          {
            title: "Module 7 - AI Safety and Regulations in 2024",
            description: "We explain the AI and privacy regulations you need to know about in 2024 if you are developing or using AI applications.",
            imgSrc: "https://d122f4822rob2u.cloudfront.net/lesson-7.png",
            duration: "20 mins",
            type: "Theory",
            learnings: [
              "Global AI and privacy regulations that are in effect today from both the developer and user perspective",
              "Regulations on the horizon",
              "How to make sure you stay on the right side of the regulations"
            ]
          }
      ];


      const slides = modules.map((module, index) => (
        <CarouselItem
            onExiting={() => setAnimating(true)}
            onExited={() => setAnimating(false)}
            key={index}
        >
          <Card className="bg-white border-0 text-dark mb-5" style={{minHeight:'400px'}}>
            <CardBody className={'px-5 border-0 py-5'}>
              <div className="row h-100">
                <div className="col-sm-6 text-center">
                  <img alt={`lesson-${index + 1}-ai-construction`} style={{width:'90%'}} className="mx-auto rounded" src={module.imgSrc} />
                </div>
                <div className="col-sm-6">
                  <CardText>
                    <div className="title-bold fs-4">{module.title}</div>
                    <p>{module.description}</p>
                    <p className="text-muted">
                        <small>
                            <FontAwesomeIcon className="me-2" icon={faClock}/>
                            Lesson duration: {module.duration}
                        </small>{" "}
                        <small className="ms-3">
                            <FontAwesomeIcon className="me-2" icon={faPersonChalkboard}/>
                            Lesson type: {module.type}
                        </small>
                    </p>
                    <p>Things you will learn:</p>
                    <ul>
                      {module.learnings.map((learning, i) => (
                        <li key={i}>{learning}</li>
                      ))}
                    </ul>
                  </CardText>
                </div>
              </div>
            </CardBody>
          </Card>
        </CarouselItem>
      ));



    return(
        props.userInfo && (props.userInfo.subscription.subscription_id===1 && !props.userInfo.is_enterprise) ?
    <>

        <div className='container pb-2' style={{maxWidth:'1500px'}}>
                    
        <Alert color="danger">
            <FontAwesomeIcon className="me-2" icon={faLock}/>
            <b>You don't have access to Civils.ai yet.</b> Get access below or ask your admin to add you to their team!
        </Alert>
        <h3 className="title-bold pt-2 pb-2">Get access to your no-code AI Software Suite</h3>

        <div className="row align-items-center">
            <div className="col col-md-6">
                <div>

                <div className="pt-2">
                    <video className="mx-auto shadow rounded" style={{ borderRadius: '10px' }} loading="lazy" width="100%" autoPlay playsInline muted loop>
                        <source src="https://d122f4822rob2u.cloudfront.net/no-code-ai-workflow.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
                </div>
            </div>
            <div className="col col-md-6">

                <h4 className="title-bold pt-2">Build your own No-Code AI workflows</h4>
                    <ol>
                        <li>Looking for a simple (but powerful) way to automate processes on your project?</li>
                        <li>Automate tendering, desk studies and compliance checks to minimise construction risks and uncertainty, all without learning a programming language.</li>
                    </ol>
                <h4 className="title-bold pt-2">How it works?</h4>
                    <ol>
                        <li>Build custom workflows by connecting blocks & prompts (or select a template).</li>
                        <li>Connect in documents and data.</li>
                        <li>Click run and let your workflow generate outputs in Excel and Word & other formats.</li>
                    </ol>
            </div>
            
        </div>

        <div className="pt-5 text-center">
            <div>👇 Book a meeting with us where we will set you up with trial access</div>
            <a className="btn btn-primary btn-lg mt-3" target="_blank" href="/contact-us">Unlock access</a>
        </div>

        <h3 className="title-bold pb-3 mt-5">
            In the meantime, access 7 hours of free training on how AI apps like Civils.ai work
        </h3>

        <div className="shadow rounded border bg-white">
          <Carousel activeIndex={activeIndex} next={next} previous={previous}>
              <CarouselIndicators items={modules} activeIndex={activeIndex} onClickHandler={goToIndex} />
              {slides}
              <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
              <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
          </Carousel>
          <div className="text-center pb-4">
              <a className="btn btn-primary btn-lg" onClick={() => navigateToTraining()}>Start training now</a>
          </div>
        </div>



    </div>
    </>
    :
    <Modal isOpen={modalDisplay} className='pricingModal' centered toggle={toggleModalDisplay} backdrop="static" keyboard={false}>
        <ModalHeader className='title-bold border-0' toggle={props.hasLibs ? toggleCancel : null}>
            <h4 className="d-inline-flex title-bold align-items-center mb-0 pb-0">
                👋 Welcome to Civils.ai
            </h4>
        </ModalHeader>
        <ModalBody>
            <div className="text-center">
                <video className="mx-auto shadow rounded mb-2 w-100" loading="lazy" autoPlay={true} muted={true} loop={true}>
                    <source src="https://d122f4822rob2u.cloudfront.net/upload2.mp4" type="video/mp4" />
                </video>
                <div>Drag and drop your PDF's to extracting information and creating a library of your projects and codes.</div>
            </div>
        </ModalBody>
        <ModalFooter className="border-0 pt-0">
            <div className="text-center w-100">
                <button className="btn btn-primary btn-lg" onClick={()=>toggleModalDisplay()}>Start my first project</button>
            </div>
        </ModalFooter>
      </Modal>
    

    )

 }; export default LoadingCivils;