import React from "react";
import { Navigate, useLocation, Outlet, useNavigate } from "react-router-dom";
import authenticationHandler from "../../utils/authenticationHandler";
import axios from "axios";

const AuthenticatedRoute = ({ children, ...props }) => {
  const navigate = useNavigate();
  const location = useLocation();

  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (401 === error.response.status) {
        navigate(`/log-in?returnUrl=${location.pathname}`);
      } else {
        return Promise.reject(error);
      }
    }
  );
  return authenticationHandler.isAuthenticated() === true ? (
    <Outlet />
  ) : (
    <Navigate
      to={{
        pathname: "/log-in",
        search: `?returnUrl=${location.pathname}`,
      }}
    />
  );
};

export default AuthenticatedRoute;
