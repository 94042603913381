import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { Spinner } from "reactstrap";

import { faLayerGroup, faCaretLeft, faSatellite, faLocationDot, faLocationCrosshairs, faRuler, faCopy, faCheck, faMap} from "@fortawesome/free-solid-svg-icons";
library.add(faLayerGroup, faCaretLeft, faSatellite, faLocationDot, faLocationCrosshairs, faRuler, faCopy, faCheck, faMap);


//document.onclick = hideMenu;
//document.oncontextmenu = rightClick;

function hideMenu() {
    document.getElementById("contextMenu").style.display = "none"
}

// custom right click menu
function rightClick(e) {
    e.preventDefault();

    if (document.getElementById("contextMenu")
            .style.display == "block") {
        hideMenu();
    }
    else{
        var menu = document.getElementById("contextMenu")

        menu.style.display = 'block';
        menu.style.position = 'absolute';

        menu.style.left = e.pageX + "px";
        menu.style.top = e.pageY + "px";
    }
}


const ContextMenu = (props) => {


    const [copyCoords, setCopyCoords] = useState('gps');
    const [elevationLoading, setElevationLoading] = useState(false);
    const [cursorElevation, setCursorElevation] = useState(null);

  // context menu functions  ----------




    // prevent the context menu closing on button click
    function contextMenuClick(e){
        e.stopPropagation(); 
        //ev.stopPropagation();
      }
  
      const copyMapCoords = () =>{
        navigator.clipboard.writeText(`${props.cursorLat}, ${props.cursorLng}`);
        setCopyCoords('tick')
      }
  
      const getElevation = () =>{
        setElevationLoading(true)
        fetch(`https://api.open-elevation.com/api/v1/lookup?locations=${props.cursorLat},${props.cursorLng}`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          method: "GET",
        })
          .then(response => response.json())
          .then(data => {
            setElevationLoading(false)
            setCursorElevation(data.results[0].elevation)
          })
      }
  
  
      // end of context menu functions  ----------


    return (
        <div onClick={e=>contextMenuClick(e)} id="contextMenu" className="context-menu" onMouseLeave={()=>{setCursorElevation(null)}} style={{display: 'none'}}>
        <ul>
          <li className="item">
            <a onMouseEnter={()=>{setCopyCoords('copy')}} onMouseLeave={()=>{setCopyCoords('gps')}} onClick={()=>copyMapCoords()}>
              <div className="row">
                <div className="col-1">{copyCoords==='gps' ? <FontAwesomeIcon className="icon-data"icon={faLocationCrosshairs}/>  : copyCoords==='copy' ? <FontAwesomeIcon className="icon-data"icon={faCopy}/> : <FontAwesomeIcon className="icon-data"icon={faCheck}/>}</div>
                <div className="col-10">{props.cursorLat}, {props.cursorLng} {copyCoords==='tick' && <small className="text-muted"> copied</small>}</div>
              </div>
            </a>
          </li>
          <li className="item">
            <a onClick={()=>getElevation()}>
              <div className="row">
                <div className="col-1"><span>{elevationLoading ? <Spinner size="sm"/> : <FontAwesomeIcon className="icon-data"icon={faSatellite}/>} </span></div>
                <div className="col-10">{cursorElevation ? `${cursorElevation}m` : 'Measure Elevation'}</div>
              </div>
            </a>
          </li>
          <li className="item">
            <a id="measureDistance" onClick={()=>{props.onMeasureDistance(true); hideMenu();}}>
              <div className="row">
                <div className="col-1"><FontAwesomeIcon className="icon-data"icon={faRuler}/></div>
                <div className="col-10">Measure Distance</div>
              </div>
            </a>
          </li>
          <li className="item d-none">
            <div className="row">
              <div className="col-1"><FontAwesomeIcon className="icon-data"icon={faLocationDot}/></div>
              <div className="col-10">Add borehole</div>
            </div>
          </li>
        </ul>
    </div>
    );

}

export default ContextMenu;
