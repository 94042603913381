import { Accordion, Card, Offcanvas } from "react-bootstrap";


const NodeManager = (props) => {

    return (
        <Offcanvas
            placement="start"
            show={props.show}
            backdrop={false}
            scroll={true}
            autoFocus={false}
            className={`nodes-sidebar ${props.show && 'hide'}`}
        >
            <Offcanvas.Header closeButton={true} onHide={() => props.setShow(!props.show)}>
                <Offcanvas.Title>Add a node</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <h5>Tasks</h5>
                <Card className="nodes-card-preview" style={{ width: '100%' }} onClick={() => props.updateSelectedNode('search')}>
                    <div className="px-2 py-2">
                        <div className="d-flex justify-content-between align-items-center">
                            <span>Search</span>
                        </div>
                    </div>
                </Card>
                <Card className="nodes-card-preview mt-2" style={{ width: '100%' }} onClick={() => props.updateSelectedNode('task-summary')}>
                    <div className="px-2 py-2">
                        <div className="d-flex justify-content-between align-items-center">
                            <span>Summary Task</span>
                        </div>
                    </div>
                </Card>
                <Card className="nodes-card-preview mt-2" style={{ width: '100%' }} onClick={() => props.updateSelectedNode('task-table')}>
                    <div className="px-2 py-2">
                        <div className="d-flex justify-content-between align-items-center">
                            <span>Table Task</span>
                        </div>
                    </div>
                </Card>
                <Card className="nodes-card-preview mt-2" style={{ width: '100%' }} onClick={() => props.updateSelectedNode('task-custom')}>
                    <div className="px-2 py-2">
                        <div className="d-flex justify-content-between align-items-center">
                            <span>Custom Task</span>
                        </div>
                    </div>
                </Card>

                {/* <h5 className="pt-4">Output</h5>

                <Card className="nodes-card-preview mt-2" style={{ width: '100%' }} onClick={() => props.updateSelectedNode('output-json')}>
                    <div className="px-2 py-2">
                        <div className="d-flex justify-content-between align-items-center">
                            <span>JSON</span>
                        </div>
                    </div>
                </Card>
                <Card className="nodes-card-preview mt-2" style={{ width: '100%' }} onClick={() => props.updateSelectedNode('output-excel')}>
                    <div className="px-2 py-2">
                        <div className="d-flex justify-content-between align-items-center">
                            <span>Excel</span>
                        </div>
                    </div>
                </Card>
                <Card className="nodes-card-preview mt-2" style={{ width: '100%' }} onClick={() => props.updateSelectedNode('output-docx')}>
                    <div className="px-2 py-2">
                        <div className="d-flex justify-content-between align-items-center">
                            <span>Word</span>
                        </div>
                    </div>
                </Card> */}

            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default NodeManager;