import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import Card from 'react-bootstrap/Card'; // Assuming you are using react-bootstrap for Card
import Loader from '../../shared/Loader';

const ImageCard = ( props ) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  const handleImageError = () => {
    setIsError(true);
    setIsLoading(false);
  };

  return (
    <Card className='w-100 my-2'>
      {props.image_url && !isError ? (
        <div className="image-container">
          {isLoading && (
            <div className="d-flex justify-content-center align-items-center p-5 text-secondary">
              <Loader loading={true}/>
            </div>
          )}
          <img
            className={`card-img-top ${isLoading ? 'd-none' : ''}`}
            src={props.image_url}
            alt="Image"
            onLoad={handleImageLoad}
            onError={handleImageError}
          />
        </div>
      ) : (
        <div className="w-100 align-items-center p-5 text-secondary">
          <FontAwesomeIcon icon={faImage} size="3x" />
        </div>
      )}
    </Card>
  );
};

export default ImageCard;
