
import { Modal, ModalBody, ModalHeader, ModalFooter, Table } from "reactstrap";
import { useState, useEffect } from "react";
import { Button, Tooltip } from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCopy, faCheck, faRobot, faCircleInfo, faSatellite} from "@fortawesome/free-solid-svg-icons";

library.add(faCopy, faCheck, faRobot, faCircleInfo);

const SaveConfirmModal = ({ ...props }) => {

    // function to toggle the modal
    const closeModal = () => {
        props.toggleModal()
    }

    // function to handle the delete confirmation
    const saveConfirmed = () => {
        props.saveConfirmed(true)
        closeModal()
    }

    return (
        <>  
            <ModalHeader className="border-0 mb-0 pb-0" toggle={props.toggleModal}> {props.displayName && props.displayName}</ModalHeader>
            <ModalBody>
                <hr className="mb-4"/>
                <div className="container-fluid w-100 pb-2 mb-1">
                    <div>{props.text}</div>
                </div>
                <hr className="mb-4"/>
                <div className="container-fluid w-100 ">
                    <a className="btn btn-primary w-100 mb-2" onClick={() => saveConfirmed()} type="button">
                        Confirm
                    </a>
                    <a className="btn btn-outline-dark w-100" onClick={() => closeModal()} type="button">
                        Cancel
                    </a>
                </div>
            </ModalBody>
        </>
    );
  };
  
  export default SaveConfirmModal;
  