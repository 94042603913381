import { useRef } from "react";
import { FileUploader } from "react-drag-drop-files";


const LibraryUploader = (props) => {
  
  const fileInputRef = useRef(null);

  const handleFileAdded = (files) => {
    
    let list_files = [];
    
    for (var i = 0; i < files.length; i++) {
      list_files.push(files[i]);
    }
    
    props.handeFileAdded(list_files);

    if (fileInputRef.current) {
      fileInputRef.current.clear();
    }
  }

  return (
    <>
      <FileUploader
        classes={`drag-drop-uploader w-100 mb-2`}
        style={{ minWidth: "100%" }}
        label="Drop files here"
        hoverTitle="Drop files here"
        name="file"
        types={["pdf"]}
        handleChange={handleFileAdded}
        maxSize={300}
        onSizeError={()=>{props.setErrorCode(6)}}
        onTypeError={()=> props.setErrorCode(7)}
        multiple={true}
        ref={fileInputRef}
    />
  </>
  );
};

export default LibraryUploader;
