import Loader from "../shared/Loader";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { Card, CardBody, CardHeader } from "reactstrap";
import { faFileExcel, faLocationDot, faMapPin, faFilePdf, faFileCode, faEye, faMapLocation, 
        faDatabase, faList, faClock,  faTriangleExclamation, faCloudArrowUp,faAngleLeft,faAngleRight} from "@fortawesome/free-solid-svg-icons";
import httpClientPy from "../../utils/httpClientPy";
import { logEvent } from "../shared/Mixpanel";
import { toast, ToastContainer } from "react-toastify";

library.add(faFileExcel, faMapPin, faLocationDot, faFilePdf, faMapLocation, faFileCode, faEye, faDatabase,faTriangleExclamation, faList, faClock, faCloudArrowUp);

const LibrarySearchHistory = (props) => {

    const [history, setHistory] = useState([]);
    const [totalHistoryPages, setTotalHistoryPages] = useState(0);
    const [loading, setLoading] = useState(false);
    const [currentPageNumber, setCurrentPageNumber] = useState(1);

    const paginate = (pageNumber) => setCurrentPageNumber(pageNumber);

    const searchHistoryRetrieval = async () => {
        setLoading(true);
        try {
            let response = await httpClientPy.get(`/library/search/history?library_id=${props.libraryId}&limit=${10}&offset=${(currentPageNumber - 1) * 10}`);
            let history = response.data.messages;
            let totalHistoryPages = response.data.total_pages;
            logEvent('Library Search History');
            setHistory(history);
            setTotalHistoryPages(totalHistoryPages);
            setLoading(false);
        } catch (err) {
            let error_code = err.response.data.detail;
            if (error_code === 4) {
                toast.error('Document limit has been exceeded, contact your admin in order to check limit balance.', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
            } else if (error_code === 3) {
                toast.error('User is not part of enterprise account.', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
            } else if (error_code === 5) {
                toast.error('Library does not exist or user is not part of the enterprise.', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
            } else if (error_code === 1) {
                toast.error('Internal problem has caused history loading to fail, please contact info@civils.ai for more information. Thank you', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
            }
            setLoading(false);
            props.toggleHistory();
        }
    }

    useEffect(() => {
        searchHistoryRetrieval();
    }, [currentPageNumber]);

    const handleRowClick = (query, result) => {
        props.setHistoryResult({query: query, response: result});
        props.toggleHistory();
    }

    return (
        <>
            <ToastContainer />
            <div>
                <div className="">
                <Card className="h-100 border-0 ">
                <CardHeader>
                    <h3 className='title-bold pb-0 mb-0 text-center'>Search History</h3>
                </CardHeader>
                {loading ?
                    <div className="my-5">
                        <Loader loading={loading} />
                    </div>
                    :
                    <CardBody>
                            {history && history.length > 0 && (
                                <>    
                                    <table class="table table table-borderless">
                                        <thead>
                                            <tr>
                                            <td className="text-center"><b>Search Query</b></td>
                                            <td className="text-center"><b>Date Searched</b></td>
                                            <td className="text-center"><b>Reference Found</b></td>
                                            <td className="text-center"><b>Num of Ref(s)</b></td>
                                            </tr>
                                        </thead>
                                        <tbody className='small'>
                                            {history.map((row, index) => (
                                                <tr key={index} className='bg-white'>
                                                    <td className="text-center" onClick={() => handleRowClick(row.query, row.result)} style={{cursor: 'pointer'}}>
                                                        {row.query}
                                                    </td>
                                                    <td className="text-center" onClick={() => handleRowClick(row.query, row.result)} style={{cursor: 'pointer'}}>
                                                        {row.created_at}
                                                    </td>
                                                    <td className="text-center" onClick={() => handleRowClick(row.query, row.result)} style={{cursor: 'pointer'}}>
                                                        {row.has_best_reference === true ? (
                                                            <span className="badge rounded-pill green">{"yes"}</span>
                                                        ) : (
                                                            <span className="badge rounded-pill red">{"no"}</span>
                                                        )}
                                                    </td>
                                                    <td className="text-center" onClick={() => handleRowClick(row.query, row.result)} style={{cursor: 'pointer'}}>
                                                        {row.num_of_references > 0 ? (
                                                            <span className="badge rounded-pill green">{row.num_of_references}</span>
                                                        ) : (
                                                            <span className="badge rounded-pill red">{row.num_of_references}</span>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <div className="d-flex justify-content-end">
                                        {currentPageNumber > 1 && (
                                            <button className="btn btn-primary btn-sm me-2" onClick={() => paginate(currentPageNumber - 1)}>
                                                <FontAwesomeIcon icon={faAngleLeft} />
                                            </button>
                                        )}
                                        {currentPageNumber} / {totalHistoryPages}
                                        {currentPageNumber < totalHistoryPages && (
                                            <button className="btn btn-primary btn-sm ms-2" onClick={() => paginate(currentPageNumber + 1)}>
                                                <FontAwesomeIcon icon={faAngleRight} />
                                            </button>
                                        )}
                                    </div>
                                </>
                            )}
                            {(!history || history.length === 0) && !loading && (
                                <>
                                <h6 className="text-muted text-center pt-3">
                                    No search history found.
                                </h6>
                                </>
                            )}
                        </CardBody>
                    }
                </Card>
                </div>
            </div>
        </>
    );
};

export default LibrarySearchHistory;
