import { HexColorPicker } from "react-colorful";
import {Modal, ModalBody, ModalHeader, Toast } from "reactstrap";
import Button from "../shared/Button";
import httpClientPy from '../../utils/httpClientPy';
import { useEffect, useState } from "react";
import CategoryEditor from "./CategoryEditor";
import { toast, ToastContainer } from "react-toastify";
import Loader from "../shared/Loader";

const LibrarySettings = (props) => {

    const [loading, setLoading] = useState(false)
    const [categories, setCategories] = useState([])
    const [categoryEditorModel, setCategoryEditorModel] = useState(false)
    const [editorTask, setEditorTask] = useState(0)
    const [selectedCategory, setSelectedCategory] = useState(null)
    const [deleteCategory, setDeleteCategory] = useState(false)

    const toggleCategoryEditorModel = (task, category) => {
        if (task === 0) {
            setEditorTask(task)
            setSelectedCategory(null)
        } else {
            setEditorTask(task)
            setSelectedCategory(category)
        }
        setCategoryEditorModel(!categoryEditorModel)
    }

    const toggleDeleteCategory = (category) => {
        setSelectedCategory(category)
        setDeleteCategory(!deleteCategory)
    }

    useEffect(() => {
        setLoading(true)
        httpClientPy.get(`/library/categories`)
        .then((response) => {
            let categories = response.data.categories
            // there are two keys in each category object, name and color
            // we need to convert them to label and value for react-select
            let categoriesForSelect = categories.map((category) => {
                return {
                    id: category.id,
                    name: category.name,
                    color: category.color,
                }
            })
            setCategories(categoriesForSelect)
        }).catch((error) => {
            let error_code = error.response.data.detail;
            if (error_code === 4) {
                toast.error('Document limit has been exceeded, contact your admin in order to check limit balance.', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
            } else if (error_code === 3) {
                toast.error('User is not part of enterprise account.', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
            } else if (error_code === 5) {
                toast.error('No categories exist for this enterprise', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
            } else if (error_code === 1) {
                toast.error('Internal problem while loading categories, please contact info@civils.ai for more information. Thank you', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
            }
            setLoading(false)
        })
        setLoading(false)
    }, [categoryEditorModel, deleteCategory])

    const handleDeleteCategory = () => {
        setLoading(true)
        httpClientPy.delete(`/library/category/delete?category_id=${selectedCategory.id}`)
        .then((response) => {
            setLoading(false)
            setSelectedCategory(null)
            setDeleteCategory(false)
        }).catch((error) => {
            let error_code = error.response.data.detail;
            if (error_code === 4) {
                toast.error('Document limit has been exceeded, contact your admin in order to check limit balance.', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
            } else if (error_code === 3) {
                toast.error('User is not part of enterprise account.', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
            } else if (error_code === 5) {
                toast.error('No categories exist for this enterprise', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
            } else if (error_code === 1) {
                toast.error('Internal problem while loading categories, please contact info@civils.ai for more information. Thank you', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
            }
            setLoading(false)
        })
    }

    return (
        <>
        <ToastContainer />
        <Modal isOpen={props.isOpen} toggle={props.toggle} size="xl">
            <ModalHeader className='title-bold border-0 pb-0 mb-0' toggle={props.toggle}>Category Management</ModalHeader>
            {loading ? (
            <Loader loading={loading} />
            ) : (
                <ModalBody>
                    <div className="justify-content-start d-flex">
                        <Button className="btn btn-primary mb-3" onClick={() => toggleCategoryEditorModel(0, null)} label={"New Category"} loading={loading} />
                    </div>
                    <table className="table table-borderless">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Color</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {categories.map((category) => {
                                return (
                                    <tr>
                                        <td>{category.name}</td>
                                        <td><div className="circle" style={{'backgroundColor': category.color}}></div></td>
                                        <td>
                                            <Button className="btn btn-sm btn-outline-primary me-2" onClick={() => toggleCategoryEditorModel(1, category)} label={"Edit"} loading={loading} />
                                            <Button className="btn btn-sm btn-outline-danger" onClick={() => toggleDeleteCategory(category)} label={"Delete"} loading={loading} />
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </ModalBody>
            )}
        </Modal>

        <CategoryEditor isOpen={categoryEditorModel} toggle={toggleCategoryEditorModel} task={editorTask} category={selectedCategory} />

        <Modal id="SelectReg" size="l" isOpen={deleteCategory} toggle={() => setDeleteCategory(false)}>
            <ModalHeader className="border-0 mb-0 pb-0" toggle={() => setDeleteCategory(false)}>Delete Category</ModalHeader>
            <ModalBody>
                <hr className="mb-4"/>
                <div className="container-fluid w-100 pb-2 mb-1">
                    <div>Are you sure you want to delete this category? This action cannot be undone.</div>
                </div>
                <hr className="mb-4"/>
                <div className="container-fluid w-100 ">
                    <a className="btn btn-primary w-100 mb-2" onClick={() => handleDeleteCategory()} type="button">
                        Confirm
                    </a>
                    <a className="btn btn-outline-dark w-100" onClick={() => setDeleteCategory(false)} type="button">
                        Cancel
                    </a>
                </div>
            </ModalBody>
        </Modal>
        </>
    );
}

export default LibrarySettings;