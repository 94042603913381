import React, { useState, useEffect } from 'react';
import { ModalHeader, ModalBody, Spinner } from 'reactstrap';
import httpClientPy from "../../../../utils/httpClientPy";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock,} from "@fortawesome/free-solid-svg-icons";
import Button from '../../../shared/Button';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import Select from 'react-select'

const RegSelecterModal = (props) => {
    const [loading, setLoading] = useState(true);
    const [regs, setRegs] = useState(null);
    const [totalRows, setTotalRows] = useState(0);
    const [regsFileNames, setRegsFileNames] = useState([]);
    const [currentRowsPerPage, setCurrentRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentSortBy, setCurrentSortBy] = useState('uploaded');
    const [currentSortOrder, setCurrentSortOrder] = useState('desc');
    const [fileNames, setFileNames] = useState([]);
    const [searchFilter, setSearchFilter] = useState({"file_names": [], "project_names": []});

    const fetchRecords = async (page, rowsPerPage, sortBy, sortOrder) => {
        setLoading(true);
        setRegs([]);
        return httpClientPy
        .post('/geo/records',
            {
            project_id: props.project.id,
            geotech: 3,
            limit: rowsPerPage,
            offset: (page-1)*rowsPerPage,
            sort_by: sortBy ? sortBy : currentSortBy,
            sort_order: sortOrder ? sortOrder : currentSortOrder,
            search: searchFilter
            })
        .then((result) => {
            setRegs(result.data.data);
            setTotalRows(result.data.total_rows.length);
            setLoading(false);
        })
        .catch((err) => {
            console.log(err);
        })
    }

    useEffect(() => {
        httpClientPy
        .get(`/geo/records/meta?project_id=${props.project.id}&geotech=3`)
        .then((result) => {
          let file_names = result.data.file_names  
          let file_names_list = []

          file_names.forEach((item) => {
            file_names_list.push({value: item, label: item})
          })
  
          setFileNames(file_names_list)
        })
        .catch((error) => {
          throw error;
        })
    }, []);

    useEffect(() => {
        fetchRecords(currentPage, currentRowsPerPage, currentSortBy, currentSortOrder);
    }, [searchFilter]);

    const handleSort = (column, sortDirection) => {
        fetchRecords(currentPage, currentRowsPerPage, column.id, sortDirection);
        setCurrentSortBy(column.id);
        setCurrentSortOrder(sortDirection);
    };

    const filterRegsName = (selectedOption) => {
        if(selectedOption) {
            setSearchFilter({'file_names': [selectedOption.value], 'project_names': []})
        } else {
            setSearchFilter({'file_names': [], 'project_names': []})
        }
    }

    const columns = [
        {
          name: 'File name',
          id: 'file_name', 
          selector: row => row.original_file_name,
          center: true,
          sortable: true,
          cell: (row) => row.original_file_name,
        },
        {
          name: 'Added',
          id: 'uploaded',
          selector: row => row.created_datetime ? new Date(row.created_datetime) : new Date(0), // Return raw datetime for sorting
          width: '100px',
          center: true,
          sortable: true,
          cell: (row) => (
            <span className="badge rounded-pill darkGrey documentBadge">
              <span><FontAwesomeIcon icon={faClock} /></span>
              <span className="ms-1">
                {row.created_datetime
                  ? moment(row.created_datetime).format('DD/MM/YYYY')
                  : 'Unknown'}
              </span>
            </span>
          ),
        },
        {
            name: '',
            width: '150px',
            center: true,
            cell: (row) => {
              return (
                <>
                  <Button label={"Select"} className="btn btn-primary" onClick={() => handleSelectClick(row)} />
                </>
              );
            },
          }
    ];   

    const customStyles = {
        headCells: {
          style: {
            fontSize: '1rem',
            fontWeight: 'bold',
          },
        },
        rows: {
          style: {
            cursor: props.type === 2 ? 'default' : 'pointer',
          },
        },
        table: {
            zIndex: 0,
        },
    };

    const handleSelectClick = (row) => {
        props.updateSelectedRegName(row.original_file_name);
        props.toggleModal();
    }

    return (
        <>
            <ModalHeader className="" toggle={()=>props.toggleModal()}>
                <h4 className="d-inline-flex title-bold align-items-center mb-0 pb-0">
                    {props.title}
                </h4>
            </ModalHeader>
            <ModalBody
                >
                {!loading && regs ?
                    <>
                        {regs.length > 0 ?
                            <>  
                                <div className="d-flex mb-2 position-relative justify-content-between" style={{'z-index': 2}}>
                                    <h6 className="title-bold"></h6>
                                    <Select
                                        className="basic-single filter-record-select-sm text-center ms-2"
                                        classNamePrefix="select"
                                        isSearchable={true}
                                        isClearable={true}
                                        name="filterRegs"
                                        isMulti={false}
                                        placeholder="Search for Regulation file name"
                                        onChange={filterRegsName}
                                        closeMenuOnSelect={true}
                                        isRtl={true}
                                        options={fileNames}
                                        defaultValue={searchFilter.file_names[0] ? {value: searchFilter.file_names[0], label: searchFilter.file_names[0]} : null}
                                    />
                                </div>
                                <div className="position-relative" style={{ zIndex: 1 }}>
                                    <DataTable
                                        className="sticky-top bg-white text-center"
                                        pagination
                                        paginationServer={true}
                                        paginationTotalRows={totalRows}
                                        paginationRowsPerPageOptions={[5, 10, 15, 25]}
                                        paginationPerPage={currentRowsPerPage}
                                        paginationDefaultPage={currentPage}
                                        persistTableHead 
                                        sortServer
                                        onSort={handleSort}
                                        defaultSortFieldId={currentSortBy}
                                        defaultSortAsc={currentSortOrder === 'asc'}
                                        searchable
                                        highlightOnHover
                                        columns={columns}
                                        data={regs}
                                        customStyles={customStyles}
                                    />
                                </div>
                            </>
                        :
                            <>
                                <div>You have no building codes uploaded yet, please upload some to check your project documents against.</div>
                            </>
                        }
                    </>
                    :
                        <div className='w-100 pt-3 text-center'>
                            <Spinner size="lg"/>
                        </div>
                }
            </ModalBody>
        </>
    )   
}

export default RegSelecterModal;