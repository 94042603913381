
  ///----------------------- ESTIMATE VIRTUAL BOREHOLE ------------------------///
  var kriging

  export function estimateVirtualBorehole(boreholeData, clickLocation, boreholeCount, selectionRadius, topography, sensitivity) {

    // these are the algorithms which are used to generate the 3D model
    // issues:
    // - if a full borehole is unknown how is this handled?
    // - if strata exists in only one borehole how is this handled
    // - issue with surface level assumption
    // - confidence level
    // - grouping

    // clone data so don't intefere with initial copy
    var predictedStrata = []
    var predictedUnknownStrata = []
    let fullStrataList = []
    let fitModelTop = []
    let fitModelBase = []

    var lat_dif = boreholeData.virtual.max_lat - boreholeData.virtual.min_lat
    var lng_dif = boreholeData.virtual.max_lng - boreholeData.virtual.min_lng
    let point_lat = null
    let point_lng = null

    let surface_pointcloud = []
    let virtual_ground_level = 0
    kriging = []
    initialize_krigging()

    if(boreholeData.topography){
        let surface_pointcloud_results = create_surface_pointcloud(boreholeData, boreholeData.topography, selectionRadius, clickLocation)
        surface_pointcloud = surface_pointcloud_results['pointcloud']
        virtual_ground_level = surface_pointcloud_results['virtual_ground_level']
    }
    else{
        // create the ground surface
        for ( let i = -50; i < 50; i+=5 ) {
            for ( let l = -50; l < 50; l+=5 ) {
                const x = parseFloat(i);
                const z = parseFloat(l);
                surface_pointcloud.push(x+5, 0, z );
            }
        }
    }


    boreholeData.virtual.surface_pointcloud = surface_pointcloud

    var water_present = false
    for(let borehole of boreholeData.boreholes){
      if(borehole.data.water_level){
        water_present = true
      }
    }

    boreholeData.virtual.water_pointcloud = []
    if(water_present){
      let water_pointcloud_results = create_water_pointcloud(boreholeData, boreholeData.topography, selectionRadius, clickLocation)
      boreholeData.virtual.water_pointcloud = water_pointcloud_results
    }


    // if there is just one borehole then copy and add to the boreholeData.boreholes list
    if(boreholeCount === 1){
      let borehole = boreholeData.boreholes[0]
      boreholeData.boreholes.push(borehole)
    }

    // classify and name the strata materials and append to full list
    for (let borehole of boreholeData.boreholes){
      
      let classified_strata = classifyStrata(borehole)
      let grouped_classified_strata = []

      for (let strata of classified_strata){
        //for all same strata in classified_strata combine them into one strata layer with the same material and set the top and base to the highest and lowest values
        if (grouped_classified_strata.length){
          let found = false
          for (let grouped_strata of grouped_classified_strata){
            if (grouped_strata.material === strata.material){
              found = true
              if(topography){
                if (grouped_strata.top < strata.top){
                  grouped_strata.top = strata.top
                }
                if (grouped_strata.base > strata.base){
                  grouped_strata.base = strata.base
                }
              }
              else{
                if (grouped_strata.top > strata.top){
                  grouped_strata.top = strata.top
                }
                if (grouped_strata.base < strata.base){
                  grouped_strata.base = strata.base
                }
              }

            }
          }
          if (!found){
            grouped_classified_strata.push(strata)
          }
        }
        else{
          grouped_classified_strata.push(strata)
        }
      }
      for (let strata of grouped_classified_strata){
        fullStrataList.push(strata)
      }
    }



    for(var i = 0; i < fullStrataList.length;  i){

      let subStrataList = []
      var geoDescription = ''
      var visible = true
      var geoltop = []
      var geolbase = []
      var x = []
      var y = []


      //shift off the top of the full strata layers list and repeatedly search through the full strata layers list
      //if a identical strata material class is found splice this from full layers list and add to the new list only if you haven't already added from this list
      

      let strataKey = fullStrataList.shift();

      subStrataList.push(strataKey)
      let idList = [strataKey.id]
      for (let j = fullStrataList.length - 1; j >= 0; j--){
      
          if(strataKey.material !== '-'){
      
              if (fullStrataList[j].material === strataKey.material && !idList.includes(fullStrataList[j].id)){
                idList.push(fullStrataList[j].id)
                subStrataList.push(fullStrataList.splice(j, 1)[0]);
              }
          }
          else{}
      }
      

      // if the strata layer is present in more than one borehole use krigging
      if (subStrataList.length > 1){

        let iterations = subStrataList.length;

        //loop to create a list of all the base geology levels and descriptions
        for (let strata of subStrataList){
          iterations--;
          geoDescription = geoDescription.concat(` ${strata.description} ${iterations>0 ? '/' : ''}`)
          visible = strata.visible
          geoltop.push( parseFloat(strata.top))
          geolbase.push( parseFloat(strata.base))
          let x_dist = distanceInKmBetweenEarthCoordinates(boreholeData.virtual.min_lat, strata.coordinates.lng, boreholeData.virtual.min_lat, boreholeData.virtual.min_lng)*1000
          let z_dist = distanceInKmBetweenEarthCoordinates(strata.coordinates.lat, boreholeData.virtual.min_lng, boreholeData.virtual.min_lat, boreholeData.virtual.min_lng)*1000
          
          x_dist = 20 * (x_dist / (2*selectionRadius)) - 20/2
          z_dist = 20 * (z_dist / (2*selectionRadius)) - 20/2
          
          x.push( parseFloat(x_dist))
          y.push( parseFloat(z_dist))
        }

        let lat_holder = clickLocation.lat
        let lng_holder = clickLocation.lng
        if(lat_holder < boreholeData.virtual.min_lat){
          lat_holder = boreholeData.virtual.min_lat
        }
        if(lat_holder > boreholeData.virtual.max_lat){
          lat_holder = boreholeData.virtual.max_lat
        }
        if(lng_holder < boreholeData.virtual.min_lng){
          lng_holder = boreholeData.virtual.min_lng
        }
        if(lng_holder > boreholeData.virtual.max_lng){
          lng_holder = boreholeData.virtual.max_lng
        }
        let center_x = distanceInKmBetweenEarthCoordinates(boreholeData.virtual.min_lat, lng_holder, boreholeData.virtual.min_lat, boreholeData.virtual.min_lng)*1000
        let center_z = distanceInKmBetweenEarthCoordinates(lat_holder, boreholeData.virtual.min_lng, boreholeData.virtual.min_lat, boreholeData.virtual.min_lng)*1000

        center_x = 20 * (center_x / (2*selectionRadius)) - 20/2
        center_z = 20 * (center_z / (2*selectionRadius)) - 20/2

        // SMALL HACK - krigging algorithm doesnt work if the top and base are the same, so here I am adding a small value
        if(geoltop[0]===geoltop[1]){geoltop[1]=geoltop[1]+0.000000001}
        if(geolbase[0]===geolbase[1]){geolbase[1]=geolbase[1]+0.00000001}

        // HACK - the krigging algorithm is only working for >2 data points, so here I am adding an additional dummy data point
        if(subStrataList.length > 1){
          geoltop.push( parseFloat(geoltop[0]))
          geolbase.push( parseFloat(geolbase[0]))
          x.push( parseFloat(x[0]+100))
          y.push( parseFloat(y[0]+100))
        }

        // run the krigging algorithm from krigging.js
        //HERE I NEED TO ITERATE OVER ENTIRE 100X100 GRID AT INTERVALS OF 5 AND KRIG THE TOP AND BOTTOM OF GEOLOGY THEN ADD TO THE PREDICTED STRATA DICT
        let model = "spherical";
        var sigma2 = parseFloat(1-sensitivity);
        var alpha = 100;

        fitModelTop[i] = kriging.train(geoltop, x, y, model, sigma2, alpha);
        fitModelBase[i] = kriging.train(geolbase, x, y, model, sigma2, alpha);
        let geolbasenew = kriging.predict( center_x,  center_z, fitModelBase[i]);
        let geoltopnew = kriging.predict( center_x,  center_z, fitModelTop[i]);

        let base = Math.round(geolbasenew * 100) / 100
        let top = Math.round(geoltopnew * 100) / 100

        // if(top>virtual_ground_level){
        //   top = virtual_ground_level
        // }
        // if(base>virtual_ground_level){
        //   base = virtual_ground_level
        // }

        var boreHolePointCloudHolder = new Array();
        for ( let m = 0; m < 20; m+=1 ) {
          boreHolePointCloudHolder[m] = new Array();

          for ( let n = 0; n < 20; n+=1 ) {
            let xcoord =  n-10
            let ycoord = m-10
            let topHolder = Math.round(kriging.predict( xcoord,  ycoord, fitModelTop[i]) * 100) / 100
            let bottomHolder = Math.round(kriging.predict( xcoord,  ycoord, fitModelBase[i]) * 100) / 100

            if(topography && surface_pointcloud[m][n] && surface_pointcloud[m][n].elevation){

              if(topHolder > surface_pointcloud[m][n].elevation){
                topHolder = surface_pointcloud[m][n].elevation
              }

              if(bottomHolder > surface_pointcloud[m][n].elevation){
                bottomHolder = surface_pointcloud[m][n].elevation
              }

            }

            point_lat = boreholeData.virtual.min_lat + (lat_dif/20)*m
            point_lng = boreholeData.virtual.min_lng + (lng_dif/20)*n

            boreHolePointCloudHolder[m][n] = {
              top: topHolder,
              base: bottomHolder,
              lat: point_lat,
              lng: point_lng
            }
            
          }
        }

        let max_top = 0
        let min_base = 0
        if(topography){
          max_top = Math.max(...geoltop).toFixed(2)
          min_base = Math.min(...geolbase).toFixed(2)
        }
        else{
          max_top = Math.min(...geoltop).toFixed(2)
          min_base = Math.max(...geolbase).toFixed(2)
        }


        predictedStrata.push({
          material: subStrataList[0].material,
          base: base,
          top: top,
          max_top: max_top,
          min_base:  min_base,
          description: geoDescription,
          pointcloud: boreHolePointCloudHolder,
          visible: visible
        })

      }


      // if you only have one borehole within selection zone, virtual borehole is identical
      else if(subStrataList.length===1 && boreholeCount===1){

        for (let strata of subStrataList){

          boreHolePointCloudHolder = new Array();
          for ( let m = 0; m < 20; m+=1 ) {
            boreHolePointCloudHolder[m] = new Array();
  
            for ( let n = 0; n < 20; n+=1 ) {
              let topHolder = strata.top
              let bottomHolder = strata.base
              visible = strata.visible
  
              point_lat = boreholeData.virtual.min_lat + (lat_dif/20)*m
              point_lng = boreholeData.virtual.min_lng + (lng_dif/20)*n

              boreHolePointCloudHolder[m][n] = {
                top: topHolder,
                base: bottomHolder,
                lat: point_lat,
                lng: point_lng,
              }
              
            }
          }


          predictedStrata.push({
            material: strata.material,
            base: strata.base,
            top: strata.top,
            description: strata.description,
            pointcloud: boreHolePointCloudHolder,
            max_top: strata.top,
            min_base:  strata.base,
            visible: visible
          })
        }

      }




      // if you have strata in one borehole and no strata in the others
      // assume layers are same thickness, then iterate through full list and insert into any unknown gaps where they intersect
      // concat the descriptions to be like 'potentially this, potentially that'
      // score how many unknowns to arrive at confidence level

      else if(subStrataList.length===1 && boreholeCount>1){

        if (topography){
          predictedStrata.sort((a, b) => (a.top < b.top) ? 1 : -1)
        }
        else{
          predictedStrata.sort((a, b) => (a.top > b.top) ? 1 : -1)
        }


        for (let strata of subStrataList){
          predictedUnknownStrata.push({
            material: '-',
            base: strata.base,
            top: strata.top,
            description: strata.description,
          })
        }
      }
    }

    let unknownToBeAdded = []
    for(var k = 0; k < predictedStrata.length;  k++){
      var holder = []
      var top
      var base

      if(k>0){
        top = predictedStrata[k-1].base 
        base = predictedStrata[k].top
      }
      else{
        top = virtual_ground_level
        base = predictedStrata[k].top
      }

      var desc = ''
      if (topography){
        if(top > base){
          for (let strata of predictedUnknownStrata){

            if (strata.top>=base && top >= strata.base && strata.top != strata.base){
              holder.push(strata)
            }
          }
          for(let unknownStrata of holder){
            desc = desc.concat(`Potentially ${unknownStrata.description} `)
          }
          unknownToBeAdded.push({
            material: '-',
            base: base,
            top: top,
            description: desc,
          })
        }
      }
      else{
        if(top < base){

          for (let strata of predictedUnknownStrata){

            if (strata.top<=base && top <= strata.base){
              holder.push(strata)
            }
          }
          for(let unknownStrata of holder){
            desc = desc.concat(`Potentially ${unknownStrata.description} `)
          }
          unknownToBeAdded.push({
            material: '-',
            base: base,
            top: top,
            description: desc,
          })
        }
      }
    }
    for (let strata of unknownToBeAdded){
      predictedStrata.push(strata)
    }

    if (topography){
      predictedStrata.sort((a, b) => (a.top < b.top) ? 1 : -1)
    }
    else{
      predictedStrata.sort((a, b) => (a.top > b.top) ? 1 : -1)
    }


    return {
      "predictedStrata":predictedStrata,
      "virtual_ground_level":virtual_ground_level,
    }
  
  }




  export function create_surface_pointcloud(boreholeData, topography, selectionRadius, clickLocation){
    let elevations = []
    let x = []
    let y = []
    let fitModelTop = []
    var lat_dif = boreholeData.virtual.max_lat - boreholeData.virtual.min_lat
    var lng_dif = boreholeData.virtual.max_lng - boreholeData.virtual.min_lng

    let lat_holder = clickLocation.lat
    let lng_holder = clickLocation.lng
    if(lat_holder < boreholeData.virtual.min_lat){
      lat_holder = boreholeData.virtual.min_lat
    }
    if(lat_holder > boreholeData.virtual.max_lat){
      lat_holder = boreholeData.virtual.max_lat
    }
    if(lng_holder < boreholeData.virtual.min_lng){
      lng_holder = boreholeData.virtual.min_lng
    }
    if(lng_holder > boreholeData.virtual.max_lng){
      lng_holder = boreholeData.virtual.max_lng
    }
    let center_x = distanceInKmBetweenEarthCoordinates(boreholeData.virtual.min_lat, lng_holder, boreholeData.virtual.min_lat, boreholeData.virtual.min_lng)*1000
    let center_z = distanceInKmBetweenEarthCoordinates(lat_holder, boreholeData.virtual.min_lng, boreholeData.virtual.min_lat, boreholeData.virtual.min_lng)*1000

    center_x = 20 * (center_x / (2*selectionRadius)) - 20/2
    center_z = 20 * (center_z / (2*selectionRadius)) - 20/2


    for (let borehole of boreholeData.boreholes){

      elevations.push( parseFloat(borehole.data.groundlevel))
      let x_dist = distanceInKmBetweenEarthCoordinates(boreholeData.virtual.min_lat, borehole.data.coordinates.lng, boreholeData.virtual.min_lat, boreholeData.virtual.min_lng)*1000
      let z_dist = distanceInKmBetweenEarthCoordinates(borehole.data.coordinates.lat, boreholeData.virtual.min_lng, boreholeData.virtual.min_lat, boreholeData.virtual.min_lng)*1000
      
      x_dist = 20 * (x_dist / (2*selectionRadius)) - 20/2
      z_dist = 20 * (z_dist / (2*selectionRadius)) - 20/2
      
      x.push( parseFloat(x_dist))
      y.push( parseFloat(z_dist))
    }

    // SMALL HACK - krigging algorithm doesnt work if the top and base are the same, so here I am adding a small value
    if(elevations[0]===elevations[1]){elevations[1]=elevations[1]+0.000000001}

    // HACK - the krigging algorithm is only working for >2 data points, so here I am adding an additional dummy data point
    if(boreholeData.boreholes.length > 1){
      elevations.push( parseFloat(elevations[0]))
      x.push( parseFloat(x[0]+100))
      y.push( parseFloat(y[0]+100))
    }

    // run the krigging algorithm from krigging.js
    //HERE I NEED TO ITERATE OVER ENTIRE 100X100 GRID AT INTERVALS OF 5 AND KRIG THE TOP AND BOTTOM OF GEOLOGY THEN ADD TO THE PREDICTED STRATA DICT
    let model = "spherical";
    let sigma2 = 0, alpha = 100;

    if(boreholeData.boreholes.length > 1){
      fitModelTop[0] = kriging.train(elevations, x, y, model, sigma2, alpha);
    }

    var boreHolePointCloudHolder = new Array();
    for ( let m = 0; m < 20; m+=1 ) {
      boreHolePointCloudHolder[m] = new Array();

      for ( let n = 0; n < 20; n+=1 ) {
        let xcoord =  n-10
        let ycoord = m-10
        let topHolder 
        if(boreholeData.boreholes.length > 1){
          topHolder = Math.round(kriging.predict( xcoord,  ycoord, fitModelTop[0]) * 100) / 100
        }
        else if (boreholeData.boreholes.length === 1){
          topHolder = elevations[0]
        }
        
        let point_lat = boreholeData.virtual.min_lat + (lat_dif/20)*m
        let point_lng = boreholeData.virtual.min_lng + (lng_dif/20)*n

        boreHolePointCloudHolder[m][n] = {
          elevation: topHolder,
          lat: point_lat,
          lng: point_lng
        }

      }

    }
    let surface
    if(boreholeData.boreholes.length > 1){
      surface  = kriging.predict( center_x,  center_z, fitModelTop[0]);
    }
    else if (boreholeData.boreholes.length === 1){
      surface = elevations[0]
    }

    let virtual_ground_level = Math.round(surface * 100) / 100

    return {
        "pointcloud": boreHolePointCloudHolder,
        "virtual_ground_level": virtual_ground_level,
      }
  }




  export function create_water_pointcloud(boreholeData, topography, selectionRadius, clickLocation){
    let water_levels = []
    let x = []
    let y = []
    let fitModelTop = []
    var lat_dif = boreholeData.virtual.max_lat - boreholeData.virtual.min_lat
    var lng_dif = boreholeData.virtual.max_lng - boreholeData.virtual.min_lng


    let lat_holder = clickLocation.lat
    let lng_holder = clickLocation.lng
    if(lat_holder < boreholeData.virtual.min_lat){
      lat_holder = boreholeData.virtual.min_lat
    }
    if(lat_holder > boreholeData.virtual.max_lat){
      lat_holder = boreholeData.virtual.max_lat
    }
    if(lng_holder < boreholeData.virtual.min_lng){
      lng_holder = boreholeData.virtual.min_lng
    }
    if(lng_holder > boreholeData.virtual.max_lng){
      lng_holder = boreholeData.virtual.max_lng
    }

    for (let borehole of boreholeData.boreholes){
      if(borehole.data.water_level){
        water_levels.push( parseFloat(borehole.data.water_level))
        let x_dist = distanceInKmBetweenEarthCoordinates(boreholeData.virtual.min_lat, borehole.data.coordinates.lng, boreholeData.virtual.min_lat, boreholeData.virtual.min_lng)*1000
        let z_dist = distanceInKmBetweenEarthCoordinates(borehole.data.coordinates.lat, boreholeData.virtual.min_lng, boreholeData.virtual.min_lat, boreholeData.virtual.min_lng)*1000
        
        x_dist = 20 * (x_dist / (2*selectionRadius)) - 20/2
        z_dist = 20 * (z_dist / (2*selectionRadius)) - 20/2
        
        x.push( parseFloat(x_dist))
        y.push( parseFloat(z_dist))
      }
    }
    

    // SMALL HACK - krigging algorithm doesnt work if the top and base are the same, so here I am adding a small value
    if(water_levels[0]===water_levels[1]){water_levels[1]=water_levels[1]+0.000000001}
    var boreHolePointCloudHolder = new Array();

    // HACK - the krigging algorithm is only working for >2 data points, so here I am adding an additional dummy data point
    if(water_levels.length <= 1){
      
      for ( let m = 0; m < 20; m+=1 ) {
        boreHolePointCloudHolder[m] = new Array();

        for ( let n = 0; n < 20; n+=1 ) {
  
          let point_lat = boreholeData.virtual.min_lat + (lat_dif/20)*m
          let point_lng = boreholeData.virtual.min_lng + (lng_dif/20)*n
  
          boreHolePointCloudHolder[m][n] = {
            water_level: water_levels[0],
            lat: point_lat,
            lng: point_lng
          }
        }
      }
      


    }
    else{

      water_levels.push( parseFloat(water_levels[0]))
      x.push( parseFloat(x[0]+100))
      y.push( parseFloat(y[0]+100))
    

    // run the krigging algorithm from krigging.js
    //HERE I NEED TO ITERATE OVER ENTIRE 100X100 GRID AT INTERVALS OF 5 AND KRIG THE TOP AND BOTTOM OF GEOLOGY THEN ADD TO THE PREDICTED STRATA DICT
    let model = "spherical";
    let sigma2 = 0, alpha = 100;
    fitModelTop[0] = kriging.train(water_levels, x, y, model, sigma2, alpha);

    for ( let m = 0; m < 20; m+=1 ) {
      boreHolePointCloudHolder[m] = new Array();

      for ( let n = 0; n < 20; n+=1 ) {
        let xcoord =  n-10
        let ycoord = m-10
        let topHolder = Math.round(kriging.predict( xcoord,  ycoord, fitModelTop[0]) * 100) / 100

        let point_lat = boreholeData.virtual.min_lat + (lat_dif/20)*m
        let point_lng = boreholeData.virtual.min_lng + (lng_dif/20)*n

        boreHolePointCloudHolder[m][n] = {
          water_level: topHolder,
          lat: point_lat,
          lng: point_lng
        }

      }

      }
    }

    return boreHolePointCloudHolder
  }


  function classifyStrata(boreholeData){
    let boreholeDataCopy = structuredClone(boreholeData)
    
    var classifiedStrata = []
    var borehole = boreholeDataCopy.strata

    if(borehole){
      for (var i = 0; i < borehole.length; i++){
        
        borehole[i].id = boreholeDataCopy.data.id
        borehole[i].coordinates = boreholeDataCopy.data.coordinates

        //group any layers of the same type together
        if(classifiedStrata.length){
          if (borehole[i].material === classifiedStrata[classifiedStrata.length - 1].material){
            let previousStrata = classifiedStrata[classifiedStrata.length - 1]
            let selectedStrata = borehole[i]
            previousStrata.base = selectedStrata.base
            previousStrata.description = previousStrata.description.concat(` / ${selectedStrata.description}`)
          }
          else{
            classifiedStrata.push(borehole[i])
          }
        }
        else {
          classifiedStrata.push(borehole[i])
        }
      }
    }
    return classifiedStrata
  }


  ///----------------------- GEORGE ESTIMATES ALGORITHM ------------------------///

  export function findMaterial(descriptionList, descriptionString) {
    for (var j = 0; j < descriptionList.length; j++) {
      let materials_list = [
        {identifier: 'fill', uscs: "-", grain:'Fill'},
        {identifier: 'backfill', uscs: "-", grain:'Fill'},
        {identifier: 'made', uscs: "-", grain:'Fill'},
        {identifier: 'clay', uscs: "Clay (CH/CL)", grain:'Fine'},
        {identifier: 'sand', uscs: "Sand (SW/SP/SM)", grain:'Coarse'},
        {identifier: 'gravel', uscs: "Gravel (GW/GP)", grain:'Coarse'},
        {identifier: 'silt', uscs: "Silt (MH/ML)", grain:'Fine'},
        {identifier: 'peat', uscs: "Peat (PT)", grain:'Fine'},
      ]
      for (const material of materials_list){
        if(descriptionList[j] === material.identifier){
          if(descriptionList[j]==='made'){
            if(descriptionList[j+1]){
              if(descriptionList[j+1]==='ground'){
                return material
              }
            }
          }else{
            return material
          }
        }
      }
    }
    if (descriptionString.includes('made ground') || descriptionString.includes('made-ground')) {
      return {identifier: 'fill', uscs: "-", grain:'Fill'}
    }
    if (descriptionString.includes('topsoil') || descriptionString.includes('made-ground')) {
      return {identifier: 'fill', uscs: "-", grain:'Fill'}
    }  
    else if (descriptionString.includes('rock') || descriptionString.includes('stone') || descriptionString.includes('chalk') || descriptionString.includes('marl') || descriptionString.includes('slate') || descriptionString.includes('salt')) {
      return {identifier: 'rock', uscs: "-", grain:'Rock'}
    }

    return {identifier: '-', uscs: "-", grain:'-'}
  }

  export function analyseSPT(material, tests, plasticity){

    const default_material = {identifier: '-', uscs: material.uscs, grain:material.grain}

    let properties = {
      grain: material.grain,
      friction: null,
      density: null,
      modulus: null,
      cDrained: null,
      cUndrained: null,
    }

    let spt_avg = null

    if (tests.length>0){
      for(let test of tests){
        if(test.test === 'N' || test.test === 'S'){
          if(spt_avg === null){
            spt_avg = parseFloat(test.result)
          }else{
            spt_avg = (spt_avg + parseFloat(test.result))/2
          }
        }
      }

      properties.spt_avg = Math.round(spt_avg)
    }
    else{
      properties.spt_avg = '-'
    }

    if(material.identifier==="clay"){
      if(spt_avg<=2 && spt_avg>0){
          properties.friction = '22-32'
          properties.density = 17.9
          properties.cDrained = 0
      }
      else if(spt_avg<=4 && spt_avg>2){
          properties.friction = '22-32'
          properties.density = 18.3
          properties.cDrained = '0-5'
      }
      else if(spt_avg<=8 && spt_avg>4){
          properties.friction = '22-32'
          properties.density = 18.3
          properties.cDrained = '0-5'
      }
      else if(spt_avg<=15 && spt_avg>8){
        properties.friction = '22-32'
        properties.density = 18.7
        properties.cDrained = 10
      }
      else if(spt_avg<=30 && spt_avg>15){
        properties.friction = '22-32'
        properties.density = 19.5
        properties.cDrained = '15-20'
      }
      else if(spt_avg<=50 && spt_avg>30){
        properties.friction = '22-32'
        properties.density = 20.7
        properties.cDrained = '20-25'
      }
      else if(spt_avg<=100 && spt_avg>50){
        properties.friction = '22-32'
        properties.density = 21.4
        properties.cDrained = '25-30'
      }
    }
    else if(material.identifier==="silt"){
      if(spt_avg<=2 && spt_avg>0){
        properties.friction = '22-30'
        properties.density = 17.9
        properties.cDrained = 0
      }
      else if(spt_avg<=4 && spt_avg>2){
          properties.friction = '22-30'
          properties.density = 18.05
          properties.cDrained = 0
      }
      else if(spt_avg<=8 && spt_avg>4){
          properties.friction = '22-30'
          properties.density = 18.3
          properties.cDrained = '0-5'
      }
      else if(spt_avg<=15 && spt_avg>8){
        properties.friction = '22-30'
        properties.density = 18.7
        properties.cDrained = 10
      }
      else if(spt_avg<=30 && spt_avg>15){
        properties.friction = '22-30'
        properties.density = 19.5
        properties.cDrained = '15-20'
      }
      else if(spt_avg<=50 && spt_avg>30){
        properties.friction = '22-30'
        properties.density = 20.7
        properties.cDrained = '20-25'
      }
      else if(spt_avg<=100 && spt_avg>50){
        properties.friction = '22-30'
        properties.density = 21.4
        properties.cDrained = '25-30'
      }
    }
    else if(material.identifier==="gravel"){
      if(spt_avg<=4 && spt_avg>0){
        properties.friction = '35-38'
        properties.density = '16-19.4'
        properties.cDrained = 0
      }
      else if(spt_avg<=10 && spt_avg>4){
          properties.friction = '37-40'
          properties.density = '16.7-19.5'
          properties.cDrained = 0
      }
      else if(spt_avg<=30 && spt_avg>10){
        properties.friction = '39-42'
        properties.density = '17.5-20.7'
        properties.cDrained = 0
      }
      else if(spt_avg<=50 && spt_avg>30){
        properties.friction = '41-44'
        properties.density = '18.6-21.5'
        properties.cDrained = 0
      }
      else if(spt_avg<=100 && spt_avg>50){
        properties.friction = '43-46'
        properties.density = '19.5-22.5'
        properties.cDrained = 0
      }
    }
    else if(material.identifier==="sand"){
      if(spt_avg<=4 && spt_avg>0){
        properties.friction = '29-34'
        properties.density = '16.4-20.7'
        properties.cDrained = 0
      }
      else if(spt_avg<=10 && spt_avg>4){
          properties.friction = '32-37'
          properties.density = '17.1-21.2'
          properties.cDrained = 0
      }
      else if(spt_avg<=30 && spt_avg>10){
        properties.friction = '35-39'
        properties.density = '19.4-20.7'
        properties.cDrained = 0
      }
      else if(spt_avg<=50 && spt_avg>30){
        properties.friction = '38-42'
        properties.density = '19.1-22.6'
        properties.cDrained = 0
      }
      else if(spt_avg<=100 && spt_avg>50){
        properties.friction = '41-44'
        properties.density = '20.7-23.3'
        properties.cDrained = 0
      }
    }

    return Object.assign({}, material, properties)


  }


  export function analyseSand(descriptionString) {

    const grain = 'Coarse'
    const default_material = {identifier: '-', uscs: "Sand (SW/SP/SM)", grain:'Coarse'}

    let properties = {
      grain: grain,
      friction: 30,
      density: 20,
      modulus: null,
      cDrained: 0,
      cUndrained: 0,
    }

    if (descriptionString.includes('loose')) {
      properties.friction = 30; 
      properties.density = 16;
      if (descriptionString.includes('very loose')) {properties.friction = 28; properties.density = 15;}
    }
    else if (descriptionString.includes('dense')) {
      properties.friction = 33; 
      properties.density = 17;
      if (descriptionString.includes('medium dense')) {properties.friction = 32; properties.density = 17;}
      else if (descriptionString.includes('very dense')) {properties.friction = 34; properties.density = 18;}
      else if (descriptionString.includes('cemented')) {properties.friction = 35; properties.density = 18;}
    }

    if (descriptionString.includes('silty')) {properties.friction = properties.friction-2; properties.density = properties.density+1;}
    else if (descriptionString.includes('clayey')) {properties.friction = properties.friction-1; properties.density = properties.density+1;}
    
    if (descriptionString.includes('well')) {properties.friction = properties.friction+2;  properties.density = properties.density+1;}
    else if (descriptionString.includes('gap')) {properties.friction = properties.friction+1; properties.density = properties.density-1;}
    else if (descriptionString.includes('fine')) {properties.friction = properties.friction-2; properties.density = properties.density-1;}

    if (descriptionString.includes('angular')) {properties.friction = properties.friction+2}
    else if (descriptionString.includes('sub')) {properties.friction = properties.friction+1}

    //decide the USCS class and return the material
    let sandMaterialList = [
      {identifier: 'gravel', uscs: "SW/SP", grain:'Coarse'},
      {identifier: 'little fines', uscs: "SW/SP", grain:'Coarse'},
      {identifier: 'no fines', uscs: "SW/SP", grain:'Coarse'},
      {identifier: 'well', uscs: "SW", grain:'Coarse'},
      {identifier: 'poor', uscs: "SP", grain:'Coarse'},
      {identifier: 'silt', uscs: "SM", grain:'Coarse'},
    ]
    for (let material of sandMaterialList) {
      if (descriptionString.includes(material.identifier)){
        return Object.assign({}, material, properties)
      }
    }
    return Object.assign({}, default_material, properties)
  }

  export function analyseClay(descriptionString) {
    
    const grain = 'Fine'
    const default_material = {identifier: '-', uscs: "Clay (CH/CL)", grain:'Fine'}

    let properties = {
      grain: grain,
      friction: 28,
      density: 20,
      modulus: null,
      cDrained: 0,
      cUndrained: 25,
    }

    if (descriptionString.includes('plasticity')) {
      if (descriptionString.includes('low plasticity')) { properties.friction = 30;}
      else if (descriptionString.includes('intermediate plasticity')) {properties.friction = 28;}
      else if (descriptionString.includes('high plasticity')) {properties.friction = 25;}
    }

    if (descriptionString.includes('soft')) {
      if (descriptionString.includes('very soft')) { properties.friction = properties.friction-2; properties.density = 18; properties.cDrained = 0; properties.cUndrained=15;}
      else{properties.friction = properties.friction-1; properties.density = 19; properties.cDrained = 0; properties.cUndrained=25;}
    }
    else if (descriptionString.includes('firm')) {properties.friction = properties.friction-1; properties.density = 19.5; properties.cDrained = 10; properties.cUndrained=50;}
    else if (descriptionString.includes('stiff')) { 
      properties.density = 20.5;
      properties.cDrained = 10;
      properties.cUndrained=100;
      if (descriptionString.includes('very stiff')) { properties.friction = properties.friction+1; properties.density = 21.5; properties.cUndrained=150;}
    }

    if (descriptionString.includes('gravely')) {properties.friction = properties.friction+2}
    else if (descriptionString.includes('sandy')) {properties.friction = properties.friction+1}
    else if (descriptionString.includes('silty')) {properties.friction = properties.friction-1; properties.cUndrained=properties.cUndrained*0.8;}
    else if (descriptionString.includes('organic')) {properties.friction = properties.friction-2; properties.cUndrained=properties.cUndrained*0.5;}

    if (descriptionString.includes('fissured')) {properties.cUndrained=properties.cUndrained*0.8;}
    else if (descriptionString.includes('stratified')) {properties.cUndrained=properties.cUndrained*0.8;}
    else if (descriptionString.includes('weathered')) {properties.cUndrained=properties.cUndrained*0.6;}


    let clayMaterialList = [
      {identifier: 'peat', uscs: "PT", grain:'Fine'},
      {identifier: 'organic', uscs: "OL/PT", grain:'Fine'},
      {identifier: 'gravel', uscs: "CL", grain:'Fine'},
      {identifier: 'sand', uscs: "CL", grain:'Fine'},
      {identifier: 'silt', uscs: "CL", grain:'Fine'},
      {identifier: 'lean', uscs: "CL", grain:'Fine'},
      {identifier: 'low plasticity', uscs: "CL", grain:'Fine'},
      {identifier: 'medium plasticity', uscs: "CL", grain:'Fine'},
      {identifier: 'brick', uscs: "CL", grain:'Fine'},
      {identifier: 'fragments', uscs: "CL", grain:'Fine'},
    ]
    for (let material of clayMaterialList) {
      if (descriptionString.includes(material.identifier)){
        return Object.assign({}, material, properties)
      }
    }
    return Object.assign({}, default_material, properties)
  }


  export function analyseGravel(descriptionString) {

    const grain = 'Coarse'
    const default_material = {identifier: '-', uscs: "Gravel (GW/GP)", grain:'Coarse'}

    let properties = {
      grain: grain,
      friction: 30,
      density: 20,
      modulus: null,
      cDrained: 0,
      cUndrained: 0,
    }

    if (descriptionString.includes('loose')) {
      properties.friction = 32; 
      properties.density = 19;
      if (descriptionString.includes('very loose')) {properties.friction = 30; properties.density = 18.5;}
    }
    else if (descriptionString.includes('dense')) {
      properties.friction = 36; 
      properties.density = 21;
      if (descriptionString.includes('medium dense')) {properties.friction = 35; properties.density = 20;}
      else if (descriptionString.includes('very dense')) {properties.friction = 38; properties.density = 21.5;}
    }

    if (descriptionString.includes('silty')) {properties.friction = properties.friction-3; properties.density = properties.density+1;}
    else if (descriptionString.includes('clayey')) {properties.friction = properties.friction-2; properties.density = properties.density+1;}
    
    if (descriptionString.includes('well')) {properties.friction = properties.friction+2}
    else if (descriptionString.includes('gap')) {properties.friction = properties.friction+1; properties.density = properties.density-1;}
    else if (descriptionString.includes('poorly')) {properties.friction = properties.friction+1; properties.density = properties.density-2;}
    else if (descriptionString.includes('uniform')) {properties.friction = properties.friction+1; properties.density = properties.density-2;}

    if (descriptionString.includes('angular')) {properties.friction = properties.friction+2}
    else if (descriptionString.includes('sub')) {properties.friction = properties.friction+1}


    let gravelMaterialList = [
      {identifier: 'well', uscs: "GW", grain:'Coarse'},
      {identifier: 'Fine', uscs: "GW", grain:'Coarse'},
      {identifier: 'rounded', uscs: "GW", grain:'Coarse'},
      {identifier: 'poor', uscs: "GP", grain:'Coarse'},
      {identifier: 'angular', uscs: "GP", grain:'Coarse'},
      {identifier: 'coarse', uscs: "GP", grain:'Coarse'},
    ]
    for (let material of gravelMaterialList) {
      if (descriptionString.includes(material.identifier)){
        return Object.assign({}, material, properties)
      }
    }
    return Object.assign({}, default_material, properties)
  }



  export function analyseSilt(descriptionString) {
    
    const grain = 'Fine'
    const default_material = {identifier: '-', uscs: "Silt (MH/ML)", grain:'Fine'}

    let properties = {
      grain: grain,
      friction: 25,
      density: 17,
      modulus: null,
      cDrained: 0,
      cUndrained: 0,
    }

    if (descriptionString.includes('alluvium') || descriptionString.includes('river')) {
      properties.friction = 22; 
      properties.cUndrained = 5; 
      properties.density = 16; 
    }
    else if (descriptionString.includes('soft')) {
      properties.friction = 26; 
      properties.density = 17; 
      if (descriptionString.includes('very soft')) {
        properties.friction = 24; 
        properties.density = 17; 
      }
    } 
    else if (descriptionString.includes('firm')) {
      properties.friction = 28; 
      properties.density = 18; 
    }

    if (descriptionString.includes('sandy')) {
      properties.friction = properties.friction+1; 
    }
    else if (descriptionString.includes('organic')) {
      properties.friction = properties.friction-1; 
    }

    if (descriptionString.includes('coarse')) {
      properties.friction = properties.friction+1; 
    }
    else if (descriptionString.includes('fine')) {
      properties.friction = properties.friction-1; 
    }

    let siltMaterialList = [
      {identifier: 'very Fine', uscs: "ML", grain:'Fine'},
      {identifier: 'clay', uscs: "ML", grain:'Fine'},
      {identifier: 'clay', uscs: "ML", grain:'Fine'},
      {identifier: 'rock', uscs: "ML", grain:'Fine'},
      {identifier: 'fragments', uscs: "ML", grain:'Fine'},
      {identifier: 'micaceous', uscs: "MH", grain:'Fine'},
      {identifier: 'diatomaceous', uscs: "MH", grain:'Fine'},
      {identifier: 'elastic', uscs: "MH", grain:'Fine'},
    ]
    for (let material of siltMaterialList) {
      if (descriptionString.includes(material.identifier)){
        return Object.assign({}, material, properties)
      }
    }
    return Object.assign({}, default_material, properties)
  }



  export function analyseFill(descriptionString) {

    const grain = 'Fill'
    const default_material = {identifier: 'fill', uscs: "-", grain:'Fill'}

    let properties = {
      grain: grain,
      friction: 30,
      density: 18,
      modulus: null,
      cDrained: 0,
      cUndrained: 0,
    }
    if (descriptionString.includes('fill')) {
      if (descriptionString.includes('rock fill')) {properties.friction = 40; properties.density = 17;}
      else if (descriptionString.includes('chalk fill')) {properties.friction = 32; properties.density = 17;}
      else if (descriptionString.includes('graded fill')) {properties.friction = 38; properties.density = 19;}
      else if (descriptionString.includes('sand fill')) {properties.friction = 35; properties.density = 17;}
      else if (descriptionString.includes('clay fill')) {properties.friction = 28; properties.density = 20;}
    }
    else if (descriptionString.includes('hardcore')) {properties.friction = 38; properties.density = 16;}
    else if (descriptionString.includes('pfa')) {properties.friction = 35; properties.density = 14;}
    else if (descriptionString.includes('slag')) {properties.friction = 32; properties.density = 14;}
    else if (descriptionString.includes('ashes')) {properties.friction = 32; properties.density = 14;}

    if (descriptionString.includes('loose')) {properties.friction = properties.friction-2; properties.density = properties.density-1;}
    else if (descriptionString.includes('compacted')) {properties.friction = properties.friction+2; properties.density = properties.density+1;}
    
    if (descriptionString.includes('silty')) {properties.friction = properties.friction-2}

    return Object.assign({}, default_material, properties)
  }

  export function analyseRock(descriptionString) {

    const grain = 'Rock'
    const default_material = {identifier: 'rock', uscs: "Rock", grain:'Rock'}

    let properties = {
      grain: grain,
      friction: 30,
      density: 22,
      modulus: null,
      cDrained: 0,
      cUndrained: 0,
    }
    if (descriptionString.includes('marl')) {properties.friction = 32; properties.density = 21;}
    else if (descriptionString.includes('sandstone')) {properties.friction = 40; properties.density = 23;}
    else if (descriptionString.includes('mudstone')) {properties.friction = 30; properties.density = 23;}
    else if (descriptionString.includes('shale')) {properties.friction = 32; properties.density = 21;}
    else if (descriptionString.includes('chalk')) {
      if (descriptionString.includes('i') || descriptionString.includes('ii') || descriptionString.includes('1') || descriptionString.includes('2')) {properties.friction = 35; properties.density = 16;}
      if (descriptionString.includes('iii') || descriptionString.includes('iv') || descriptionString.includes('3') || descriptionString.includes('4')) {properties.friction = 32; properties.density = 15;}
      if (descriptionString.includes('v') || descriptionString.includes('vi') || descriptionString.includes('5') || descriptionString.includes('6')) {properties.friction = 30; properties.density = 14;}
    }

    if (descriptionString.includes('very weak')) {properties.friction = properties.friction-2;}
    
    if (descriptionString.includes('thickly bedded')) {properties.friction = properties.friction-1;}
    else if (descriptionString.includes('thickly laminated')) {properties.friction = properties.friction-2;}
    else if (descriptionString.includes('thinly laminated')) {properties.friction = properties.friction-2;}


    return Object.assign({}, default_material, properties)
  }


  export function analyseOrganic(descriptionString) {

    const grain = 'Organic'
    const default_material = {identifier: 'peat', uscs: "-", grain:'Organic'}

    let properties = {
      grain: grain,
      friction: 0,
      density: 12,
      modulus: null,
      cDrained: 0,
      cUndrained: 5,
    }
    if (descriptionString.includes('firm')) {properties.friction = 10;}
    else if (descriptionString.includes('spongy')) {properties.friction = 5;}
    else if (descriptionString.includes('plastic')) {properties.friction = 5;}

    return Object.assign({}, default_material, properties)
  }


// Extend the Array class
Array.prototype.max = function() {
  return Math.max.apply(null, this);
};
Array.prototype.min = function() {
  return Math.min.apply(null, this);
};
Array.prototype.mean = function() {
  var i, sum;
  for(i=0,sum=0;i<this.length;i++)
sum += this[i];
  return sum / this.length;
};
Array.prototype.pip = function(x, y) {
  var i, j, c = false;
  for(i=0,j=this.length-1;i<this.length;j=i++) {
if( ((this[i][1]>y) != (this[j][1]>y)) &&
    (x<(this[j][0]-this[i][0]) * (y-this[i][1]) / (this[j][1]-this[i][1]) + this[i][0]) ) {
    c = !c;
}
  }
  return c;
}

  function initialize_krigging() {
    kriging = function() {
    kriging = {};

    var createArrayWithValues = function(value, n) {
        var array = [];
        for ( var i = 0; i < n; i++) {
            array.push(value);
        }
        return array;
    };

    // Matrix algebra
    function kriging_matrix_diag(c, n) {
        var Z = createArrayWithValues(0, n * n);
        for(let i=0;i<n;i++) Z[i*n+i] = c;
        return Z;
    };
    function kriging_matrix_transpose(X, n, m) {
  var i, j, Z = Array(m*n);
  for(i=0;i<n;i++)
      for(j=0;j<m;j++)
    Z[j*n+i] = X[i*m+j];
  return Z;
    };
    function kriging_matrix_scale(X, c, n, m) {
  var i, j;
  for(i=0;i<n;i++)
      for(j=0;j<m;j++)
    X[i*m+j] *= c;
    };
    function kriging_matrix_add(X, Y, n, m) {
  var i, j, Z = Array(n*m);
  for(i=0;i<n;i++)
      for(j=0;j<m;j++)
    Z[i*m+j] = X[i*m+j] + Y[i*m+j];
  return Z;
    };
    // Naive matrix multiplication
    function kriging_matrix_multiply(X, Y, n, m, p) {
  var i, j, k, Z = Array(n*p);
  for(i=0;i<n;i++) {
      for(j=0;j<p;j++) {
    Z[i*p+j] = 0;
    for(k=0;k<m;k++)
        Z[i*p+j] += X[i*m+k]*Y[k*p+j];
      }
  }
  return Z;
    };
    // Cholesky decomposition
    function kriging_matrix_chol(X, n) {
  var i, j, k, sum, p = Array(n);
  for(i=0;i<n;i++) p[i] = X[i*n+i];
  for(i=0;i<n;i++) {
      for(j=0;j<i;j++)
    p[i] -= X[i*n+j]*X[i*n+j];
      if(p[i]<=0) return false;
      p[i] = Math.sqrt(p[i]);
      for(j=i+1;j<n;j++) {
    for(k=0;k<i;k++)
        X[j*n+i] -= X[j*n+k]*X[i*n+k];
    X[j*n+i] /= p[i];
      }
  }
  for(i=0;i<n;i++) X[i*n+i] = p[i];
  return true;
    };
    // Inversion of cholesky decomposition
    function kriging_matrix_chol2inv(X, n) {
  var i, j, k, sum;
  for(i=0;i<n;i++) {
      X[i*n+i] = 1/X[i*n+i];
      for(j=i+1;j<n;j++) {
    sum = 0;
    for(k=i;k<j;k++)
        sum -= X[j*n+k]*X[k*n+i];
    X[j*n+i] = sum/X[j*n+j];
      }
  }
  for(i=0;i<n;i++)
      for(j=i+1;j<n;j++)
    X[i*n+j] = 0;
  for(i=0;i<n;i++) {
      X[i*n+i] *= X[i*n+i];
      for(k=i+1;k<n;k++)
    X[i*n+i] += X[k*n+i]*X[k*n+i];
      for(j=i+1;j<n;j++)
    for(k=j;k<n;k++)
        X[i*n+j] += X[k*n+i]*X[k*n+j];
  }
  for(i=0;i<n;i++)
      for(j=0;j<i;j++)
    X[i*n+j] = X[j*n+i];

    };
    // Inversion via gauss-jordan elimination
  function kriging_matrix_solve(X, n) {
  var m = n;
  var b = Array(n*n);
  var indxc = Array(n);
  var indxr = Array(n);
  var ipiv = Array(n);
  var i, icol, irow, j, k, l, ll;
  var big, dum, pivinv, temp;

  for(i=0;i<n;i++)
      for(j=0;j<n;j++) {
    if(i==j) b[i*n+j] = 1;
    else b[i*n+j] = 0;
      }
  for(j=0;j<n;j++) ipiv[j] = 0;
  for(i=0;i<n;i++) {
      big = 0;
      for(j=0;j<n;j++) {
    if(ipiv[j]!=1) {
        for(k=0;k<n;k++) {
      if(ipiv[k]==0) {
          if(Math.abs(X[j*n+k])>=big) {
        big = Math.abs(X[j*n+k]);
        irow = j;
        icol = k;
          }
      }
        }
    }
      }
      ++(ipiv[icol]);

      if(irow!=icol) {
    for(l=0;l<n;l++) {
        temp = X[irow*n+l];
        X[irow*n+l] = X[icol*n+l];
        X[icol*n+l] = temp;
    }
    for(l=0;l<m;l++) {
        temp = b[irow*n+l];
        b[irow*n+l] = b[icol*n+l];
        b[icol*n+l] = temp;
    }
      }
      indxr[i] = irow;
      indxc[i] = icol;

      if(X[icol*n+icol]==0) return false; // Singular

      pivinv = 1 / X[icol*n+icol];
      X[icol*n+icol] = 1;
      for(l=0;l<n;l++) X[icol*n+l] *= pivinv;
      for(l=0;l<m;l++) b[icol*n+l] *= pivinv;

      for(ll=0;ll<n;ll++) {
    if(ll!=icol) {
        dum = X[ll*n+icol];
        X[ll*n+icol] = 0;
        for(l=0;l<n;l++) X[ll*n+l] -= X[icol*n+l]*dum;
        for(l=0;l<m;l++) b[ll*n+l] -= b[icol*n+l]*dum;
    }
      }
  }
  for(l=(n-1);l>=0;l--)
      if(indxr[l]!=indxc[l]) {
    for(k=0;k<n;k++) {
        temp = X[k*n+indxr[l]];
        X[k*n+indxr[l]] = X[k*n+indxc[l]];
        X[k*n+indxc[l]] = temp;
    }
      }

  return true;
    }

    // Variogram models
    function kriging_variogram_gaussian(h, nugget, range, sill, A) {
  return nugget + ((sill-nugget)/range)*
  ( 1.0 - Math.exp(-(1.0/A)*Math.pow(h/range, 2)) );
    };
    function kriging_variogram_exponential(h, nugget, range, sill, A) {
  return nugget + ((sill-nugget)/range)*
  ( 1.0 - Math.exp(-(1.0/A) * (h/range)) );
    };
    function kriging_variogram_spherical(h, nugget, range, sill, A) {
  if(h>range) return nugget + (sill-nugget)/range;
  return nugget + ((sill-nugget)/range)*
  ( 1.5*(h/range) - 0.5*Math.pow(h/range, 3) );
    };

    // Train using gaussian processes with bayesian priors
    kriging.train = function(t, x, y, model, sigma2, alpha) {
  var variogram = {
      t      : t,
      x      : x,
      y      : y,
      nugget : 0.0,
      range  : 0.0,
      sill   : 0.0,
      A      : 1/3,
      n      : 0
  };
  switch(model) {
  case "gaussian":
      variogram.model = kriging_variogram_gaussian;
      break;
  case "exponential":
      variogram.model = kriging_variogram_exponential;
      break;
  case "spherical":
      variogram.model = kriging_variogram_spherical;
      break;
  };

  // Lag distance/semivariance
  var i, j, k, l, n = t.length;
  var distance = Array((n*n-n)/2);
  for(i=0,k=0;i<n;i++)
      for(j=0;j<i;j++,k++) {
    distance[k] = Array(2);
    distance[k][0] = Math.pow(
        Math.pow(x[i]-x[j], 2)+
        Math.pow(y[i]-y[j], 2), 0.5);
    distance[k][1] = Math.abs(t[i]-t[j]);
      }
  distance.sort(function(a, b) { return a[0] - b[0]; });
  variogram.range = distance[(n*n-n)/2-1][0];

  // Bin lag distance
  var lags = ((n*n-n)/2)>30?30:(n*n-n)/2;
  var tolerance = variogram.range/lags;
  var lag = createArrayWithValues(0,lags);
  var semi = createArrayWithValues(0,lags);
  if(lags<30) {
      for(l=0;l<lags;l++) {
    lag[l] = distance[l][0];
    semi[l] = distance[l][1];
      }
  }
  else {
      for(i=0,j=0,k=0,l=0;i<lags&&j<((n*n-n)/2);i++,k=0) {
    while( distance[j][0]<=((i+1)*tolerance) ) {
        lag[l] += distance[j][0];
        semi[l] += distance[j][1];
        j++;k++;
        if(j>=((n*n-n)/2)) break;
    }
    if(k>0) {
        lag[l] /= k;
        semi[l] /= k;
        l++;
    }
      }
      if(l<2) return variogram; // Error: Not enough points
  }

  // Feature transformation
  n = l;
  variogram.range = lag[n-1]-lag[0];
  var X = createArrayWithValues(1,2 * n);
  var Y = Array(n);
  var A = variogram.A;
  for(i=0;i<n;i++) {
      switch(model) {
      case "gaussian":
    X[i*2+1] = 1.0-Math.exp(-(1.0/A)*Math.pow(lag[i]/variogram.range, 2));
    break;
      case "exponential":
    X[i*2+1] = 1.0-Math.exp(-(1.0/A)*lag[i]/variogram.range);
    break;
      case "spherical":
    X[i*2+1] = 1.5*(lag[i]/variogram.range)-
        0.5*Math.pow(lag[i]/variogram.range, 3);
    break;
      };
      Y[i] = semi[i];
  }

  // Least squares
  var Xt = kriging_matrix_transpose(X, n, 2);
  var Z = kriging_matrix_multiply(Xt, X, 2, n, 2);
  Z = kriging_matrix_add(Z, kriging_matrix_diag(1/alpha, 2), 2, 2);
  var cloneZ = Z.slice(0);
  if(kriging_matrix_chol(Z, 2))
      kriging_matrix_chol2inv(Z, 2);
  else {
      kriging_matrix_solve(cloneZ, 2);
      Z = cloneZ;
  }
  var W = kriging_matrix_multiply(kriging_matrix_multiply(Z, Xt, 2, 2, n), Y, 2, n, 1);

  // Variogram parameters
  variogram.nugget = W[0];
  variogram.sill = W[1]*variogram.range+variogram.nugget;
  variogram.n = x.length;

  // Gram matrix with prior
  n = x.length;
  var K = Array(n*n);
  for(i=0;i<n;i++) {
      for(j=0;j<i;j++) {
    K[i*n+j] = variogram.model(Math.pow(Math.pow(x[i]-x[j], 2)+
                Math.pow(y[i]-y[j], 2), 0.5),
            variogram.nugget,
            variogram.range,
            variogram.sill,
            variogram.A);
    K[j*n+i] = K[i*n+j];
      }
      K[i*n+i] = variogram.model(0, variogram.nugget,
              variogram.range,
              variogram.sill,
              variogram.A);
  }

  // Inverse penalized Gram matrix projected to target vector
  var C = kriging_matrix_add(K, kriging_matrix_diag(sigma2, n), n, n);
  var cloneC = C.slice(0);
  if(kriging_matrix_chol(C, n))
      kriging_matrix_chol2inv(C, n);
  else {
      kriging_matrix_solve(cloneC, n);
      C = cloneC;
  }

  // Copy unprojected inverted matrix as K
  var K = C.slice(0);
  var M = kriging_matrix_multiply(C, t, n, n, 1);
  variogram.K = K;
  variogram.M = M;

  return variogram;
    };

    // Model prediction
    kriging.predict = function(x, y, variogram) {
  var i, k = Array(variogram.n);
  for(i=0;i<variogram.n;i++)
      k[i] = variogram.model(Math.pow(Math.pow(x-variogram.x[i], 2)+
              Math.pow(y-variogram.y[i], 2), 0.5),
          variogram.nugget, variogram.range,
          variogram.sill, variogram.A);
  return kriging_matrix_multiply(k, variogram.M, 1, variogram.n, 1)[0];
    };
    kriging.variance = function(x, y, variogram) {
  var i, k = Array(variogram.n);
  for(i=0;i<variogram.n;i++)
      k[i] = variogram.model(Math.pow(Math.pow(x-variogram.x[i], 2)+
              Math.pow(y-variogram.y[i], 2), 0.5),
          variogram.nugget, variogram.range,
          variogram.sill, variogram.A);
  return variogram.model(0, variogram.nugget, variogram.range,
      variogram.sill, variogram.A)+
  kriging_matrix_multiply(kriging_matrix_multiply(k, variogram.K,
              1, variogram.n, variogram.n),
        k, 1, variogram.n, 1)[0];
    };

    // Gridded matrices or contour paths
    kriging.grid = function(polygonsKrigging, variogram, width) {
  var i, j, k, n = polygonsKrigging.length;
  if(n==0) return;

  // Boundaries of polygonsKrigging space
  var xlim = [polygonsKrigging[0][0][0], polygonsKrigging[0][0][0]];
  var ylim = [polygonsKrigging[0][0][1], polygonsKrigging[0][0][1]];
  for(i=0;i<n;i++) // polygonsKrigging
      for(j=0;j<polygonsKrigging[i].length;j++) { // Vertices
    if(polygonsKrigging[i][j][0]<xlim[0])
        xlim[0] = polygonsKrigging[i][j][0];
    if(polygonsKrigging[i][j][0]>xlim[1])
        xlim[1] = polygonsKrigging[i][j][0];
    if(polygonsKrigging[i][j][1]<ylim[0])
        ylim[0] = polygonsKrigging[i][j][1];
    if(polygonsKrigging[i][j][1]>ylim[1])
        ylim[1] = polygonsKrigging[i][j][1];
      }

  // Alloc for O(n^2) space
  var xtarget, ytarget;
  var a = Array(2), b = Array(2);
  var lxlim = Array(2); // Local dimensions
  var lylim = Array(2); // Local dimensions
  var x = Math.ceil((xlim[1]-xlim[0])/width);
  var y = Math.ceil((ylim[1]-ylim[0])/width);

  var A = Array(x+1);
  for(i=0;i<=x;i++) A[i] = Array(y+1);
  for(i=0;i<n;i++) {
      // Range for polygonsKrigging[i]
      lxlim[0] = polygonsKrigging[i][0][0];
      lxlim[1] = lxlim[0];
      lylim[0] = polygonsKrigging[i][0][1];
      lylim[1] = lylim[0];
      for(j=1;j<polygonsKrigging[i].length;j++) { // Vertices
    if(polygonsKrigging[i][j][0]<lxlim[0])
        lxlim[0] = polygonsKrigging[i][j][0];
    if(polygonsKrigging[i][j][0]>lxlim[1])
        lxlim[1] = polygonsKrigging[i][j][0];
    if(polygonsKrigging[i][j][1]<lylim[0])
        lylim[0] = polygonsKrigging[i][j][1];
    if(polygonsKrigging[i][j][1]>lylim[1])
        lylim[1] = polygonsKrigging[i][j][1];
      }

      // Loop through polygon subspace
      a[0] = Math.floor(((lxlim[0]-((lxlim[0]-xlim[0])%width)) - xlim[0])/width);
      a[1] = Math.ceil(((lxlim[1]-((lxlim[1]-xlim[1])%width)) - xlim[0])/width);
      b[0] = Math.floor(((lylim[0]-((lylim[0]-ylim[0])%width)) - ylim[0])/width);
      b[1] = Math.ceil(((lylim[1]-((lylim[1]-ylim[1])%width)) - ylim[0])/width);
      for(j=a[0];j<=a[1];j++)
    for(k=b[0];k<=b[1];k++) {
        xtarget = xlim[0] + j*width;
        ytarget = ylim[0] + k*width;
        if(polygonsKrigging[i].pip(xtarget, ytarget))
      A[j][k] = kriging.predict(xtarget,
              ytarget,
              variogram);
    }
  }
  A.xlim = xlim;
  A.ylim = ylim;
  A.zlim = [variogram.t.min(), variogram.t.max()];
  A.width = width;
  return A;
    };
    kriging.contour = function(value, polygonsKrigging, variogram) {

    };

    // Plotting on the DOM
    kriging.plot = function(canvas, grid, xlim, ylim, colors) {
  // Clear screen
  var ctx = canvas.getContext("2d");
  ctx.clearRect(0, 0, canvas.width, canvas.height);

  // Starting boundaries
  var range = [xlim[1]-xlim[0], ylim[1]-ylim[0], grid.zlim[1]-grid.zlim[0]];
  var i, j, x, y, z;
  var n = grid.length;
  var m = grid[0].length;
  var wx = Math.ceil(grid.width*canvas.width/(xlim[1]-xlim[0]));
  var wy = Math.ceil(grid.width*canvas.height/(ylim[1]-ylim[0]));
  for(i=0;i<n;i++)
      for(j=0;j<m;j++) {
    if(grid[i][j]==undefined) continue;
    x = canvas.width*(i*grid.width+grid.xlim[0]-xlim[0])/range[0];
    y = canvas.height*(1-(j*grid.width+grid.ylim[0]-ylim[0])/range[1]);
    z = (grid[i][j]-grid.zlim[0])/range[2];
    if(z<0.0) z = 0.0;
    if(z>1.0) z = 1.0;

    ctx.fillStyle = colors[Math.floor((colors.length-1)*z)];
    ctx.fillRect(Math.round(x-wx/2), Math.round(y-wy/2), wx, wy);
      }

    };


    return kriging;
  }();

}

if (module && module.exports){
  module.exports = kriging;
}  


export function distanceInKmBetweenEarthCoordinates(lat1, lon1, lat2, lon2) {
  var earthRadiusKm = 6378.14;

  var dLat = degreesToRadians(lat2-lat1);
  var dLon = degreesToRadians(lon2-lon1);

  lat1 = degreesToRadians(lat1);
  lat2 = degreesToRadians(lat2);

  var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
          Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2); 
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
  return earthRadiusKm * c;
}

function degreesToRadians(degrees) {
  return degrees * Math.PI / 180;
}

export function kmToLongitude(km, latitude) {
  var earthRadius = 6371.0;  // Radius of the earth in km
  var degreeToRadian = Math.PI / 180;  // Conversion factor from degrees to radians

  // Calculate the length of a degree of longitude at this latitude
  var longitudeKm = earthRadius * Math.cos(latitude * degreeToRadian) * 2 * Math.PI / 360;

  // Convert the distance in km to degrees
  var longitudeDegrees = km / longitudeKm;

  return longitudeDegrees;
}

export function kmToLatitude(km) {
  var earthRadius = 6371.0;  // Radius of the earth in km

  // The length of a degree of latitude in kilometers, approximately
  var latitudeKm = 2 * Math.PI * earthRadius / 360;

  // Convert the distance in km to degrees
  var latitudeDegrees = km / latitudeKm;

  return latitudeDegrees;
}
