import { Navigate, Route, Routes } from "react-router-dom";
import "./styles/app.scss";
import { DefaultLayout } from "./components/shared/layouts/DefaultLayout";
import SignUpPage from "./components/signup/SignUpPage";
import AuthenticatedRoute from "./components/shared/AuthenticatedRoute";
import LoginPage from "./components/login/LoginPage";
import VerifyPage from "./components/login/VerifyPage";
import httpClientPy from "./utils/httpClientPy";
import authenticationHandler from "./utils/authenticationHandler";
import MapPage from "./components/map/MapPage";
import { FreeLayout } from "./components/shared/layouts/FreeLayout";
import { ProjectLayout } from "./components/shared/layouts/ProjectLayout";
import { AdminLayout } from "./components/shared/layouts/AdminLayout";
import Admin from "./components/admin/Admin";
import PartnerAdmin from "./components/partnerAdmin/PartnerAdmin";
import ProjectsList from "./components/project/ProjectsList";
import ProjectDetail from "./components/project/ProjectDetail";
import AdminUsers from "./components/admin/AdminUsers";
import Pricing from "./components/pricing/Pricing";
import Training from "./components/training/Training";
import Management from "./components/user/Management";
import { Worker } from "@react-pdf-viewer/core";
import AdminEnterprise from "./components/admin/AdminEnterprise";
import AdminRecords from "./components/admin/AdminRecords";
import LibraryList from "./components/library/LibraryList";
import AdminVectorUpdate from "./components/admin/AdminVectorUpdate";
import ForgotPw from "./components/login/ForgotPw";
import ResetPw from "./components/login/ResetPw";
import WorkFlowList from "./components/workflow/main/WorkFlowList";
import WorkflowOutputList from "./components/workflow/outputs/OutputsList";
import BookingForm from "./components/shared/BookingForm";


const onUnAuthorized = () => {
  //prevent default
  authenticationHandler.logout();
  window.location.href = "/log-in";
};

const initScript = () => {
  if (authenticationHandler.isAuthenticated() === true) {
    httpClientPy
      .get(`/user/active`)
      .then((response) => {
        if (response.status === 401 || response.status === 403) {
          onUnAuthorized();
        }
      })
      .catch((error) => {
        onUnAuthorized();
      });
  }
};

initScript();

const App = () => {
  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
      <Routes>
        <Route element={<AuthenticatedRoute />}>
          <Route element={<FreeLayout />}>
            <Route path="/map" element={<MapPage />} />
          </Route>
        </Route>
        <Route element={<DefaultLayout />}>
          <Route path="/" element={<Navigate to="/projects" />} />
        </Route>
        <Route element={<FreeLayout />}>
          <Route path="/log-in" element={<LoginPage />} />
          <Route path="/sign-up" element={<SignUpPage />} />
          <Route path="/invite" element={<SignUpPage />} />
          <Route path="/verification" element={<VerifyPage />} />
          <Route path="/forgot-password" element={<ForgotPw />} />
          <Route path="/reset-password" element={<ResetPw />} />
          <Route path="/contact-us" element={<BookingForm />} />
        </Route>
        <Route element={<AuthenticatedRoute />}>
          <Route element={<AdminLayout />}>
            <Route path="/admin" element={<Admin />} />
          </Route>
        </Route>
        <Route element={<AuthenticatedRoute />}>
          <Route element={<AdminLayout />}>
            <Route path="/admin/records" element={<AdminRecords />} />
          </Route>
        </Route>
        <Route element={<AuthenticatedRoute />}>
          <Route element={<AdminLayout />}>
            <Route path="/admin/vectors/update" element={<AdminVectorUpdate />} />
          </Route>
        </Route>
        <Route element={<AuthenticatedRoute />}>
          <Route element={<AdminLayout />}>
            <Route path="/admin/users" element={<AdminUsers />} />
          </Route>
        </Route>
        <Route element={<AuthenticatedRoute />}>
          <Route element={<AdminLayout />}>
            <Route path="/admin/enterprise" element={<AdminEnterprise />} />
          </Route>
        </Route>
        <Route element={<AuthenticatedRoute />}>
          <Route element={<AdminLayout />}>
            <Route path="/partner/admin" element={<PartnerAdmin />} />
          </Route>
        </Route>
        <Route element={<AuthenticatedRoute />}>
          <Route element={<ProjectLayout />}>
            <Route path="/projects" element={<ProjectsList />} />
          </Route>
        </Route>
        <Route element={<AuthenticatedRoute />}>
          <Route element={<ProjectLayout />}>
            <Route path="/project" element={<ProjectDetail />} />
          </Route>
        </Route>
        <Route element={<AuthenticatedRoute />}>
          <Route element={<ProjectLayout />}>
            <Route path="/project/workflows" element={<WorkFlowList />} />
          </Route>
        </Route>
        <Route element={<AuthenticatedRoute />}>
          <Route element={<ProjectLayout />}>
            <Route path="/project/workflows/outputs" element={<WorkflowOutputList />} />
          </Route>
        </Route>
        <Route element={<AuthenticatedRoute />}>
          <Route element={<ProjectLayout />}>
            <Route path="/pricing" element={<Pricing />} />
          </Route>
        </Route>
        <Route element={<AuthenticatedRoute />}>
          <Route element={<ProjectLayout />}>
            <Route path="/training" element={<Training />} />
          </Route>
        </Route>
        <Route element={<AuthenticatedRoute />}>
          <Route element={<ProjectLayout />}>
            <Route path="/user/management" element={<Management />} />
          </Route>
        </Route>
        <Route element={<AuthenticatedRoute />}>
          <Route element={<ProjectLayout />}>
            <Route path="/library" element={<LibraryList />} />
          </Route>
        </Route>
      </Routes>
    </Worker>
  );
};

export default App;
