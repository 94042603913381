import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faSquareCheck } from '@fortawesome/free-solid-svg-icons';

const CheckListItem = ({ children }) => (
  <li style={{ listStyleType: 'none', marginBottom: '0.5rem' }}>
    <span style={{ marginRight: '8px', color: 'green' }}>
      <FontAwesomeIcon icon={faSquareCheck} />
    </span>
    {children}
  </li>
);

export default CheckListItem;