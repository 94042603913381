import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import Button from '../shared/Button';
import httpClientPy from '../../utils/httpClientPy';
import { toast, ToastContainer } from 'react-toastify';

function LibraryDeleteConfirm(props) {

  const handleConfirm = () => {

    if(props.item === 'library') {

      handleDeleteLibrary();

    } 
    
    if (props.item === 'document' && props.selectedFileIndex !== undefined) {

      if (props.selectedFileId !== undefined && props.selectedFileId !== null) {
        // this means we are deleting a document that is already in the library
        handleDeleteExistingDocument();  
      } 
      
      props.removeFileFromList(props.selectedFileIndex);

    }

    if (props.item === 'prompt') {

      handleDeletePrompt();
      props.nulify();

    }

    props.toggle();
  }

  const handleDeleteLibrary = async () => {

    try {

      let response = await httpClientPy.delete(`/library/delete?library_id=${props.selectedLibrary}`);
      toast.success('Library deleted successfully', {position: toast.POSITION.TOP_RIGHT, autoClose: 2000})
    } catch (error) {
      
      let error_code = error.response.data.detail;
      if (error_code === 4) {
          toast.error('Document limit has been exceeded, contact your admin in order to check limit balance.', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
      } else if (error_code === 3) {
          toast.error('User is not part of enterprise account.', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
      } else if (error_code === 5) {
          toast.error('Library does not exist or user is not part of the enterprise.', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
      } else if (error_code === 1) {
          toast.error('Internal problem has caused process to fail, please contact info@civils.ai for more information. Thank you', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
      }

    }

    props.libraryDeleted()
  }

  const handleDeleteExistingDocument = async () => {
    
    try {

      let response = await httpClientPy.delete(`/library/document/delete?document_id=${props.selectedFileId}`);
      toast.success('Document deleted successfully', {position: toast.POSITION.TOP_RIGHT, autoClose: 2000})
    } catch (error) {
      
      let error_code = error.response.data.detail;
      if (error_code === 4) {
          toast.error('Document limit has been exceeded, contact your admin in order to check limit balance.', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
      } else if (error_code === 3) {
          toast.error('User is not part of enterprise account.', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
      } else if (error_code === 5) {
          toast.error('Document does not exist or user is not part of the enterprise.', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
      } else if (error_code === 1) {
          toast.error('Internal problem has caused process to fail, please contact info@civils.ai for more information. Thank you', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
      }
    
    }
  }

  const handleDeletePrompt = async () => {

    try {

      let response = await httpClientPy.delete(`/library/management/prompts/delete?prompt_id=${props.prompt_id}`);
      toast.success('Prompt deleted successfully', {position: toast.POSITION.TOP_RIGHT, autoClose: 2000})
      props.getPrompts();
    } catch (error) {

      let error_code = error.response.data.detail;
      if (error_code === 4) {
          toast.error('Document limit has been exceeded, contact your admin in order to check limit balance.', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
      } else if (error_code === 3) {
          toast.error('User is not part of enterprise account.', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
      } else if (error_code === 5) {
          toast.error('Prompt does not exist or user is not part of the enterprise.', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
      } else if (error_code === 1) {
          toast.error('Internal problem has caused process to fail, please contact info@civils.ai for more information. Thank you', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
      }

    }
  }

  return (
    <>
      <ToastContainer />
      <Modal isOpen={props.isOpen} toggle={props.toggle}>
        <ModalHeader toggle={props.toggle}>Delete Confirmation</ModalHeader>
        <ModalBody>
          Are you sure you want to <b>delete</b> this {props.item} ?
          <br />
          This action cannot be <b>reversed</b>.
          {props.extraMessage && <><br/><p>{props.extraMessage}</p></>}
        </ModalBody>
        <ModalFooter>
            <Button
                label='Delete'
                className="btn btn-danger"
                onClick={handleConfirm}
            />
            <Button
                label='Cancel'
                className="btn btn-secondary"
                onClick={props.toggle}
            />
        </ModalFooter>
      </Modal>
    </>
  );
}

export default LibraryDeleteConfirm;
