import React from 'react';
import {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import httpClientPy from '../../utils/httpClientPy';
import { faChartLine, faUserMinus, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from "../shared/Button";
import { Card, CardBody, CardHeader, Modal } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { interpolateGreens } from 'd3';
import { ModalBody, ModalFooter, ModalHeader } from 'react-bootstrap';
import AdminEnterpriseUsers from './AdminEnterpriseUsers';

const AdminEnterprise = (props) => {

    const navigate = useNavigate();

    const [enterprises, setEnterprises] = useState(null);
    const [subs, setSubs] = useState(null);
    const [filteredEnts, setFilteredEnts] = useState(null);
    const [loading, setLoading] = useState(false);
    const [searchOptionSelected, setSearchOptionSelected] = useState('email');
    const [searchType, setSearchType] = useState('eq');
    const [searchText, setSearchText] = useState('');
    const [toggleAddEnterprise, setToggleAddEnterprise] = useState(false);
    const [selectedEnterprise, setSelectedEnterprise] = useState(null);
    const [addEnterpriseAdminModal, setAddEnterpriseAdminModal] = useState(false);
    const [toggleAddSubscription, setToggleAddSubscription] = useState(false);
    const [historyUsageModal, setHistoryUsageModal] = useState(false);
    const [usageData, setUsageData] = useState(null);
    const [subscriptionSelected, setSubscriptionSelected] = useState(null);
    const [selectedSubscriptionLimit, setSelectedSubscriptionLimit] = useState(null);

    useEffect(() => {
        httpClientPy.get(`/user`).then((response) => {
            let userData = response.data
            if(![12, 19, 22, 50, 51, 30].includes(userData.george_id)){
                navigate('/')
            }
        })
    }, []);

    useEffect(() => {
        httpClientPy.get(`/enterprises`).then((response) => {
            setEnterprises(response.data.enterprises)
        })
        httpClientPy.get(`/subscriptions?ent=1`).then((response) => {
            setSubs(response.data.subs)
        })
    }, [loading]);

    const navigateToUsers = () => {
        navigate('/admin/users');
    }

    useEffect(() => {
        if (enterprises) {
            if (searchText === '') {
                setFilteredEnts(enterprises)
            } else {
                handleSearch({target: {value: searchText}})
            }
        }
    }, [enterprises]);

    const handleSearch = (e) => {
        setLoading(true);
        
        let searchValue = e.target.value;

        if(searchOptionSelected === 'id') {
            if(searchType === 'eq') {
                setFilteredEnts(enterprises.filter(ent => ent.id === parseInt(searchValue)));
            } else {
                setFilteredEnts(enterprises.filter(ent => ent.id.toString().includes(searchValue)));
            }
        }

        if(searchOptionSelected === 'name') {
            if(searchType === 'eq') {
                setFilteredEnts(enterprises.filter(ent => ent.name === searchValue));
            } else {
                setFilteredEnts(enterprises.filter(ent => (ent.name).includes(searchValue)));
            }
        }

        if (searchValue === '') {
            setFilteredEnts(enterprises);
            setLoading(false);
        }

        setSearchText(searchValue);

        setLoading(false);
    }

    const handleSubscriptionChange = (id, type) => {
        if (type === "0") {
            type = "1";
        }
        setLoading(true);
        httpClientPy.post('/enterprises/subscription/update', {
            id: id,
            subscription_id: type
        }).then((response) => {
            if(response.status === 200) {
                setLoading(false);
            }
        }).catch((error) => {
            console.log(error);
        });
    };

    const toggleAddEnterpriseModal = () => {
        setToggleAddEnterprise(!toggleAddEnterprise);
    }

    const toggleAddSubscriptionModal = () => {
        setToggleAddSubscription(!toggleAddSubscription);
    }

    const toggleHistoryUsageModal = (id) => {
        if(historyUsageModal) {
            setUsageData(null);
        } else {
            setLoading(true);
            httpClientPy.get(`/enterprises/usage/history?enterprise_id=${id}`).then((response) => {
                setUsageData(response.data.usage);
                setLoading(false);
            }
            ).catch((error) => {
                console.log(error);
            });
        }
        setHistoryUsageModal(!historyUsageModal);
    }

    const handleChangeSubscription = (e) => {
        setSubscriptionSelected(e);
        setSelectedSubscriptionLimit(subs.filter(sub => sub.id === parseInt(e))[0])
    }

    const AddEnterprise = () => {
        setLoading(true);
        let name = document.getElementById('name').value;
        httpClientPy.post('/enterprises/create', {
            name: name,
            subscription_id: subscriptionSelected
        }).then((response) => {
            if(response.status === 200) {
                setLoading(false);
                toggleAddEnterpriseModal();
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    const AddSubscription = () => {
        setLoading(true);
        let subs_type = document.getElementById('subs-type').value;
        let document_limit = document.getElementById('document_limit').value;
        let document_limit_type = document.getElementById('document_limit_type').value;
        let queries_limit = document.getElementById('queries_limit').value;
        let queries_limit_type = document.getElementById('queries_limit_type').value;
        let document_size = document.getElementById('document_size').value;
        let is_premium = document.querySelector('input[name="is_premium"]:checked').value;
        let is_digtise = document.querySelector('input[name="is_digtise"]:checked').value;
        let is_civilsgpt = document.querySelector('input[name="is_civilsgpt"]:checked').value;
        let is_drawing = document.querySelector('input[name="is_drawing"]:checked').value;
        let subscription_price = document.getElementById('subscription_price').value;
        let subs_period = document.getElementById('subs_period').value;

        const body = {
            subscription_type: subs_type,
            document_limit: document_limit,
            document_limit_type: document_limit_type,
            queries_limit: queries_limit,
            queries_limit_type: queries_limit_type,
            document_size: document_size,
            is_premium: is_premium == "true" ? true : false,
            is_digitise: is_digtise == "true" ? true : false,
            is_civilsgpt: is_civilsgpt == "true" ? true : false,
            is_drawing: is_drawing == "true" ? true : false,
            subscription_price: subscription_price,
            subscription_period: subs_period
        }
    
        httpClientPy.post('/subscriptions/enterprise/create', body
        ).then((response) => {
            if(response.status === 200) {
                setLoading(false);
                toggleAddSubscriptionModal();
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    const columns = [
        {
            name: '# ID',
            selector: 'id',
            center: true,
            cell: (row) => {
              return <small>{row.id}</small>;
            },
        },
        {
            name: 'Enterprise',
            selector: 'Enterprise',
            center: true,
            cell: (row) => {
              return <small>{row.name}</small>;
            },
        },
        {
            name: 'Document Limit Used',
            selector: 'document_limt',
            center: true,
            cell: (row) => {
              return <small>{row.total_documents}</small>;
            },
        },
        {
            name: 'Query Limit Used',
            selector: 'query_limit',
            center: true,
            cell: (row) => {
              return <small>{row.total_queries}</small>;
            },
        },
        {
          name: 'Subscription',
          selector: 'update',
          center: true,
          cell: (row) => {
            return (
              <>
                <select name="subscription" id="subscription" className="form-select" onChange={(e) => handleSubscriptionChange(row.id, e.target.value)} value={row.subscription_id ? row.subscription_id : 0}>
                  <option value={0}>None</option>
                  {subs.map((sub) => (
                    <option key={sub.id} value={sub.id}>
                      {sub.subscription_type} | {sub.subscription_period}
                    </option>
                  ))}

                </select>
              </>
            );
          },
        },
        {
          name: 'Actions',
          selector: 'actions',
          center: true,
          cell: (row) => {
            return (
                <>
                    <button
                        type='button'
                        className='btn btn-primary'
                        onClick={() => {
                            setSelectedEnterprise(row);
                            setAddEnterpriseAdminModal(true);
                        }}
                    >
                        <FontAwesomeIcon icon={faUserPlus} />
                    </button>

                    <button
                        type='button'
                        className='btn btn-primary ms-2'
                        onClick={() => {
                            setSelectedEnterprise(row);
                            toggleHistoryUsageModal(row.id);
                        }}
                    >
                        <FontAwesomeIcon icon={faChartLine} />
                    </button>
                </>
            );
          },
        },
    ];

    const usageColumns = [
        {
            name: 'Subscription Type',
            selector: 'subscription_type',
            center: true,
            cell: (row) => {
                return <small>{row.subscription_type}</small>;
            },
        },
        {
            name: 'Document Limit Used',
            selector: 'document_limt',
            center: true,
            cell: (row) => {
                return <small>{row.total_documents}</small>;
            },
        },
        {
            name: 'Query Limit Used',
            selector: 'query_limit',
            center: true,
            cell: (row) => {
                return <small>{row.total_queries}</small>;
            },
        },
        {
            name: 'Subscription Period (Days)',
            selector: 'subscription_period',
            center: true,
            cell: (row) => {
                return <small>{row.subscription_period}</small>;
            },
        },
        {
            name: 'Is Active',
            selector: 'is_active',
            center: true,
            cell: (row) => {
                return <small>{row.is_active ? 'Yes' : 'No'}</small>;
            },
        }
    ];

    const customStyles = {
        headCells: {
            style: {
                fontSize: '1rem',
                fontWeight: 'bold',
                zIndex: 0,
            },
        },
    };

    return (
        <>
            {!loading && filteredEnts && subs ? (
                    <Card className="h-100 mt-2 mx-2">
                        <CardHeader className="bg-white">
                            <div className="row g-0 h-100 px-2">
                                <div className="col-12 d-flex justify-content-between align-items-center">
                                    <div className="d-flex align-items-center">

                                        <div onClick={navigateToUsers} className="btn mb-2 btn-primary mt-1">User Management</div>
                                        <div className="btn mb-2 ms-2 btn-primary disabled mt-1">Enterprise Management</div>

                                    </div>
                                    <div className="d-flex align-items-center">
                                        <button type="button" class="btn btn-primary me-2" onClick={toggleAddSubscriptionModal}>New subscription</button>
                                        <button type="button" class="btn btn-primary" onClick={toggleAddEnterpriseModal}>New enterprise</button>
                                    </div>
                                </div>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <div className='row justify-content-end'>
                                <div className='col-md-2 text-center'>
                                    <select className="form-select" onChange={(e) => setSearchOptionSelected(e.target.value)} value={searchOptionSelected}>
                                        <option value="id">ID</option>
                                        <option value="name">Name</option>
                                    </select>
                                </div>
                                <div className='col-md-2 text-center'>
                                    <select className="form-select" onChange={(e) => setSearchType(e.target.value)} value={searchType}>
                                        <option value="eq">Equals</option>
                                        <option value="like">Similar</option>
                                    </select>
                                </div>
                                <div className='col-md-2 text-center'>
                                    <input type="text" className="form-control" placeholder="Search" onChange={handleSearch} value={searchText} />
                                </div>
                            </div>
                            <div className="row g-0 h-100 px-2 mt-2">
                                <DataTable
                                    className="sticky-top bg-white text-center"
                                    pagination
                                    searchable
                                    highlightOnHover
                                    columns={columns}
                                    data={filteredEnts}
                                    customStyles={customStyles}
                                />
                            </div>
                        </CardBody>
                    </Card>
            ) : (
                <>
                <div className='col-md-12 d-flex justify-content-center flex-column align-items-center' style={{ height: '70vh' }}>
                    <span>
                        <span
                            className="spinner-border spinner-border-md"
                            role="status"
                            aria-hidden="true"
                        ></span>
                        <span className="visually-hidden">Loading...</span>
                    </span>
                </div>
                </>
            )}
            {subs &&
                <Modal id="addEnterprise" size="l" isOpen={toggleAddEnterprise} toggle={toggleAddEnterpriseModal} centered={true}>
                    <ModalHeader className="border-0 mb-0 pb-0" toggle={toggleAddEnterpriseModal}>
                        <h4 className="d-inline-flex title-bold align-items-center mb-0 pb-0">
                            Add Enterprise Account
                        </h4>
                    </ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="name">Name</label>
                                    <input type="text" className="form-control" id="name" placeholder="Enter name" />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="newsubscription">Subscription</label>
                                    <select className="form-select" id="newsubscription" onChange={(e) => handleChangeSubscription(e.target.value)}>
                                        <option value="0">None</option>
                                        {subs.map((sub) => (
                                            <option key={sub.id} value={sub.id}>
                                                {sub.subscription_type} | {sub.subscription_period}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            {subscriptionSelected && selectedSubscriptionLimit &&
                                <div className="col-md-12">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Detail</th>
                                                <th>Value</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Document Limit</td>
                                                <td>
                                                    {selectedSubscriptionLimit.document_limit} | {selectedSubscriptionLimit.document_limit_type}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Queries Limit</td>
                                                <td>
                                                    {selectedSubscriptionLimit.queries_limit} | {selectedSubscriptionLimit.queries_limit_type}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>CivilsGPT</td>
                                                <td>
                                                    <input type="checkbox" checked={true ? selectedSubscriptionLimit.is_civilsgpt : false} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Digitiser</td>
                                                <td>
                                                    <input type="checkbox" checked={true ? selectedSubscriptionLimit.is_digitise : false} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Drawing</td>
                                                <td>
                                                    <input type="checkbox" checked={true ? selectedSubscriptionLimit.is_drawing : false} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Value</td>
                                                <td>
                                                    ${selectedSubscriptionLimit.subscription_price} | {selectedSubscriptionLimit.subscription_period}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            }
                        </div>
                    </ModalBody>
                    <ModalFooter className="border-0 justify-content-center">
                        <button disabled={loading} type="button" class="btn btn-primary" onClick={()=> AddEnterprise()}>Add</button>
                        <button type="button" class="btn btn-warning" onClick={toggleAddEnterpriseModal}>Cancel</button>
                    </ModalFooter>
                </Modal>
            }
            <Modal className="mt-5" id="addEnterpriseAdmin" size="lg" isOpen={addEnterpriseAdminModal} toggle={()=>setAddEnterpriseAdminModal(!addEnterpriseAdminModal)} centered={true}>
                <AdminEnterpriseUsers toggleModal = {()=> setAddEnterpriseAdminModal(!addEnterpriseAdminModal)} ent={selectedEnterprise} />
            </Modal>
            <Modal id="newEnterpriseSubscription" size="l" isOpen={toggleAddSubscription} toggle={toggleAddSubscriptionModal} centered={true}>
                <ModalHeader className="border-0 mb-0 pb-0" toggle={toggleAddSubscriptionModal}>
                    <h4 className="d-inline-flex title-bold align-items-center mb-0 pb-0">
                        Add New Subscription
                    </h4>
                </ModalHeader>
                <ModalBody>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="subs-type">Name</label>
                                <input type="text" className="form-control" id="subs-type" placeholder="Enter name of subscription" />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="document_limit">Document Limit</label>
                                <input type="number" className="form-control" id="document_limit" placeholder="Enter Number of Document Limit" />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="document_limit_type">Document Limit Type</label>
                                <select className="form-select" id="document_limit_type">
                                    <option value="month">Month</option>
                                    <option value="yearly">Year</option>
                                    <option value="lifetime">Lifetime</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="queries_limit">Queries Limit</label>
                                <input type="number" className="form-control" id="queries_limit" placeholder="Enter Number of Queries Limit" />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="queries_limit_type">Queries Limit Type</label>
                                <select className="form-select" id="queries_limit_type">
                                    <option value="month">Month</option>
                                    <option value="yearly">Year</option>
                                    <option value="lifetime">Lifetime</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="document_size">Document Size Limit</label>
                                <select className="form-select" id="document_size">
                                    <option value="1">1 MB</option>
                                    <option value="5">5 MB</option>
                                    <option value="10">10 MB</option>
                                    <option value="20">20 MB</option>
                                    <option value="32">32 MB</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="is_premium">Enable Premium Features</label>
                                <div className="radio-group">
                                    <input type="radio" name="is_premium" id="is_premium_yes" value="true" />
                                    <label htmlFor="is_premium_yes">Yes</label>
                                </div>
                                <div className="radio-group">
                                    <input type="radio" name="is_premium" id="is_premium_no" value="false" />
                                    <label htmlFor="is_premium_no">No</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="is_digtise">Enable Digitsation</label>
                                <div className="radio-group">
                                    <input type="radio" name="is_digtise" id="is_digtise_yes" value="true" />
                                    <label htmlFor="is_digtise_yes">Yes</label>
                                </div>
                                <div className="radio-group">
                                    <input type="radio" name="is_digtise" id="is_digtise_no" value="false" />
                                    <label htmlFor="is_digtise_no">No</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="is_civilsgpt">Enable CivilsGPT</label>
                                <div className="radio-group">
                                    <input type="radio" name="is_civilsgpt" id="is_civilsgpt_yes" value="true" />
                                    <label htmlFor="is_civilsgpt_yes">Yes</label>
                                </div>
                                <div className="radio-group">
                                    <input type="radio" name="is_civilsgpt" id="is_civilsgpt_no" value="false" />
                                    <label htmlFor="is_civilsgpt_no">No</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="is_drawing">Enable Drawings</label>
                                <div className="radio-group">
                                    <input type="radio" name="is_drawing" id="is_drawing_yes" value="true" />
                                    <label htmlFor="is_drawing_yes">Yes</label>
                                </div>
                                <div className="radio-group">
                                    <input type="radio" name="is_drawing" id="is_drawing_no" value="false" />
                                    <label htmlFor="is_drawing_no">No</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="subscription_price">Price</label>
                                <input type="number" className="form-control" id="subscription_price" placeholder="Enter the Price of subscription" />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="subs_period">Period</label>
                                <select className="form-select" id="subs_period">
                                    <option value="month">Month</option>
                                    <option value="yearly">Year</option>
                                    <option value="lifetime">Lifetime</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter className="border-0 justify-content-center">
                    <button disabled={loading} type="button" class="btn btn-primary" onClick={()=> AddSubscription()}>Add</button>
                    <button type="button" class="btn btn-warning" onClick={toggleAddSubscriptionModal}>Cancel</button>
                </ModalFooter>
            </Modal>
            {usageData &&
                <Modal id="historyUsage" size="xl" isOpen={historyUsageModal} toggle={toggleHistoryUsageModal} centered={true}>
                    <ModalHeader className="border-0 mb-0 pb-0" toggle={toggleHistoryUsageModal}>
                        <h4 className="d-inline-flex title-bold align-items-center mb-0 pb-0">
                            History Usage
                        </h4>
                    </ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="col-md-12">
                                <DataTable
                                    className="sticky-top bg-white text-center"
                                    pagination
                                    highlightOnHover
                                    columns={usageColumns}
                                    data={usageData}
                                    customStyles={customStyles}
                                />
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter className="border-0 justify-content-center">
                        <button type="button" className="btn btn-primary" onClick={toggleHistoryUsageModal}>Close</button>
                    </ModalFooter>
                </Modal>
            }
        </>
    );
}

export default AdminEnterprise;