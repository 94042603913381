import React from "react";


const Admin = () => {
    return (
        <>Please sign-in to an admin account</>
    )
    }
  export default Admin;

