import React, { useEffect, useState } from "react";
import httpClientPy from "../../../utils/httpClientPy.js";
import { Marker } from "react-leaflet";
import L from "leaflet";


const MapMarkerLabel = (props) => {
  
  function createLabelIcon(labelText) {
    return new L.DivIcon({
        html: `<div className="marker-label">${labelText}</div>`,
        className: `marker-label-container ${props.zoom.current < 18 && "d-none"}`,
        iconSize: L.point(0, 0),
        iconAnchor: [15, 30],
    });
  }

  return(
      <>
      <Marker
          position={[props.latitude, props.longitude]}
          icon={createLabelIcon(`${props.markerText}`)}
      />
      </>
  )
};

export default MapMarkerLabel;





