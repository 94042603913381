import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faCheck } from '@fortawesome/free-solid-svg-icons';

const ArrowListItem = ({ children }) => (
  <li style={{ listStyleType: 'none', marginBottom: '0.5rem' }}>
    <span style={{ marginRight: '8px', color: 'black' }}>
      <FontAwesomeIcon icon={faCaretRight} />
    </span>
    {children}
  </li>
);

export default ArrowListItem;