import { useEffect, useState } from "react";
import LibraryPromptSelector from "./LibraryPromptSelector";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook, faCheck, faCopy, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { marked } from 'marked';
import httpClientPy from "../../utils/httpClientPy";
import { CardHeader, Spinner, Tooltip } from "reactstrap";
import Button from "../shared/Button";
import { logEvent } from "../shared/Mixpanel";
import { toast, ToastContainer } from "react-toastify";

const LibrarySearch = (props) => {

    const [chatInput, setChatInput] = useState('');
    const [generating, setGenerating] = useState(false);
    const [answer, setAnswer] = useState(null);
    const [typingCivilsGPT, setTypingCivilsGPT] = useState(false);
    const [result, setResult] = useState(null);

    const [libraryPrompts, setLibraryPrompts] = useState([]);
    const [selectedPrompt, setSelectedPrompt] = useState(null);
    const [promptLibLoading, setPromptLibLoading] = useState(true);
    const [copyStatus, setCopyStatus] = useState(false);
    const [tooltipOpen1, setTooltipOpen1] = useState(false);
    const [allRefMsgId, setAllRefMsgId] = useState([]);

    const toggle1 = () => setTooltipOpen1(!tooltipOpen1);

    const handleChangeQuery = (e) => {
        setChatInput(e.target.value);
    }

    const submitMessage = async () => {
        setResult(null)

        let result = {
            query: selectedPrompt ? selectedPrompt.prompt : chatInput,
            response: null
        }
        setResult(result);
        let answer = await getResult(result.query);
        result.response = answer;
        handleBestRefAutoClick(answer);
        setResult(result);
    }

    const getLibraryPrompts = async () => {
        setPromptLibLoading(true);
        try {
            let response = await httpClientPy.get(`/library/prompts?library_id=${props.selectedLibrary}`);
            setLibraryPrompts(response.data.prompts);
        } catch (error) {
            let error_code = error.response.data.detail;
            if (error_code === 4) {
                toast.error('Document limit has been exceeded, contact your admin in order to check limit balance.', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
            } else if (error_code === 3) {
                toast.error('User is not part of enterprise account.', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
            } else if (error_code === 5) {
                toast.error('Prompts does not exist or user is not part of the enterprise.', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
            } else if (error_code === 1) {
                toast.error('Internal problem while loading prompts, please contact info@civils.ai for more information. Thank you', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
            }
            setPromptLibLoading(false);
        }
        setPromptLibLoading(false);
    }

    function capitalizeFirstLetter(string) {
        if (!string) return ''; // Handle empty string case
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const handleAllReferenceShow = (msg_id) => {
        setAllRefMsgId([...allRefMsgId, msg_id]);
    }

    const handleAllReferenceHide = (msg_id) => {
        setAllRefMsgId(allRefMsgId.filter((id) => id !== msg_id));
    }

    const renderer = (text, msg_id) => {
        try {
            const json = JSON.parse(text);
            if (!json.hasOwnProperty('all') || !json.hasOwnProperty('cad')) {
                const html = marked.parse(text);
                return <div dangerouslySetInnerHTML={{ __html: html }} />;
            } else if (json.all === false) {
                // remove key 'all' from json
                delete json.all;
                delete json.cad;
                return Object.entries(json).map(([file, pages]) => (
                    <div key={file}>
                        {file} - {' '}
                        {Object.entries(pages).map(([page_num], index, arr) => (
                            <span key={`${file}-${page_num}`}>
                                <a
                                    className='reference-link circle-owner'
                                    style={{ backgroundColor: '#5b5fcc' }}
                                    onClick={() => handleReferenceClick(file, page_num, pages[page_num])}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {page_num}
                                </a>
                                {index < arr.length - 1 ? '' : ''}
                            </span>
                        ))}
                    </div>
                ));
            } else if (json.all === true) {
                delete json.all;
                delete json.cad;
                return (
                    <>
                        {!allRefMsgId.includes(msg_id) &&
                            <button className="btn btn-outline-dark btn-sm mt-2" onClick={() => handleAllReferenceShow(msg_id)}>All references</button>
                        }
                        {allRefMsgId.includes(msg_id) &&
                            <div>
                                <div><b>All Reference(s):</b></div>
                                {Object.entries(json).map(([file, pages]) => (
                                    <div key={file}>
                                        {file} - {' '}
                                        {Object.entries(pages).map(([page_num], index, arr) => (
                                            <span key={`${file}-${page_num}`}>
                                                <a
                                                    className='reference-link circle-owner'
                                                    style={{ backgroundColor: '#5b5fcc' }}
                                                    onClick={() => handleReferenceClick(file, page_num, pages[page_num])}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {page_num}
                                                </a>
                                                {index < arr.length - 1 ? '' : ''}
                                            </span>
                                        ))}
                                    </div>
                                ))}
                                <button className="btn btn-outline-dark btn-sm mb-3" onClick={() => handleAllReferenceHide(msg_id)}>Hide references</button>
                            </div>
                        }
                    </>
                )
            } 
        } catch (error) {
            const html = marked.parse(text);
            return <div dangerouslySetInnerHTML={{ __html: html }} />;
        }
    };

    const handleReferenceClick = (file, page_num, vector_ids) => {
        httpClientPy.post('/library/search/reference', {
            library_id: props.selectedLibrary,
            vectors: vector_ids
        }).then((response) => {
            props.setFileUrl(response.data.file_url)
            props.setInitialPage(page_num)
            props.setHighlightArea(response.data.highlights)
        }).catch((error) => {
            let error_code = error.response.data.detail;
            if (error_code === 4) {
                toast.error('Document limit has been exceeded, contact your admin in order to check limit balance.', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
            } else if (error_code === 3) {
                toast.error('User is not part of enterprise account.', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
            } else if (error_code === 5) {
                toast.error('Document does not exist or user is not part of the enterprise.', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
            } else if (error_code === 1) {
                toast.error('Internal problem while loading reference, please contact info@civils.ai for more information. Thank you', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
            }
        });
    }

    const handleCopy = () => {
        const copyText = document.getElementById('copy_this').innerText;
        navigator.clipboard.writeText(copyText).then(() => {
            setCopyStatus(true)
            // set a timeout before setting the copy status back to false
            setTimeout(() => {
                setCopyStatus(false)
            }
            , 3000);
        }).catch(err => {
            setCopyStatus(false)
        });
    };

    const getResult = async (chat_query) => {
        setGenerating(true);
        
        let requestBody
        let api
        const token = localStorage.getItem('auth_token');

        api = '/library/search/result'
        requestBody = JSON.stringify({
            library_id: props.selectedLibrary,
            query: chat_query,
        });

        let text = '';

        try {
            const response = await fetch(process.env.REACT_APP_BASE_URL_PY + api, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: requestBody,
            });


            const reader = response.body.getReader();
            const decoder = new TextDecoder();
            
            while (true) {
                const { done, value } = await reader.read();

                if (done) {
                    break;
                }

                text += decoder.decode(value);
                const renderedHtml = marked.parse(text);
                setAnswer(renderedHtml);
            }

        } catch (error) {
            console.error('Error:', error);
        }
        setGenerating(false);
        setChatInput("")
        setSelectedPrompt(null)
        setAnswer(null)
        logEvent('Search Library', { 'query': chat_query, 'record_id': props.selectedId });

        return text;
    };

    const handleBestRefAutoClick = (response) => {
        // find json in response as response is a string but there is a section where it has {}
        let jsonStartIndex = response.indexOf('{');
        let jsonEndIndex = response.lastIndexOf('}');

        if (jsonStartIndex !== -1 && jsonEndIndex !== -1) {
            let json_str = response.substring(jsonStartIndex, jsonEndIndex+1);
            let splitted_json_str = json_str.split('\n\n');
            let json = JSON.parse(splitted_json_str[0]);
            delete json['all'];
            delete json['cad'];

            
            let file = Object.keys(json)[0];
            let pages = json[file];
            let page = Object.keys(pages)[0];

            handleReferenceClick(file, page, pages[page]);
        }
    }

    useEffect(() => {
        if (props.historyResult) {
            setResult(props.historyResult);
        }
    }, [props.historyResult])

    useEffect(() => {
        getLibraryPrompts();
    }, [props.selectedLibrary]);

    useEffect(() => {
        if (selectedPrompt) {
            submitMessage();
        }
    }, [selectedPrompt])

    useEffect(() => {
    
        if (result === null || result.response === null) {
            return;
        }

        let response = result.response;
        handleBestRefAutoClick(response);
        
    }, [result])

    // detect if user pressed enter
    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !generating) {
            e.preventDefault();
            if(typingCivilsGPT){
                submitMessage();
            }
        }
    }

    // this is the useEffect to handle the keypress event listener
    useEffect(() => {

        // use the handleKeyPress
        window.addEventListener('keypress', handleKeyPress);

        // this will clean up the event every time the component is re-rendered
        return function cleanup() {
            window.removeEventListener('keypress', handleKeyPress);
        };

    });

    return (
        <>
        <ToastContainer />
        {promptLibLoading ? (
        <>
            <div className="disabled d-flex flex-column align-items-center justify-content-center" style={{ height: '100%' }}>
                <span>
                    <Spinner color="dark" size="sm" className="mx-auto" />
                </span>
            </div>
        </>
        ) : (
            <>
                <div id="chatList" className="d-flex flex-column w-100">
                    <div id="copy_parent" 
                        className={`bg-offwhite ${typingCivilsGPT ? 'd-none d-sm-flex flex-column flex-grow-1' : 'd-flex flex-column flex-grow-1'}`} 
                        style={{ 
                            overflowY: 'auto',
                            minHeight: '30dvh', 
                        }}
                    >
                        {result &&
                            <CardHeader className="py-2 bg-light">{capitalizeFirstLetter(result.query)}</CardHeader>
                        }
                        {!selectedPrompt && result === null &&
                            <LibraryPromptSelector prompts={libraryPrompts} 
                                                    setSelectedPrompt={(prompt) => setSelectedPrompt(prompt)} />
                        }
                        {generating && !answer && (
                            <div className="search_msg mt-2 px-2">
                                <button className="btn border-0 disabled" type="button">
                                    <Spinner size="sm" color="primary" /><span className='ps-2'>I'm searching your document...</span>
                                </button>
                            </div>
                        )}
                        {generating && answer && (
                            <div className='px-2' style={{ flex: 1 }}>
                                <div className="search_msg">
                                    <div className="message" dangerouslySetInnerHTML={{ __html: answer }} />
                                </div>
                            </div>
                        )}
                        {result && result.response !== null && (
                            <div style={{ position: 'relative' }}>
                                <div className='p-2' style={{ position: 'absolute', top: 0, right: 0 }}>
                                    <Button id='copy_all_btn' className="btn btn-link text-dark" onClick={handleCopy}>
                                        {!copyStatus ? <FontAwesomeIcon icon={faCopy}></FontAwesomeIcon> : <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>}
                                    </Button>
                                    <Tooltip isOpen={tooltipOpen1} placement={'bottom'} target="copy_all_btn" toggle={toggle1}>
                                        Copy answer
                                    </Tooltip>
                                </div>
                                <div className='px-2' style={{ flex: 1 }}>
                                    <div id='copy_this' className="search_msg">
                                        {result.response.split('\n\n').map((paragraph, index) => (
                                            <div className="message" key={index}>
                                                {renderer(paragraph)}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className='container bg-light px-0 mx-auto px-3 py-2'>
                    <div className="d-flex align-items-center">
                        {result !== null && (
                            <div className='col-auto'>
                                <a className="btn btn-outline-dark me-2 btn-lg" onClick={() => {setSelectedPrompt(null); setResult(null)}} >
                                    <span>
                                        <FontAwesomeIcon className="me-0" icon={faBook}/>
                                    </span>
                                </a>
                            </div>
                        )}
                        <div className="form-floating text-dark flex-grow-1 me-2">
                            <input 
                                id='question_chat' 
                                autoComplete="off" 
                                onFocus={()=>setTypingCivilsGPT(true)}
                                onBlur={()=>setTypingCivilsGPT(false)}
                                onChange={(e) => handleChangeQuery(e)} 
                                type="text" 
                                value={chatInput}
                                className="form-control gpt_input" 
                                placeholder={"Search for info in all your library docs"} 
                            />
                            <label htmlFor='question_chat' className='text-secondary'>
                                Search for info in all your library docs
                            </label>
                        </div>
                        {(generating || chatInput.length<1) ? (
                            <button className="btn btn-secondary disabled border-0 btn-lg" disabled type="button">
                                <FontAwesomeIcon icon={faMagnifyingGlass}></FontAwesomeIcon>
                            </button>  
                        ) : (
                            <button className="btn btn-primary border-0 btn-lg" type="button" onClick={() => submitMessage()}>
                                <FontAwesomeIcon icon={faMagnifyingGlass}></FontAwesomeIcon>
                            </button>    
                        )}
                    </div>
                </div>
            </>
        )}
        </>
    )
}

export default LibrarySearch;