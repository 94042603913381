import React, { useCallback, useEffect, useState, useRef } from "react";
import BoreholeLogDetails from "../dataTab/BoreholeLogDetails";
import httpClientPy from "../../../utils/httpClientPy.js";


const MapDetailsModal = (props) => { 

    const [modal, setModal] = useState(false);
    const [selectedData, setSelectedData] = useState(false);
    const [selectedDataStrata, setSelectedDataStrata] = useState(false);
    const [selectedDataTest, setSelectedDataTest] = useState(false);
    const [convert, setConvert] = useState(false);
    const [recordUpdated, setRecordUpdated] = useState(false);

    useEffect(() => {
        setModal(true);

        if(props.selectedDataItem || recordUpdated){
        fetchBoreholeData(props.selectedDataItem, props.projectDetails.id).then((response) => {
            let data = response.data;
            data['position'] = {lat: data.geo_record_lat, lng: data.geo_record_lng}
            setSelectedData(data);
            // order strata by top
            let strata_holder = response.data.geo_record_strata

            if(strata_holder[0].top > strata_holder[0].base){
                strata_holder.sort(function(a, b) {
                  return parseFloat(b.top) - parseFloat(a.top);
                });
              }
              else{
                strata_holder.sort(function(a, b) {
                  return parseFloat(a.top) - parseFloat(b.top);
                });
                }
            setSelectedDataStrata(strata_holder);
            setSelectedDataTest(response.data.geo_record_test);
        });
        setRecordUpdated(false);
        }
    }, [props.selectedDataItem, convert, recordUpdated]);

    const updateConvert = () => {
        setConvert(!convert);
    }



    return(
        <>
            <BoreholeLogDetails data={selectedData} 
                                onSearchGeoRecord={(lat, lng, id, type)=>setModal(false)}
                                strata={selectedDataStrata} 
                                tests={selectedDataTest} 
                                updateMap={()=>props.updateMap()}
                                updateConvert={()=>updateConvert()}
                                toggleModal={()=>props.toggleModal()}
                                projectId={props.projectDetails.id}
                                project={props.projectDetails}
                                setGeoRecordPosition = {(position) => props.setGeoRecordPosition(position)}
                                toggleUpdateBorehole = {(status) => props.toggleUpdateBorehole(status)}
                                boreHoleInfoUpdate={(status) => {props.boreHoleInfoUpdate(status)}}
                                setGeoRecord = {(record) => props.setGeoRecord(record)}
                                setRecordUpdated={(status) => setRecordUpdated(status)}
            />
        </>

    )

 }; export default MapDetailsModal;

 const fetchBoreholeData = (id, project_id) => {
    return httpClientPy.get(`/geo/record?geo_record_id=${id}&project_id=${project_id}&format=json`).then((response) => {
      return response;
    });
  };