import axios from "axios";

const service = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_PY
})

service.interceptors.request.use(
  (config) => {

    // config.headers['Access-Control-Allow-Origin'] = '*.civils.ai';
    // config.headers['Access-Control-Allow-Methods'] = 'GET, POST, PUT, DELETE, PATCH';
    // config.headers['Access-Control-Allow-Headers'] = 'Content-Type, Authorization';
    // config.headers['Content-Type'] = 'application/json';

    if (!config.headers.Authorization) {
      const token = localStorage.getItem("auth_token");

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default service;
