import mixpanel from 'mixpanel-browser';

export const initMixpanel = () => {
    if (process.env.REACT_APP_ENVIRONMENT === "production") {
        mixpanel.init(process.env.REACT_APP_MIXPANEL, {debug: false, track_pageview: false, persistence: 'localStorage'});
    }   
}

export const setMixpanelID = (userId, email, enterprise, enterprise_name) => {
    if (process.env.REACT_APP_ENVIRONMENT === "production") {
        mixpanel.identify(userId);
 
        mixpanel.people.set({'$email': email,
                            'id': userId,
                            'enterprise': enterprise,
                            'enterprise_name': enterprise_name
                        });
    }
}

export const logEvent = (eventName, eventProperties) => {
    if (process.env.REACT_APP_ENVIRONMENT === "production") {
        mixpanel.track(eventName, eventProperties);
    }
}