import React, { useState, useRef, useEffect } from "react";
import { Modal } from "reactstrap";
import { FeatureGroup, Marker, Polygon, useMap } from "react-leaflet";
import L from "leaflet";
import { EditControl } from "react-leaflet-draw";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import ChartGeoSectionCut from "./geoCutSectionComponents/ChartGeoSectionCut";
import httpClientPy from "../../../utils/httpClientPy";
import { Alert } from "reactstrap";
import Button from "../../shared/Button";
import { logEvent } from "../../shared/Mixpanel";
import Loader from "../../shared/Loader";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const GeoCutSection = (props) => {
    // variable to store the mapLayers
    const [mapLayers, setMapLayers] = useState([]);
    // variable to store if the polyline creation is completed
    const [polylineCompleted, setPolylineCompleted] = useState(false);
    // variable to check if delete polyline is done
    const [deletePolyline, setDeletePolyline] = useState(false);
    // variable to check if model for chart is open
    const [toggle, setToggle] = useState(false);
    // variable to display polygon
    const [displayPolygon, setDisplayPolygon] = useState(false);
    //variable to check if polyline is being edited
    const [editPolyline, setEditPolyline] = useState(false);
    //variable to check if offset distance is being changed
    const [offsetDistanceChanged, setOffsetDistanceChanged] = useState(false);
    //variable to store the offset
    const [offset, setOffset] = useState(100);
    //variable to check if mouse is at slider area
    const [alertArea, setAlertArea] = useState(false);

    // set markers from response of the API
    const [markers, setMarkers] = useState([]);
    // set the polygon from response of the API
    const [polygon, setPolygon] = useState([]);
    // set the info from response of the API
    const [info, setInfo] = useState([]);
    // set the distance from response of the API
    const [distance, setDistance] = useState([]);
    // set the loading spinner for the chart
    const [loading, setLoading] = useState(false);
    // set the middle point from the polyline
    const [middlePoint, setMiddlePoint] = useState([]);
    // set the coords from the polyline
    const [coords, setCoords] = useState([]);
    // set the granular points from the API
    const [granularPoints, setGranularPoints] = useState([]);

    const toggleChartSection = () => {
        props.onToggleSectionCut(!toggle)
        setToggle(!toggle);
    };

    const exitSection = () => {
        props.onToggleSectionCut(false)
    };

    // this is the reference to the polyline drawing tool
    const polyRef = useRef();
    const mapholder = useMap();
    let polyline = useRef();
    let vertexCount = useRef();

    // disable dragging on map when alertArea is true
    useEffect(() => {
        if (alertArea) {
            mapholder.dragging.disable();
        } else {
            mapholder.dragging.enable();
        }
    }, [alertArea]);

    // this function is ran when the page is loaded and the edit control are loaded
    const _onMounted = (e) => {
        document.getElementsByClassName('leaflet-control-container')[0].style.visibility = 'hidden';
        polyRef.current = e;
        vertexCount.current = 0;
    };

    // this useEffect checks if the polyref is null or not
    useEffect(() => {
        if (!polyRef.current) {
            return;
        }
        // if not null then enable the polyline drawing tool with custom options and tooltip
        polyline.current = new L.Draw.Polyline(mapholder, polyRef.current.options.polyline);
        polyline.current.options.shapeOptions.color = "purple";
        polyline.current.options.allowIntersection = false;
        polyline.current.options.repeatMode = false;
        polyline.current.enable();
        L.drawLocal.draw.handlers.polyline.tooltip.start = "Click to start drawing area"
        L.drawLocal.draw.handlers.polyline.tooltip.cont = "Click to continue drawing area"
        L.drawLocal.draw.handlers.polyline.tooltip.end = "Click last point to close complete or press escape to cancel drawing"
        document.getElementsByClassName("leaflet-draw-tooltip")[0].style.backgroundColor = "purple";
        return () => {
            polyline.current.disable();
        }
    }, [polyRef]);

    // this function is ran when the polygon is created or finished
    const _onCreate = (e) => {
        // get the layer type and layer
        const { layerType, layer } = e;
        
        // check if the layer type is polygon
        if (layerType === "polyline") {
            // get the layer id
            const { _leaflet_id } = layer;

            // add the layer to the mapLayers
            setMapLayers((layers) => [
                ...layers,
                { id: _leaflet_id, latlngs: layer.getLatLngs() },
            ]);
        }
        // set the polylineCompleted to true
        setPolylineCompleted(true);
    };

    // function to check on new vertex draw and calculate the number of vertexes
    const _onDrawVertex = (e) => {
       
        vertexCount.current += 1;
        if (vertexCount.current > 1) {

            const elements = document.querySelectorAll('.leaflet-marker-icon.leaflet-div-icon.leaflet-editing-icon.leaflet-touch-icon.leaflet-zoom-animated.leaflet-interactive');
            const lastElement = elements[elements.length - 1];

            setTimeout(function(){
                lastElement.click()
            }, 50);  
                
        }
    }    

    const updateLocation = () => {
        setToggle(false)        
        setEditPolyline(true);
        setPolylineCompleted(false);
        setTimeout(function(){
            document.getElementsByClassName('leaflet-draw-edit-edit')[0].click();
            document.getElementsByClassName('leaflet-control-container')[0].style.visibility = 'hidden';
        }, 200);             
    }

    const offsetDistanceChange = () => {
        setToggle(false)
        setOffsetDistanceChanged(!offsetDistanceChanged);
    }

    const cancelSection = () => {
        try{
            let button = document.querySelectorAll('.leaflet-draw-actions.leaflet-draw-actions-top')[0]
            button.lastElementChild.firstElementChild.click()
            setEditPolyline(false);
            setToggle(true)
        }
        catch{
            setEditPolyline(false);
            setToggle(true)
        }
    }

    const _onEdited = (e) => {
        setPolylineCompleted(true);

        setDeletePolyline(false);
        const {
            layers: { _layers },
        } = e;

        Object.values(_layers).map(({ _leaflet_id, editing }) => {
        setMapLayers((layers) =>
                layers.map((l) =>
                l.id === _leaflet_id
                    ? { ...l, latlngs: [ ...editing.latlngs[0] ] }
                    : l
                )
            );
        });
    };

    const _onDeleted = (e) => {
        setDeletePolyline(true);
        const {
            layers: { _layers },
        } = e;
        
        Object.values(_layers).map(({ _leaflet_id }) => {
            setMapLayers((layers) => layers.filter((l) => l.id !== _leaflet_id));
        });
        setPolylineCompleted(false);
    };

    useEffect(() => {

        if (polylineCompleted === true) {
            
            var coords = [];
            mapLayers.map((element) =>{
                element.latlngs.map((latlng) => {
                    coords.push({lat: latlng.lat, lng: latlng.lng});
                }
            )})

            setCoords(coords);

            if (coords.length > 0) {
                setLoading(true)
                httpClientPy
                .post(`/geo/2d/sections`, {
                    coords: coords,
                    offset: offset,
                    type: !offsetDistanceChanged ? 0 : 1,
                    project_id: props.projectDetail.id
                })
                .then((response) => {
                    // console.log(response.data)
                    if (!offsetDistanceChanged) {
                        setPolygon(response.data.boxes);
                        setInfo(response.data.infos);
                        setDistance(response.data.distance);
                        setMiddlePoint(response.data.middle_point);
                        setGranularPoints(response.data.granular_points);
                        setLoading(false)
                    } else {
                        setPolygon(response.data.boxes);
                        setLoading(false)
                    }
                })
                .finally(() => {
                    if (!offsetDistanceChanged) {
                        logEvent('2D Section', {"Step": "Draw Section"});
                        toggleChartSection();
                        setAlertArea(false);
                    }
                });
            }
        }
    }, [mapLayers, offset, offsetDistanceChanged]);


    const getUpdatedBoreholes = () => {
        httpClientPy
        .post(`/geo/2d/sections`, {
            coords: coords,
            offset: offset,
            type: !offsetDistanceChanged ? 0 : 1,
            project_id: props.projectDetail.id
        })
        .then((response) => {
            // console.log(response.data)
            setInfo(response.data.infos);
        })
    }


    useEffect(() => {
        if (polygon.length > 0) {
            setDisplayPolygon(true);
        }
    }, [polygon]);
    
    return (
        <>  
            {/* {markers.length > 0 &&
                markers.map((marker) => (
                <Marker
                    position={[
                        marker[0],
                        marker[1]
                    ]}
                >
                </Marker>
                ))
            }     */}
            { displayPolygon === true &&
                <Polygon
                    positions={polygon}
                    color="purple"
                    weight={3}
                />
            }
            <FeatureGroup>
                <EditControl
                    position="topright"
                    onMounted={_onMounted}
                    onCreated={_onCreate}
                    onDrawVertex={_onDrawVertex}
                    draw={{
                        rectangle: false,
                        polyline: {
                            allowIntersection: false,
                            shapeOptions: {
                                color: "purple",
                                weight: 3,
                            },
                        },
                        circle: false,
                        circlemarker: false,
                        marker: false,
                        polygon: false,
                    }}
                />
            </FeatureGroup>
            <Modal id="chartGeoSectionCut" className="fullscreen_modal" fullscreen={true} isOpen={toggle} toggle={toggleChartSection} centered={true}>
                <ChartGeoSectionCut displayName="2D Geological Section" 
                                    project={props.projectDetail}
                                    loading={loading}
                                    info={info}
                                    distance={distance}
                                    toggleModal = {()=> toggleChartSection}
                                    onUpdateLocation = {()=> updateLocation()}
                                    offsetDistanceChange = {()=> offsetDistanceChange()}
                                    offset={offset}
                                    coords={coords}
                                    middlePoint={middlePoint}
                                    granularPoints={granularPoints}
                                    boreHoleInfoUpdate={()=> getUpdatedBoreholes()}
                                    setGeoRecordPosition = {(position) => props.setGeoRecordPosition(position)}
                                    toggleUpdateBorehole = {(status) => props.toggleUpdateBorehole(status)}
                                    setGeoRecord = {(record) => props.setGeoRecord(record)}
                                    />
            </Modal>
            {offsetDistanceChanged &&
                <Alert color="light" className="border text-center m-0 w-100" 
                        style={{position: 'fixed', bottom: 0, zIndex:500, borderRadius:0}} onMouseEnter={() => setAlertArea(!alertArea)}
                        onMouseLeave={() => setAlertArea(!alertArea)}>
                    <div className="">
                        <input type="range" min="10" max="500" step="1" value={offset} 
                                onChange={(event) => setOffset(event.target.value)} className="form-range" id="offsetRange"
                                style={{ width: '50%', margin: '0 auto' }}>
                        </input>
                        <label htmlFor="offsetRange" className="form-label text-center text-secondary w-100">
                            <small>Offset Range is {offset} meters</small>
                        </label>
                    </div>
                    <Button
                        onClick={() => setOffsetDistanceChanged(!offsetDistanceChanged)}
                        label="Confirm"
                        className="btn btn-primary me-3"
                    ></Button>
                    <Button
                        onClick={() => setOffsetDistanceChanged(!offsetDistanceChanged)}
                        label="Cancel"
                        className="btn btn-warning"
                    ></Button>
                </Alert>
            }
            {/*
            {editPolyline && 
                <Alert color="light" className="border text-start m-0 w-100" style={{position: 'fixed', bottom: 0, zIndex:9999}}>
                    <Button
                        onClick={()=>{saveSection()}}
                        label="Save changes"
                        className="btn btn-primary me-3"
                    ></Button>
                    <Button
                        onClick={()=>{cancelSection()}}
                        label="Cancel edit section"
                        className="btn btn-secondary"
                    ></Button>
                </Alert>
            }*/}
            {!editPolyline && !offsetDistanceChanged &&
                <Alert color="light" className="border text-center m-0 w-100" style={{position: 'fixed', bottom: 0, zIndex:500, borderRadius:0}}>
                    <Button
                        onClick={()=>{exitSection()}}
                        label="Cancel (or hit ESC)"
                        className="btn btn-danger"
                    ></Button>
                </Alert>
            }
        </>
    );
};

export default GeoCutSection;