
import { useEffect, useState, useRef } from "react";  
import {distanceInKmBetweenEarthCoordinates, kmToLongitude, kmToLatitude } from "../../shared/algorithms/geoRecordAlgorithms.js"
import { useMap} from "react-leaflet";

var insideStatus = false
var insideStatusHolder = false
var boreholeList = {ne:[], se:[], sw:[], nw:[]}
var boreholeData = {virtual: [], boreholes:[]}
var insideMarkers = []
var mousedownTime;

const VirtualBorehole = (props) => {
    //nearestBoreholeList is a dicionary used to track the 4 nearest boreholes to the 'virtual borehole'
    const nearestBoreholeList = useRef([]);


    // listen for clicks with virtual borehole
    useEffect(() => {
        document.addEventListener("mousedown", handleMouseDown)
        document.addEventListener("mouseup", handleMouseUp)

        // cleanup
        return () => {
          document.removeEventListener("mousedown", handleMouseDown)
          document.addEventListener("mouseup", handleMouseUp)
        }
      }, [])

      function handleMouseDown(){
        if(props.addBorehole || props.updateBorehole){mousedownTime = new Date().getTime();}
        else if(insideStatus==true){
            mousedownTime = new Date().getTime();
        }
      }

      function handleMouseUp(){
        const mouseupTime = new Date().getTime(),
        timeDifference = mouseupTime - mousedownTime;
        
        if(props.addBorehole || props.updateBorehole){
            if(timeDifference<200){
            }
        }
        else if(insideStatus==true){
            if(timeDifference<200){
                props.createVirtualBorehole(true)
            }
        }
      }



    // Check if any of the markers with a status of 'ready' are within the bounds of the virtual borehole
    if (props.readyMarkers.length>0 && !props.fixVirtualBorehole && props.virtualBorehole){
        insideStatus = false
        insideMarkers = []
        const selectionRadius = (((props.selectionRadiusSlider/400)*76000) / Math.pow(2, props.zoom - 3) * Math.cos(props.clickLocation.lat * Math.PI / 180))*0.05196;
        for (let marker of props.readyMarkers){
            insideStatusHolder = checkIfInside(marker, props.clickLocation, selectionRadius)
            if(insideStatusHolder){
                insideStatus = true
                // check if a marker with same coordinates is already in the list else add it
                let found = false
                for (let insideMarker of insideMarkers){
                    if (insideMarker.properties.coordinates.lat === marker.properties.coordinates.lat && insideMarker.properties.coordinates.lng === marker.properties.coordinates.lng){
                        found = true
                    }
                }
                if (!found){
                    insideMarkers.push(marker)
                }
            }
        }
    }

    if (insideMarkers.length>0 && props.fixVirtualBorehole){
        // start a new dictionary of the borehole list if you have ready markers
        nearestBoreholeList.current = insideMarkers

    }

    // each time the nearestBoreholeList is updated then fetch the borehole data for these records 
    // then use this data to use the 'estimateVirtualBorehole' function i.e krigging algorithm
    // use these results to update boreholeData
    useEffect(()=>{
        if(nearestBoreholeList.current.length>0){
            const selectionRadius = (((props.selectionRadiusSlider/400)*76000) / Math.pow(2, props.zoom - 3) * Math.cos(props.clickLocation.lat * Math.PI / 180))*0.05196;
            let max_lat = props.clickLocation.lat + kmToLatitude(selectionRadius)
            let min_lat = props.clickLocation.lat - kmToLatitude(selectionRadius)
            let max_lng = props.clickLocation.lng + kmToLongitude(selectionRadius, props.clickLocation.lat)
            let min_lng = props.clickLocation.lng - kmToLongitude(selectionRadius, props.clickLocation.lat)


            boreholeData.virtual = [] 
            boreholeData.boreholes = []

            boreholeData.virtual.strata = []
            boreholeData.virtual.location = props.clickLocation
            boreholeData.virtual.selectionRadius = selectionRadius*1000
            boreholeData.virtual.max_lat = max_lat
            boreholeData.virtual.min_lat = min_lat
            boreholeData.virtual.max_lng = max_lng
            boreholeData.virtual.min_lng = min_lng
            boreholeData.virtual.boreholeCount = nearestBoreholeList.current.length
            boreholeData.virtual.nearest_boreholes = nearestBoreholeList.current
            props.updateBoreholeList(boreholeData)
        }
    }, [nearestBoreholeList.current])





    // the virtual borehole cursor animation
    useEffect(()=>{

        var isRunning

        if ((props.virtualBorehole && !props.fixVirtualBorehole) || ((props.addBorehole || props.updateBorehole) && !props.fixBorehole)){
            isRunning = true
        }
        else{
            isRunning = false
        }


        if (window.matchMedia("(min-width: 768px)").matches) {
            let mousePosX = 0,
                mousePosY = 0,
                mouseCircle = document.getElementById("mouse-circle");
                let status = true


            document.onmousemove = (e) => {
                mousePosX = e.pageX;
                mousePosY = e.pageY;
            };


            let delay = 6,
                revisedMousePosX = 0,
                revisedMousePosY = 0;
                
                delayMouseFollow();

            function delayMouseFollow() {
                if(isRunning){
                    requestAnimationFrame(delayMouseFollow);
                    revisedMousePosX += (mousePosX - revisedMousePosX) / delay;
                    revisedMousePosY += (mousePosY - revisedMousePosY) / delay;
            
                    mouseCircle.style.top = revisedMousePosY + "px";
                    mouseCircle.style.left = revisedMousePosX + "px";
                }
                else{
                    isRunning=false
                    var id = window.requestAnimationFrame(function(){});
                    while(id--){
                    window.cancelAnimationFrame(id);
                    }
                }
        }
        
    }
    }, [props.virtualBorehole, props.fixVirtualBorehole, props.fixBorehole, props.addBorehole, props.updateBorehole])

    return (
        <>
            <div
            id="mouse-circle"
            style={{
                height: `${props.selectionRadiusSlider}px`,
                width: `${props.selectionRadiusSlider}px`,
                marginLeft: `${-props.selectionRadiusSlider / 2}px`,
                marginTop: `${-props.selectionRadiusSlider / 2}px`
            }}
            className={`text-center ${
                props.addBorehole || props.updateBorehole
                ? "virtualBorehole-hidden"
                : insideStatus
                ? "virtualBorehole-success"
                : "virtualBorehole-fail"
            }`}
            >
            {props.addBorehole || props.updateBorehole ? (
                <>
                {insideStatus ? (
                    <img src="/assets/borehole-green.svg" />
                ) : (
                    <img src="/assets/borehole-red.svg" />
                )}
                <div className="add-borehole-text">Click to add borehole to your map</div>
                </>
            ) : (
                <div className="mouse-circle-text">
                {insideMarkers.length === 0 ? (
                    <span>Move modelling zone near your boreholes, click if area is green</span>
                ) : (
                    <span>
                    <span className="title-bold">{insideMarkers.length}</span> boreholes selected for 3D modelling
                    </span>
                )}
                </div>
            )}

            </div>

        </>
    );
    };

    export default VirtualBorehole;


function checkIfInside(spotCoordinates, center, radius) {

    let newRadius = distanceInKmBetweenEarthCoordinates(spotCoordinates.properties.coordinates.lat, spotCoordinates.properties.coordinates.lng, center.lat, center.lng);

    if( newRadius < radius ) {
        //point is inside the circle
        return true
    }
    else if(newRadius > radius) {
        //point is outside the circle
        return false
    }
    else {
        //point is on the circle
        return true
    }

}
