import { faSortUp, faEdit,  faTrash, faCheck,  faClose, faRedo, faClockRotateLeft, faPlus, faMinus, faFileExcel, faSortDown, faListCheck, faArrowRight, faWindowMinimize } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Spinner, Tooltip, Modal, ModalHeader, ModalBody } from 'reactstrap';
import httpClientPy from "../../../../utils/httpClientPy";
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import { marked, use } from 'marked';
import { logEvent } from '../../../shared/Mixpanel';
import RegSelecterModal from './RegSelecterModal';
import DeleteConfirmModal from '../../../shared/layouts/DeleteConfirmModal';
import CheckHistory from './CheckHistory';
import { faWindowMaximize } from '@fortawesome/fontawesome-free-solid';

marked.setOptions({
    gfm: true,
    tables: true,
  });


const RegChecker = (props) => {

    const [isAdvanceSearch, setIsAdvanceSearch] = useState(false);
    const [selectReg, setSelectReg] = useState(false);
    const [selectedRegName, setSelectedRegName] = useState(null);
    const [loadingExtract, setLoadingExtract] = useState(false);
    const [text, setText] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [allComments, setAllComments] = useState(null);
    const [allCommentsUUID, setAllCommentsUUID] = useState(null);
    const [deleteComment, setDeleteComment] = useState(false);
    const [selectedCommentId, setSelectedCommentId] = useState(null);
    const [selectedCommentText, setSelectedCommentText] = useState(null);
    const [editComment, setEditComment] = useState(false);
    const [openHistory, setOpenHistory] = useState(false);
    const [loadingEdit, setLoadingEdit] = useState(false);
    const [loadingGeneration, setLoadingGeneration] = useState(false);
    const [showText, setShowText] = useState(true);
    const [currentMasterlistPage, setCurrentMasterlistPage] = useState(1);
    const [masterListTotalPages, setMasterListTotalPages] = useState(1);
    const [isAsc, setIsAsc] = useState(false);

    const [tooltipOpen1, setTooltipOpen1] = useState(false);
    const [tooltipOpen2, setTooltipOpen2] = useState(false);
    const [tooltipOpen3, setTooltipOpen3] = useState(false);

    const toggle1 = () => setTooltipOpen1(!tooltipOpen1);
    const toggle2 = () => setTooltipOpen2(!tooltipOpen2);
    const toggle3 = () => setTooltipOpen3(!tooltipOpen3);

    const paginate = (pageNumber) => setCurrentMasterlistPage(pageNumber);


    
    const handleSelectReg = () => {
        setSelectReg(!selectReg);
        setIsAdvanceSearch(false);
    }

    const handleTextClick = () => {
        setIsEditing(true);
    }

    const handleInputChange = (e) => {
        setText(e.target.value);
    }

    const handleInputBlur = () => {
        setIsEditing(false);
    }

    const handleCommentInputChange = (e) => {
        setSelectedCommentText(e.target.value);
    }

    useEffect(() => {
        if (props.base64img !== null) {
            setAllComments(null);
            props.toggleShowMasterlist(false);
            setLoadingExtract(true);
            httpClientPy.post('/extract/text', { 
                base64_img: props.base64img 
            }).then((response) => {
                setText(response.data.text);
                setShowText(true);
                setLoadingExtract(false);
            }).catch((error) => {
                console.log(error);
            });
        } else {
            setText(null);
        }
        props.updateBase64img()
    }, [props.base64img]);

    useEffect(() => {
        setOpenHistory(props.openCheckHistory);
    }, [props.openCheckHistory]);

    useEffect(() => {
        if (!props.showMasterlist) {
            return;
        }
        httpClientPy.get(`/masterlist/comments?project_id=${props.project.id}&report_id=${props.selectedId}&limit=5&offset=${(currentMasterlistPage - 1) * 5}&is_asc=${isAsc}`)
        .then((response) => {
            if (response.data.checks.length > 0) {
                props.toggleShowMasterlist(true);
                props.updateMasterListComments(response.data.checks);
                setMasterListTotalPages(response.data.total_pages);
                setAllComments(null);
                setAllCommentsUUID(null);
            }
        }).catch((error) => {
            console.log(error);
        })
    }, [currentMasterlistPage, isAsc, props.showMasterlist]);

    const renderer = (text) => {
        const html = marked.parse(text);
        return <div dangerouslySetInnerHTML={{ __html: html }} />;
    };    

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && e.shiftKey) {
            e.preventDefault();
            const { selectionStart, selectionEnd } = e.target;
            const newValue = text.substring(0, selectionStart) + '\n' + text.substring(selectionEnd);
            setText(newValue);
            // Move the cursor to the new position
            setTimeout(() => {
                e.target.selectionStart = e.target.selectionEnd = selectionStart + 1;
            }, 0);
        }
    };

    const generateComments = async () => {
        setLoadingGeneration(true);
        let requestBody
        let api
        const token = localStorage.getItem('auth_token');

        api = '/comments/all'
        requestBody = JSON.stringify({
            report_id: props.selectedId,
            reg_file_name: selectedRegName,
            project_id: props.project.id,
        });


        try {
            const response = await fetch(process.env.REACT_APP_BASE_URL_PY + api, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: requestBody,
            });

            const reader = response.body.getReader();
            const decoder = new TextDecoder();

            while (true) {
                const { done, value } = await reader.read();

                if (done) {
                    break;
                }
                setAllComments("");
                let curr_text = decoder.decode(value);
                try {
                    let json = JSON.parse(curr_text);
                    setAllComments(json['comments']);
                    setAllCommentsUUID(json['metadata_id']);
                } catch (error) {
                    setAllComments(curr_text);
                }
            }
            
            setLoadingGeneration(false);
        } catch (error) {
            console.error('Error:', error);
        }
    }

    const generateSectionBasedComments = async () => {
        setLoadingGeneration(true);
        let requestBody
        let api
        const token = localStorage.getItem('auth_token');

        api = '/comments/section'
        requestBody = JSON.stringify({
            report_id: props.selectedId,
            reg_file_name: selectedRegName,
            project_id: props.project.id,
            text: text,
        });


        try {
            const response = await fetch(process.env.REACT_APP_BASE_URL_PY + api, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: requestBody,
            });

            const reader = response.body.getReader();
            const decoder = new TextDecoder();

            while (true) {
                const { done, value } = await reader.read();

                if (done) {
                    break;
                }
                setAllComments("");
                let curr_text = decoder.decode(value);
                try {
                    let json = JSON.parse(curr_text);
                    setAllComments(json['comments']);
                    setAllCommentsUUID(json['metadata_id']);
                } catch (error) {
                    setAllComments(curr_text);
                }
            }
            setText(null);
            setLoadingGeneration(false);

        } catch (error) {
            console.error('Error:', error);
        }
    }

    const handleGenerateComments = (type) => {
        setAllCommentsUUID(null);
        props.toggleShowMasterlist(false);
        if (type === 0) {
            generateComments();
        } else {
            generateSectionBasedComments();
        }
    }

    const handleReferenceClick = (refs) => {

        httpClientPy.post('/comments/reference', {
            ref_id: refs[0],
            project_id: props.project.id,
            report_id: props.selectedId,
        }).then((response) => {
            if (refs[2] == props.currentFileName) {
                props.updatePageNumber(parseInt(refs[1]));
                props.updateInitialPage(parseInt(refs[1]));
                props.updateHighlights(response.data.highlight_area);
            } else {
                props.updateFileUrl(response.data.file_path);
                props.updateRecordId(response.data.record_id)
                props.updateFileName(response.data.file_name);
                props.updateFileType(response.data.geotech);
                props.updatePageNumber(parseInt(refs[1]));
                props.updateInitialPage(parseInt(refs[1]));
                props.updateHighlights(response.data.highlight_area);
            }

            logEvent('Reference Clicked', { 'File': response.data.file_name, 'Page': refs[1], 'Type': 'check' })
        }).catch((error) => {
            console.log(error);
        });  
    }

    const handleEditComment = (is_masterlist_edit) => {
        setLoadingEdit(true);
        let comments;
        
        httpClientPy.post('/comment/update', {
            comment_id: selectedCommentId,
            comment_updated_text: selectedCommentText,
        }).then((response) => {
            if (is_masterlist_edit) {
                comments = [...props.masterListComments];
                let commentIndex = comments.findIndex(comment => comment.id === selectedCommentId);
                comments[commentIndex].comment = selectedCommentText;
                props.updateMasterListComments(comments);
            } else {
                comments = [...allComments];
                let commentIndex = comments.findIndex(comment => comment.id === selectedCommentId);
                comments[commentIndex].comment = selectedCommentText;
                setAllComments(comments);
            }
            setEditComment(false);
            setSelectedCommentId(null);
            setSelectedCommentText(null);
            setLoadingEdit(false);
        }).catch((error) => {
            console.log(error);
        })
    }

    const handleDeleteComment = () => {
        setLoadingEdit(true);
        let comments;

        httpClientPy.post('/comment/delete', {
            comment_id: selectedCommentId,
        }).then((response) => {
            if (props.masterListComments) {
                comments = [...props.masterListComments];
                let commentIndex = comments.findIndex(comment => comment.id === selectedCommentId);
                comments.splice(commentIndex, 1);
                props.updateMasterListComments(comments);
            }
            if (allComments) {
                comments = [...allComments];
                let commentIndex = comments.findIndex(comment => comment.id === selectedCommentId);
                comments.splice(commentIndex, 1);
                setAllComments(comments);
            }
            setDeleteComment(false);
            setSelectedCommentId(null);
            setLoadingEdit(false);
        }).catch((error) => {
            console.log(error);
        });
    }

    const addToMasterlist = (commentId) => {
        httpClientPy.post('/masterlist/comments/add', {
            add_type: commentId === "" ? "all" : "single",
            comment_id: commentId === "" ? null : commentId,
            metadata_id: allCommentsUUID,
        }).then((response) => {
            let comments = [...allComments];
            let commentIndex = comments.findIndex(comment => comment.id === commentId);
            comments[commentIndex].masterlist = true;
            setAllComments(comments);
        }).catch((error) => {
            console.log(error);
        });
    }

    const removeFromMasterlist = (commentId) => {
        httpClientPy.post('/masterlist/comments/remove', {
            remove_type: commentId === "" ? "all" : "single",
            comment_id: commentId === "" ? null : commentId,
            metadata_id: allCommentsUUID,
        }).then((response) => {
            let comments = [...allComments];
            let commentIndex = comments.findIndex(comment => comment.id === commentId);
            comments[commentIndex].masterlist = false;
            setAllComments(comments);
        }).catch((error) => {
            console.log(error);
        });
    }

    const toggleDeleteComment = (commentId) => {
        setDeleteComment(true);
        setSelectedCommentId(commentId);
    }

    const toggleEditComment = (comment) => {
        setEditComment(true);
        setSelectedCommentId(comment.id);
        setSelectedCommentText(comment.comment);
    }

    const cancelEditComment = () => {
        setEditComment(false);
        setSelectedCommentId(null);
    }

    const handleCheckHistory = () => {
        setOpenHistory(!openHistory);
    }
    
    const handleHistoryClicked = (row) => {
        setText(row.section);
        setShowText(false);
        props.toggleShowMasterlist(false);
        setAllCommentsUUID(row.metadata_id);
        httpClientPy.get(`/comments/report?metadata_id=${row.metadata_id}`)
        .then((response) => {
            setAllComments(response.data.checks);
            setOpenHistory(false);
            props.setOpenCheckHistory(false);
        }).catch((error) => {
            console.log(error);
        });
    }

    const handleShowMasterlist = () => {
        props.toggleShowMasterlist(!props.showMasterlist);
        setText(null);
    }

    const downloadExcel = () => {
        httpClientPy.get(`/masterlist/comments/download?project_id=${props.project.id}&report_id=${props.selectedId}`)
        .then((response) => {
            let file_name = response.data.report_file_name
            let base64 = response.data.b64
            const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64}`;
            const downloadLink = document.createElement("a");
            downloadLink.href = linkSource;
            downloadLink.download = file_name;
            downloadLink.click();
        }).catch((error) => {
            console.log(error);
        });
    }

    return (
        <>
            <div id="regCheck" className="d-flex flex-column w-100" >
                <div id="copy_parent" 
                    className={`bg-offwhite ${1 === 2 ? 'd-none d-sm-flex flex-column flex-grow-1' : 'd-flex flex-column flex-grow-1'}`} 
                    style={{ 
                        overflowY: 'auto',
                        minHeight: '30dvh', 
                    }}
                >  
                    {props.showMasterlist && props.masterListComments && (
                        <div className="h-100 d-flex justify-content-center" key={0}>
                            <div className='row g-0 w-100'>
                                <div className='col-12'>
                                    <div className="msg">
                                        <div className='d-flex justify-content-between mb-2'>
                                            <div className="align-self-center">
                                                <b>All comments list</b>
                                            </div>
                                        </div>
                                        <table class="table table-bordered bg-light">
                                            <thead>
                                                <tr>
                                                <td width={450}><b>Comment</b></td>
                                                <td width={50}><b>Project doc</b></td>
                                                <td width={50}><b>Building code</b></td>
                                                <td width={100}><b>Updated <a className='btn-sm btn-light ms-1' onClick={() => setIsAsc(!isAsc)}><FontAwesomeIcon icon={isAsc ? faSortUp : faSortDown}/></a></b></td>
                                                <td width={80}><b>Action</b></td>
                                                </tr>
                                            </thead>
                                            <tbody className='small'>
                                                {Object.entries(props.masterListComments).map(([index, comment]) => (
                                                    <tr key={index} className='bg-white'>
                                                        <td>
                                                            {editComment && selectedCommentId === comment.id ? (
                                                                <textarea
                                                                    className="form-control"
                                                                    value={selectedCommentText}
                                                                    onChange={handleCommentInputChange}
                                                                    rows={selectedCommentText.split('\n').length + 5}
                                                                />
                                                            ) : (
                                                                <h6>{comment.comment}</h6>
                                                            )}
                                                        </td>
                                                        <td>
                                                            {Object.entries(comment.proposal_reference).map((refArray, index) => (
                                                                <span key={index}>
                                                                    <a
                                                                        className='reference-link circle-owner'
                                                                        style={{ backgroundColor: '#5b5fcc' }}
                                                                        onClick={() => handleReferenceClick(refArray[1])}
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                    >
                                                                        {refArray[1][1]}
                                                                    </a>
                                                                </span>
                                                            ))}
                                                        </td>
                                                        <td>
                                                            {Object.entries(comment.requirement_reference).map((refArray, index) => (
                                                                <span key={index}>
                                                                    <a
                                                                        className='reference-link circle-owner'
                                                                        style={{ backgroundColor: '#5b5fcc' }}
                                                                        onClick={() => handleReferenceClick(refArray[1])}
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                    >
                                                                        {refArray[1][1]}
                                                                    </a>
                                                                </span>
                                                            ))}
                                                        </td>
                                                        <td>
                                                            <span className="badge rounded-pill darkGrey documentBadge">
                                                                {comment.comment_created_datetime}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            {editComment && selectedCommentId === comment.id ? (
                                                                <>
                                                                    <a className="btn btn-success btn-sm me-1">
                                                                        {loadingEdit ? (
                                                                            <Spinner size="sm" color="light" />
                                                                        ) : (
                                                                            <FontAwesomeIcon icon={faCheck} onClick={() => handleEditComment(true)}/>
                                                                        )}
                                                                    </a>
                                                                    <a className="btn btn-danger btn-sm" onClick={() => cancelEditComment()}>
                                                                        {loadingEdit ? (
                                                                            <Spinner size="sm" color="light" />
                                                                        ) : (
                                                                            <FontAwesomeIcon icon={faClose}/>
                                                                        )}
                                                                    </a>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <a className="btn btn-light btn-sm me-1" onClick={() => toggleEditComment(comment)}><FontAwesomeIcon icon={faEdit}/></a>
                                                                    <a className="btn btn-light btn-sm me-1" onClick={() => toggleDeleteComment(comment.id)}><FontAwesomeIcon icon={faTrash}/></a>
                                                                </>
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        <div className="d-flex justify-content-end">
                                            {currentMasterlistPage > 1 && (
                                                <button className="btn btn-primary btn-sm me-2" onClick={() => paginate(currentMasterlistPage - 1)}>Previous</button>
                                            )}
                                            {currentMasterlistPage} / {masterListTotalPages}
                                            {currentMasterlistPage < masterListTotalPages && (
                                                <button className="btn btn-primary btn-sm ms-2" onClick={() => paginate(currentMasterlistPage + 1)}>Next</button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {text === null && !loadingExtract && allComments === null && props.masterListComments === null ? (
                        <div className="h-100 d-flex justify-content-center align-items-center text-center" key={0}>
                            <div className='row w-100'>
                                <div className='col-12'>
                                    <div class="">Select a <strong>spec or regulation</strong> to start generating comments or issues.</div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="h-100 d-flex justify-content-center" key={1}>
                            <div className='row g-0 w-100'>
                                <div className='col-12'>
                                    <div className="msg">
                                        {loadingExtract && (
                                            <>
                                                <button className="btn border-0 disabled" type="button">
                                                    <Spinner size="sm" color="primary" /><span className='ps-2'>Extracting text from cropped area...</span>
                                                </button>
                                            </>
                                        )}
                                        {text !== null && (
                                            <>
                                            <div className='px-2'>
                                                {showText ? (
                                                    <>
                                                    <b><a onClick={() => setShowText(!showText)}><FontAwesomeIcon icon={faWindowMinimize} className="me-2 pb-1"/>Selected Section:</a></b>
                                                    {isEditing ? (
                                                        <>
                                                            <textarea
                                                                className="form-control"
                                                                value={text}
                                                                onChange={handleInputChange}
                                                                onBlur={handleInputBlur}
                                                                onKeyDown={handleKeyDown}
                                                                rows={text.split('\n').length + 5}
                                                            />
                                                            <div className="d-flex justify-content-end">
                                                                <button className="btn btn-primary mt-2 mb-2" onClick={() => setText(null)}>Clear</button>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <div onClick={handleTextClick} className='text-wrap-extract'>
                                                            {renderer(text)}
                                                        </div>
                                                    )}
                                                    </>
                                                ) : (
                                                    <b><a onClick={() => setShowText(!showText)}><FontAwesomeIcon icon={faWindowMaximize} className="me-2"/>Selected Section:</a></b>
                                                )}
                                            </div>
                                            </>
                                        )}
                                        {allComments !== null && (
                                            <>
                                                {typeof allComments === 'string' ? (
                                                    <div className='p-2'>
                                                        <Spinner size="sm" color="primary" /><span className='ps-2 text-start text-muted'>Generating comments now, please wait ...</span>
                                                    </div>
                                                ) : (
                                                    <>
                                                        <table class="table table-bordered bg-light">
                                                            <thead>
                                                                <tr>
                                                                <td width={500}><b>Comment</b></td>
                                                                <td width={80}><b>Project doc</b></td>
                                                                <td width={80}><b>Building code</b></td>
                                                                <td width={100}><b>Action</b></td>
                                                                </tr>
                                                            </thead>
                                                            <tbody className='small'>
                                                                {Object.entries(allComments).map(([index, comment]) => (
                                                                    <tr key={index} className='bg-white'>
                                                                        <td>
                                                                            {editComment && selectedCommentId === comment.id ? (
                                                                                <textarea
                                                                                    className="form-control"
                                                                                    value={selectedCommentText}
                                                                                    onChange={handleCommentInputChange}
                                                                                    rows={selectedCommentText.split('\n').length + 5}
                                                                                />
                                                                            ) : (
                                                                                <h6>{comment.comment}</h6>
                                                                            )}
                                                                        </td>
                                                                        <td>
                                                                            {Object.entries(comment.proposal_reference).map((refArray, index) => (
                                                                                <span key={index}>
                                                                                    <a
                                                                                        className='reference-link circle-owner'
                                                                                        style={{ backgroundColor: '#5b5fcc' }}
                                                                                        onClick={() => handleReferenceClick(refArray[1])}
                                                                                        target="_blank"
                                                                                        rel="noopener noreferrer"
                                                                                    >
                                                                                        {refArray[1][1]}
                                                                                    </a>
                                                                                </span>
                                                                            ))}
                                                                        </td>
                                                                        <td>
                                                                            {Object.entries(comment.requirement_reference).map((refArray, index) => (
                                                                                <span key={index}>
                                                                                    <a
                                                                                        className='reference-link circle-owner'
                                                                                        style={{ backgroundColor: '#5b5fcc' }}
                                                                                        onClick={() => handleReferenceClick(refArray[1])}
                                                                                        target="_blank"
                                                                                        rel="noopener noreferrer"
                                                                                    >
                                                                                        {refArray[1][1]}
                                                                                    </a>
                                                                                </span>
                                                                            ))}
                                                                        </td>
                                                                        <td>
                                                                            {editComment && selectedCommentId === comment.id ? (
                                                                                <>
                                                                                    <a className="btn btn-success btn-sm me-1">
                                                                                        {loadingEdit ? (
                                                                                            <Spinner size="sm" color="light" />
                                                                                        ) : (
                                                                                            <FontAwesomeIcon icon={faCheck} onClick={() => handleEditComment(false)}/>
                                                                                        )}
                                                                                    </a>
                                                                                    <a className="btn btn-danger btn-sm" onClick={() => cancelEditComment()}>
                                                                                        {loadingEdit ? (
                                                                                            <Spinner size="sm" color="light" />
                                                                                        ) : (
                                                                                            <FontAwesomeIcon icon={faClose}/>
                                                                                        )}
                                                                                    </a>
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <a className="btn btn-light btn-sm me-1" onClick={() => toggleEditComment(comment)}><FontAwesomeIcon icon={faEdit}/></a>
                                                                                    <a className="btn btn-light btn-sm me-1" onClick={() => toggleDeleteComment(comment.id)}><FontAwesomeIcon icon={faTrash}/></a>
                                                                                    {/* {comment.masterlist === false ? (
                                                                                        <>
                                                                                            <a id="addToMaster" className="btn btn-primary btn-sm" onClick={() => addToMasterlist(comment.id)}><FontAwesomeIcon icon={faPlus}/></a>
                                                                                        </>
                                                                                    ) : (
                                                                                        <>
                                                                                            <a id="remFromMaster" className="btn btn-danger btn-sm"  onClick={() => removeFromMasterlist(comment.id)}><FontAwesomeIcon icon={faMinus}/></a>

                                                                                        </>
                                                                                    )} */}
                                                                                </>
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                        {/* {numberOfCommentsMsList !== numberOfComments ? (
                                                            <div className="d-flex justify-content-end">
                                                                <button className="btn btn-primary" onClick={() => addToMasterlist("")}>Add All Comments to Masterlist</button>
                                                            </div>
                                                        ) : (
                                                            <div className="d-flex justify-content-end">
                                                                <button className="btn btn-danger" onClick={() => removeFromMasterlist("")}>Remove All Comments from Masterlist</button>
                                                            </div>
                                                        )} */}
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            
            <div className="d-flex align-items-center">
                {isAdvanceSearch && 
                    <>
                        <div className="form-floating text-dark flex-grow-1 me-2 ms-2">
                            <input 
                                id='question_chat' 
                                autoComplete="off" 
                                // onFocus={()=>setTypingCivilsGPT(true)}
                                // onBlur={()=>setTypingCivilsGPT(false)}
                                // onChange={(e) => handleChangeQuery(e)} 
                                type="text" 
                                // value={chatInput}
                                className="form-control gpt_input" 
                                placeholder={"Type keyword to check for issues, comments and suggestions"} 
                            />
                            <label htmlFor='question_chat' className='text-secondary'>
                                Type keyword to check for issues, comments and suggestions
                            </label>
                        </div>  
                    </>
                }
            </div>
            <div className='container border bg-light mx-auto px-0 px-3 py-2'>
                <div className="d-flex my-1 justify-content-between align-items-center">
                    {/* Left-aligned buttons */}
                    {props.masterListComments && props.masterListComments.length > 0 ? (
                        <div className="d-flex align-items-center">
                            <button id="downloadExcel" className="btn btn-link text-dark btn-lg d-flex align-items-center" onClick={() => downloadExcel()} disabled={loadingExtract || loadingGeneration}>
                                <FontAwesomeIcon className='icon-excel' icon={faFileExcel}/>
                            </button>
                            <Tooltip isOpen={tooltipOpen2} placement={'top'} target="downloadExcel" toggle={toggle2}>
                                Download comments list
                            </Tooltip>
                        </div>
                    ): (
                        <div className="d-flex align-items-center"></div>
                    )}
                    
                    {/* Right-aligned buttons */}
                    <div className="d-flex align-items-center">
                        {selectedRegName === null ? (
                            <button id="selectRegTip" className="btn btn-white regselect d-flex align-items-center me-2" onClick={() => handleSelectReg()} disabled={loadingExtract || loadingGeneration}>
                                Compare to <FontAwesomeIcon icon={faSortUp} className={"mt-2 ms-2"}/>
                            </button>
                        ) : (
                            <div className="d-flex align-items-center" style={{ maxWidth: '500px' }}>
                                <small className='me-2 text-truncate'>{selectedRegName}</small>
                                <a className="btn btn-white regselect d-flex align-items-center me-2 flex-shrink-0" onClick={() => handleSelectReg()} disabled={loadingExtract || loadingGeneration}>
                                    <FontAwesomeIcon icon={faRedo} />
                                </a>
                                <a className="btn btn-danger d-flex align-items-center me-2 flex-shrink-0" onClick={() => setSelectedRegName(null)} disabled={loadingExtract || loadingGeneration}>
                                    <FontAwesomeIcon icon={faClose} />
                                </a>
                            </div>
                        )}
                        <button className="btn btn-primary" disabled={selectedRegName === null || loadingExtract || props.fileType !== 0 || loadingGeneration} onClick={() => handleGenerateComments(text ? 1: 0)}>
                            {text ? 'Check Selection': 'Check'}
                            {(loadingExtract || loadingGeneration) &&
                            <span className='ps-2'>
                                <Spinner size="sm" color="light" />
                            </span>
                            }
                        </button>
                    </div>
                </div>
            </div>

            <Modal id="SelectReg" size="xl" isOpen={selectReg} toggle={handleSelectReg} className="modal-dialog-centered">
                <RegSelecterModal
                    toggleModal={handleSelectReg}
                    title="Select a spec or regulation"
                    project={props.project}
                    updateSelectedRegName={(name) => setSelectedRegName(name)}
                />
            </Modal>

            <Tooltip isOpen={tooltipOpen1} placement={'top'} target="selectRegTip" toggle={toggle1}>
                {selectedRegName !== null ? selectedRegName: 'Select a regulation or specification to compare'}
            </Tooltip>

            <Modal id="CheckHistory" size="xl" isOpen={openHistory} toggle={handleCheckHistory} className="modal-dialog-centered">
                <CheckHistory
                    toggleModal={handleCheckHistory}
                    title="Comments and issues History"
                    project={props.project}
                    reportId={props.selectedId}
                    updateCommentsStates={(row) => handleHistoryClicked(row)}
                />
            </Modal>
            
            <Modal id="SelectReg" size="l" isOpen={deleteComment} toggle={() => setDeleteComment(false)} className="modal-dialog-centered">
                <ModalHeader className="border-0 mb-0 pb-0" toggle={() => setDeleteComment(false)}> Delete Comment</ModalHeader>
                <ModalBody>
                    <hr className="mb-4"/>
                    <div className="container-fluid w-100 pb-2 mb-1">
                        <div>Are you sure you want to delete this comment? This action cannot be undone.</div>
                    </div>
                    <hr className="mb-4"/>
                    <div className="container-fluid w-100 ">
                        <a className="btn btn-primary w-100 mb-2" onClick={() => handleDeleteComment()} type="button">
                            Confirm
                        </a>
                        <a className="btn btn-outline-dark w-100" onClick={() => setDeleteComment(false)} type="button">
                            Cancel
                        </a>
                    </div>
                </ModalBody>
            </Modal>
        </>
    )

}

export default RegChecker;
