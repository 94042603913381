
import { Modal, ModalBody, ModalHeader, ModalFooter, Table } from "reactstrap";
import { useState, useEffect } from "react";
import { Button, Tooltip } from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCopy, faCheck, faRobot, faCircleInfo, faSatellite} from "@fortawesome/free-solid-svg-icons";
import httpClientPy from "../../../../utils/httpClientPy.js";
import Loader from "../../../shared/Loader.js";
import DeleteConfirmModal from "../../../shared/layouts/DeleteConfirmModal.js";
import SaveConfirmModal from "../../../shared/layouts/SaveConfirmModal.js";

library.add(faCopy, faCheck, faRobot, faCircleInfo);

const UpdateBoreholeForm = ({ ...props }) => {
    
    const [geoTableDisplay, setGeoTableDisplay] = useState("geology");
    const [descriptionRows, setDescriptionRows] = useState([{ id: 1, classification: '', description: '', thickness: '' },]);
    const [testRows, setTestRows] = useState([    { id: 1, name: 'N', depth: '', result: '' },  ]);
    const [groundLevel, setGroundLevel] = useState(0);
    const [recordName, setRecordName] = useState();
    const [projectName, setProjectName] = useState();
    const [dataRecord, setDataRecord] = useState();
    const [waterLevel, setWaterLevel] = useState(null);
    const [loadingData, setLoadingData] = useState(true);

    const [loading, setLoading] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [saving, setSaving] = useState(false);
    const [deleteConfirmed, setDeleteConfirmed] = useState(false);
    const [deleteId, setDeleteId] = useState(0);
    const [rowDeleteId, setRowDeleteId] = useState(0);
    const [deleteType, setDeleteType] = useState("");

    const [displayProjectNameFail, setDisplayProjectNameFail] = useState(false);

    // function to get data from geo/record?geo_record_id with the geo record id set from props geo record id and set the description rows and test rows to the data returned from the api call
    const getGeoRecord = () => {

        return httpClientPy
        .get(`/geo/record?geo_record_id=${props.geoRecordId}&project_id=${props.project.id}&format=json`)
        .then((response) => {
            if(response.data.borehole_name && response.data.borehole_name.length > 0){
                setRecordName(response.data.borehole_name)
            }
            else if(response.data.reference && response.data.reference.length > 0){
                setRecordName(response.data.reference)
            }
            else{
                setRecordName('Unknown project')
            }

            if(response.data.description && response.data.description.length > 0){
                setProjectName(response.data.description)
            } else {
                setProjectName('Unknown project')
            }

            let strataData = response.data.geo_record_strata

            // order by top reverse
            strataData.sort(function(a, b) {
                return parseFloat(a.top) - parseFloat(b.top);
            });

            if(strataData[0].top!==0){
                strataData.sort(function(a, b) {
                    return parseFloat(b.top) - parseFloat(a.top);
                });
            }

            setGroundLevel(strataData[0].top)



            var descriptionRows = []
            for (let row of strataData){
                let thickness = Math.abs(row.top - row.base)
                descriptionRows.push({
                    id: row.id,
                    classification: row.classification,
                    description: row.description,
                    thickness: parseFloat(thickness.toFixed(2))
                })
            }

            let test_holder = []

            // for (let row of response.data.geo_record_test){
            //     testRows.push({
            //         id: row.id,
            //         name: row.test,
            //         depth: row.depth,
            //         result: row.report? row.report : parseFloat(row.result).toFixed(0)
            //     })
            // }

            for(var j = 0; j < response.data.geo_record_test.length; j++){

                if(response.data.geo_record_test[j].test==="SCR" || response.data.geo_record_test[j].test==="TCR" || response.data.geo_record_test[j].test==="RQD"){

                    let scrtcr_found = false
                    for (let temp_test of test_holder){
                        if(String(Math.round(((response.data.geo_record_test[j].depth))*100)/100) === temp_test.depth){
                            if(response.data.geo_record_test[j].test==="SCR"){
                                temp_test.scr = parseInt(parseFloat(response.data.geo_record_test[j].result))
                            }
                            else if(response.data.geo_record_test[j].test==="TCR"){
                                temp_test.tcr = parseInt(parseFloat(response.data.geo_record_test[j].result))
                            }
                            else if(response.data.geo_record_test[j].test==="RQD"){
                                temp_test.result = parseInt(parseFloat(response.data.geo_record_test[j].result))
                            }
                            scrtcr_found = true
                        }
                    }
                    if(scrtcr_found===false){
                        let res = response.data.geo_record_test[j].report ? response.data.geo_record_test[j].report : response.data.geo_record_test[j].result
                        test_holder.push({ id: j+1, rec_id: response.data.geo_record_test[j].id, name: response.data.geo_record_test[j].test, depth: String(Math.round(((response.data.geo_record_test[j].depth))*100)/100), result: String(res)})
                    } 
                    
                }
                else if(response.data.geo_record_test[j].test==="water_strike"){
                    setWaterLevel(Math.round((response.data.geo_record_test[j].depth)*100)/100)
                }
                else{
                    let res = response.data.geo_record_test[j].report ? response.data.geo_record_test[j].report : response.data.geo_record_test[j].result
                    test_holder.push({ id: j+1, rec_id: response.data.geo_record_test[j].id, name: response.data.geo_record_test[j].test, depth: String(Math.round(((response.data.geo_record_test[j].depth))*100)/100), result: String(res)})
                }
            } 

            setDescriptionRows(descriptionRows)
            setTestRows(test_holder)
            setDataRecord(response.data)
            setLoadingData(false)
        })
    }

    useEffect(() => {
        getGeoRecord()
    }, [props.geoRecordId])

    const submitForm = () => {
        setLoading(true);
        var geo_record_strata = []
        var top_holder = parseFloat(groundLevel)
        if(groundLevel !== 0){
            for (let row of descriptionRows){
                let row_thickness = parseFloat(row.thickness)
                geo_record_strata.push({
                    id: row.id,
                    top: top_holder,
                    base: top_holder - row_thickness,
                    classification: row.classification,
                    name: row.description,
                    created_datetime: "2023-01-02T07:34:16.263Z",
                    last_updated_datetime: "2023-01-02T07:34:16.263Z",
                    is_active: 1,
                    grouping: ""
                })
                top_holder -= row_thickness
            }
        }
        else{
            for (let row of descriptionRows){
                let row_thickness = parseFloat(row.thickness)
                geo_record_strata.push({
                    id: row.id,
                    top: top_holder,
                    base: top_holder + row_thickness,
                    classification: row.classification,
                    name: row.description,
                    created_datetime: "2023-01-02T07:34:16.263Z",
                    last_updated_datetime: "2023-01-02T07:34:16.263Z",
                    is_active: 1,
                    grouping: ""
                })
                top_holder += row_thickness
            }
        }


        let data_test = []
        for(let testHolder of testRows){
            let test = testHolder.name
            let depth = testHolder.depth
            let result = testHolder.result

            // append to data_test
            data_test.push({
                id: testHolder.rec_id,
                test: test,
                depth: depth,
                result: result,
                created_datetime: "2023-01-02T07:34:16.263Z",
                last_updated_datetime: "2023-01-02T07:34:16.263Z",
                is_active: 1
            })

            if(testHolder.scr){
                data_test.push({
                    id: testHolder.rec_id,
                    test: "SCR",
                    depth: depth,
                    result: testHolder.scr,
                    created_datetime: "2023-01-02T07:34:16.263Z",
                    last_updated_datetime: "2023-01-02T07:34:16.263Z",
                    is_active: 1
                })
            }

            if(testHolder.tcr){
                data_test.push({
                    id: testHolder.rec_id,
                    test: "TCR",
                    depth: depth,
                    result: testHolder.tcr,
                    created_datetime: "2023-01-02T07:34:16.263Z",
                    last_updated_datetime: "2023-01-02T07:34:16.263Z",
                    is_active: 1
                })
            }
        }

        if (waterLevel){
            data_test.push({
                id: 0,
                test: "water_strike",
                depth: waterLevel,
                result: 0,
                created_datetime: "2023-01-02T07:34:16.263Z",
                last_updated_datetime: "2023-01-02T07:34:16.263Z",
                is_active: 1
            })
        }

        let data_record = {
            geo_coordinate_system: dataRecord.geo_coordinate_system,
            northing: dataRecord.northing != '' ? dataRecord.northing : null,
            easting: dataRecord.easting != '' ? dataRecord.easting : null,
            description: projectName,
            borehole_name: recordName,
        }
        
        
        return httpClientPy
        .put(`/geo/record/update`, {
            id: props.geoRecordId,
            update_mode: 'manual',
            data_record: data_record,
            data_strata: geo_record_strata,
            data_test: data_test,
        })
        .then((response) => {
            setLoading(false);
            props.boreHoleInfoUpdate(response.data);
        })
    }

    // send request to /geo/record/delete with the geo record id, record id and delete type
    const deleteRecord = () => {
        setLoading(true);
        return httpClientPy
        .delete(`/geo/record/delete?geo_record_id=${props.geoRecordId}&record_id=${deleteId}&record_type=${deleteType}`)
        .then((response) => {
            setLoading(false);
            props.boreHoleInfoUpdate(true);
            setDeleting(false);
        })
    }

    const handleLevelChange = (e) => {
        setGroundLevel(e.target.value);
    };


    const toggleDeleteConfirmModal = () => {
        setDeleting(!deleting);
    };

    const toggleSaveConfirmModal = () => {
        setSaving(!saving);
    };

    // This function adds a new row to the table.
    const addDescriptionRow = () => {
        // Create a new row object with a unique ID and blank name and age values.
        const newDescriptionRow = {
        id: descriptionRows.length + 1,
        classification: '',
        description: '',
        thickness: '',
        };

        // Use the setRows function to add the new row to the state.
        setDescriptionRows([...descriptionRows, newDescriptionRow]);
    };

    // This function removes a row from the table.
    const removeDescriptionRow = (id) => {
        // Use the setRows function to filter out the row with the specified ID.
        setDescriptionRows(descriptionRows.filter((row) => row.id !== id));
    };

    // This function updates the name or age value for a row.
    const updateDescriptionValue = (event, id, field) => {
        // Use the setRows function to create a new array of rows with the updated value.
        setDescriptionRows(
            descriptionRows.map((row) => {
            if (row.id === id) {
            return {
                ...row,
                [field]: event.target.value,
            };
            }
            return row;
        })
        );
    };

    // This function will remove the description row and open a confirmation dialog.
    const handleDescriptionRowRemove = (id) => {
        // Remove the row from the table.
        setDeleteId(id);
        setDeleteType("geology");
        toggleDeleteConfirmModal();
    };

    // This function adds a new row to the table.
    const addTestRow = () => {
        // Create a new row object with a unique ID and blank name and age values.
        let temp_name = 'N'
        if(testRows && testRows.length > 0){
            temp_name = testRows[testRows.length-1]['name']
        }
        const newTestRow = {
        id: testRows.length + 1,
        rec_id: 0,
        name: temp_name,
        depth: '',
        result: '',
        };

        // Use the setRows function to add the new row to the state.
        setTestRows([...testRows, newTestRow]);
    };

    // This function removes a row from the table.
    const removeTestRow = (id) => {
        // Use the setRows function to filter out the row with the specified ID.
        setTestRows(testRows.filter((row) => row.id !== id));
    };

    // This function updates the name or age value for a row.
    const updateTestValue = (event, id, field) => {
        // Use the setRows function to create a new array of rows with the updated value.
        setTestRows(
            testRows.map((row) => {
                if (row.id === id) {
                    return {
                        ...row,
                        [field]: event.target.value,
                    };
                }
                return row;
            })
        );
    };

    // This function will remove the test row and open a confirmation dialog.
    const handleTestRowRemove = (id, row_id) => {
        // Remove the row from the table.
        setRowDeleteId(row_id)
        setDeleteId(id);
        setDeleteType("test");
        toggleDeleteConfirmModal();
    };

    // If deleteConfirm is true then remove the desctiption row or test row
    useEffect(() => {
        if (deleteConfirmed && deleteType === "geology") {
            deleteRecord();
            removeDescriptionRow(deleteId);
            setDeleteId(0);
            setDeleteType("");
            setDeleteConfirmed(false);
        } else if (deleteConfirmed && deleteType === "test") {
            deleteRecord();
            removeTestRow(rowDeleteId);
            setDeleteId(0);
            setDeleteType("");
            setDeleteConfirmed(false);
        }
    }, [deleteConfirmed]);

    return (
        <>  
        {loadingData ?
            <div className="pt-5">
                <Loader className="d-inline-block" loading={loadingData} />
            </div>
            :
            <>
                <ModalBody>
                    <div>

                        <div className="input-group mb-4" >

                                    <div className="form-floating me-2" style={{maxWidth: "150px"}}>
                                        <input
                                            name="groundLevel"
                                            type="number"
                                            className="form-control"
                                            id="groundLevel"
                                            value={groundLevel}
                                            onChange={handleLevelChange}
                                            autoComplete="off"
                                            step="0.1"
                                            required
                                        />
                                    <label htmlFor="groundLevel">Ground level (m)</label>
                                    </div>

                                    <div className="form-floating" style={{maxWidth: "150px"}}>
                                        <input
                                            name="waterlevel"
                                            type="number"
                                            className="form-control"
                                            id="waterLevel"
                                            value={waterLevel}
                                            onChange={(e) => setWaterLevel(e.target.value)}
                                            autoComplete="off"
                                            step="0.1"
                                            required
                                        />
                                    <label htmlFor="groundLevel">Water level (m)</label>
                                    </div>
                                </div>

                                { displayProjectNameFail &&
                                    <div className="text-danger text-center mb-2">Enter a borehole name and ensure there are no spaces</div>
                                }

                        <div className="btn-group dimmed" role="group" aria-label="Toggle geological data">
                            <input
                            type="radio"
                            className="btn-check"
                            name="geoRecordDetailRadioButtonUpdate"
                            id="geoRecordDetailRadioButtonAllUpdate"
                            checked={geoTableDisplay === "geology"}
                            autoComplete="off"
                            onChange={() => {
                                setGeoTableDisplay("geology");
                                setDeleteType("geology");
                            }}
                            />
                            <label
                            className="btn btn-outline-dark shadow-none"
                            htmlFor="geoRecordDetailRadioButtonAllUpdate"
                            >
                            Geology
                            </label>

                            <input
                            type="radio"
                            className="btn-check"
                            name="geoRecordDetailRadioButtonUpdate"
                            autoComplete="off"
                            id="geoRecordDetailRadioButtonTestUpdate"
                            checked={geoTableDisplay === "test"}
                            onChange={() => {
                                setGeoTableDisplay("test");
                                setDeleteType("test");
                            }}
                            />
                            <label
                            className="btn btn-outline-dark shadow-none"
                            htmlFor="geoRecordDetailRadioButtonTestUpdate"
                            >
                            Testing results
                            </label>

                        </div>


                            {geoTableDisplay === "geology" ?
                            <div  className="tbodyDiv dataModal overflow-auto">

                                <hr className="mb-3"/>
                                <div className="tbodyDiv dataModal overflow-auto">

                                <table className="table">
                                <thead>
                                <tr>
                                    <th className="col-4"><small>Geology classification</small></th>
                                    <th className="col-4"><small>Geology description</small></th>
                                    <th><small>Thickness (m)</small></th>
                                </tr>
                                </thead>
                                <tbody>
                                {descriptionRows.map((row) => (
                                    <tr key={row.id}>
                                    <td>
                                        <div className="input-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            required
                                            value={row.classification}
                                            onChange={(event) => updateDescriptionValue(event, row.id, 'classification')}
                                        />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="input-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            required
                                            value={row.description}
                                            onChange={(event) => updateDescriptionValue(event, row.id, 'description')}
                                        />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="input-group">
                                        <input
                                            type="number"
                                            className="form-control"
                                            required
                                            value={row.thickness}
                                            onChange={(event) => updateDescriptionValue(event, row.id, 'thickness')}
                                        />
                                        </div>
                                    </td>
                                    <td>
                                        <button
                                        type="button"
                                        className="btn btn-outline-danger"
                                        onClick={() => handleDescriptionRowRemove(row.id)}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                            <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                            </svg>
                                        </button>
                                    </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                            <button type="button" className="btn btn-outline-success" onClick={addDescriptionRow}>
                                Add row
                            </button>
                                </div>
                            </div>
                            :
                            <div className="tbodyDiv overflow-auto">
                                <table className="table">
                                <thead>
                                <tr>
                                    <th><small>Test type</small></th>
                                    <th><small>Depth (m)</small></th>
                                    <th><small>Result</small></th>
                                </tr>
                                </thead>
                                <tbody>
                                {testRows.map((row) => (
                                    <tr key={row.id}>
                                    <td>
                                        <div className="input-group">
                                        <select className="form-select" aria-label="Default select example" value={row.name} onChange={(event) => updateTestValue(event, row.id, 'name')}>
                                            <option value="N">N</option>
                                            <option value="RQD">RQD</option>
                                            <option value="VANE">Vane Shear Test</option>
                                        </select>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="input-group">
                                        <input
                                            type="number"
                                            className="form-control"
                                            required
                                            value={row.depth}
                                            onChange={(event) => updateTestValue(event, row.id, 'depth')}
                                        />
                                        </div>
                                    </td>
                                    <td>
                                        
                                    <div className="row g-1">
                                        { row.name==="RQD" ? 
                                            <>
                                                <div className="col">
                                                    <div className="form-floating">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="rqd_input"
                                                            name="rqd_input"
                                                            value={row.result}
                                                            onChange={(event) => updateTestValue(event, row.id, 'result')}
                                                        />
                                                        <label htmlFor="rqd_input">RQD</label>

                                                    </div> 
                                                </div>
                                                <div className="col">
                                                    <div className="form-floating">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="tcr_input"
                                                            name="tcr_input"
                                                            value={row.tcr}
                                                            onChange={(event) => updateTestValue(event, row.id, 'tcr')}
                                                        />
                                                        <label htmlFor="tcr_input">TCR</label>

                                                    </div> 
                                                </div>       
                                                <div className="col">
                                                    <div className="form-floating">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="scr_input"
                                                            name="scr_input"
                                                            value={row.scr}
                                                            onChange={(event) => updateTestValue(event, row.id, 'scr')}
                                                        />
                                                        <label htmlFor="scr_input">SCR</label>

                                                    </div> 
                                                </div>      
                                            </>    
                                            :
                                            <div className="col">
                                                <div className="input-group">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={row.result}
                                                    onChange={(event) => updateTestValue(event, row.id, 'result')}
                                                />
                                                </div>
                                            </div>                                        
                                        }
                                    </div>
                                    </td>
                                    <td>
                                        <button
                                        type="button"
                                        className="btn btn-outline-danger"
                                        onClick={() => handleTestRowRemove(row.rec_id, row.id)}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                            <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                            </svg>
                                        </button>
                                    </td>
                                    </tr>
                                ))}
                                </tbody>
                                </table>
                                <button type="button" className="btn btn-outline-success" onClick={addTestRow}>
                                    Add
                                </button>
                            </div>
                            }
                        </div>
                </ModalBody>
                <ModalFooter className="border-0 mt-0 pt-0">
                    <a className="btn btn-primary w-100" onClick={()=>setSaving(true)} type="button" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        {loading && <span style={{marginRight: '0.5rem'}}><Loader className="d-inline-block" loading={loading} /></span>}
                        <span>Save borehole data</span>
                    </a>
                </ModalFooter>
            </>
            }
            <Modal id="deleteInfoConfirm" size="sm" isOpen={deleting} toggle={toggleDeleteConfirmModal} centered={true}>
                <DeleteConfirmModal displayName={`Delete a borehole ${deleteType}`} 
                                    toggleModal = {()=> toggleDeleteConfirmModal}
                                    deleting = {(status) => {setDeleting(status)}}
                                    deleteConfirmed = {(status) => {setDeleteConfirmed(status)}}
                                    deleteType = {deleteType} />
            </Modal>
            <Modal id="saveInfoConfirm" size="sm" isOpen={saving} toggle={toggleSaveConfirmModal} centered={true}>
                <SaveConfirmModal displayName={`Save a borehole`} 
                                    text = "Are you sure you want to save updates to your borehole data? This action cannot be undone."
                                    toggleModal={toggleSaveConfirmModal}
                                    saveConfirmed = {(status) => {submitForm()}}
                />
            </Modal>
        </>
    );
  };
  
  export default UpdateBoreholeForm;
  