import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import httpClientPy from "../../utils/httpClientPy";
import SideBar from "./SideBar";
import ProjectOverview from "./detailComponents/ProjectOverview";
import SearchResult from "./detailComponents/SearchResult";
import UploadsList from "./AiAssitantComponents/UploadsList";


const ProjectDetail = (props) => {


  const location = useLocation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [project, setProject] = useState(null);
  const [projectExtraDetails, setProjectExtraDetails] = useState(null);

  const [userInfo, setUserInfo] = useState(null);

  const [chatEnabled, setChatEnabled] = useState(window.innerWidth < 500 ? true : false);
  const [chatMessage, setChatMessage] = useState(null);
  const [historicalMsg, setHistoricalMsg] = useState(null)
  const [lastId, setLastId] = useState(0);

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  // set pageview from state provided type if available
  const [pageView, setPageView] = useState( null);
  // set subpage from state provided subpage if available
  const [subpage, setSubpage] = useState( null );
  const [task, setTask] = useState(1);
  const [selectedData, setSelectedData] = useState(null);
  const [admin, setAdmin] = useState(false);

  const getProjectData = (id) => {
    httpClientPy.get(`/project/${id}`)
      .then((response) => {
        setProject(response.data.project);
        setProjectExtraDetails(response.data.extra_data);
        setLoading(false);
      })
      .catch((err) => {
        // console.log(err.response.data.detail)
        setError(true);
        setErrorMessage(err.response.data.detail);
        setLoading(false);
      }
    );
  }


    useEffect(() => {
      httpClientPy.get(`/user`).then((response) => {
          let userList = response.data
          if (userList.is_enterprise) {
              userList.allow_digitise = userList.enteprise_is_digitiser
              userList.allow_civilsgpt = userList.enterprise_is_civilsgpt
              userList.allow_drawing = userList.enterprise_is_drawing
          } else {
              userList.allow_digitise = userList.subscription_details ?  userList.subscription_details.is_digitise : false
              userList.allow_civilsgpt = userList.subscription_details ? userList.subscription_details.is_civilsgpt : false
              userList.allow_drawing = userList.subscription_details ? userList.subscription_details.is_drawing : false
          }
          setUserInfo(userList)
          setAdmin(userList.enterprise_admin)
      })
  }, [])

  

  useEffect(() => {

    if (location.state === null) {
      setError(true);
      setErrorMessage("Project not found");
      setLoading(false);
    } else {
      // set pageview from state provided type if available
      setPageView( location.state !== null ? location.state.type : null);
      // set subpage from state provided subpage if available
      setSubpage( location.state !== null ? location.state.subpage : null);

      if (location.state.project === undefined) {
        const id = location.state.id
        getProjectData(id);
      } else {
        const id = location.state.project.id;
        getProjectData(id);
      }
    }
  }, []);

  const handleChat = async (text) => {
    try {
        if(text !== null && text !== ''){
          let response = await httpClientPy.post(`/search/init`, { project_id: project.id, query: text });
          let lastId = response.data.search_query_id;

          setLastId(lastId);
          setChatMessage(text);
          setChatEnabled(true);
        }
    } catch (err) {
        console.log(err);
        setChatMessage(null);
        setChatEnabled(false);
    }
  }

  const handleSearchHistoryClick = (id) => {
      httpClientPy.post(`/search/single/history`, {
        project_id: project.id,
        query_id: id,
    })
    .then((response) => {
      setLastId(response.data.messages[1].id)
      setHistoricalMsg(response.data.messages);
      setChatEnabled(true);
    })
    .catch((error) => {
        console.log(error);
    });
    
  }

  const handleChatClose = () => {
    setChatEnabled(false);
    setChatMessage(null);
    setHistoricalMsg(null);
    setTask(1);
    setSelectedData(null);
  }

  useEffect(() => {
    if((task === 3 || task === 2) && selectedData !== null){
      setChatEnabled(true);
    }
  }, [task])


  return (
      <>
          <SideBar page={'detail'} 
                    loading={loading}
                    subpage={subpage} type={pageView} setType={(type)=>setPageView(type)}  setSubpage={(type)=>setSubpage(type)}
                    project={project} 
                    chatEnabled={chatEnabled}
                    closeChat={()=>handleChatClose()}
                    setUserInfo={(user)=>setUserInfo(user)} 
                    projectExtraDetails={projectExtraDetails}
                    userInfo={userInfo}
                    admin={admin}
                    onComplete={() => getProjectData(project.id)}
                    />
          {!loading ? (
            <>
            {!error ? (
              <>
              {pageView === null ||  pageView === undefined ? 
                <div className="row">
                  { !chatEnabled ? (
                    <div className="container-flex">
                      {project && projectExtraDetails &&
                      <ProjectOverview 
                        project={project}
                        extraDetails={projectExtraDetails}
                        handleChat={(text)=>handleChat(text)}
                        handleSearchHistoryClick={(id)=>handleSearchHistoryClick(id)}
                        getProjectData={(id)=>getProjectData(id)}
                        userInfo={userInfo} 
                        updateTask={(task)=>setTask(task)}
                        updateSelectedData={(data)=>setSelectedData(data)}
                      />
                      }
                    </div>
                  ) : (
                    <div className="container-flex">
                      <SearchResult 
                        project={project} 
                        initMessage={chatMessage} 
                        historicalMsg={historicalMsg}
                        lastId={lastId}
                        handleChatClose={()=>handleChatClose()}
                        userInfo={userInfo}
                        task={task}
                        selectedData={selectedData}
                      />
                    </div>
                  )}
                </div>
                :
                <UploadsList project={project} pageView={pageView}/>
                }
              </>
            ) : (
              <>
                  <div className='col-md-12 d-flex justify-content-center flex-column align-items-center' style={{ height: '70vh' }}>
                      <img src="assets/build_img.png" height={'100px'} alt="Civils.ai" />
                      <h2><b>Oops!</b></h2>
                      <h4>{errorMessage}</h4>
                      <button className="btn btn-primary mt-2" onClick={() => navigate('/projects')}>View all projects</button>
                  </div>
              </>
            )}
            </>
          ) : (
            <>
                <div className='col-md-12 d-flex justify-content-center flex-column align-items-center' style={{ height: '70vh' }}>
                    <span>
                        <span
                            className="spinner-border spinner-border-md"
                            role="status"
                            aria-hidden="true"
                        ></span>
                        <span className="visually-hidden">Loading...</span>
                    </span>
                </div>
            </>
          )}
      </>
  )

}

export default ProjectDetail;


