const getItem = (key) => {
    try {
      const raw = localStorage.getItem(key);
      const json = JSON.parse(raw);
      return json;
    } catch {
      return null;
    }
  };
  
  const setItem = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
  };
  
  export default {
    getItem,
    setItem,
  };
  