import {ModalBody, Table, ModalHeader, Input, ModalFooter, Modal} from "reactstrap";
import React, {useRef, useEffect, useLayoutEffect, useState} from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import * as d3 from "d3";
import Shrinkable from "../../../shared/textHolders/Shrinkable.js";
import Loader from "../../../shared/Loader";
import httpClientPy from "../../../../utils/httpClientPy";
import { faCopy, faFilePdf, faMapLocation, faMountain, faTriangleExclamation} from "@fortawesome/free-solid-svg-icons";
import Select, { components } from 'react-select'
import Button from "../../../shared/Button.js";
import UpdateBoreholeForm from "./UpdateBoreholeForm";

library.add(faCopy, faMapLocation);

const BoreholeDetailsModal = (props) => {

    const singleSvgRef = useRef();
    
    const [boreholes, setBoreholes] = useState([]);
    //boreholeSelection controls which borehole details are being displayed in the table within this component
    const [boreholeSelection, setBoreholeSelection] = useState(props.boreholeSelection);
    const [loading, setLoading] = useState(true);
    //boreholeSelection controls which borehole details are being displayed in the table within this component

    const [bhList, setBhList] = useState([]);
    const [currentBh, setCurrentBh] = useState(null);
    const [tests, setTests] = useState({});

    const generateBhChart = (bhData) => {
        
        const svg = d3.select(singleSvgRef.current);

        // check if svg is empty or not and remove all elements if not empty
        if (!svg.empty()) {
            svg.selectAll('*').remove();
        }

        // copy bhData to temp and temp data now is object
        let tempData = JSON.parse(JSON.stringify(bhData));

        // remove rows where the visible is false from temp_data
        tempData = tempData.filter(d => d.visible === true);

        const width = svg.node().parentNode.clientWidth - 50;
        const height = svg.node().parentNode.clientHeight - 50;

        //get max of all top from tempData that is a dict
        const maxTop = Math.max(...tempData.map(d => Math.max(...d.top)));
        //get miin of all bottom
        const minTop = Math.min(...tempData.map(d => Math.min(...d.bottom)));
        
        const xScale = d3.scaleLinear().domain([0, props.distance + 15]).range([0, width]);
        
        const yScale = d3.scaleLinear().domain([minTop - 5, maxTop + 5]).range([height, 0]);

        const xAxis = d3.axisTop().scale(xScale).ticks(10).tickSize(-height);
        const yAxis = d3.axisLeft().scale(yScale).ticks(10).tickSize(-width);
        
        svg.append('g').attr('transform', 'translate(30, 30)').call(xAxis).selectAll('line').style('stroke', '#ccc');
        svg.append('g').attr('transform', 'translate(30, 30)').call(yAxis).selectAll('line').style('stroke', '#ccc');

        // Append x-axis label
        svg.append('text')
            .attr('class', 'x-axis-label')
            .attr('text-anchor', 'middle')
            .attr('x', width / 2)
            .attr('y', 15)
            .text('DISTANCE ALONG SECTION BASELINE (m)');

        // Append y-axis label
        svg.append('text')
            .attr('class', 'y-axis-label')
            .attr('text-anchor', 'middle')
            .attr('x', -height / 2)
            .attr('y', 12)
            .attr('transform', 'rotate(-90)')
            .text('ELEVATION (m AOD)');

        // Create new array for stacked bar chart
        const barData = tempData.map((d) => {
            return d.top.map((value, index) => {
                return {
                    top: value,
                    bottom: d.bottom[index],
                    distance_to_first_point: d.distance_to_first_point,
                    distance_to_line: d.distance_to_line,
                    color: d.colors_classification[index],
                    visible: d.visible
                };
            });
        }).flat();

        svg.append('defs')
            .append('pattern')
            .attr('id', 'diagonal-pattern')
            .attr('patternUnits', 'userSpaceOnUse')
            .attr('width', 4)
            .attr('height', 4)
            .append('path')
            .attr('d', 'M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2')
            .attr('stroke', 'black')
            .attr('stroke-width', 0.5);

        // Define the stack function
        const stack = d3.stack().keys(['top']);

        // Stack the data
        const stackedBarData = stack(barData);

        const bars = svg.append('g').attr('transform', 'translate(30, 30)');

        bars.selectAll('g')
            .data(stackedBarData)
            .enter()
            .append('g')
            .selectAll('rect')
            .data(d => d)
            .enter()
            .append('rect')
            .attr('x', d => xScale(d.data.distance_to_first_point))
            .attr('y', d => yScale(d.data.top))
            .attr('height', d => Math.round(yScale(d.data.bottom) - yScale(d.data.top)))
            .attr('width', 10)
            .attr("fill", d => d.data.color === '#FFF' ? 'url(#diagonal-pattern)' : d.data.color)
            .attr("stroke", "black")
            .attr("stroke-width", 0.5)

        // Create new array for stacked bar chart but only taking the distance and first top value
        const lineData = tempData.map((d) => {
            return {
                top: d.top[0],
                distance_to_first_point: d.distance_to_first_point,
                distance_to_line: d.distance_to_line,
                reference: d.reference,
                alt_name: d.alt_name,
                visible: d.visible
            };
        });
        
        svg.append('g')
            .selectAll('text')
            .data(lineData)
            .enter()
            .append('text')
            .attr('text-anchor', 'start')
            .attr('x', d => xScale(d.distance_to_first_point))
            .attr('y', d => yScale(d.top) - 5)
            .attr('transform', 'translate(30, 30)')
            .text(d => `${props.togglefullBhName? d.reference : d.alt_name} [${d.distance_to_line} m]`)
            .attr('transform', (d, i) => `rotate(-30, ${xScale(d.distance_to_first_point)}, ${yScale(d.top) - 5}) translate(10, 45)`)
            .attr('font-size', '10px')
            .attr('font-weight', 'bold')
            .attr("fill", "black");

        svg.append('g')
            .selectAll('line')
            .data(lineData)
            .enter()
            .append('line')
            .attr('x1', d => xScale(d.distance_to_first_point))
            .attr('y1', d => yScale(d.top))
            .attr('x2', d => xScale(d.distance_to_first_point) + 50)
            .attr('y2', d => yScale(d.top))
            .attr('transform', 'translate(30, 30)')
            .attr("stroke-width", 0.5)
            .attr("stroke", "purple");

        svg.append('g')
            .selectAll('text')
            .data(lineData)
            .enter()
            .append('text')
            .attr('text-anchor', 'start')
            .attr('x', d => xScale(d.distance_to_first_point) + 50)
            .attr('y', d => yScale(d.top))
            .attr('transform', 'translate(30, 30)')
            .text(d => `GL [${d.top} m]`)
            .attr('font-size', '10px')
            .attr("fill", "purple");

        // Create new array of tests that has the distance test name and test depth
        const testData = tempData.map((d) => {
            return d.tests.map((value) => {
                return {
                    test: value.name,
                    depth: value.depth,
                    result: value.result,
                    distance_to_first_point: d.distance_to_first_point,
                    distance_to_line: d.distance_to_line,
                    visible: d.visible
                };
            });
        }).flat();

        // remove elements where the test is WL
        const otherTest = testData.filter(d => d.test !== "WL");
        
        // get the key for test that has value true
        let testKeys = Object.keys(tests).filter(key => tests[key] === true);

        // only test that are in testKeys
        const filteredTest = otherTest.filter(d => testKeys.includes(d.test));

        svg.append('g')
            .selectAll('line')
            .data(filteredTest)
            .enter()
            .append('line')
            .attr('x1', d => xScale(d.distance_to_first_point))
            .attr('y1', d => yScale(d.depth))
            .attr('x2', d => xScale(d.distance_to_first_point) + 20)
            .attr('y2', d => yScale(d.depth))
            .attr('transform', 'translate(30, 30)')
            .attr("stroke", "green")
            .attr("stroke-width", 0.5);

        svg.append('g')
            .selectAll('text')
            .data(filteredTest)
            .enter()
            .append('text')
            .attr('text-anchor', 'start')
            .attr('x', d => xScale(d.distance_to_first_point) + 20)
            .attr('y', d => yScale(d.depth))
            .attr('transform', 'translate(30, 30)')
            .text(d => `${d.test} [${d.result}]`)
            .attr('font-size', '10px')
            .attr("fill", "green")

        // only test that have WL
        const wlTest = testData.filter(d => d.test === "WL");

        // only test that are in testKeys
        const FwlTest = wlTest.filter(d => testKeys.includes(d.test));

        svg.append('g')
            .selectAll('line')
            .data(FwlTest)
            .enter()
            .append('line')
            .attr('x1', d => xScale(d.distance_to_first_point))
            .attr('y1', d => yScale(d.depth))
            .attr('x2', d => xScale(d.distance_to_first_point) - 10)
            .attr('y2', d => yScale(d.depth))
            .attr('transform', 'translate(30, 30)')
            .attr("stroke-width", 0.5)
            .attr("stroke", "blue");

        svg.append('g')
            .selectAll('text')
            .data(FwlTest)
            .enter()
            .append('text')
            .attr('text-anchor', 'end')
            .attr('x', d => xScale(d.distance_to_first_point) - 10)
            .attr('y', d => yScale(d.depth))
            .attr('transform', 'translate(30, 30)')
            .text(d => `${d.test} [${d.depth} m]`)
            .attr('font-size', '10px')
            .attr("fill", "blue");

    }

    const updateData = (data) => {
        // create new boreholesData array with updated visibility
        let testDict = {};
        let c = 0;
        const newBoreholesData = data.map((b, i) => {
            if (i === boreholeSelection) {
                b.tests.forEach(t => {
                    if (!(t.name in testDict)) {
                        if(c === 0) {
                            testDict[t.name] = true;
                        } else {
                            testDict[t.name] = false;
                        }
                        c++;
                    }
                });
                if("WL" in testDict) {
                    testDict["WL"] = true;
                }
                return { ...b, visible: true };
            } else {
                return { ...b, visible: false };
            }
        });
        setBoreholes(newBoreholesData);
        setCurrentBh(newBoreholesData[boreholeSelection]);
        if(Object.keys(tests).length !== Object.keys(testDict).length) {
            setTests(testDict);
        }
    }



    useEffect(() => {
        const svg = d3.select(singleSvgRef.current);
      
        // set the svg width and height to parent width and height
        const width = svg.node().parentNode.clientWidth;
        const height = svg.node().parentNode.clientHeight;

        // Set the width and height of the SVG
        svg.attr('width', width).attr('height', height);
        generateBhChart(boreholes);
        setLoading(false);
    }, [boreholes, tests]);

    useEffect(() => {
        let data = structuredClone(props.info);
        let bhList = [];

        // loop through boreholes and add the layers to the items array
        data.forEach((b, index) => {
            bhList.push({value: index, label: props.togglefullBhName ? b.reference : b.alt_name})
        });

        // set the bhList
        setBhList(bhList);
    }, []);    

    useEffect(() => {
        updateData(props.info);
    }, [props.info, boreholeSelection]);  


    return (
        <>  
            <ModalHeader className="border-0 mb-0 pb-0" toggle={props.toggleModal()}>
                {loading ?
                    <div className="container text-center mt-5">
                        <Loader loading={loading} />
                    </div>
                :
                    <div className="title-bold">
                        Update borehole - {props.togglefullBhName ? boreholes[boreholeSelection].reference : boreholes[boreholeSelection].alt_name}
                    </div>
                }
            </ModalHeader>
            <hr className="mb-1 mt-1 pb-0 pt-0"/>
            <ModalBody>
                <div className="row g-0 updateModalBody">
                    <div className="col-5">
                        <div className="border border-secondary rounded w-100" style={{height:'90%'}}>
                            <svg ref={singleSvgRef} />
                        </div>
                        <div className="w-100 pt-2 text-center">
                                {tests &&
                                    Object.keys(tests).map((key, index) => {
                                        return (
                                            <div className="form-check form-switch d-inline-block ms-1 me-2" key={index}>
                                                <input className="form-check-input" type="checkbox" id={key} checked={tests[key]} onChange={() => setTests({...tests, [key]: !tests[key]})}/>
                                                <label className="form-check-label" htmlFor={key}>{key === 'N' ? 'SPT' : key === 'WL' ? 'Water' : key}</label>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                    </div>
                    {loading ?
                        <div className="container text-center mt-5">
                            <Loader loading={loading} />
                        </div>
                    :
                        <div className="col-7 h-100 d-flex align-items-start flex-column px-2">
                            <div className="d-flex w-100 bg-light border">
                                <div className="py-4 w-100 v-stack px-3" style={{zIndex: '9999'}}>
                                    <div className="w-70 px-1">
                                        <Select 
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            isSearchable={true}
                                            name="bhNames"
                                            options={bhList}
                                            isMulti={false}
                                            onChange={(e) => setBoreholeSelection(e.value)}
                                            closeMenuOnSelect={false}
                                            value={bhList[boreholeSelection]}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="w-100 overflow-auto">
                                <UpdateBoreholeForm
                                    geoRecordId={currentBh.geo_record_id}
                                    boreHoleInfoUpdate={() => {props.boreHoleInfoUpdate()}}
                                    project={props.project}
                                />
                            </div>
                        </div>
                    }               
                </div>
            </ModalBody>
            {/* <Modal isOpen={toggleConfirmModal} toggle={toggleModal} centered={true} backdrop={false}>
                <ModalHeader className="border-0 mb-0 pb-0">
                    <div className="title-bold">
                        Confirm changes
                    </div>
                </ModalHeader>
                <hr className="mb-1 mt-1 pb-0 pt-0"/>
                <ModalBody>
                    We have detected changes to the strata. Would you like to save these changes?
                </ModalBody>
                <ModalFooter>
                    <Button className='btn btn-primary' label="Yes" loading={loading} color="primary" onClick={() => handleYesClick()} />
                    <Button className='btn btn-warning' label="No" loading={loading} color="warning" onClick={() => handleNoClick()} />
                </ModalFooter>
            </Modal> */}
        </>
    );
};

export default BoreholeDetailsModal;