//Text.js
import { React, useState } from "react";

const Shrinkable = (props) => {
  const [readMore, setReadMore] = useState(false);

  return (
    <div>
      <div className="text" key={props.key}>
        { props.text.length > 35 ?
            <>
                {readMore ? props.text : `${props.text.substring(0, 35)}...`}
                <a className="btn btn-link btn-sm showButton" onClick={() => setReadMore(!readMore)}>
                    {readMore ? "show less" : "  show all"}
                </a>
            </>
        :
            <span>{props.text}</span>
        }
      </div>
    </div>
  );
};

export default Shrinkable;