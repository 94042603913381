import { marked } from "marked";
import { useEffect, useRef, useState } from "react";
import { Card, CardBody, Button, Spinner, Tooltip, CardFooter } from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCopy } from "@fortawesome/free-solid-svg-icons";
import httpClientPy from '../../../utils/httpClientPy';
import { toast } from "react-toastify";
import { logEvent } from "../../shared/Mixpanel";
import PdfViewer from "../../project/detailComponents/PdfViewer";
import MultiDownload from "./MultiDownload";

marked.setOptions({
    gfm: true,
    tables: true,
});

const OutputLayout = (props) => {

    const [loading, setLoading] = useState(false);
    const [outputs, setOutputs] = useState([]);
    const [selectedId, setSelectedId] = useState(-1);
    const [selectedInnerSearchId, setSelectedInnerSearchId] = useState(-1);
    const [selectedOutput, setSelectedOutput] = useState(null);
    const [copyStatus, setCopyStatus] = useState(false);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [copiedInfo, setCopiedInfo] = useState(null);
    const [showPdf, setShowPdf] = useState(false);
    const [checkedResults, setCheckedResults] = useState([]);
    const [runStatus, setRunStatus] = useState(false);

    // pdf related states
    const [fileUrl, setFileUrl] = useState(null);
    const [pageNumberInput, setPageNumberInput] = useState(1);
    const [initialPage, setInitialPage] = useState(1);
    const [fileType, setFileType] = useState(null);
    const [highlightedAreas, setHighlightedAreas] = useState([]);
    const [currentFileName, setCurrentFileName] = useState(null);
    const [task, setTask] = useState(4);

    const innerSearchRefs = useRef({});
    const mainOutRef = useRef(null);

    const toggle = () => setTooltipOpen(!tooltipOpen);

    const docMapper = {
        "0": 'Specifications',
        "1": 'Surveys',
        "2": 'Contracts',
        "3": 'Drawings',
        "4": 'Risk Registers',
        "5": 'Others',
        "6": "Plans"
    }

    function capitalizeFirstLetter(string) {
        if (!string) return ''; // Handle empty string case
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    useEffect(() => {
        if (selectedInnerSearchId && innerSearchRefs.current[selectedInnerSearchId]) {
            innerSearchRefs.current[selectedInnerSearchId].scrollIntoView({ behavior: 'smooth' });
        } else if (mainOutRef.current) {
            mainOutRef.current.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, [selectedInnerSearchId, selectedOutput]);

    const handleOutputSelect = (output) => {
        setSelectedId(output.id);
        let newOutputs = outputs.map((out) => {
            if (out.id === output.id && !out.viewed) {
                out.is_viewed = true;
            }
            return out;
        });
        setOutputs(newOutputs);
        setSelectedOutput(output);
        setSelectedInnerSearchId(-1); // Reset inner search selection
        if (mainOutRef.current) {
            mainOutRef.current.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }

    const handleInnerSearchClick = (output, innerSearchId) => {
        let newOutputs = outputs.map((out) => {
            if (out.id === output.id && !out.viewed) {
                out.is_viewed = true;
            }
            return out;
        });
        setOutputs(newOutputs);
        setSelectedOutput(output);
        setSelectedId(output.id);
        setSelectedInnerSearchId(innerSearchId);
    };

    const handleCopy = (copyText, sub_id, main_id) => {
        setCopiedInfo({'sub_id': sub_id, 'main_id': main_id});
        navigator.clipboard.writeText(copyText).then(() => {
            setCopyStatus(true)
            // set a timeout before setting the copy status back to false
            setTimeout(() => {
                setCopyStatus(false)
                setCopiedInfo(null)
            }
            , 3000);
        }).catch(err => {
            setCopyStatus(false)
            setCopiedInfo(null)
        });
    };

    const handleReferenceClick = (file, page, ref_ids, type) => {
        httpClientPy.post('/search/reference', {
            file_name: file,
            ref_ids: ref_ids,
            project_id: props.project.id
        }).then((response) => {
            setShowPdf(true)
            setFileUrl(response.data.file_path);
            setPageNumberInput(parseInt(page));
            setInitialPage(parseInt(page));
            setFileType(type);
            setHighlightedAreas(response.data.highlight_area);
            setCurrentFileName(file);
        }).catch((error) => {
            toast.error('Internal problem while loading reference, please contact info@civils.ai for more information. Thank you', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
        })
        
        logEvent('Reference Clicked', { 'File': file, 'Page': page, 'Type': type });  
    }

    const handleAddToCheckedResults = (outputId) => {
        let newCheckedResults = [...checkedResults];
        if (newCheckedResults.includes(outputId)) {
            newCheckedResults = newCheckedResults.filter((id) => id !== outputId);
        } else {
            newCheckedResults.push(outputId);
        }
        setCheckedResults(newCheckedResults);
    }

    const renderer = (json) => {
        let keys = Object.keys(json);
                
        return( 
            <>
                <div>
                    {keys.map((key) => {
                        if (key !== '3') {
                            return Object.entries(json[key]).map(([file, pages]) => (
                                <div key={file}>
                                    {file} - {' '}
                                    {Object.entries(pages).map(([page_num], index, arr) => (
                                        <span key={`${file}-${page_num}`}>
                                            <a
                                                className='reference-link circle-owner'
                                                style={{ backgroundColor: '#5b5fcc' }}
                                                onClick={() => handleReferenceClick(file, page_num, pages[page_num], docMapper[key])}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {page_num}
                                            </a>
                                            {index < arr.length - 1 ? '' : ''}
                                        </span>
                                    ))}
                                </div>
                            ));
                        } else {
                            return (
                                <div className="my-1">
                                    <div className='py-1' style={{ display: 'flex', overflowX: 'auto', gap: '10px', whiteSpace: 'wrap'}}>
                                        {Object.entries(json[key]).map(([file, pages]) => (
                                            <img 
                                                src={pages['image_url']}
                                                alt="Image" 
                                                loading='lazy'
                                                className="card-img-top cad-preview"
                                                ContentType="image/jpeg"
                                                onClick={() => handleReferenceClick(file, 1, pages[1], docMapper["3"])}
                                                style={{ objectFit: 'contain', width: '150px',}} />
                                        ))}
                                    </div>
                                </div>
                            );
                        }
                    })}
                </div>
            </>
        )
    };

    useEffect(() => {
        setLoading(true)
        httpClientPy.get(`/workflow/run/output?workflow_id=${props.workFlowId}&run_id=${props.workFlowRunId}&project_id=${props.project.id}`)
        .then((response) => {
            if (response.data.status === 'failed') {
                setOutputs([]);
            } else {
                setOutputs(response.data.outputs);
                localStorage.setItem('is_unsaved', true)
            }
        }).catch((error) => {
            toast.error('An error occurred while fetching the workflow outputs', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
        }).finally(() => {
            setLoading(false)
        })
    }, [props.workFlowId, props.workFlowRunId]);

    useEffect(() => {
        if (outputs.length > 0 && selectedOutput === null) {
            handleOutputSelect(outputs[0])
            // select all the outputs by default
            let outputIds = outputs.map((output) => output.id);
            setCheckedResults(outputIds);
        }
    }, [outputs]);

    return (
        <>
            {loading ? (
                <div className="container">
                    <div className="row">
                        <div className='d-flex justify-content-center flex-column align-items-center' style={{ height: '80vh' }}>
                            <Spinner animation="border" variant="primary" style={{ width: '3rem', height: '3rem' }} />
                            <div className="pt-3">Results loading...</div>
                        </div>
                    </div>
                </div>
            ) : outputs.length === 0 ? (
                <div className="container">
                    <div className="row">
                        <div className='d-flex justify-content-center flex-column align-items-center' style={{ height: '80vh' }}>
                            <h3><b>No result(s) available</b></h3>
                            <div className="pt-2">There are no result(s) available for this workflow, click on the run button to generate result(s)</div>
                        </div>
                    </div>
                </div>
            ) : (
                <Card 
                    className={`chat-card chat-card-open shadow`}
                    style={{ height: '100%', top: '45px' }}
                >
                    <CardBody className={`py-0 bg-white w-100`}>
                        <div className='row h-100'>
                            <div id='side-out' className={`border-end custom-col-2-5 bg-light px-0 d-flex flex-column ${showPdf ? 'd-none': 'd-block'}`} style={{height: 'calc(100vh - 55px)', overflowY: 'auto'}}>
                                <div className="flex-grow-1 overflow-auto">
                                    {outputs.map((output, index) => (
                                        <div className={`output-list-card px-3 pt-2 ${selectedId === output.id && 'selected'} ${index === outputs.length - 1 && 'mb-4'}`} key={index} onClick={() => handleOutputSelect(output)}>
                                            <div className="d-flex align-items-center">
                                                <input className="form-check-input" type="checkbox" id="flexCheckDefault" 
                                                    checked={checkedResults.includes(output.id)} 
                                                    onChange={() => handleAddToCheckedResults(output.id)} />
                                                <span className="ms-2 mt-2">
                                                    <h6 className={`d-inline mb-0`}><b>{capitalizeFirstLetter(output.search_query)}</b></h6>
                                                </span>
                                            </div>
                                            <ul className="list-unstyled mt-2">
                                                {output.task_outputs.map((task, index) => (
                                                    <li className={`mb-1`} key={index}>
                                                        <div
                                                        className={`align-items-center output-list-card-inner ${
                                                            selectedId === output.id ? "" : "text-truncate overflow-hidden text-nowrap w-100"
                                                        }`}
                                                        style={{
                                                            maxWidth: '100%'  // or any fixed width you desire
                                                          }}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleInnerSearchClick(output, task.id);
                                                        }}
                                                        >
                                                        <small>{capitalizeFirstLetter(task.task_input)}</small>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    ))}
                                </div>
                                {checkedResults.length > 0 && (
                                    <CardFooter id="footer" className="bg-light d-flex justify-content-end px-3 py-2">
                                        <div className="row w-100 g-0">
                                            <div className="col text-end">
                                                <div className="btn-group w-100" role="group" aria-label="Download options">
                                                    <MultiDownload selectedItems={checkedResults} project={props.project} />
                                                </div>
                                            </div>
                                        </div>
                                  </CardFooter>
                                )}
                            </div>
                            <div id='pdfview' className={`border-end ${showPdf ? 'd-block col-6' : 'd-none'}`} style={{height: '90vh' }}>
                                <PdfViewer
                                    project={props.project} 
                                    task={task}
                                    closePdfViewer={() => setShowPdf(false)}
                                    fileUrl={fileUrl} 
                                    pageNumberInput={pageNumberInput}
                                    initialPage={initialPage}
                                    fileType={fileType}
                                    setInitialPage={(page)=>setInitialPage(page)}
                                    highlightArea = {highlightedAreas} 
                                    updateTask={(id)=>setTask(id)}
                                    currentFileName={currentFileName}
                                    updatePageNumber={(page)=>setPageNumberInput(page)}
                                    updateBase64img={null}
                                />
                            </div>
                            <div id='main-out' className={`d-flex ${showPdf ? 'col-6' : 'custom-col-9-5'} bg-offwhite px-0 flex-column chatList-open`} style={{ height: 'calc(100vh - 30px)' }}>
                                {selectedOutput ? (
                                    <>
                                        <div className="d-flex justify-content-between align-items-center bg-light border-bottom">
                                                <div className="px-3 py-2" style={{ margin: 0 }}>
                                                    <div className="bg-light p-2">
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <span className="d-block d-md-flex text-center text-md-start align-items-center pb-2 pb-md-0">
                                                                <h5 className="d-inline mb-0"><b>{capitalizeFirstLetter(selectedOutput.search_query)}</b></h5>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div> 
                                        </div>
                                        <div className="flex-grow-1 pb-3" ref={mainOutRef} style={{ overflowY: 'auto', overflowX: 'hidden' }}>
                                            {selectedOutput.task_outputs.length > 0 ? (
                                                <>
                                                {selectedOutput.task_outputs.map((task, index) => (
                                                    <div className="border-bottom" key={task.id} ref={el => innerSearchRefs.current[task.id] = el}>
                                                        <div className="px-4 pt-2 pb-2 bg-light border-bottom d-md-flex text-center text-md-start justify-content-between align-items-center">
                                                            <h6 className="d-inline mb-0"><b>{capitalizeFirstLetter(task.task_input)}</b></h6>
                                                            <Button id='copy_all_btn' className="btn btn-link btn-sm text-dark" onClick={() => handleCopy(task.result, task.id, selectedId)}>
                                                                {copyStatus && copiedInfo.sub_id === task.id && copiedInfo.main_id === selectedId ? <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon> 
                                                                : <FontAwesomeIcon icon={faCopy}></FontAwesomeIcon>}
                                                            </Button>
                                                            <Tooltip isOpen={tooltipOpen} placement={'bottom'} target="copy_all_btn" toggle={toggle}>
                                                                Copy answer
                                                            </Tooltip>
                                                        </div>
                                                        <div className="px-4 py-2 markdown-content" style={{ overflowX: 'auto' }} dangerouslySetInnerHTML={{ __html: marked.parse(task.result) }}></div>
                                                        <div className={`${index === selectedOutput.task_outputs.length - 1 && 'mb-0'}`}>
                                                            <div className="pb-4 px-4 pt-3 text-muted text-end">
                                                                <small>{renderer(task.references)}</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                                </>
                                            ) : (
                                                <div className="border-bottom" key={selectedId} ref={el => innerSearchRefs.current[selectedId] = el}>
                                                    <div className="px-4 pt-2 pb-2 bg-light border-bottom d-md-flex text-center text-md-start justify-content-between align-items-center">
                                                    <h6 className="d-inline mb-0"><b>{capitalizeFirstLetter(selectedOutput.search_query)}</b></h6>
                                                        <Button id='copy_all_btn' className="btn btn-sm btn-link text-dark" onClick={() => handleCopy(selectedOutput.result, 0, selectedId)}>
                                                            {copyStatus && copiedInfo.main_id === selectedId ? <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon> 
                                                            : <FontAwesomeIcon icon={faCopy}></FontAwesomeIcon>}
                                                        </Button>
                                                        <Tooltip isOpen={tooltipOpen} placement={'bottom'} target="copy_all_btn" toggle={toggle}>
                                                            Copy answer
                                                        </Tooltip>
                                                    </div>
                                                    <div className="px-4 py-2 markdown-content" style={{ overflowX: 'auto' }} dangerouslySetInnerHTML={{ __html: marked.parse(selectedOutput.result) }}></div>
                                                    <div className="pb-3 px-4 pt-2 text-muted text-end">
                                                        <small>{renderer(selectedOutput.references)}</small>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </>
                                ) : (
                                    <div className="container">
                                        <div className="row">
                                            <div className='d-flex justify-content-center flex-column align-items-center' style={{ height: '80vh' }}>
                                                <h3><b>Select a result from the list</b></h3>
                                                <div className="pt-2">Click on a result from the list to view output from the workflow run</div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </CardBody>
                </Card>
            )}
        </>
    )
}

export default OutputLayout;