import React, { useState, useEffect, useRef } from "react";
import { FileUploader } from "react-drag-drop-files";
import httpClientPy from "../../utils/httpClientPy";
import { Progress, UncontrolledAlert, Tooltip, Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpFromBracket, faBook, faBoreHole, faCircleInfo, faInfo, faInfoCircle, faLessThanEqual, faLock, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { color } from "d3";
import localStorageService from "../../utils/localStorageService";

const Uploader = (props) => {
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errors, setErrors] = useState([]);
  const [progress, setProgress] = useState(0);
  const fileSize = useRef();
  const [agsUpload, setAgsUpload] = useState(false);
  const [numberOfPdf, setNumberOfPdf] = useState(0);
  const [errorCode, setErrorCode] = useState(0);
  const [geotechData, setGeotechData] = useState(props.userInfo.george_digitiser ? true : null);



  useEffect(() => {
    
    // let geotechDataStatus = false;

    // console.log(props.userInfo, props.digitiseOnly)

    // if(props.userInfo && props.userInfo.digitise_only && props.userInfo.george_access) {
      
    // }
    // else if(props.userInfo && props.userInfo.digitise_only) {
      
    // }
    // else if (props.userInfo && props.userInfo.digitise_only){

    // }

    // // if(props.digitiseOnly) {
    // //   geotechDataStatus = true;
    // // }
    // // else if (localStorageService.getItem("geotechDataStatus") !== null) {
    // //   geotechDataStatus = localStorageService.getItem("geotechDataStatus");
    // // } 

    // // setGeotechData(geotechDataStatus)


    // // const toggleGeotechData = (status) => {
    // //   setGeotechData(status);
    // //   localStorageService.setItem("geotechDataStatus", status);
    // // }

    const handleFileDrop = (e) => {
        e.preventDefault();
        const files = Array.from(e.dataTransfer.files);
        handleChange(files);
    }

    window.addEventListener("dragover", (e) => e.preventDefault());
    window.addEventListener("drop", handleFileDrop);
    return () => {
      window.removeEventListener("dragover", (e) => e.preventDefault());
      window.removeEventListener("drop", handleFileDrop);
    };

  }, []);

  useEffect(() => {

    if(loading){
      const intervalId = setInterval(() => {
        setProgress((prevCount) => {
          if (prevCount >= 100) {
            clearInterval(intervalId);
            return prevCount;
          }
          else{
            return prevCount + 1;
          }
        });
      }, 50*fileSize.current);

      return () => {
        clearInterval(intervalId);
        setProgress(0)
      };
    }
  }, [loading]);



  const handleChange = (files) => {
    setNumberOfPdf(0);
    let total_size_mb = 0;
    if (files.length === 0) {
      return;
    }

    const formData = new FormData();

    fileSize.current = 1;
    for (var i = 0; i < files.length; i++) {
      formData.append("file_obj", files[i]);
      // console.log(`file ${i}: ${files[i].name} ${files[i].size} ${files[i].type}`);
      // convert file size to MB
      // increment number of pdf files
      if(files[i].type === "application/pdf"){
        setNumberOfPdf(numberOfPdf+1);
      }
      
      total_size_mb += files[i].size/1000000;

      if(files[i].size>300000) {
        fileSize.current += files[i].size/2000000;
      }
    }

    let url = ""
    if (props.geoRecordId) {
      // append geo record id to the url
      url = `${props.uploadUrl}?geo_record_id=${props.geoRecordId}`;
    } else {
      url = props.uploadUrl;
    }

    if (props.projectId) {
      // append project id to the url
      url = `${props.uploadUrl}?project_id=${props.projectId}`;
    }

    
    if(files[0].type !== "application/pdf"){ // if ags then true for geotech data
      url = `${url}&geotech_data=${true}`;
    } else {
      url = `${url}&geotech_data=${geotechData}`;
    }

    setLoading(true);
    setShowSuccess(false);
    setShowError(false);
    setAgsUpload(false);
    setErrorCode(0);
    
    httpClientPy
      .post(url, formData)
      .then((response) => {
        setErrorCode(response.data.code);
        if(response.data.info.length > 0){
          setAgsUpload(true);
          props.updateAgsInfo(response.data.info);
        }
      })
      .catch((errorResponse) => {
        if (errorResponse.response.data) {
          setErrors(errorResponse.response.data.errors);
        }
        setShowError(true);
      })
      .finally(() => {
        if(!showError) {
          setShowSuccess(true);
          props.onComplete();
        }
          // console.log('upload complete')
        setLoading(false);
      });
  };


  return (
    <>
      <div className="uploader_holder w-100 mx-auto">


        {loading ? (
          <div className="text-center">
            <Progress max={100} value={progress} animated color="primary" className="my-2" style={{ width: '50%', margin: 'auto' }}>{progress}%</Progress>
          </div>
        ) : (
          <>
          
            <div className="drag-drop-uploader-holder">
              <FileUploader
                classes="drag-drop-uploader w-100 mb-2"
                style={{ minWidth: "100%" }}
                label="Upload or drop your files here"
                name="file"
                types={["pdf", "ags"]}
                handleChange={handleChange}
                multiple={true}
              />
            </div>
        </>
      )}

      <div className="container">
        {showSuccess && errorCode != 4 && (
          agsUpload && !showError ?
            <UncontrolledAlert color="success">
              <span className="title-bold">All set, your AGS files are extracted </span><span>&nbsp;You can view the records in my geotech data.</span>
            </UncontrolledAlert>
            :
              (numberOfPdf > 0 && !showError) ? 
                geotechData ? (
                <UncontrolledAlert color="success">
                  <span className="title-bold">All set, we're extracting the data. </span><span>&nbsp;We'll email you when your file is ready.</span>
                </UncontrolledAlert>
                ) : (
                <UncontrolledAlert color="success">
                  <span className="title-bold">Success! </span><span>&nbsp;Your project AI is now trained on this knowledge.</span>
                </UncontrolledAlert>
                )
              :
                !showError && errorCode != 3 ?
                <UncontrolledAlert color="warning">
                  <span className="title-bold">The uploaded file has records that are already associated with your account.</span>
                </UncontrolledAlert>
                :
                  errorCode == 3 &&
                  <UncontrolledAlert color="danger">
                    <span className="title-bold"><b>Check filetype.</b> Only PDF and AGS4 files are currently supported. 
                    Currently only AGS4 is supported.</span>
                  </UncontrolledAlert>
        )}

        { errorCode == 4 && (
          <UncontrolledAlert color="warning">
            <span className="title-bold">As a Pro account user, you are limited to uploading a maximum of 1 PDF file. If you would like to unlock all our features, please visit for more <a href="https://civils.ai/pricing">info</a>.</span>
          </UncontrolledAlert>
        )}

        { errorCode == 5 && (
          <UncontrolledAlert color="warning">
            <span className="title-bold">As a Pro account user, you are limited to uploading a maximum of 20 Non Geotech PDF files. If you would like to unlock all our features, please visit for more <a href="https://civils.ai/pricing">info</a>.</span>
          </UncontrolledAlert>
        )}

        {showError && !(errors && errors.length > 0) && (
          <UncontrolledAlert color="danger">
            <span className="title-bold">Sorry something went wrong!</span><span>&nbsp;Please try uploading your file again later.</span>
          </UncontrolledAlert>
        )}
        </div>

      </div>
    </>
  );
};

export default Uploader;
