

const Pricing = () => {

    return (
        <div class="container mt-5">
            <h1 class="text-center">Choose a Plan</h1>
            <div class="row mt-4">
                
                <div class="col-md-4">
                    <div class="card">
                        <div class="card-header bg-primary text-white">
                            <h3>Free</h3>
                        </div>
                        <div class="card-body">
                            <h4 class="card-title">$0/month</h4>
                            <ul class="list-group">
                                <li class="list-group-item">Basic Features</li>
                                <li class="list-group-item">Limited Support</li>
                            </ul>
                        </div>
                        <div class="card-footer">
                            <a href="#" class="btn btn-primary btn-block">Get Started</a>
                        </div>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="card">
                        <div class="card-header bg-warning">
                            <h3>Premium</h3>
                        </div>
                        <div class="card-body">
                            <h4 class="card-title">$9.99/month</h4>
                            <ul class="list-group">
                                <li class="list-group-item">Advanced Features</li>
                                <li class="list-group-item">Priority Support</li>
                            </ul>
                        </div>
                        <div class="card-footer">
                            <a href="#" class="btn btn-warning btn-block">Get Started</a>
                        </div>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="card">
                        <div class="card-header bg-success text-white">
                            <h3>Corporate</h3>
                        </div>
                        <div class="card-body">
                            <h4 class="card-title">Custom Pricing</h4>
                            <ul class="list-group">
                                <li class="list-group-item">Enterprise Features</li>
                                <li class="list-group-item">Dedicated Support</li>
                            </ul>
                        </div>
                        <div class="card-footer">
                            <a href="#" class="btn btn-success btn-block">Contact Us</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Pricing;