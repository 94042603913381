import chroma from 'chroma-js';
import httpClientPy from '../../utils/httpClientPy';
import Select from 'react-select';
import { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';

const CustomSelectCategory = (props) => {

    const [categories, setCategories] = useState([])

    useEffect(() => {
      if (props.categories) {
        setCategories(props.categories)
      } else {
        httpClientPy.get(`/library/categories`)
        .then((response) => {
            let categories = response.data.categories
            // there are two keys in each category object, name and color
            // we need to convert them to label and value for react-select
            let categoriesForSelect = categories.map((category) => {
                return {
                    label: category.name,
                    value: category.id,
                    color: category.color,
                }
            })
            setCategories(categoriesForSelect)
        }).catch((error) => {
          let error_code = error.response.data.detail;
          if (error_code === 4) {
              toast.error('Document limit has been exceeded, contact your admin in order to check limit balance.', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
          } else if (error_code === 3) {
              toast.error('User is not part of enterprise account.', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
          } else if (error_code === 5) {
              toast.error('No categories exist for this enterprise', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
          } else if (error_code === 1) {
              toast.error('Internal problem while loading categories, please contact info@civils.ai for more information. Thank you', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
          }
        })
      }
    }, [props.categoriesUpdated, props.categories])

    const colourStyles = {
        control: (styles) => ({ ...styles, backgroundColor: 'white' }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
          const color = chroma(data.color);
          return {
            ...styles,
            backgroundColor: isDisabled
              ? undefined
              : isSelected
              ? data.color
              : isFocused
              ? color.alpha(0.1).css()
              : undefined,
            color: isDisabled
              ? '#ccc'
              : isSelected
              ? chroma.contrast(color, 'white') > 2
                ? 'white'
                : 'black'
              : data.color,
            cursor: isDisabled ? 'not-allowed' : 'default',
      
            ':active': {
              ...styles[':active'],
              backgroundColor: !isDisabled
                ? isSelected
                  ? data.color
                  : color.alpha(0.3).css()
                : undefined,
            },
          };
        },
        multiValue: (styles, { data }) => {
          const color = chroma(data.color);
          return {
            ...styles,
            backgroundColor: color.alpha(0.1).css(),
          };
        },
        multiValueLabel: (styles, { data }) => ({
          ...styles,
          color: data.color,
        }),
        multiValueRemove: (styles, { data }) => ({
          ...styles,
          color: data.color,
          ':hover': {
            backgroundColor: data.color,
            color: 'white',
          },
        }),
    };

    const handleChange = (selectedOption) => {
        props.onChange(selectedOption)
    }

    return (
      <>
        <ToastContainer />
        {props.selectedOption ? (
          <Select
              className={props.className}
              classNamePrefix="select"
              closeMenuOnSelect={props.isMulti ? false : true}
              isMulti={props.isMulti}
              options={categories}
              styles={colourStyles}
              placeholder={props.placeholder}
              onChange={handleChange}
              value={props.selectedOption}
          />
        ) : (
          <Select
              className={props.className}
              classNamePrefix="select"
              closeMenuOnSelect={props.isMulti ? false : true}
              isMulti={props.isMulti}
              options={categories}
              styles={colourStyles}
              placeholder={props.placeholder}
              onChange={handleChange}
          />
        )}
      </>
    )
}

export default CustomSelectCategory;