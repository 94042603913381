import { useEffect } from "react";
import { useMap } from "react-leaflet";
import L from "leaflet";
import "./LeafletRuler.js";
import "../../styles/LeafletRuler.css";

export default function LeafletRuler(props) {
  const map = useMap();

  useEffect(() => {
    if (!map) return;

    L.control.ruler().addTo(map);
  }, [map, props.measureDistance]);
  
  return null;
}

