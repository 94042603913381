import { faAdd, faAngleLeft, faAngleRight, faCheck, faEdit, faTrash, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import httpClientPy from "../../utils/httpClientPy";
import { logEvent } from "../shared/Mixpanel";
import Loader from "../shared/Loader";
import LibraryDeleteConfirm from "./LibraryDeleteConfirm";
import LibraryPromptCreate from "./LibraryPromptCreate";
import { toast, ToastContainer } from "react-toastify";

const LibraryPromptMngmt = (props) => {

    const [searchInput, setSearchInput] = useState('');
    const [prompts, setPrompts] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(false);
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [selectedPrompt, setSelectedPrompt] = useState(null);
    const [selectedPromptValue, setSelectedPromptValue] = useState('');
    const [deletePromptModal, setDeletePromptModal] = useState(false);
    const [createPromptModal, setCreatePromptModal] = useState(false);
    const [action, setAction] = useState(null);

    const paginate = (pageNumber) => setCurrentPageNumber(pageNumber);

    const getPrompts = async () => {
        setLoading(true);
        try {
            let response = await httpClientPy.get(`/library/management/prompts?search_key=${searchInput}&library_id=${props.selectedLibrary}&limit=${10}&offset=${(currentPageNumber - 1) * 10}`);
            logEvent('Prompt Management View');
            if (searchInput === '') {
                setPrompts(response.data.prompts);
            } else {
                // make the section of text that matches the search input bold
                let searchInputArr = searchInput.split(' ');
                let searchInputArrLen = searchInputArr.length;
                let promptsArr = response.data.prompts;
                let promptsArrLen = promptsArr.length;
                for (let i = 0; i < promptsArrLen; i++) {
                    let promptArr = promptsArr[i].prompt.split(' ');
                    let promptArrLen = promptArr.length;
                    for (let j = 0; j < promptArrLen; j++) {
                        for (let k = 0; k < searchInputArrLen; k++) {
                            if (promptArr[j].toLowerCase().includes(searchInputArr[k].toLowerCase())) {
                                promptArr[j] = `<b>${promptArr[j]}</b>`;
                            }
                        }
                    }
                    promptsArr[i].prompt = promptArr.join(' ');
                }
                setPrompts(promptsArr);
            }
            setTotalPages(response.data.total_pages);
            setLoading(false);
        } catch (err) {
            let error_code = err.response.data.detail;
            if (error_code === 4) {
                toast.error('Document limit has been exceeded, contact your admin in order to check limit balance.', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
            } else if (error_code === 3) {
                toast.error('User is not part of enterprise account.', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
            } else if (error_code === 5) {
                toast.error('Library does not exist or user is not part of the enterprise.', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
            } else {
                toast.error('An error occurred while fetching prompt data. Please contact info@civils.ai.', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
            }
        }
    }

    const toggleDeletePromptModal = () => setDeletePromptModal(!deletePromptModal);
    const toggleCreatePromptModal = () => setCreatePromptModal(!createPromptModal);

    const handleUpdatePrompt = async () => {
        setLoading(true);
        try {
            let response = await httpClientPy.put(`/library/management/prompts/update/`, 
                { 
                    prompt: selectedPromptValue,
                    library_id: props.selectedLibrary,
                    prompt_id: selectedPrompt
                }
            );
            logEvent('Prompt Updated', { prompt_id: selectedPrompt });
            setSelectedPrompt(null);
            setSelectedPromptValue('');
            setAction(null);
            toast.success('Prompt updated successfully.', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
            getPrompts();
            setLoading(false);
        } catch (err) {
            let error_code = err.response.data.detail;
            if (error_code === 4) {
                toast.error('Document limit has been exceeded, contact your admin in order to check limit balance.', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
            } else if (error_code === 3) {
                toast.error('User is not part of enterprise account.', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
            } else if (error_code === 5) {
                toast.error('Prompt does not exist or user is not part of the enterprise.', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
            } else {
                toast.error('An error occurred while updating prompt. Please contact info@civils.ai.', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
            }
        }
    }

    const handleEditPrompt = (prompt) => {
        setAction(1) // 1 for edit
        setSelectedPromptValue(prompt.prompt);
        setSelectedPrompt(prompt.id);
    }

    const handleDeletePrompt = (prompt) => {
        setAction(2) // 2 for delete
        setSelectedPrompt(prompt.id);
        toggleDeletePromptModal();
    }

    useEffect(() => {
        if (props.selectedLibrary) {
            getPrompts();
        }
    }, [searchInput, currentPageNumber, props.selectedLibrary]);

    return (
        <>
            <ToastContainer />
            <Modal isOpen={props.isOpen} toggle={props.toggle} size="xl">
                <ModalHeader toggle={props.toggle} className='border-0 pb-0 mb-0'>
                    <span className='title-bold'>Prompt Management</span> <br/>
                    <div className='d-flex justify-content-between align-items-center'>
                        <small>Update prompts that can be utilized for searching information in this library</small>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <div className="d-flex justify-content-between align-items-center mb-2">
                        <input type="text" className="small-search-input ml-auto" placeholder="Search prompt" value={searchInput} onChange={(e) => setSearchInput(e.target.value)} />
                        <a className="btn btn-outline-dark" onClick={() => toggleCreatePromptModal()}>
                            <span><FontAwesomeIcon className="me-2" icon={faAdd}/></span>
                            <span className="">Create Prompt</span>
                        </a>
                    </div>
                    {loading ? (
                        <div className="my-5">
                            <Loader loading={loading} />
                        </div> 
                    ) : ( 
                        <div style={{ overflowY: "auto", maxHeight: "80vh" }}>  
                            <table className="table table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th className="text-center" style={{ width: "75%" }}>Prompts</th>
                                        <th className="text-center" style={{ width: "15%" }}>Last Updated At</th>
                                        <th className="text-center">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!loading && prompts && prompts.length !== 0 ? (
                                        <>
                                        {prompts.map((prompt, index) => (
                                            <tr key={index}>
                                                {prompt.id === selectedPrompt && action === 1 ? (
                                                    <>
                                                    <td>
                                                        <textarea type="text" className="form-control" value={selectedPromptValue} onChange={(e) => setSelectedPromptValue(e.target.value)} />
                                                    </td>
                                                    <td className="text-center">{prompt.updated_at}</td>
                                                    <td>
                                                        <a className="btn btn-outline-success me-2" onClick={() => handleUpdatePrompt()}>
                                                            <span><FontAwesomeIcon icon={faCheck}/></span>
                                                        </a>
                                                        <a className="btn btn-outline-warning" onClick={() => {setSelectedPrompt(null); setSelectedPromptValue(''); setAction(null)}}>
                                                            <span><FontAwesomeIcon icon={faX}/></span>
                                                        </a>
                                                    </td>
                                                    </>
                                                ) : (
                                                    <>
                                                    <td dangerouslySetInnerHTML={{ __html: prompt.prompt }}></td>
                                                    <td className="text-center">{prompt.updated_at}</td>
                                                    <td>
                                                        <a className="btn btn-outline-primary me-2" onClick={() => handleEditPrompt(prompt)}>
                                                            <span><FontAwesomeIcon icon={faEdit}/></span>
                                                        </a>
                                                        <a className="btn btn-outline-danger" onClick={() => handleDeletePrompt(prompt)}>
                                                            <span><FontAwesomeIcon icon={faTrash}/></span>
                                                        </a>
                                                    </td>
                                                    </>
                                                )}
                                                
                                            </tr>
                                        ))}
                                        </>
                                    ) : (
                                        <tr>
                                            <td colSpan="3" className="text-center">No prompts found</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                            <div className="d-flex justify-content-end">
                                {currentPageNumber > 1 && (
                                    <button className="btn btn-primary btn-sm me-2" onClick={() => paginate(currentPageNumber - 1)}>
                                        <FontAwesomeIcon icon={faAngleLeft} />
                                    </button>
                                )}
                                {currentPageNumber} / {totalPages}
                                {currentPageNumber < totalPages && (
                                    <button className="btn btn-primary btn-sm ms-2" onClick={() => paginate(currentPageNumber + 1)}>
                                        <FontAwesomeIcon icon={faAngleRight} />
                                    </button>
                                )}
                            </div>
                        </div>
                    )}
                </ModalBody>
            </Modal>

            <LibraryDeleteConfirm isOpen={deletePromptModal} 
                                toggle={() => {toggleDeletePromptModal(); setSelectedPrompt(null); setAction(null)}} 
                                prompt_id={selectedPrompt}
                                library_id={props.selectedLibrary}
                                nulify={() => {setSelectedPrompt(null); setAction(null)}}
                                getPrompts={getPrompts}
                                item="prompt"
            />

            <LibraryPromptCreate library_id={props.selectedLibrary} 
                                 isOpen={createPromptModal} 
                                 toggle={toggleCreatePromptModal} 
                                 getPrompts={getPrompts}
            />
        </>
    )
}

export default LibraryPromptMngmt;