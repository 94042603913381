import React from "react";
const AuthFormHeader = () => {
  return (
      <div>
        <img src="assets/logo-black.svg" height={'35px'} alt="Civils.ai" />
      </div>
  );
};

export default AuthFormHeader;
