import { useCallback, useEffect, useMemo, useState } from 'react';
import BaseCard from './BaseCard';
import Button from '../../shared/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { debounce } from 'lodash';
 
const TaskBox = (props) => {

    const [mainTask, setMainTask] = useState(props.data.task);
	const [details, setDetails] = useState(props.data.input);
	const [areNodesRunning, setAreNodesRunning] = useState(false);

	const debouncedUpdate = useMemo(
		() => debounce((value) => {
			props.updateNodeData(props.id, { input: value });
		}, 500),
		[props.id, props.updateNodeData]
	);

	const onChange = useCallback((evt) => {

		const value = evt.target.value;
		setDetails(value);
        debouncedUpdate(value);

	}, [debouncedUpdate]);

    const updateMainTask = (value) => {
        setMainTask(value);
        props.updateNodeData(props.id, { task: value });
    }

	useEffect(() => {
		return () => {
			debouncedUpdate.cancel();
		}
	}, [debouncedUpdate]);

	return (
		<>	
			<BaseCard {...props} areNodesRunning={areNodesRunning} setAreNodesRunning={setAreNodesRunning} setMainTask={updateMainTask}>
                <textarea
                    id="text"
                    name="details"
                    placeholder={props.data.input === 'custom' ? 'Required details' : 'Optional details'}
                    onChange={onChange}
                    className="nodrag nowheel form-control mt-2"
                    style={{ width: '100%' }}
                    value={details}
                    rows={details.split('\n').length}
                    required={props.data.input === 'custom'}
                    disabled={props.data.is_running || props.isDigitsationWf || areNodesRunning}
                ></textarea>
			</BaseCard>
		</>
	);
}

export default TaskBox;