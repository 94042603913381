import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
    ReactFlow,
    Controls,
    Background,
    useNodesState,
    useEdgesState,
    addEdge,
    useReactFlow,
    ReactFlowProvider
} from '@xyflow/react';

import SearchBox from '../nodes/SearchBox';
import FileSelector from '../nodes/FileSelector';
import OutputBox from '../nodes/OutputBox';
import ButtonEdge from '../edges/ButtonEdge';
import httpClientPy from '../../../utils/httpClientPy';
   
import '@xyflow/react/dist/style.css';
import { toast } from 'react-toastify';
import Button from '../../shared/Button';
import { capitalize, set } from 'lodash';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarsProgress, faExternalLink, faWindowMinimize } from '@fortawesome/free-solid-svg-icons';
import { Card, Spinner } from 'react-bootstrap';
import NodeCanvasSelector from './NodeCanvasSelector';
import TaskBox from '../nodes/TaskBox';

   
const initialNodes = [
    {
        id: 'node-1',
        type: 'fileSelector',
        position: { x: -350, y: -75},
        data: {
                title: "Input(s)",
                input: [],
                task: null,
                width: '18rem', 
                type: null,
                node: 'selector', 
                is_running: false,
                is_fail: false,
                is_complete: false
            }
    },
    {
        id: 'node-2',
        type: 'searchBox',
        position: { x: 0, y: 0 },
        data: {
                title: "Search", 
                input: [""],
                task: null,
                width: '18rem',
                type: null, 
                node: 'search', 
                is_running: false,
                is_fail: false,
                is_complete: false
            }
    },
    {
        id: 'node-3',
        type: 'taskBox',
        position: { x: 350, y: 0 },
        data: {
                title: "Task Instruction", 
                input: '',
                task: 'custom',
                width: '25rem',
                type: null, 
                node: 'task', 
                is_running: false,
                is_fail: false,
                is_complete: false
        }
    },
    {
        id: 'node-4',
        type: 'outputBox',
        position: { x: 850, y: -25},
        data: {
                title: "Output file(s)",
                input: null,
                task: null,
                width: '25rem', 
                type: 'db', 
                node: 'output-main', 
                is_running: false,
                is_fail: false,
                is_complete: false
            }
    },
];

const initialEdges = [
    { id: 'edge-1', source: 'node-1', target: 'node-2', type: 'buttonEdge', animated: false, data: { is_running: false } },
    { id: 'edge-2', source: 'node-2', target: 'node-3', type: 'buttonEdge', animated: false, data: { is_running: false } }, 
    { id: 'edge-3', source: 'node-3', target: 'node-4', type: 'buttonEdge', animated: false, data: { is_running: false } },
]

const Canvas = (props) => {

    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [autoXPos, setAutoXPos] = useState(null);
    const [autoYPos, setAutoYPos] = useState(null);
    const [screenXy, setScreenXy] = useState(null);
    const [autoSourceNode, setAutoSourceNode] = useState(null);
    const [autoSourceNodeId, setAutoSourceNodeId] = useState(null);
    const [runningFlowInfo, setRunningFlowInfo] = useState(null);
    const [showProgress, setShowProgress] = useState(false);
    const [loading, setLoading] = useState(false);

    const { screenToFlowPosition } = useReactFlow();
    const reactFlowInstance = useRef(null);
    const reactFlowWrapper = useRef(null);

    const onInit = (instance) => {
        reactFlowInstance.current = instance;
    };

    const canvasStyle = useMemo(() => ({
        width: props.show ? '90%' : '100%',
        height: '94vh',
        marginLeft: props.show ? '15vw' : '0',
        transition: 'margin-left 0.3s ease, width 0.3s ease'
    }), [props.show]);

    const onConnect = useCallback(
        (params) => {
            
            const sourceNode = nodes.find((node) => node.id === params.source);
            const targetNode = nodes.find((node) => node.id === params.target);

            if (sourceNode.data.node === 'search' && targetNode.data.node === 'output') {
                toast.error('Invalid connection between search as source and output as target, need to connect to database output first', 
                    { position: toast.POSITION.TOP_RIGHT, autoClose: 5000 });
                return;
            }

            if (sourceNode.data.node === 'search' && targetNode.data.node === 'output-main') {
                let connections_current_search_to_any_task = edges.filter(edge => {
                    return edge.source === sourceNode.id && nodes.find(node => node.id === edge.target).data.node === 'task';
                });
                if (connections_current_search_to_any_task.length > 0) {
                    toast.error('Invalid connection between search as source and output as target when task instruction is connected to search, in order to connect to output, search and task instruction has to be disconnected first', 
                        { position: toast.POSITION.TOP_RIGHT, autoClose: 5000 });
                    return;
                }
            }

            if (sourceNode.data.node === 'task' && targetNode.data.node === 'search') {
                toast.error('Invalid connection between task instruction as source and search as target', 
                    { position: toast.POSITION.TOP_RIGHT, autoClose: 5000 });
                return;
            }

            if (sourceNode.data.node === 'task' && targetNode.data.node === 'task') {
                toast.error('Invalid connection between task instruction as source and task instruction as target, to refine output connect task instruction to search',
                    { position: toast.POSITION.TOP_RIGHT, autoClose: 5000 });
                return;
            }

            if (sourceNode.data.node === 'task' && targetNode.data.node === 'output') {
                toast.error('Invalid connection between task instruction as source and output as target, need to connect to database output first',
                    { position: toast.POSITION.TOP_RIGHT, autoClose: 5000 });
                return;
            }

            if (sourceNode.data.node === 'selector' && targetNode.data.node === 'task') {
                toast.error('Invalid connection between file selector as source and task instruction as target, task instruction should be connected from search', 
                    { position: toast.POSITION.TOP_RIGHT, autoClose: 5000 });

                return;
            }

            if (sourceNode.data.node === 'selector' && (targetNode.data.node === 'output' || targetNode.data.node === 'output-main')) {
                toast.error('Invalid connection between file selector as source and output as target, output should be connected from search or task instruction', 
                    { position: toast.POSITION.TOP_RIGHT, autoClose: 5000 });

                return;
            }

            if (sourceNode.data.node === 'output-main' && (targetNode.data.node === 'search' || targetNode.data.node === 'task')) {
                toast.error('Invalid connection between output as source and search or task instruction as target', 
                    { position: toast.POSITION.TOP_RIGHT, autoClose: 5000 });

                return;
            }

            if (sourceNode.data.node === 'search' && targetNode.data.node === 'search') {
                toast.error('Invalid connection between search as source and search as target, to refine output connect search to task instruction',
                    { position: toast.POSITION.TOP_RIGHT, autoClose: 5000 });

                return;
            }

            if (sourceNode.data.node === 'search' && targetNode.data.node === 'task') {
                let connections_search_output = edges.filter(edge => {
                    return edge.source === sourceNode.id && nodes.find(node => node.id === edge.target).data.node === 'output-main';
                });
                
                if (connections_search_output.length > 0) {
                    setEdges((eds) => eds.filter(edge => edge.id !== connections_search_output[0].id));
                }
            }
            
            setEdges((edges) => addEdge({ ...params, type: 'buttonEdge', animated: false, data: { is_running: false } }, edges));
            localStorage.setItem('is_unsaved', true);
        },
        [setEdges, nodes, edges],
    );

    const onConnectEnd = useCallback(
        (event, connectionState) => {
          if (!connectionState.isValid) {
            if (connectionState.fromNode.data.node === 'task') { return }
            const { clientX, clientY } = 'changedTouches' in event ? event.changedTouches[0] : event;
            let position =  screenToFlowPosition({
                x: clientX,
                y: clientY,
            })
            setAutoXPos(position.x);
            setAutoYPos(position.y);
            setAutoSourceNode(connectionState.fromNode.data.node)
            setAutoSourceNodeId(connectionState.fromNode.id)
            setScreenXy({ x: clientX, y: clientY });
          }
        },
        [reactFlowWrapper, reactFlowInstance]
    );

    const updateNodeData = useCallback((nodeId, data) => {
        setNodes((nodes) =>
            nodes.map((node) => {
                if (node.id === nodeId) {
                    return {
                        ...node,
                        data: {
                            ...node.data,
                            ...data
                        }
                    };
                }
                return node;
            })
        );
    }, [setNodes]);

    const getWorkflowInfo = async (id) => {

        setLoading(true);
        setNodes([])
        setEdges([])
        httpClientPy.get(`/workflow?workflow_id=${id}&project_id=${props.project.id}`)
        .then((response) => {
            props.setWorkflow(response.data.workflow)
            setNodes(response.data.workflow.nodes)
            setEdges(response.data.workflow.edges)
        }).catch((error) => {
            toast.error('Error while loading workflow', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
        }).finally(() => {
            setLoading(false);
        })

    }

    const getWorkflowRunInfo = async (id, retrigger) => {

        if (!retrigger) {
            setLoading(true);
            setNodes([])
            setEdges([])
        }
        httpClientPy.get(`/workflow/run?run_id=${id}&project_id=${props.project.id}`)
        .then((response) => {
            setNodes(response.data.nodes)
            setEdges(response.data.edges)
            let info = {
                name: response.data.workflow_run.name,
                uuid: response.data.workflow_run.run_uuid,
                created_at: response.data.workflow_run.created_at,
                status: response.data.workflow_run.status,
                elapsed_time: response.data.elapsed_time,
                percentage: response.data.percentage
            }
            setRunningFlowInfo(info)
            props.setWorkflow({...props.workflow, is_digitsation: response.data.workflow_run.is_digitsation})
            localStorage.setItem('is_unsaved', response.data.is_unsaved);
            setLoading(false);
        }).catch((error) => {
            toast.error('Error while loading workflow run', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
        })
        
    }

    useEffect(() => {
        // Call fitView after the transition completes
        const timer = setTimeout(() => {
            if (reactFlowInstance.current) {
                reactFlowInstance.current.fitView({ duration: 500 });
            }
        }, 300); // Delay matches the CSS transition duration
    
        return () => clearTimeout(timer);
    }, [props.show, nodes]);

    useEffect(() => {
        if (props.selectedNode) {
            
            let width = '18rem';
            let title = "";
            let type = "";
            let data_type = null;
            let node = "";
            let source = null;
            let input = null;
            let task = null;
            let pos_x = autoXPos !== null ? autoXPos : Math.max(0, nodes[nodes.length - 1].position.x + 150);
            let pos_y = autoYPos !== null ? autoYPos : Math.random() * 400;
            let number_of_search = nodes.filter((node) => node.data.node === 'search').length;
            let number_of_inner_search = nodes.filter((node) => node.data.node === 'task').length;
            let max_id = Math.max(...nodes.map(node => parseInt(node.id.split('-')[1])));

            if (props.selectedNode === 'search') {
                if (number_of_search > 15) {
                    toast.error('Maximum number of search nodes reached', { position: toast.POSITION.TOP_RIGHT, autoClose: 5000 });
                } else {
                    title = 'Search';
                    node = 'search';
                    type = 'searchBox';
                    input = [""];
                    source = nodes.filter((node) => node.data.node === 'selector').length > 0 ? nodes.filter((node) => node.data.node === 'selector')[0].id : null;
                    const searchNodes = nodes.filter((node) => node.data.node === 'search');
                    let max_x = searchNodes.length > 0 ? Math.max(...searchNodes.map(node => node.position.x)) : pos_x;
                    let max_y = searchNodes.length > 0 ? Math.max(...searchNodes.map(node => node.position.y)) : pos_y;
                    pos_x = autoXPos !== null ? autoXPos : max_x;
                    pos_y = autoYPos !== null ? autoYPos : max_y + 150;
                }
            } else if (props.selectedNode === 'task-table' || props.selectedNode === 'task-summary' || props.selectedNode === 'task-custom') {
                if (number_of_inner_search > 25) {
                    toast.error('Maximum number of inner instruction nodes reached', { position: toast.POSITION.TOP_RIGHT, autoClose: 5000 });
                } else {
                    title = 'Task Instruction';
                    width = '25rem';
                    node = 'task';
                    type = 'taskBox';
                    input = ''
                    task = props.selectedNode.split('-')[1];
                    source = autoSourceNodeId;
                    const innerSearch = nodes.filter((node) => node.data.node === 'task');
                    let max_x = innerSearch.length > 0 ? Math.max(...innerSearch.map(node => node.position.x)) : pos_x;
                    let max_y = innerSearch.length > 0 ? Math.max(...innerSearch.map(node => node.position.y)) : pos_y;
                    pos_x = autoXPos !== null ? autoXPos : max_x;
                    pos_y = autoYPos !== null ? autoYPos : max_y + 150;
                    // delete any edges between search and output-main
                    // let connections_search_output = edges.filter(edge => {
                    //     const source = nodes.find(node => node.id === edge.source);
                    //     const target = nodes.find(node => node.id === edge.target);
                    //     return source.data.node === 'search' && target.data.node === 'output-main';
                    // });
                    // if (connections_search_output.length > 0) {
                    //     setEdges((eds) => eds.filter(edge => edge.source !== connections_search_output[0].source && edge.target !== connections_search_output[0].target));
                    // }
                }
            }
            
            let newNode = {}

            if (title !== "") {
                newNode = {
                    id: `node-${max_id + 1}`,
                    type: type,
                    position: { x: pos_x, y: pos_y },
                    data: { title: title, input: input, task: task, width: width, type: data_type, node: node, is_running: false, is_fail: false, is_complete: false }
                };

                setNodes((nds) => nds.concat(newNode));
            }

            if (source) {
                setEdges((eds) => addEdge({ source: source, target: newNode.id, type: 'buttonEdge', animated: false, data: { is_running: false } }, eds));
            }

            localStorage.setItem('is_unsaved', true);
            setAutoXPos(null);
            setAutoYPos(null);
            setScreenXy(null);
            setAutoSourceNode(null);
            setAutoSourceNodeId(null);
            props.setSelectedNode(null);
        }
    }, [props.selectedNode]);

    useEffect(() => {
        if (edges.length === 0 || nodes.length === 0) {
            return;
        }

        if (edges === props.workflow.edges && nodes === props.workflow.nodes) {
            return;
        }

        let workflow = props.workflow;
        workflow.nodes = nodes;
        workflow.edges = edges;
        props.setWorkflow(workflow);
        
    }, [nodes, edges]);

    useEffect(() => {
        setNodes((nds) =>
            nds.map(node => ({
                ...node,
                data: {
                    ...node.data,
                    is_error: props.nodesError.includes(node.id)
                }
            }))
        );
    }, [props.nodesError, setNodes]);

    useEffect(() => {


        if (props.action === 'run') {

            getWorkflowRunInfo(props.workFlowRunId)
            setShowProgress(true);
            
        } else if (props.action === 'edit') {

            setRunningFlowInfo(null);
            
            if ((localStorage.getItem('is_unsaved') === 'false' || localStorage.getItem('is_unsaved') === null )  && props.workFlowId !== null && props.workFlowId !== -1) {
                getWorkflowInfo(props.workFlowId)
            } else {
                
                getWorkflowRunInfo(props.workFlowRunId)
                setShowProgress(false);

            }

        } else {

            setNodes(initialNodes);
            setEdges(initialEdges);
            setRunningFlowInfo(null);

        }

    }, [props.workFlowId, props.workFlowRunId, props.action]);

    useEffect(() => {
        let interval = null;
        if (runningFlowInfo !== null && runningFlowInfo.status === 'running') {
            // create an interval to update the running flow info
            interval = setInterval(() => {
                getWorkflowRunInfo(props.workFlowRunId, true)
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [runningFlowInfo]);

    useEffect(() => {
        if (props.runTriggered) {
            let nodes_data = nodes.map(node => { return { ...node, data: { ...node.data, is_running: true, is_complete: false, is_fail: false } } });
            let edges_data = edges.map(edge => { return { ...edge, animated: true, data: { ...edge.data, is_running: true } } });

            setNodes(nodes_data);
            setEdges(edges_data);
        }
    }, [props.runTriggered]);

    const nodeTypes = useMemo(() => ({ 
        searchBox: (nodeProps) =>   <SearchBox {...nodeProps} 
                                             updateNodeData={updateNodeData}
                                             isDigitsationWf = {props.workflow.is_digitsation}
                                    />,

        taskBox: (nodeProps) => <TaskBox {...nodeProps}
                                         updateNodeData={updateNodeData}
                                         isDigitsationWf = {props.workflow.is_digitsation}
                                />,

        fileSelector: (nodeProps) => <FileSelector {...nodeProps}
                                                updateNodeData={updateNodeData} 
                                                setEnableRun={props.setEnableRun}
                                                project = {props.project}
                                                extraDetails = {props.extraDetails} 
                                                isDigitsationWf = {props.workflow.is_digitsation}
                                                setGlobalFileToUpload={props.setGlobalFileToUpload}
                                    />,

        outputBox: (nodeProps) => <OutputBox {...nodeProps} 
                                            updateNodeData={updateNodeData}
                                            isDigitsationWf = {props.workflow.is_digitsation}
                                 />
    }), [props.workflow.is_digitsation]);

    const edgeTypes = useMemo(() => ({ 
        buttonEdge: (edgeProps) => <ButtonEdge {...edgeProps} action={props.action} />
    }), [props.action]);

    return (
        <>  
            <div style={canvasStyle} ref={reactFlowWrapper}>
                <ReactFlow
                    onInit={onInit}
                    nodes={nodes}
                    edges={edges}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    onConnect={onConnect}
                    onConnectEnd={onConnectEnd}
                    fitView
                    nodeTypes={nodeTypes}
                    edgeTypes={edgeTypes}
                >
                    <Controls position='bottom-right' />
                    {loading ? (
                        <div className='d-flex justify-content-center align-items-center' style={{ width: '100%', height: '100%' }}>
                            <Spinner animation="border" variant="primary" />
                        </div>
                    ) : (
                        <>
                        {runningFlowInfo && props.action === 'run' &&
                            <>
                                {showProgress ? (
                                    <div className='current-active-job-card'>
                                        <div className="job-card my-2">
                                            <div className="job-info">
                                                <div className='d-flex justify-content-between'>
                                                    <h3 className="job-name">Job Name: <b>{runningFlowInfo.name}</b></h3>
                                                    <Button 
                                                        className='btn btn-sm btn-light d-flex align-items-center justify-content-center' 
                                                        label={<FontAwesomeIcon icon={faWindowMinimize} className='pb-1'/>}
                                                        onClick={() => setShowProgress(false)}
                                                    />
                                                </div>
                                                {/* <p className="job-id">ID: <b>{runningFlowInfo.uuid}</b></p> */}
                                                <p className="job-started">Start Time: <b>{moment(runningFlowInfo.created_at).format('DD MMM YYYY hh:mm:ss A Z')}</b></p>
                                                <p className="time-elapsed">Time Elapsed: <b>{runningFlowInfo.elapsed_time}</b></p>
                                                <div className='d-flex justify-content-between'>
                                                    <p className="job-status">Status: 
                                                        <span className={`ms-1 badge rounded-pill documentBadge ${runningFlowInfo.status === 'running' ? 'blue' : runningFlowInfo.status === 'completed' ? 'green' : 'red'}`}>
                                                            <b>{capitalize(runningFlowInfo.status)}</b>
                                                        </span>
                                                    </p>
                                                    {runningFlowInfo.status === 'completed' && 
                                                        <Button 
                                                            className='btn btn-sm btn-primary d-flex align-items-center justify-content-center' 
                                                            label="View Output(s)"
                                                            onClick={() => props.setSelectedBtn("outputs")}
                                                        />
                                                    }
                                                </div>
                                            </div>
                                            <div className="progress-container">
                                                <div className="progress-bar" style={{ width: `${runningFlowInfo.percentage}%` }}></div>
                                            </div>
                                            <p className="progress-text">{runningFlowInfo.percentage}% Complete</p>
                                        </div>
                                    </div>
                                ) : (
                                    <div className='current-active-job-card'>
                                        <Button className='btn btn-sm btn-light' 
                                                label={<FontAwesomeIcon icon={faBarsProgress}></FontAwesomeIcon>} 
                                                onClick={() => setShowProgress(true)}></Button>
                                    </div>
                                )}
                            </>
                        }
                        {autoXPos !== null && autoYPos !== null &&
                            <NodeCanvasSelector x = {screenXy.x} 
                                                y = {screenXy.y} 
                                                setSelectedNode={props.setSelectedNode} 
                                                sourceNode = {autoSourceNode} />
                        }
                        </>
                    )}
                    <Background variant="dots" gap={12} size={1} />
                </ReactFlow>
            </div>
        </>
    );
}

function CanvasProvider(props) {
    return (
      <ReactFlowProvider>
        <Canvas {...props} />
      </ReactFlowProvider>
    );
  }

export default CanvasProvider;