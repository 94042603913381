const Loader = ({ loading, ...props }) => {
  return (
    <div className="d-flex justify-content-center">
      {loading && (
        <span>
          <span
            className={`spinner-border ${
              props.size === "large" ? "" : "spinner-border-sm"
            }`}
            role="status"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Loading...</span>
        </span>
      )}
    </div>
  );
};

export default Loader;
