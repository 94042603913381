import { useState } from "react";
import { Card, CardBody, CardHeader } from "reactstrap";


const LibraryPromptSelector = (props) => {

    const [prompts, setPrompts] = useState(props.prompts);

    return (
        <Card className="d-flex mt-3 mx-auto" style={{ maxWidth: '90%' }}>
            <CardHeader className="bg-light">
                <span style={{ maxWidth: '100%' }} className="d-inline-block title-bold text-truncate">Select a prompt from the library below or write your own</span>
            </CardHeader>
            <CardBody>
                <div style={{ overflowY: 'auto', maxHeight: '50vh' }}>
                    <table className="table table-bordered table-hover" style={{ cursor: 'pointer' }}>
                        <tbody>
                            {prompts.map((p) => (
                                <tr key={p.id} className="cursor-pointer" onClick={() => props.setSelectedPrompt(p)}>
                                    <td>
                                        <div className="d-flex justify-content-between">
                                            <span className="text-wrap">{p.prompt}</span>
                                        </div>    
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </CardBody>
        </Card>
    )
}

export default LibraryPromptSelector;