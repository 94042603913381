
import { Modal, ModalBody, ModalHeader, ModalFooter, Table, Card, CardBody, CardHeader } from "reactstrap";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Progress } from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCopy, faCheck, faRobot, faCircleInfo,faLocationDot, faBook, faSatellite, faWater, faArrowUpRightFromSquare, faFileExcel,faFilePdf, faWandMagicSparkles, faTrash, faPenToSquare} from "@fortawesome/free-solid-svg-icons";
import {findMaterial, analyseSPT, analyseSand, analyseClay, analyseGravel, analyseSilt, analyseFill, analyseRock, analyseOrganic} from "../../shared/algorithms/geoRecordAlgorithms.js"
import ModelSingleBH from "./ModelSingleBH.js"
import {findBoreholeColor} from './findBoreholeColor'
import httpClientPy from "../../../utils/httpClientPy.js";
import AGSDownloadButton from "../../geoRecords/agsDownloadButton";
import GeoRecordDownloadButton from "../../geoRecords/geoRecordDownloadButton";

import moment from "moment";
import { Link } from "react-router-dom";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import annotationPlugin from "chartjs-plugin-annotation";
import { logEvent } from "../../shared/Mixpanel.js";
import UpdateBoreholeForm from "./UpdateBoreholeForm";
import DeleteConfirmModal from "../../shared/layouts/DeleteConfirmModal.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  annotationPlugin
);

let agsFileIcon = <img style={{height:"1.2rem"}} src="/assets/AGS.svg"/>
library.add(faCopy, faCheck, faRobot, faCircleInfo, faBook, faSatellite, faWater, faArrowUpRightFromSquare, faFilePdf, faLocationDot);

const BoreholeLogDetails = ({ loading, label, ...props }) => {
  //geoTableDisplay is used to display either the geological description, test or predicted values table
  const [geoTableDisplay, setGeoTableDisplay] = useState("geology");
  //copiedTable is used to track whether the copy table to clipboard button is pressed and provide a success message 
  const [copiedTable, setCopiedTable] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [satelliteElevation, setSatelliteElevation] = useState(0);
  const [displayDimensions, setDisplayDimensions] = useState(true);
  const [testSelection, setTestSelection] = useState();
  const [correlationSelected, setCorrelationSelected] = useState(1);
  const [chartView, setChartView] = useState(true);
  const link = useRef();
  const [modal, setModal] = useState(true);
  const [modalLoading, setModalLoading] = useState(false);
  const [modalErrors, setModalErrors] = useState(false);
  const [showModalErrors, setShowModalErrors] = useState(false);
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [modalUpdateBorehole, setModalUpdateBorehole] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [deleting, setDeleting] = useState(false);
  const [deleteConfirmed, setDeleteConfirmed] = useState(false);
  const [log, setLog] = useState();
  const [hasExtraFiles, setHasExtraFiles] = useState(false);

    const spt_id = useRef(null);

    const navigate = useNavigate();

    const [privateFileUrl, setPrivateFileUrl] = useState();
    var borehole_length = 0
    var strataCopy = []
    var tests_present = false

    // timer for copied table icon animation
    useEffect(() => {
        let interval;
        if (copiedTable===true) {
          interval = setInterval(() => {
            setCopiedTable(false)
        }, 1000);
        }
        return () => clearInterval(interval);
      }, [copiedTable]);

    // timer for copied table icon animation
    useEffect(() => {
      if (props.data && !props.data.link){
        setLog(props.data)
        link.current = document.createElement("a");
        // let a variable georecord_id be props.data.id if it exists if not set it to props.data.selectedDataItem
        let georecord_id = props.data.id ? props.data.id : props.data.selectedDataItem

        httpClientPy.get(`/geo/record/fetch/pdf?record_id=${georecord_id}&project_id=${props.projectId}`, {
          responseType: 'blob'  // Important: Set response type as 'blob'
        }).then(response => {
          const file = new Blob(
            [response.data], 
            { type: 'application/pdf' }  // Set the MIME type to 'application/pdf'
          );
          const fileURL = URL.createObjectURL(file);
          setPrivateFileUrl(fileURL);  // This URL can be used directly as the src of an iframe
        }).catch(error => {
          console.error('Error fetching PDF:', error);
          setPrivateFileUrl()

        });
        if(props.data.files && props.data.files.length > 0) {
          setHasExtraFiles(true);
        }

      }
      else{
        setPrivateFileUrl()
      }

      if (props.data) {
        if(!props.data.extraction_requested) {
          setGeoTableDisplay("pdf")
        }
      }

    }, [props.data]);
    
    // if this record contains strata then iterate through the strata layers and analyse each material
    if (props.strata){

      strataCopy = structuredClone(props.strata)

      var cumulative_depth = 0
      var startvalue = -1000
      var max_level = startvalue

      for (var i = 0; i < strataCopy.length; i++) {

        strataCopy[i].topMBGL = Math.round(cumulative_depth*100)/100
        let thickness_temp = Math.abs(strataCopy[i].top - strataCopy[i].base)
        cumulative_depth += thickness_temp
        strataCopy[i].baseMBGL = Math.round(cumulative_depth*100)/100

        if(strataCopy[i].top>strataCopy[i].base){
          if(strataCopy[i].top>max_level){max_level=strataCopy[i].top}
        }
        else{
          if(strataCopy[i].top<max_level){max_level=strataCopy[i].top}
        }

        //make all string lowercase
        let descriptionString = strataCopy[i].description !== null ? strataCopy[i].description.toLowerCase() : ""
        descriptionString = descriptionString.replace(/[^a-zA-Z ]/g, "")
        let descriptionList = descriptionString.split(" ")

        let material = findMaterial(descriptionList, descriptionString)
        material.topMBGL = strataCopy[i].topMBGL
        material.baseMBGL = strataCopy[i].baseMBGL
        if(material.uscs !== '-'){
          strataCopy[i].material = material.uscs
        }
        else{
          strataCopy[i].material = material.grain
        }

        if(correlationSelected===1){
          // depending on which material is identified then run a geotech analysis on the material
          if (material.identifier==='clay') {material=analyseClay(descriptionString);}
          else if (material.identifier==='sand') {material=analyseSand(descriptionString);}
          else if (material.identifier==='gravel') {material=analyseGravel(descriptionString);}
          else if (material.identifier==='silt') {material=analyseSilt(descriptionString);}
          else if (material.identifier==='fill') {material=analyseFill(descriptionString);}
          else if (material.identifier==='rock') {material=analyseRock(descriptionString);}
          else if (material.identifier==='peat') {material=analyseOrganic(descriptionString);}
        }
        else if(correlationSelected===2 && material.identifier!=='rock'){
          let tests_within_strata = []
          for(let single_test of props.tests){
            if(single_test.depth<=strataCopy[i].baseMBGL && single_test.depth>=strataCopy[i].topMBGL){
              tests_within_strata.push(single_test)
            }
          }
          material = analyseSPT(material, tests_within_strata, null)
        }


        strataCopy[i].grain = material.grain
        strataCopy[i].uscs = material.uscs
        strataCopy[i].N = '-'
        strataCopy[i].density = material.density
        strataCopy[i].friction = material.friction
        strataCopy[i].E = material.modulus
        strataCopy[i].c = material.cDrained
        strataCopy[i].cund = material.cUndrained
        strataCopy[i].spt_avg = material.spt_avg

      }
      borehole_length = Math.round(cumulative_depth*100)/100

      findBoreholeColor(strataCopy)


      var water_strike = null
      var test_types = []

      if (props.tests){
        for (var j = 0; j < props.tests.length; j++) {

          if(props.tests[j]['test']=='water_strike'){
            water_strike = props.tests[j].depth
          }
          else if (props.tests[j]['test']!==null){
            tests_present = true
            // if props.tests[j]['test'] not in test_types then push it to test_types
            if(!test_types.includes(props.tests[j]['test'])){
              test_types.push(props.tests[j]['test'])
              if (props.tests[j]['test']==='N' || props.tests[j]['test']==='S'){
                spt_id.current = test_types.length-1
              }
            }
          }
        }
        // put tests in alphabetical order
        test_types.sort()
        
      }


      if(startvalue==max_level){max_level = 0}
      var ground_level = max_level
      if(ground_level==0){
        ground_level = null
      }
      let lat = 0
      let lng = 0
      var latlngStatus = true
      if(props.data.position && props.data.position.lat){
        lat = props.data.position.lat
      }
      else{
        var latlngStatus = false
      }
      if(props.data.position && props.data.position.lng){
        lng = props.data.position.lng
      }
      else{
        var latlngStatus = false
      }

      

      // fetch(`https://www.elevation.civils.ai/v1/srtm30m?locations=${lat},${lng}`, {
      //   headers: {
      //       'Accept': 'application/json',
      //       'Content-Type': 'application/json',
      //   },
      //   method: "GET",
      //   })
      //   .then(response => response.json())
      //   .then(data => {
      //       if(latlngStatus){
      //           setSatelliteElevation(data.results[0].elevation.toFixed(1))
      //         }
      //         else{
      //           setSatelliteElevation(0)
      //         }
      //   })

    }

    let test_data_x = []
    let test_data_y = []
    let first_found = false
    let annotations_holder = {}
    let final_depth = 0
    let max_result = 100


      if(correlationSelected!==1 || geoTableDisplay==='test'){
        let test_type = test_types[testSelection]
        let ordered_tests = []

        // order the tests by depth
        if(props.tests){
          ordered_tests = props.tests.sort((a, b) => (a.depth > b.depth) ? 1 : -1)
        }

        for (let test_holder of ordered_tests){
          if(test_holder.test==test_type){
            if(!first_found){
              // test_data_y.push(test_holder.depth)
              // test_data_x.push(0)
            }
            test_data_y.push(test_holder.depth)
            if(!test_holder.result && test_holder.report){
              test_data_x.push(100)
            }
            else if (test_holder.result){
              test_data_x.push(test_holder.result)
            }
            first_found = true
            max_result = Math.max(max_result, test_holder.result)
          }
        }
        if(max_result===0){
          max_result = 100
        }
      }
      else{
        max_result = 50
      }

      let i_annotations = 0
      let top_holder = 0
      for(let strata of strataCopy){
        let strata_text = strata.uscs ? strata.uscs : '-'
        if(strata_text.length>50){
          strata_text = strata_text.substring(0,50)+'...'
        }

        annotations_holder[i_annotations] = {
          type: 'box',
          xMin: 0,
          xMax: max_result,
          yMin: top_holder,
          yMax: strata.baseMBGL,
          borderColor: 'rgb(0, 0, 0)',
          borderWidth: 1,
          backgroundColor: `rgba(${strata.color[0]}, ${strata.color[1]}, ${strata.color[2]}, 0.3)`,    
        }
        i_annotations++;
        annotations_holder[i_annotations] = {
          type: 'label',
          xValue: (max_result)/2,
          yValue: (strata.baseMBGL+top_holder)/2,
          content: [strata_text],
          color: 'rgba(0, 0, 0,0.6)', // Set the font color to red
          font: {
            size: 10,
          }
        }
        top_holder = strata.baseMBGL
        i_annotations++;

      }

    const data = {
      labels: test_data_x,
      datasets: [
        {
          label: 'Line Display',
          data: test_data_y,  // Use the same data as scatter points
          type: 'scatter',       // Set the type to 'line'
          showLine: true,
          borderColor: '#000000',
          borderWidth:2,
          pointRadius:3,
          //point fill color
          pointBackgroundColor: '#D22B2B', 

        }
      ]
    };

    const data2 = {
      // empty dataset
      datasets: [
        {
          label: 'Line Display',
          data: [],  // Use the same data as scatter points
          type: 'scatter',       // Set the type to 'line'
          showLine: true,
          borderColor: '#000000',
          borderWidth:2,
          pointRadius:3,
          //point fill color
          pointBackgroundColor: '#D22B2B',
        }
      ]
    }


    // Chart options

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        title: {
          display: true,
          text: `${test_types ? test_types[testSelection] : '-'} Test Results`,
        },
        annotation: {
          annotations: 
            annotations_holder
          }
      },
      scales: {
        y: {
          reverse: true,
          title: {
            display: true,
            text: 'Depth (mBGL)',
          },
          min: 0, // Set the maximum value to 0
          max:cumulative_depth,
        },
        x: {
          title: {
            display: true,
            text: '-',
          },
          max: max_result,
          min: 0,
          display: correlationSelected!==1 || geoTableDisplay==='test',
        },
      },
    };


    const options2 = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        title: {
          display: true,
          text: `${test_types ? test_types[testSelection] : '-'} Test Results`,
        },
      },
      scales: {
        y: {
          reverse: true,
          title: {
            display: false,
            text: 'Depth (mBGL)',
          },
          ticks: {
            display: false, // Hide scale labels
          },
          min: 0, // Set the maximum value to 0
          max:cumulative_depth,
        },
        x: {
          title: {
            display: correlationSelected!==1 || geoTableDisplay==='test',
            text: `${test_types ? test_types[testSelection] : '-'}`,
          },
          max: max_result,
          min: 0,
          display: correlationSelected!==1 || geoTableDisplay==='test',
        },
      },
    };
    

    // copy the table to the clipboard
    const copyTable = () => {
      const el = document.getElementById('copyTable');
      var body = document.body, range, sel;
      if (document.createRange && window.getSelection) {
          range = document.createRange();
          sel = window.getSelection();
          sel.removeAllRanges();
          try {
              range.selectNodeContents(el);
              sel.addRange(range);
          } catch (e) {
              range.selectNode(el);
              sel.addRange(range);
          }
      } else if (body.createTextRange) {
          range = body.createTextRange();
          range.moveToElementText(el);
          range.select();
      }
      document.execCommand("Copy");
      if (window.getSelection) {window.getSelection().removeAllRanges();}
      else if (document.selection) {document.selection.empty();}
      setCopiedTable(true)
    }


    const handleDownload = (record_id) => {

      link.current = document.createElement("a");
      httpClientPy.get(`/geo/record/fetch/pdf?record_id=${record_id}&project_id=${props.projectId}`, {
        responseType: 'blob'  // Important: Set the response type to 'blob'
      }).then(response => {
        const file = new Blob(
          [response.data], 
          { type: 'application/pdf' }  // Specify the file type
        );
        let url = URL.createObjectURL(file);
        link.current.href = url;
        link.current.download = url.split("/").pop();
        link.current.click();
      })
      .catch((errorResponse) => {
          console.log(errorResponse)
          throw errorResponse;
      }
      )

    };

    const handleRequestDigitization = (id) => {
      setModalLoading(true);
      return httpClientPy
      .post(`/geo/record/associate`, {
          geo_record_ids: [id],
          project_id: props.projectId
      })
      .then((response) => {
          setShowModalSuccess(true);
          setShowToast(true)
          props.onDataRequest()
      })
      .catch((errorResponse) => {
      if (errorResponse) {
          setModalErrors(errorResponse);
      }
          setShowModalErrors(true);
      })
      .finally(() => {
          setModalLoading(false);
          logEvent('Request digitization', { 'Record ID': id });
          props.updateMap();
          props.updateConvert();
          setGeoTableDisplay('geology')
      });
  };


  const triggerUpdatePosition = (item) => {
    // get the url
    let url = window.location.href;
    
    if (item.position === undefined) {
      item.position = {lat: 0, lng: 0};
    }
    logEvent('Update position', { 'Record ID': item.id });
    if(props.project) {
      if(url.includes('map')) {
        props.setGeoRecordPosition("update");
        props.toggleUpdateBorehole(true);
        props.setGeoRecord(item);
        props.toggleModal();
      } else {
        navigate('/map', { state: { geo_record: item, project_detail: props.project, position_action: 'update' } });
      }
    } else {
      props.dataSelected("update");
      props.geoRecordId(item.id);
      props.onSearchGeoRecord(item.position.lat, item.position.lng, item.id, 'update')
    }
  };

  const toggleDeleteConfirmModal = (id) => {
    setDeleteId(id);
    setDeleting(!deleting);
  };

  const triggerUpdateInfo = (id) => {
    setModalUpdateBorehole(!modalUpdateBorehole);
  };

  // send request to /geo/record/delete with the geo record id, record id and delete type
  const deleteRecord = () => {
    // setLoading(true);
    return httpClientPy
    .delete(`/geo/record/delete?geo_record_id=${deleteId}&record_id=${deleteId}&record_type=position`)
    .then((response) => {
        logEvent('Delete record', { 'Record ID': deleteId, 'Record Type': 'position' });
        // setLoading(false);
        props.boreHoleInfoUpdate(deleteId);
        setDeleting(false);
        let newLog = log;
        newLog.position = {lat: 0, lng: 0};
        newLog.easting = 0;
        newLog.northing = 0;
        setLog(newLog);
    })
}

  useEffect(() => {
    if (deleteConfirmed) {
        deleteRecord();
        setDeleteId(0);
        setDeleteConfirmed(false);
    }
}, [deleteConfirmed]);


  return (
      <>  
      { props.data &&
        <>
        <ModalHeader className="border-0 mb-0 pb-0 title-bold" toggle={()=>props.toggleModal()}>
            <h4 className="d-inline-flex title-bold align-items-center mb-0 pb-0">
              Borehole Details - {(props.data.borehole_name && props.data.borehole_name!=='Unknown project') ? props.data.borehole_name : props.data.reference}
            </h4>
        </ModalHeader>
        <ModalBody className="detailsBody" style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <div className="row">
          <div className={`d-flex justify-content-center align-items-center ${chartView ? ((geoTableDisplay === "test" || (geoTableDisplay==='predict' && correlationSelected === 2 ) ) ? `col-3` : `col-2`) : (`col-6`)}`}>
            <div
                className="btn-group btn-group-visualise mt-2 mb-3"
                role="group"
                aria-label="Toggle geological data"
              >
              <input
                  type="radio"
                  className="btn-check"
                  name="chartPreviewBtn"
                  id="chartPreviewBtn"
                  autoComplete="off"
                  disabled={!props.data.extraction_requested}
                  checked={(props.data.status === 'pending') ? false : chartView}
                  onChange={() => {
                    setChartView(!chartView);
                  }}
                />
                <label
                  className="btn btn-outline-dark shadow-none"
                  htmlFor="chartPreviewBtn"
                >
                  Chart
                </label>

                <input
                  type="radio"
                  className="btn-check"
                  name="pdfPreviewBtn"
                  id="pdfPreviewBtn"
                  autoComplete="off"
                  checked={(props.data.status === 'pending') ? true : !chartView}
                  onChange={() => {
                    setChartView(!chartView);
                  }}
                />
                <label
                  className="btn btn-outline-dark shadow-none"
                  htmlFor="pdfPreviewBtn"
                >
                  PDF
                </label>
              </div>
          </div>
          <div className={chartView ? ((geoTableDisplay === "test" || (geoTableDisplay==='predict' && correlationSelected === 2 ) ) ? `col-9` : `col-10`) : (`col-6`)}>
            <div
                className="btn-group btn-group-visualise mt-2 mb-3"
                role="group"
                aria-label="Toggle geological data"
              >
                <input
                  type="radio"
                  className="btn-check"
                  name="geoRecordDetailRadioButton"
                  id="geoRecordDetailRadioButtonAll"
                  autoComplete="off"
                  disabled={!props.data.extraction_requested}
                  checked={geoTableDisplay === "geology"}
                  onChange={() => {
                    setGeoTableDisplay("geology");
                    setCopiedTable(false)
                  }}
                />
                <label
                  className="btn btn-outline-dark shadow-none"
                  htmlFor="geoRecordDetailRadioButtonAll"
                >
                  Geology
                </label>

                <input
                  type="radio"
                  className="btn-check"
                  name="geoRecordDetailRadioButton"
                  id="geoRecordDetailRadioButtonTest"
                  autoComplete="off"
                  disabled={!tests_present || !props.data.extraction_requested}
                  checked={geoTableDisplay === "test"}
                  onChange={() => {
                    setGeoTableDisplay("test");
                    setTestSelection(0)
                    setCopiedTable(false)
                  }}
                />
                <label
                  className="btn btn-outline-dark shadow-none"
                  htmlFor="geoRecordDetailRadioButtonTest"
                >
                  Key data
                </label>

                <input
                  type="radio"
                  className="btn-check"
                  name="geoRecordDetailRadioButton"
                  id="geoRecordDetailRadioButtonPredict"
                  autoComplete="off"
                  disabled={!props.data.extraction_requested}
                  checked={geoTableDisplay === "predict"}
                  onChange={() => {
                    setGeoTableDisplay("predict");
                    setCopiedTable(false)
                    setCorrelationSelected(1)
                  }}
                />
                <label
                  className="btn btn-outline-dark shadow-none"
                  htmlFor="geoRecordDetailRadioButtonPredict"
                >
                  Parameter correlations
                </label>


                <input
                  type="radio"
                  className="btn-check"
                  name="geoRecordDetailRadioButton"
                  id="geoRecordDetailRadioButtonDetails"
                  autoComplete="off"
                  checked={geoTableDisplay === "details"}
                  onChange={() => {
                    setGeoTableDisplay("details");
                  }}
                />
                <label
                    className="btn btn-outline-dark shadow-none"
                    htmlFor="geoRecordDetailRadioButtonDetails"
                >
                    Extra details
                </label>

                {hasExtraFiles && 
                <>
                    <input
                    type="radio"
                    className="btn-check"
                    name="geoRecordDetailRadioButton"
                    id="geoRecordDetailRadioButtonExtraDetails"
                    autoComplete="off"
                    checked={geoTableDisplay === "extra_files"}
                    onChange={() => {
                      setGeoTableDisplay("extra_files");
                    }}
                  />
                  <label
                      className="btn btn-outline-dark shadow-none"
                      htmlFor="geoRecordDetailRadioButtonExtraDetails"
                  >
                      Extra Files
                  </label>
                </>
                }


            </div>
          </div>
        </div>
        <div className="row modellingModalBody h-100" style={{ flex: '1' }}>
        {chartView && props.data.status !== 'pending' ? (
          <>
            <div className="col-2 h-100 d-none d-lg-block text-center">
                {strataCopy && strataCopy.length > 0 && props.data.extraction_requested &&  (

                        <Chart data={data2} options={options} />

                )}
              </div>
              {(geoTableDisplay === 'test' || (geoTableDisplay==='predict' && correlationSelected === 2 ) ) &&
                <div className="col-1 h-100">
                  <Chart data={data} options={options2} />
                </div>
              }
            </>
          ) :
          (
            <div className="col-6">
            {props.data && props.data.link ? (
              <div>
                <div className="previewContainer mt-2"> 
                 
                  <iframe
                    src={props.data.link.replace("http:","https:")}
                    className="boreholePreview">
                  </iframe>
                </div>
              </div>
              ): (
              <div>
                {props.data.file_path === null || !props.data.file_path.includes(".ags") ? (
                  <div className="previewContainer">
                    {/* display pdf from s3 url privateUrl */}
                    <iframe
                      src={privateFileUrl}
                      className="boreholePreview">
                    </iframe>
                  </div>
                ) : (
                  <div className="previewContainer">
                    <div className="mx-2 alert text-center" role="alert">
                      <strong>
                        "This data was uploaded using AGS uploader, hence no PDF is available for this record."
                      </strong>
                    </div>
                  </div>
                )}
              </div>
              )
            }
            </div>
          )
          }

          <div className="col h-100">

              <div className="tbodyDiv dataModal overflow-auto">
              {geoTableDisplay === 'test' &&
                <>
                  {tests_present &&
                  <div className="pb-3">
                    <div
                      className="btn-group btn-group-visualise dimmed"
                      role="group"
                      aria-label="Toggle geological data"
                    >
                    {test_types.map((item, i) => {
                        return (
                            <>

                                <input
                                    type="radio"
                                    className="btn-check"
                                    name="virtualBoreholeButton"
                                    id={`test${i+1}`}
                                    autoComplete="off"
                                    checked={testSelection === i}
                                    onChange={() => {
                                      setTestSelection(i);
                                    }}
                                />
                                <label
                                    className="btn btn-outline-dark shadow-none"
                                    htmlFor={`test${i+1}`}
                                >
                                    { <>{item}</> }
                                </label>
                            </>
                            )
                        })
                      }
                    </div>
                  </div>
                  }
                </>
              }
              
              {geoTableDisplay === 'geology' ?
                <>

                  <Table id="copyTable">
                    <thead className="sticky-top bg-white">
                      <tr>
                        <th colSpan={2} className="title-bold text-center">Classification</th>
                        <th className="title-bold">Geological description</th>
                        <th className="title-bold">Top (mBGL)</th>
                        <th className="title-bold">Bottom (mBGL)</th>
                      </tr>
                    </thead>
                      {strataCopy && (
                        <tbody>

                          {strataCopy.map((item, i) => {
                              return (
                                <tr key={i}>
                                  <td style={{minWidth:"40px"}}><div className="stratakey border mx-auto" style={{backgroundColor:`rgb(${item.color[0]}, ${item.color[1]}, ${item.color[2]})`}}></div></td>
                                  <td style={{minWidth:"40px"}}>{item.classification ? item.classification : '-'}</td>
                                  <td>{item.description}</td>
                                  <td>{item.topMBGL}</td>
                                  <td>{item.baseMBGL}</td>
                                </tr>
                              )
                            })
                          }                
                        </tbody>
                      )}
                    </Table>
                  </>
                  : geoTableDisplay === 'test' ?
                  <>
                    {tests_present ? (
                    <Table id="copyTable">
                    <thead className="sticky-top bg-white">
                      <tr>
                        <th>Test type</th>
                        <th>Depth of test (m)</th>
                        <th>Test result</th>
                      </tr>
                    </thead>
                      {props.tests && (
                        <tbody>

                          {props.tests.map((item, i) => {
                            if(item.test===test_types[testSelection]){
                              return (
                                <tr key={i}>
                                  <td>{item.test}</td>
                                  <td>{item.depth}</td>
                                  <td>
                                  {
                                  (typeof item.result === 'string')
                                  ? (item.result === 'null' ? null : item.test === 'PEN' ? parseFloat(item.result).toFixed(2) : parseFloat(item.result).toFixed(0))
                                  : item.report
                                  }
                                  </td>
                                </tr>
                              )
                            }
                            })
                          }                
                        </tbody>
                      )}
                    </Table>
                    ) : (
                      <div className="text-center pt-4">
                        <p className="text-muted"><FontAwesomeIcon icon={faCircleInfo}/> No test data available</p>
                      </div>
                    )}
                  </>
                    : geoTableDisplay === 'details' ? (
                      <div className="h-100 bg-white">

                    <Table>
                      {props.data &&
                          <tbody>
                              {props.data.samplingDate && (
                              <tr>
                                  <th>Sampling date:</th>
                                  <td>
                                  {moment(
                                      props.data.samplingDate
                                  ).format("yyyy")}</td>
                              </tr>
                              )}
                              {props.data.displayName && (
                              <tr>
                              <th>Project:</th>
                                  <td>
                                      {props.data.description.toString().toLowerCase()}
                                  </td>
                              </tr>
                              )}
                              {props.data.description && (
                              <>
                                <tr>
                                <th>Civils ID:</th>
                                    <td>
                                    {props.data.uuid}
                                    </td>
                                </tr>
                                <tr>
                                <th>Reference:</th>
                                    <td>
                                    {props.data.borehole_name || props.data.reference}
                                    </td>
                                </tr>
                                <tr>
                                <th>Project:</th>
                                    <td>
                                    {props.data.description || props.data.description}
                                    </td>
                                </tr>
                                <tr>
                                <th>Ground Level:</th>
                                    <td>
                                    {strataCopy && ground_level ? ground_level : '-'}
                                    {strataCopy && ground_level && 'm'}
                                    </td>
                                </tr>
                                <tr>
                                <th>Water Level:</th>
                                    <td>
                                    {strataCopy && water_strike ? water_strike : '-'}
                                    {strataCopy && water_strike && 'mBGL'}

                                    </td>
                                </tr>
                              </>
                              )}
                              {props.data.description && (
                              <tr>
                              <th>Easting:</th>
                                  <td>
                                  {props.data.easting}
                                  </td>
                              </tr>
                              )}
                              {props.data.description && (
                              <tr>
                              <th>Northing:</th>
                                  <td>
                                  {props.data.northing}
                                  </td>
                              </tr>
                              )}
                              {props.data.orientation1 && (
                              <tr>
                              <th>Orientation:</th>
                                  <td>
                                  {props.data.orientation1}
                                  </td>
                              </tr>
                              )}
                              {props.data.orientation2 && (
                              <tr>
                              <th>Inclination:</th>
                                  <td>
                                  {props.data.orientation2}
                                  </td>
                              </tr>
                              )}
                          </tbody> 
                      }                             
                    </Table>
                      </div> 
                    )
                  : geoTableDisplay === 'predict' ?
                  <>
                  <div className="pb-3">
                    <div
                      className="btn-group btn-group-visualise dimmed"
                      role="group"
                      aria-label="Toggle geological data"
                    >
                      <input
                          type="radio"
                          className="btn-check"
                          name="correlationGroup"
                          id="correlation1"
                          autoComplete="off"
                          checked={correlationSelected === 1}
                          onChange={() => {setCorrelationSelected(1)}}
                      />
                      <label
                          className="btn btn-outline-dark shadow-none"
                          htmlFor="correlation1"
                      >
                        Description correlation
                      </label>
                      <input
                          type="radio"
                          className="btn-check"
                          name="correlationGroup"
                          id="correlation2"
                          autoComplete="off"
                          disabled={spt_id.current === null}
                          checked={correlationSelected === 2}
                          onChange={() => {
                            setCorrelationSelected(2)
                            setTestSelection(spt_id.current)
                          }}
                      />
                      <label
                          className="btn btn-outline-dark shadow-none"
                          htmlFor="correlation2"
                      >
                        SPT correlation
                      </label>
                    </div>
                    {correlationSelected === 1 ? (
                      <div className="pt-2">
                        <small>Method accuracy: </small>
                        <div>
                          <Progress max={100} value={35} animated color="danger" className="my-2" style={{ width: '100px' }}></Progress>
                        </div>
                        <div className="text-muted"><small><i>Not suitable for final design. View description algorithm details <a rel="noreferrer" href="https://civils-corp.notion.site/Documentation-George-AI-assistant-67ae6689850c4b6fb00ea09ca8e8bd1c" target="_blank">here</a></i></small></div>
                    </div>
                    ) : (
                      <div className="pt-2">
                        <small>Method accuracy: </small>
                        <div>
                          <Progress max={100} value={60} animated color="warning" className="my-2" style={{ width: '100px' }}></Progress>
                        </div>
                        <div className="text-muted"><small><i>Not suitable for final design. View SPT algorithm details <a rel="noreferrer" href="https://civils.ai/soil-geotechnical-design-properties" target="_blank">here</a></i></small></div>
                      </div>
                    )}
                  </div>
                  {correlationSelected === 1 ? (
                    <Table id="copyTable">
                    <thead className="sticky-top bg-white">
                      <tr>
                        <th></th>
                        <th>Description</th>
                        <th>Top (mBGL)</th>
                        <th>Base (mBGL)</th>
                        <th>Grain type</th>
                        <th>Grouping (USCS Class)</th>
                        <th>ρ<sub>bulk</sub> <small>(kN/m<sup>3</sup>)</small></th>
                        <th>φ' <small>(<sup>o</sup>)</small></th>
                        <th>C' <small>(kN/m<sup>2</sup>)</small></th>
                        <th>C<sub>ud</sub> <small>(kN/m<sup>2</sup>)</small></th>
                      </tr>
                    </thead>
                    {strataCopy && (
                        <tbody>
                          {strataCopy.map((item, i) => {
                              return (
                                <tr key={i}>
                                  <td style={{minWidth:"40px"}}><div className="stratakey border mx-auto" style={{backgroundColor:`rgb(${item.color[0]}, ${item.color[1]}, ${item.color[2]})`}}></div></td>
                                  <td>{item.description}</td>
                                  <td>{item.topMBGL}</td>
                                  <td>{item.baseMBGL}</td>
                                  <td>{item.grain}</td>
                                  <td>{item.uscs}</td>
                                  <td>{item.density}</td>
                                  <td>{item.friction}</td>
                                  <td>{item.c}</td>
                                  <td>{item.cund}</td>

                                </tr>
                              )
                            })
                          }                
                        </tbody>
                      )}
                    </Table>
                  ) : correlationSelected === 2 ? (
                    <Table id="copyTable">
                    <thead className="sticky-top bg-white">
                      <tr>
                        <th></th>
                        <th>Description</th>
                        <th>Top (mBGL)</th>
                        <th>Base (mBGL)</th>
                        <th>Grain type</th>
                        <th>Grouping (USCS Class)</th>
                        <th>SPT Avg.</th>
                        <th>ρ<sub>bulk</sub> <small>(kN/m<sup>3</sup>)</small></th>
                        <th>φ' <small>(<sup>o</sup>)</small></th>
                        <th>C' <small>(kN/m<sup>2</sup>)</small></th>
                        <th>C<sub>ud</sub> <small>(kN/m<sup>2</sup>)</small></th>
                      </tr>
                    </thead>
                    {strataCopy && (
                        <tbody>
                          {strataCopy.map((item, i) => {
                              return (
                                <tr key={i}>
                                  <td style={{minWidth:"40px"}}><div className="stratakey border mx-auto" style={{backgroundColor:`rgb(${item.color[0]}, ${item.color[1]}, ${item.color[2]})`}}></div></td>
                                  <td>{item.description}</td>
                                  <td>{item.topMBGL}</td>
                                  <td>{item.baseMBGL}</td>
                                  <td>{item.grain}</td>
                                  <td>{item.uscs}</td>
                                  <td>{item.spt_avg}</td>
                                  <td>{item.density}</td>
                                  <td>{item.friction}</td>
                                  <td>{item.c}</td>
                                  <td>{item.cund}</td>

                                </tr>
                              )
                            })
                          }                
                        </tbody>
                      )}
                    </Table>
                  ) : (
                    <></>
                  )}
                  </>
                  : geoTableDisplay === 'topo' ?
                  <div className="container h-100">

                    {/* <div className="input-group mb-2" >

                    <div className="form-floating p-0" style={{maxWidth: "220px"}}>
                      <input
                          name="groundLevel"
                          type="number"
                          className="form-control"
                          id="groundLevel"
                          value={satelliteElevation}
                          disabled
                          autoComplete="off"
                          step="0.1"
                          required
                      />
                    <label htmlFor="groundLevel">Satellite ground level (m)</label>
                    </div>
                    <span className="align-baseline input-group-text btn btn-light disabled p-3" style={{border: "1px solid #ced4da"}} id="basic-addon1"><FontAwesomeIcon className="icon-satellite"icon={faSatellite}/></span>

                    </div> */}

                  </div>
                  : geoTableDisplay === 'extra_files' ?
                  <div className="h-100 w-50">
                    <Card className="mb-3">
                      <CardHeader className="bg-white">
                        <h5 className="title-bold">Extra files</h5>
                      </CardHeader>
                      {props.data.files && props.data.files.length > 0 && props.data.files.map((file, i) => {
                        return (
                          <CardBody key={i} body outline color="info">
                            <div className="row">
                              <div className="col-12">
                                <div className="d-flex justify-content-between align-items-center">
                                  <div>
                                    {file.file_name}
                                  </div>
                                  <Button
                                    size="sm" 
                                    className="ms-2 btn-primary btn" 
                                    outline 
                                    color="white" 
                                    onClick={() => {
                                      window.open(file.file_path, '_blank')
                                    }}
                                  >
                                    View
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </CardBody>
                        );
                      })}
                    </Card> 
                  </div>
                  :
                  <></>
                }
              </div>
            </div>
          </div>
          {/* {geoTableDisplay!=='test' &&
            <div className="row pt-1 mb-0">
              <div className="col-3 text-center">
                <div className="form-check form-switch d-inline-block ms-1 me-2">
                    <input className="form-check-input" type="checkbox" id="dimensionsCheckbox" checked={displayDimensions} onChange={()=>{setDisplayDimensions(!displayDimensions)}}/>
                    <label className="form-check-label" htmlFor="dimensionsCheckbox">
                        <> Dimensions </>
                    </label>
                </div>
              </div>
            </div>
          } */}
        </ModalBody>

        <ModalFooter className="border-0">
          <div className="row g-0 container-fluid">
            <div className="col-6 text-start text-secondary">


            {!loading &&
                  <>
                  {props.data.status === 'ready' ? (
                    <>
                    {!showModalErrors && !showModalSuccess && !props.data.extraction_requested ? (
                      <>
                          <div className="mb-4 small d-block d-sm-none">Hit convert to load this file from BGS archived PDF</div>
                          <Button
                          disabled={
                              showModalErrors ||
                              showModalSuccess ||
                              props.data.extraction_requested
                          }
                          color="primary"
                          size="lg"
                          onClick={() => {
                              handleRequestDigitization(
                              props.data.id
                              );
                              }}
                          >
                              <FontAwesomeIcon className="icon-satellite"icon={faWandMagicSparkles}/>
                              &nbsp; Convert
                          </Button>
                      </>
                    ) : (
                      <>

                      {props.data.link ? (
                        <a
                          className="btn btn-outline-secondary border-white me-2"
                          href={props.data.link}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <FontAwesomeIcon className="icon-pdf" icon={faFilePdf}/>
                        </a>
                      
                      ): ( 
                          <a
                            className = "btn btn-outline-secondary border-white me-2"
                            onClick={(event) => {
                              event.preventDefault();
                              handleDownload(props.data.id, "pdf");
                            }}
                          >
                          <FontAwesomeIcon
                            className="icon-pdf"
                            icon={faFilePdf}
                          />
                          </a>
                        
                      )}

                      <GeoRecordDownloadButton
                        disabled={loading}
                        label={
                            <FontAwesomeIcon
                            className="icon-excel mb-0"
                            icon={faFileExcel}
                            />
                        }
                        className="btn btn-outline-secondary border-white me-2"
                        geoRecord={props.data}
                        projectId={props.projectId}
                      />
                      <AGSDownloadButton
                        disabled={loading}
                        label={agsFileIcon}
                        className="btn btn-outline-secondary border-white me-2 agsIconDetailsTab"
                        geoRecord={props.data}
                        projectId={props.projectId}
                      />
                      </>
                    )}
                    </>
                  ) : props.data.status === 'pending' ? (
                    <>
                      <Button
                        disabled={
                          showModalErrors ||
                          showModalSuccess ||
                          props.data.extraction_requested
                        }
                        size="lg"
                        color="primary"
                        onClick={() => {
                          handleRequestDigitization(
                              props.data.id
                          );
                        }}
                        >
                        {!props.data.extraction_requested ? (
                            <span>
                                <FontAwesomeIcon className="icon-satellite"icon={faWandMagicSparkles}/>
                                &nbsp; Convert
                            </span>
                        ) : (
                            <span>
                                <span>&nbsp; Extracting data from PDF...</span>
                            </span>
                        )}
                        </Button>
                    </>
                  ) : (
                    <>
                    </>
                  )}
                  </>
                }
            </div>
            <div className="col-6 text-end">
                {props.data && !props.data.link && strataCopy &&
                  <>
                    <Button size="sm" className="me-2" outline color="secondary" disabled={copiedTable} onClick={() => triggerUpdateInfo(props.data.id)}>
                      <FontAwesomeIcon
                        className="icon-view"
                        icon={faPenToSquare}
                      />
                      <>&nbsp;Edit borehole</>
                    </Button> 
                    <Button 
                    size="sm" 
                    className="me-2" 
                    outline 
                    color="secondary" 
                    disabled={copiedTable} 
                    onClick={() => triggerUpdatePosition(log)}
                    >
                      <FontAwesomeIcon
                        className="icon-view"
                        icon={faLocationDot}
                      />
                      <>&nbsp;Update location</>
                    </Button> 
                    {props.data.position && props.data.position.lat != 0 && props.data.position.lng != 0 &&
                      <Button 
                      size="sm" 
                      className="me-2" 
                      outline 
                      color="secondary" 
                      disabled={copiedTable} 
                      onClick={() => toggleDeleteConfirmModal(log.id)}
                      >
                        <FontAwesomeIcon
                          className="icon-view"
                          icon={faTrash}
                        />
                        <>&nbsp;Delete location</>
                      </Button> 
                    }
                  </>
                }
            </div>
          </div>
        </ModalFooter>
        <Modal id="updateBareholeModal" centered={true} fullscreen={true} isOpen={modalUpdateBorehole} toggle={()=>setModalUpdateBorehole(!modalUpdateBorehole)}>
          <UpdateBoreholeForm displayName="Update borehole record" 
                              toggleModal={()=>setModalUpdateBorehole(!modalUpdateBorehole)}
                              geoRecordId={props.data.id} 
                              boreHoleInfoUpdate={(id) => {props.boreHoleInfoUpdate(id)}}
                              project={props.project}
                              setRecordUpdated={(status) => props.setRecordUpdated(status)}
                              />
        </Modal>
        <Modal id="deleteInfoConfirm" size="sm" isOpen={deleting} toggle={toggleDeleteConfirmModal} centered={true}>
            <DeleteConfirmModal displayName={`Delete borehole location`} 
                                toggleModal = {()=> toggleDeleteConfirmModal}
                                deleting = {(status) => {setDeleting(status)}}
                                deleteConfirmed = {(status) => {setDeleteConfirmed(status)}}
                                deleteType = "location" />
          </Modal>
        </>
      }
      </>
  );
  };
  
  export default BoreholeLogDetails;
  

  const b64toBlob = (dataUri, contentType) => {
    var byteString = window.atob(dataUri.split(",")[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
  
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: contentType });
  };