import { useState } from "react";
import AuthFormHeader from "../shared/AuthFormHeader";
import ValidationSummary from "../shared/ValidationSummary";
import Button from "../shared/Button";
import httpClientPy from "../../utils/httpClientPy";
import { NavLink } from "react-router-dom";

const Auth = (props) => {

    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loginRequest, setLoginRequest] = useState({
        email: "",
    });

    const handleChange = (e) => {
        setLoginRequest({ ...loginRequest, [e.target.name]: e.target.value });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        setErrors([]);
        let domain = loginRequest.email.split('@')[1]
        httpClientPy.post('/user/login/sso', {domain: domain, email: loginRequest.email})
        .then((result) => {
            window.location.href = result.data.url;
        })
        .catch((errorResponse) => {
            setErrors(errorResponse.response.data.detail);
            setLoading(false);
            throw errorResponse;
        })
        .finally(() => {
            setLoading(false);
        }); 
    }

    return (
        <main className="form-signin mt-5 m-auto container text-center">
        <AuthFormHeader />
        <h1 className="title-bold pt-4">Log in</h1>
        <div className='mt-3'><ValidationSummary errors={errors} /></div>
        <div className="mt-3">
        <form onSubmit={handleSubmit}>
          <div className="form-floating mb-2">
            <input
              name="email"
              type="email"
              className="form-control"
              id="emailInput"
              placeholder="name@example.com"
              value={loginRequest.email}
              onChange={handleChange}
              required
            />
            <label htmlFor="emailInput">Email address</label>
          </div>

          <div className="vstack gap-2">
            <Button
              type="submit"
              label="Continue with SAML"
              className="w-100 btn btn-primary"
              loading={loading}
            />

        <div>
          {/* {pendingActivation &&
            <UncontrolledAlert color="warning">
              <span className="title-bold">Your account is still pending for activation, it may take up to 24 hours to activate your account. Please <a href="mailto:info@civils.ai"> contact us</a> for any questions.</span>
            </UncontrolledAlert>
          } */}
        </div>
            <div className="text-secondary"><small>
              You can also continue with <NavLink onClick={() => props.setSSOLoginRequest(false)}>email</NavLink>
              </small>
            </div>
            {/* <div className="checkbox text-secondary text"><small>
              <label>
                <input type="checkbox" value="remember-me" disabled checked />&nbsp; I agree to Civil.ai's{" "}
                <a
                  href="https://civils.ai/user-license-agreement"
                  target="_blank"
                >
                  license agreement
                </a>
                .
              </label></small>
            </div> */}
          </div>
        </form>
        </div>
      </main>
    )
}

export default Auth;