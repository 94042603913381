const ValidationSummary = ({ errors }) => {
  return (
    <div className="validation-summary">
      {errors.length > 0 && (
        <div className="container alert alert-danger fade show" role="alert">
          {errors.map((item, i) => {
            return (
              <span
                key={i}
                className="d-block text"
                dangerouslySetInnerHTML={{ __html: item }}
              ></span>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ValidationSummary;
