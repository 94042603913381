import React, { useState, useEffect, useRef } from "react";
import { FileUploader } from "react-drag-drop-files";
import httpClientPy from "../../../utils/httpClientPy";
import ValidationSummary from "../ValidationSummary";
import { Progress, UncontrolledAlert, Card, CardHeader, CardBody, Spinner, Modal, ModalBody, ModalHeader } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesDown, faBan, faBook, faBoreHole, faCity, faFolderTree, faGear, faImage, faLayerGroup, faList, faListCheck, faPen, faPersonDigging, faPlug, faUserSlash } from "@fortawesome/free-solid-svg-icons";
import { color } from "d3";
import localStorageService from "../../../utils/localStorageService";
import { useNavigate } from "react-router-dom";
import PricingModal from "../payments/Pricing";
import { logEvent } from "../Mixpanel";

const SearchUploader = (props) => {
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errors, setErrors] = useState([]);
  const [progress, setProgress] = useState(0);
  const fileSize = useRef();
  const [agsUpload, setAgsUpload] = useState(false);
  const [numberOfPdf, setNumberOfPdf] = useState(0);
  const [errorCode, setErrorCode] = useState(0);
  const [isFreeUser, setIsFreeUser] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState(null);
  const [uploadedFilesCount, setUploadedFilesCount] = useState(0);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    
    if (props.userInfo && !props.userInfo.is_enterprise && props.userInfo.subscription_details && props.userInfo.subscription_details.subscription_type === "free") {
      setIsFreeUser(true);
    } else {
      setIsFreeUser(false);
    }

    const handleFileDrop = (e) => {
        e.preventDefault();
        const files = Array.from(e.dataTransfer.files);
        handleChange(files);
    }

    window.addEventListener("dragover", (e) => e.preventDefault());
    window.addEventListener("drop", handleFileDrop);
    return () => {
      window.removeEventListener("dragover", (e) => e.preventDefault());
      window.removeEventListener("drop", handleFileDrop);
    };

  }, []);



  useEffect(() => {

    if(loading){
      const intervalId = setInterval(() => {
        setProgress((prevCount) => {
          if (prevCount >= 100) {
            clearInterval(intervalId);
            return prevCount;
          }
          else{
            return prevCount + 1;
          }
        });
      }, 50*fileSize.current);

      return () => {
        //wait 5 seconds before setting progress 0
        setTimeout(() => {
          clearInterval(intervalId);
          setProgress(0)
        }, 5000)
      };
    }
  }, [loading]);


  useEffect(() => {
    if(showSuccess){
      setTimeout(() => {
        setShowSuccess(false)
      }, 3000)
    }
  }, [showSuccess]);


  // useEffect(() => {
  //   if(errorCode === 4 || errorCode === 11 || errorCode === 5 || errorCode === 13){
  //     setModalPricingOpen(true);
  //   }
  // }, [errorCode]);




  const uploadFiles = () => {
    setShowConfirmation(false);
    setNumberOfPdf(0);
    let total_size_mb = 0;

    const formData = new FormData();

    fileSize.current = 1;
    for (var i = 0; i < uploadedFiles.length; i++) {
      formData.append("file_obj", uploadedFiles[i]);
      // console.log(`file ${i}: ${files[i].name} ${files[i].size} ${files[i].type}`);
      // convert file size to MB
      // increment number of pdf files
      if(uploadedFiles[i].type === "application/pdf"){
        setNumberOfPdf(numberOfPdf+1);
      }
      
      total_size_mb += uploadedFiles[i].size/1000000;

      if(uploadedFiles[i].size>300000) {
        fileSize.current += uploadedFiles[i].size/2000000;
      }
    }

    let url = ""
      
    if (props.geoRecordId) {
      // append geo record id to the url
      url = `${props.uploadUrl}?geo_record_id=${props.geoRecordId}`;
    } else {
      url = props.uploadUrl;
    }

    if (props.projectId) {
      // append project id to the url
      url = `${props.uploadUrl}?project_id=${props.projectId}`;
    }
    
    if (props.mode === null) {
      url = url;
    }
    else if (props.mode === 0) {
      url = `${url}&geotech_data=${false}`;
    }
    else if (props.mode !== 0 && props.mode !== 2 && props.mode !== null) {
      url = `${url}&geotech_data=${true}`;
    }
    else if (props.mode === 2) {
      url = `${url}&drawing=${true}`;
    }

    if (props.mode === 3) {
      url = `/geo/record/upload/reg?project_id=${props.projectId}`;
    }
    
    setLoading(true);
    setShowSuccess(false);
    setShowError(false);
    setAgsUpload(false);
    setErrorCode(0);
    
    httpClientPy
      .post(url, formData)
      .then((response) => {
        setErrorCode(response.data.code);
        if(response.data.code && response.data.code>1){
          setShowError(true)
        }
        if(response.data.info.length > 0){
          setAgsUpload(true);
          props.updateAgsInfo(response.data.info);
        }
      })
      .catch((errorResponse) => {
        if (errorResponse.response.data) {
          setErrors(errorResponse.response.data.errors);
        }
        setShowError(true);
      })
      .finally(() => {
        if(!showError) {
          if (props.mode===null) {
            logEvent("Autodetect Doc Upload", { "Event": "Autodetect Report Upload Success" });
          }
          else if (props.mode===0) {
            logEvent("Design Doc Upload", { "Event": "Project Report Upload Success" });
          } else if (props.mode===2) {
            logEvent("CAD Doc Upload", { "Event": "CAD Drawing Upload Success" });
          } else if (props.mode===3) {
            logEvent("Regulations Doc Upload", { "Event": "Regulations Report Upload Success" });
          }
          setShowSuccess(true);
          props.onComplete();
        }
          // console.log('upload complete')
        setLoading(false);
      });


  }

  const handleChange = (files) => {
    if (files.length === 0) {
      return;
    }

    setUploadedFiles(files);
    setUploadedFilesCount(files.length);
    if(props.mode !== null) {
      setShowConfirmation(true);
    } 
  };

  // if there is change to setUploadedFiles and props.mode is null then start upload
  useEffect(() => {
    if(uploadedFiles && props.mode === null){
      uploadFiles();
    }
  }
  ,[uploadedFiles]);

  return (
    <>
          <div id="chat_input_container" className="w-100 text-dark">

            <div className="w-100">
              {showSuccess && errorCode !== 4 && errorCode !== 5 && errorCode != 11 && (
                agsUpload && !showError ?
                  <UncontrolledAlert color="success" className="mb-0">
                    <span className="title-bold">All set, your AGS files are extracted </span><span>&nbsp;You can view the records in my geotech data.</span>
                  </UncontrolledAlert>
                  :
                    (numberOfPdf > 0 && !showError) ? 
                      props.mode === 3 ? (
                      <UncontrolledAlert color="success"  className="mb-0">
                        <span className="title-bold">All set, we're extracting the data. </span><span>&nbsp;We'll email you when your file is ready.</span>
                      </UncontrolledAlert>
                      ) : props.mode === 1 || 2 || null ? (
                      <UncontrolledAlert color="success"  className="mb-0">
                        <span className="title-bold">Success! </span><span>&nbsp;Your file is now processing, we'll email you once it's available.</span>
                      </UncontrolledAlert>
                      ) : (
                        null
                      )
                    :
                      !showError && errorCode != 3 ?
                      <UncontrolledAlert color="warning"  className="mb-0">
                        <span className="title-bold">The uploaded file has records that are already associated with your account.</span>
                      </UncontrolledAlert>
                      :
                        errorCode == 3 &&
                        <UncontrolledAlert color="danger"  className="mb-0">
                          <span className="title-bold"><b>Check file type.</b> Only PDF and AGS4 files are currently supported. 
                          Currently only AGS4 is supported.</span>
                        </UncontrolledAlert>
              )}

              { errorCode == 4 && (
                <UncontrolledAlert color="warning"  className="mb-0">
                  <span className="title-bold">Please upgrade your account. You've reached your document upload limit for your account tier.</span>
                </UncontrolledAlert>
              )}

              { errorCode == 5 && (
                <UncontrolledAlert color="warning"  className="mb-0">
                  <span className="title-bold">Please upgrade your account. You've reached your document upload limit for your account tier.</span>
                </UncontrolledAlert>
              )}

              { errorCode == 6 && (
                <UncontrolledAlert color="warning"  className="mb-0">
                  <span className="title-bold">This file exceeds the size limit of {props.mode == 2 ? '300' : isFreeUser ? '10' : "32"}MB</span><span> - please upload a smaller document</span>
                </UncontrolledAlert>
              )}

              { errorCode == 7 && (
                <UncontrolledAlert color="warning"  className="mb-0">
                  <span className="title-bold">File type isn't a PDF</span><span> - please upload a PDF file instead.</span>
                </UncontrolledAlert>
              )}

              { errorCode == 11 && (
                <UncontrolledAlert color="warning"  className="mb-0">
                  <span className="title-bold">Uploaded PDF File is not readble. We recommend upgrading to Basic or enterprise to utilize more powerful document extractor</span>
                </UncontrolledAlert>
              )}
              { errorCode == 12 && (
                <UncontrolledAlert color="warning"  className="mb-0">
                  <span className="title-bold">Your account has view only permission, please speak with project admin</span>
                </UncontrolledAlert>
              )}

              {showError && errors && errors.length > 0 && (
                <ValidationSummary errors={errors} />
              )}

              {showError && (errors && errors.length > 0) && (
                <UncontrolledAlert color="danger"  className="mb-0">
                  <span className="title-bold">Sorry something went wrong!</span><span>&nbsp;Please try uploading your file again later.</span>
                </UncontrolledAlert>
              )}
            </div>


          {props.userInfo &&
          <div className="uploader_holder w-100 py-5  mx-auto">

            <div className="row justify-content-center align-items-center g-0">
              <div className="col-12 col-lg-3">
                <Card className="shadow bg-light">
                  <CardHeader>
                    <h6 className="title-bold mt-2">{props.mode === null ? <><FontAwesomeIcon className="me-2" icon={faBook} /> Autodetect doc type</> : props.mode === 0 ? <><FontAwesomeIcon className="me-2" icon={faBook} /> Project document</> : props.mode === 3 ? <><FontAwesomeIcon className="me-2" icon={faBook} /> Spec or Regulation</> :<><FontAwesomeIcon className="me-2" icon={faImage} /> CAD Drawing</>}</h6>
                  </CardHeader>
                  <CardBody>

                    <div className="mb-3">
                          {props.mode === 0 || props.mode === 3 ?
                            <>
                              {(props.userInfo.allow_civilsgpt || props.userInfo.subscription.subscription_id!==1) ?
                                <>
                                {props.mode === 0 ? (
                                  <small><FontAwesomeIcon className="me-2" icon={faAnglesDown} />Upload project documents here</small>
                                ): (
                                  <small><FontAwesomeIcon className="me-2" icon={faAnglesDown} />Upload building codes here</small>
                                )}
                                </>
                              :
                                <span><FontAwesomeIcon className="me-2" icon={faBan} />Your account currently doesn't have permission to upload this filetype</span>
                              }
                            </>
                            : props.mode === 2 ?
                            <>
                            {(props.userInfo.allow_drawing || props.userInfo.subscription.subscription_id!==1) ?
                                <small><FontAwesomeIcon className="me-2" icon={faAnglesDown} />Upload drawings here</small>
                              :
                                <span><FontAwesomeIcon className="me-2" icon={faBan} />Your account currently doesn't have permission to upload this filetype</span>
                              }
                            </>
                            : null
                            } 
                    </div>

                    <>
                      
                      <div className="drag-drop-uploader-holder">
                        {!showError ?
                        <>
                          {(loading || (progress > 0 && progress < 100)) &&
                            <div className="drag-drop-uploader disabled d-flex flex-column align-items-center justify-content-center" style={{ height: '100%' }}>
                              <span>
                                <Spinner color="dark" size="sm" className="mx-auto" />
                                <span>File upload in progress</span>
                              </span>
                            </div>
                          }
                          { props.userInfo && (props.userInfo.allow_drawing || props.userInfo.allow_civilsgpt) ? (
                            <FileUploader
                              classes={`drag-drop-uploader w-100 mb-2 ${(loading || (progress > 0 && progress < 100)) && 'd-none'}`}
                              style={{ minWidth: "100%" }}
                              label="Drop files here"
                              hoverTitle="Drop files here"
                              name="file"
                              types={["pdf"]}
                              handleChange={handleChange}
                              maxSize={props.mode == 2 ? 300 : 32}
                              onSizeError={()=>{setErrorCode(6)}}
                              onTypeError={()=>setErrorCode(7)}
                              multiple={true}
                            />
                          ) : (
                            <FileUploader
                              classes='drag-drop-uploader w-100 mb-2'
                              style={{ minWidth: "100%" }}
                              label="Drop files here"
                              hoverTitle="Drop files here"
                              name="file"
                              types={["pdf"]}
                              handleChange={handleChange}
                              maxSize={props.mode == 2 ? 300 : isFreeUser ? 10 : 32}
                              onSizeError={()=>{setErrorCode(6)}}
                              onTypeError={()=>setErrorCode(7)}
                              multiple={true}
                              disabled={props.mode !== null ? true : false}
                            />
                          )}
                        </>
                        :
                        <div className="drag-drop-uploader disabled d-flex flex-column align-items-center justify-content-center" style={{ height: '100%' }}>
                            <span>Upload error, please try again</span>
                            <button onClick={()=>{setErrorCode(0); setShowError(false);}} className="btn mt-2 btn-sm btn-primary">Try again</button>
                        </div>
                        }
                      </div>
                    </>
                  </CardBody>
                </Card>

              </div>
              <div className="d-none d-lg-block col-2">
                {!showError ? (
                  <div
                    className="progress my-2"
                    style={{ height: '1rem', borderRadius: 0, margin: 'auto', transition: 'none' }}
                  >
                    <div
                      className={`progress-bar custom-progress-bar ${progress === 100 && 'bg-success'}`}
                      role="progressbar"
                      aria-valuenow={progress}
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style={{ width: `${progress}%` }}
                    >
                      {progress}%
                    </div>
                  </div>
                ) : (
                  <div
                    className="progress my-2"
                    style={{ height: '1rem', borderRadius: 0, margin: 'auto', transition: 'none' }}
                  >
                    <div
                      className="progress-bar custom-progress-bar"
                      role="progressbar"
                      aria-valuenow="0"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style={{ width: '0%' }}
                    >
                      {progress}%
                    </div>
                  </div>
                )}
              </div>


              <div className="d-none d-lg-block col-3">
                  <Card className="shadow-sm bg-light">
                    <CardHeader>
                      <h6 className="title-bold pt-2">{props.mode === null ? <span><FontAwesomeIcon className="me-2" icon={faPen} /> Output</span> : props.mode === 0 ? <span><FontAwesomeIcon className="me-2" icon={faCity} /> Project</span> : props.mode === 3 ? <span><FontAwesomeIcon className="me-2" icon={faListCheck} /> Library</span> : props.mode === 2 ? <span><FontAwesomeIcon className="me-2" icon={faListCheck} /> Library</span> : null}</h6>
                    </CardHeader>
                    <CardBody>

                    <div class="input-group input-group-sm">
                        <label class="input-group-text" for="inputGroupSelect01">
                          <FontAwesomeIcon icon={faFolderTree} />
                        </label>
                        <input type="text" class="form-control" disabled value={props.mode === null ? 'Auto organise' : props.mode === 0 ? 'Project Documents' : props.mode === 3 ? 'Building codes' : props.mode === 2 ? 'CAD details' : null} aria-label="Knowledge base" aria-describedby="basic-addon1"/>
                      </div>
                    </CardBody>
                  </Card>
              </div>
            </div>

          </div>
        }

        {props.extraDetails && (props.extraDetails.pending_geotech_data > 0 || props.extraDetails.pending_non_geotech_data > 0) && 
          <div className="text-end">
            <div className="btn btn-sm btn-outline-secondary bg-white disabled">
              <Spinner color="success" size="sm"/>
              
              <span className="text-dark ps-2">{props.extraDetails.pending_geotech_data + props.extraDetails.pending_non_geotech_data} Background task(s) in progress</span>
            </div>
          </div>
        }

      </div>
        <Modal isOpen={showConfirmation} toggle={() => setShowConfirmation(!showConfirmation)} centered>
          <ModalHeader toggle={() => setShowConfirmation(!showConfirmation)}>     
            <h4 className="title-bold">Confirm your uploaded files</h4>
          </ModalHeader>
          <ModalBody>
            <p>You are uploading <span className="title-bold">{uploadedFilesCount && uploadedFilesCount} {props.mode === 0 ? 'Project Report(s)' : props.mode === 3 ? 'Regulation Report(s)': 'Drawing(s)'  }</span></p>
            <div className="d-flex justify-content-end">
              <button className="btn btn-success me-2" onClick={() => uploadFiles()}>Confirm</button>
              <button className="btn btn-outline-secondary" onClick={() => setShowConfirmation(!showConfirmation)}>Cancel</button>
            </div>
          </ModalBody>
        </Modal>
    </>
  );
};

export default SearchUploader;
