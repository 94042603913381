const isAuthenticated = () => {
    if (localStorage.getItem("auth_token") == null) {
      logout();
      return false;
    } else {
      return true;
    }
  };
  
  const logout = () => {
    localStorage.removeItem("auth_token");
    localStorage.removeItem("displayName");
    localStorage.removeItem("onboarded");
    localStorage.removeItem("new_user");
    sessionStorage.clear();
  };
  
  const displayName = () => {
    var displayName = localStorage.getItem("displayName");
    if (displayName == null) {
      logout();
    } else {
      return displayName;
    }
  };
  
  export default {
    isAuthenticated: isAuthenticated,
    displayName: displayName,
    logout: logout,
  };
  