import {Card, CardBody, CardTitle, CardText, CardHeader, CardFooter} from 'reactstrap';
import SideBar from "../project/SideBar";
import httpClientPy from '../../utils/httpClientPy';
import { useEffect, useState } from 'react';
import Button from '../shared/Button';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DeleteConfirmationModal from '../shared/DeleteConfirmation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

const Management = () => {

    const [userInfo, setUserInfo] = useState(null);
    const [apiKeys, setApiKeys] = useState(null);
    const [dateJoined, setDateJoined] = useState(null);
    const [loadingUInfo, setLoadingUInfo] = useState(false);
    const [loadingPw, setLoadingPw] = useState(false);
    const [loadingKey, setLoadingKey] = useState(false);
    const [currentPw, setCurrentPw] = useState(null);
    const [newPw, setNewPw] = useState(null);
    const [newPwConfirm, setNewPwConfirm] = useState(null);
    const [pwError, setPwError] = useState(false);
    const [admin, setAdmin] = useState(false);

    useEffect(() => {
        httpClientPy.get(`/user`).then((response) => {
            let userList = response.data
            if (userList.is_enterprise) {
                userList.allow_digitise = userList.enteprise_is_digitiser
                userList.allow_civilsgpt = userList.enterprise_is_civilsgpt
            } else {
                userList.allow_digitise = userList.subscription_details ?  userList.subscription_details.is_digitise : false
                userList.allow_civilsgpt = userList.subscription_details ? userList.subscription_details.is_civilsgpt : false
            }
            setUserInfo(userList)
            setAdmin(userList.enterprise_admin)
            const originalDate = new Date(userList.date_joined);
            // Convert to UTC format
            const utcDate = new Date(originalDate.toUTCString());
            // Format the UTC date as needed
            const formattedDate = utcDate.toISOString().replace(/T/, ' ').replace(/\..+/, '');
            setDateJoined(formattedDate)
        })

        httpClientPy.get(`/keys`).then((response) => {
            let apiKeys = []
            response.data.api_keys.forEach((key) => {
                key.created_datetime = new Date(key.created_datetime).toISOString().replace(/T/, ' ').replace(/\..+/, '')
                apiKeys.push(key)
            })

            setApiKeys(apiKeys)
        })

        return () => {
            setUserInfo(null)
            setApiKeys(null)
        }
    }, [])

    const handleValueChange = (e) => {
        setUserInfo({...userInfo, [e.target.id]: e.target.value})
    }

    const handlePasswordValueChange = (e) => {
        switch(e.target.id) {
            case 'current_pw':
                setCurrentPw(e.target.value)
                break;
            case 'new_pw':
                setNewPw(e.target.value)
                break;
            case 'new_pw_confirm':
                setNewPwConfirm(e.target.value)
                if(e.target.value !== newPw) {
                    setPwError(true)
                } else {
                    setPwError(false)
                }
                break;
            default:
                break;
        }
    }

    const handleUpdateInfo = () => {
        setLoadingUInfo(true)
        const body = {
            first_name: userInfo.first_name,
            last_name: userInfo.last_name,
            email: userInfo.email,
            institution: userInfo.institution
        }
        httpClientPy.put(`/user/update`, body)
            .then((response) => {
                // show alert on top right corner for 3 seconds
                toast.success('User information updated successfully!', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})

                setLoadingUInfo(false)
            
            }).catch((error) => {
                toast.error('Error updating user information. Please try again.', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
                setLoadingUInfo(false)
            })
    }

    const handleUpdatePassword = () => {
        // if current password is empty or new password is empty or new password confirmation is empty then return
        if(currentPw === null || newPw === null || newPwConfirm === null) {
            return
        }
        setLoadingPw(true)
        const body = {
            password: currentPw,
            new_password: newPw
        }
        httpClientPy.put(`/user/update/password`, body)
            .then((response) => {
                toast.success('Password updated successfully!', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
                setCurrentPw(null)
                setNewPw(null)
                setNewPwConfirm(null)
                setLoadingPw(false)
            
            }).catch((error) => {
                toast.error(error.response.data.detail, {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
                setCurrentPw(null)
                setNewPw(null)
                setNewPwConfirm(null)
                setLoadingPw(false)
            })
        document.getElementById('current_pw').value = ''
        document.getElementById('new_pw').value = ''
        document.getElementById('new_pw_confirm').value = ''
    }

    async function handleCreateKey() {
        setLoadingKey(true)

        const body = {
            user_id: userInfo.george_id,
        }

        httpClientPy.post(`/generate/key`, body)
            .then((response) => {
                toast.success('API Key created successfully!', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
                response.data.api_key.created_datetime = new Date(response.data.api_key.created_datetime).toISOString().replace(/T/, ' ').replace(/\..+/, '')
                setApiKeys([...apiKeys, response.data.api_key])
                setLoadingKey(false)
            
            }).catch((error) => {
                toast.error('Error creating API Key. Please try again.', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
                setLoadingKey(false)
            })

        setLoadingKey(false)
    }

    async function disableKey(key_id) {
        setLoadingKey(true)

        const body = {
            key_id: key_id,
            user_id: userInfo.george_id,
        }
        
        httpClientPy.post(`/disable/key`, body)
            .then((response) => {
                toast.success('API Key disabled successfully!', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
                
                // for the response.data.api_key.id that is return, update its is_active to 0 in the apiKeys array

                let updatedKeys = apiKeys.map((key) => {
                    if(key.id === response.data.api_key.id) {
                        key.is_active = 0
                    }
                    return key
                })

                setApiKeys(updatedKeys)
                setLoadingKey(false)
            
            }).catch((error) => {
                toast.error('Error disabling API Key. Please try again.', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
                setLoadingKey(false)
            })

    }

    const manageSubscription = () => {
        httpClientPy.get('/subscriptions/manage').then(res=>{
            window.location.href = res.data.url;
        } ).catch(err=>{
            console.log(err);
        })
    }

    return (
        <>
        {userInfo &&
            <>
            <ToastContainer />
            <div className="d-flex">
                <div id="sidebarParent" className=' d-none d-md-block' style={{minWidth:'190px'}}>
                    <SideBar page={'umanage'} userInfo={userInfo} admin={admin} />
                </div>
                <div className='container'>
                    <div className="px-3 pe-lg-0 py-4 row">
                        <Card>
                            <CardHeader style={{backgroundColor:'transparent'}}>
                                <CardTitle tag="h4">
                                        User Management
                                </CardTitle>
                            </CardHeader>
                            <CardBody>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="first_name">First Name</label>
                                                <input type="text" className="form-control" id="first_name" placeholder="First Name" value={userInfo.first_name} onChange={handleValueChange} disabled={userInfo.is_sso || userInfo.is_social_login ? true : false}/>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="last_name">Last Name</label>
                                                <input type="text" className="form-control" id="last_name" placeholder="Last Name" value={userInfo.last_name} onChange={handleValueChange} disabled={userInfo.is_sso || userInfo.is_social_login ? true : false}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="email">Email</label>
                                                <input type="email" className="form-control" id="email" placeholder="Email" value={userInfo.email} onChange={handleValueChange} disabled={userInfo.is_sso || userInfo.is_social_login ? true : false}/>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="institution">Organisation</label>
                                                <input type="text" className="form-control" id="institution" placeholder="Organisation" value={userInfo.institution} onChange={handleValueChange} disabled={userInfo.is_sso ? true : false}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="reg_date">Registered Date (UTC)</label>
                                                <input type="text" className="form-control" id="reg_date" disabled value={dateJoined}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                            <CardFooter style={{backgroundColor:'transparent'}}>
                                {!userInfo.is_sso &&
                                    <div className="d-flex justify-content-end">
                                        <Button className="btn btn-primary" onClick={handleUpdateInfo} label="Update" loading={loadingUInfo}/>
                                    </div>
                                }
                            </CardFooter>
                        </Card> 
                    </div>
                    {!userInfo.is_sso && !userInfo.is_social_login ? (
                        <div className="px-3 mb-4 pe-lg-0 row">
                            <Card>
                                <CardHeader style={{backgroundColor:'transparent'}}>
                                    <CardTitle tag="h4">
                                            Password Management
                                    </CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="current_pw">Current Password</label>
                                                    <input type="password" className="form-control" id="current_pw" placeholder="Current Password" onChange={handlePasswordValueChange}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="new_pw">New Password</label>
                                                    <input type="password" className="form-control" id="new_pw" placeholder="New Password" onChange={handlePasswordValueChange}/>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="new_pw_confirm">Confirm New Password</label>
                                                    <input type="password" className="form-control" id="new_pw_confirm" placeholder="New Password Confirmation" onChange={handlePasswordValueChange}/>
                                                    {pwError && <div className="text-danger">Passwords do not match</div>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                                <CardFooter style={{backgroundColor:'transparent'}}>
                                    <div className="d-flex justify-content-end">
                                        <Button className="btn btn-primary" onClick={handleUpdatePassword} label="Update" loading={loadingPw} disabled={pwError ? true : false}/>
                                    </div>
                                </CardFooter>
                            </Card> 
                        </div>
                    ) : (
                        <div className="px-3 mb-4 pe-lg-0 row">
                            <Card>
                                <CardBody>
                                    <div className="container">
                                        {userInfo.is_sso ? (
                                            <>
                                            You are currently logged in using a SAML SSO provider. Details cannot be updated directly from Civils.ai. Please contact your SSO administrator to update your details.
                                            </>
                                        ) : (
                                            <>
                                            You are currently logged in using a 3rd party provider, hence your details cannot be updated within Civils.ai
                                            </>
                                        )}
                                    </div>
                                </CardBody>
                            </Card> 
                        </div>
                    )}
                    <div className="px-3 pe-lg-0 row">
                        <Card>
                            <CardHeader style={{backgroundColor:'transparent'}}>
                                <CardTitle tag="h4">
                                        Subscription Management
                                </CardTitle>
                            </CardHeader>
                            <CardBody>
                                <div className="container">
                                    {userInfo.is_enterprise ? (
                                        <>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="alert alert-info">
                                                    You are currently on an enterprise subscription. Please contact your admin or account owner for details of your subscription.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                {/* Add additional content for enterprise account */}
                                            </div>
                                        </div>
                                        </>
                                    ) : (
                                        <>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th>Subscription Name</th>
                                                            <th>Used/Document Quota</th>
                                                            <th>Used/Queries Quota</th>
                                                            <th>Start Date (UTC)</th>
                                                            <th>End Date (UTC)</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>{userInfo.subscription_details.subscription_type}</td>
                                                            <td>{userInfo.usage.total_documents}/{userInfo.subscription_details.document_limit}</td>
                                                            <td>{userInfo.usage.total_queries}/{userInfo.subscription_details.queries_limit}</td>
                                                            <td>{
                                                                new Date(userInfo.subscription.subscription_start_date).toLocaleString('en-GB', {day: '2-digit',
                                                                                                                                                 month: '2-digit',
                                                                                                                                                 year: 'numeric',
                                                                                                                                                 hour: '2-digit',
                                                                                                                                                 minute: '2-digit',
                                                                                                                                                 second: '2-digit'
                                                                                                                                                }
                                                                                                                                        )
                                                                }
                                                            </td>
                                                            <td>{
                                                                new Date(userInfo.subscription.subscription_end_date).toLocaleString('en-GB', {day: '2-digit',
                                                                                                                                                 month: '2-digit',
                                                                                                                                                 year: 'numeric',
                                                                                                                                                 hour: '2-digit',
                                                                                                                                                 minute: '2-digit',
                                                                                                                                                 second: '2-digit'
                                                                                                                                                }
                                                                                                                                        )
                                                                }
                                                            </td>
                                                            <td>
                                                                {userInfo.subscription_details.subscription_type !== 'free' &&
                                                                    <Button className="btn btn-primary" label="Manage" onClick={manageSubscription}/>
                                                                }
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        </>
                                    )}
                                </div>
                            </CardBody>
                        </Card> 
                    </div>
                    {userInfo.subscription && userInfo.subscription.subscription_id > 2 || userInfo.is_enterprise === true &&
                        <div className="px-3 pe-lg-0 py-4 row">
                            <Card>
                                <CardHeader style={{backgroundColor:'transparent'}}>
                                    <CardTitle tag="h4">
                                        <div className="d-flex justify-content-between">
                                            API Key Management
                                            <Button className="btn btn-primary" onClick={handleCreateKey} label="Create Key" loading={loadingKey}/>
                                        </div>
                                    </CardTitle>
                                </CardHeader>
                                <CardBody>
                                    {!apiKeys ? 
                                    (<div className="text-center">No API Keys found</div>) 
                                    : 
                                    (<table className="geo-records-list-holder">
                                        <thead>
                                            <tr>
                                                <th scope="col">API Key</th>
                                                <th scope="col">Created Date (UTC)</th>
                                                <th scope="col">Status</th>
                                                <th scope="col" width='15%'>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {apiKeys.map((key, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{key.api_key.substring(0, 15)}...</td>
                                                        <td>{key.created_datetime}</td>
                                                        <td>{key.is_active === 1 ? <span className={`badge rounded-pill green `}>Active</span>: <span className={`badge rounded-pill red `}>Inactive</span>}</td>
                                                        <td>
                                                            {key.is_active === 1 ? <Button className="btn icon-view" onClick={() => {navigator.clipboard.writeText(key.api_key)}} label={<FontAwesomeIcon className="fa-solid fa-location-crosshairs" icon={faCopy}/>} /> : null}
                                                            {key.is_active === 1 ? <DeleteConfirmationModal itemId={key.id} onDeleteConfirm={disableKey}/> : "No Actions Available" }
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                    )}
                                </CardBody>
                            </Card> 
                        </div>
                    }
                </div>
                
            </div>
            </>
        }
        </>
    )
}

export default Management;