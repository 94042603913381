import React, { useState, useEffect, useRef } from "react";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import httpClientPy from '../../utils/httpClientPy';

const ProjectDropdown = (props) => {

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const toggleDropdown = () => setDropdownOpen(prevState => !prevState);
  const navigate = useNavigate();

  const deleteProject = () => {

    if(props.project_id) {
      httpClientPy.post(
          `/project/update`, 
          {
              project_id: props.project_id,
              update_type: "delete",
          }
      )
      .then((response) => {
        setDisplayModal(false);
        props.refreshProjects();
      })
      .catch((err) => {
          setError(true);
          setErrorMessage(err.response.data.detail);
      }
      );
    }
  }

  const dismissError = () => {
    setDisplayModal(!displayModal)
    setError(false);
    setErrorMessage(null);
  }


  return (
    <>
    <Dropdown isOpen={dropdownOpen} direction="down" className="project-dropdown-modal" toggle={toggleDropdown} onClick={(event) => event.stopPropagation()}>
      <DropdownToggle className="sub-item btn text-start border-0 text-dark" tag="div">
          <span><FontAwesomeIcon icon={faEllipsis}/></span>
      </DropdownToggle>
      <DropdownMenu className="project-dropdown-modal" container="body">
          <DropdownItem onClick={()=>setDisplayModal(!displayModal)}>Delete project</DropdownItem>
      </DropdownMenu>
    </Dropdown>
    <Modal isOpen={displayModal} toggle={()=>setDisplayModal(!displayModal)}>
      <ModalHeader>
      <h4 className="title-bold">{!error ? 'Delete this project' : 'Something went wrong.'}</h4>
      </ModalHeader>
      <ModalBody>
          {!error ?
          <>
            <div>Are you sure you want to delete this project? All project data will be removed in 5 days.</div>
            <div className="d-flex justify-content-end mt-4">
                <button className="btn btn-danger" onClick={()=>deleteProject()}>Delete project</button>
                <button className="btn btn-outline-secondary ms-2" onClick={()=>setDisplayModal(!displayModal)}>Cancel</button>
            </div>
          </> :
          <>
            <div className='d-flex justify-content-center flex-column align-items-center'>
                <div>{errorMessage}</div>
                <button className="btn btn-outline-secondary ms-2" onClick={()=>dismissError()}>Okay</button>
            </div>
          </>
          }
      </ModalBody>
    </Modal>
    </>
  );
};

export default ProjectDropdown;
