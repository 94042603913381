import React, { useRef, useEffect, useMemo, useState } from "react";
import AuthFormHeader from "../shared/AuthFormHeader";
import { Card, CardHeader, CardBody, CardText } from "reactstrap";
import CheckListItem from "../signup/CheckListItem";
import countryList from "react-select-country-list";
import Button from "./Button";

const BookingForm = () => {
    const formRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const countriesOptions = useMemo(() => countryList().getData(), [])
    const [submissionStatus, setSubmissionStatus] = useState(null); // null, 'success' or 'error'

    // Remove the Mailchimp script approach if handling submission manually
    useEffect(() => {
        // Optionally, if you still need to load external scripts, load them conditionally
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const form = e.target;
        const formData = new FormData(form);
        const queryString = new URLSearchParams(formData).toString();
    
        try {
            const response = await fetch(form.action, {
                method: 'POST',
                mode: 'no-cors', // add no-cors mode
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                body: queryString
            });
            // Since response is opaque, assume success:
            setSubmissionStatus('success');
        } catch (error) {
            setSubmissionStatus('error');
        } finally {
            setLoading(false);
        }

    };

    return (
        <div className={`sign-up-container container min-vh-100 d-flex flex-column justify-content-center`}>
            <div className="text-center">
                <AuthFormHeader />
                <h1 className="title-bold mt-3 text-center display-5 text-secondary">Book a demo to access Civils.ai</h1>
            </div>
            <div className="mt-4 mx-auto text-dark container text-center">
                <div className="row g-4">
                    <div className="col-12 col-md-6 d-none d-md-block">
                        <Card className='h-100 text-start shadow'>
                        <CardHeader className='title-bold h5 bg-white p-4'>
                            Why start using Civils.ai?
                        </CardHeader>
                        <CardBody className="p-4">

                            <CardText>
                                <ul className='custom-pricing-list'>
                                    <h5 className="title-bold">For tendering</h5>
                                    <CheckListItem>Upload project specs, contracts and the site investigations into Civils.ai and extract requirements and risks.</CheckListItem>
                                    <CheckListItem>Automatically organize requirements by discipline and generate detailed breakdowns to streamline your bid preparation.</CheckListItem>
                                    <h5 className="title-bold pt-3">For compliance checking</h5>
                                    <CheckListItem>Access and query all your project requirements instantly from a centralized source.</CheckListItem>
                                </ul>
                            </CardText>
                        </CardBody>
                        </Card>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="mx-auto form-signin">
                        {submissionStatus ? (
                                submissionStatus === 'success' ? (
                                    <div className="alert alert-success">
                                        Booking is confirmed. A representative from Civils.ai will contact you in 24 hours.
                                    </div>
                                ) : (
                                    <div className="alert alert-danger">
                                        There was an error processing your booking. Please try again.
                                    </div>
                                )
                            ) : (
                                <form
                                    action="https://civils.us8.list-manage.com/subscribe/post?u=303b361d94a48c0b79cd3aa37&amp;id=690be3feb4&amp;f_id=001bc3e2f0"
                                    method="post"
                                    id="mc-embedded-subscribe-form"
                                    name="mc-embedded-subscribe-form"
                                    className="validate"
                                    ref={formRef}
                                    onSubmit={handleSubmit}
                                >
                                    <div id="mc_embed_signup_scroll">
                                        <div className="small italic text-muted text-start">
                                            <span className="asterisk">*</span> indicates required
                                        </div>
                                        <div className="mc-field-group form-floating mt-3 mb-2">
                                            <input
                                                type="email"
                                                name="EMAIL"
                                                className="required email form-control"
                                                id="mce-EMAIL"
                                                required
                                            />
                                            <label htmlFor="mce-EMAIL">Email Address <span className="asterisk">*</span></label>
                                        </div>
                                        <div className="mc-field-group form-floating mt-3 mb-2">
                                            <input type="text" name="FNAME" id="mce-FNAME" className="form-control" />
                                            <label htmlFor="mce-FNAME">First Name </label>
                                        </div>
                                        <div className="mc-field-group form-floating mt-3 mb-2">
                                            <input type="text" name="LNAME" id="mce-LNAME" className="form-control" />
                                            <label htmlFor="mce-LNAME">Last Name </label>
                                        </div>
                                        <div className="mc-field-group form-floating mt-3 mb-2">
                                            <input type="text" name="CORP" id="mce-CORP" required className="required form-control" />
                                            <label htmlFor="mce-CORP">Company <span className="asterisk">*</span></label>
                                        </div>
                                        <div className="mc-field-group form-floating mt-3 mb-2">
                                            <input type="text" name="JOBD" id="mce-JOBD" className="required form-control" required />
                                            <label htmlFor="mce-JOBD">Job description <span className="asterisk">*</span></label>
                                        </div>
                                        <div className="mc-field-group form-floating mt-3 mb-2">
                                            <select name="COUN" id="mce-COUN" className="required form-control" required>
                                                <option value="">Please select a country</option>
                                                {countriesOptions.map((country) => (
                                                    <option key={country.value} value={country.value}>{country.label}</option>
                                                ))}
                                            </select>
                                            <label htmlFor="mce-COUN">Country <span className="asterisk">*</span></label>
                                        </div>
                                        <div hidden>
                                            <input type="hidden" name="tags" value="5445287" />
                                        </div>
                                        {/* <div id="mce-responses" className="clear">
                                            <div
                                                className="response text-danger"
                                                id="mce-error-response"
                                                style={{ display: 'none' }}
                                            ></div>
                                            <div
                                                className="response text-success"
                                                id="mce-success-response"
                                                style={{ display: 'none' }}
                                            ></div>
                                        </div> */}
                                        <div
                                            aria-hidden="true"
                                            style={{ position: 'absolute', left: '-5000px' }}
                                        >
                                            <input
                                                type="text"
                                                name="b_303b361d94a48c0b79cd3aa37_690be3feb4"
                                                tabIndex="-1"
                                            />
                                        </div>
                                        <div className="clear">
                                            {loading ? (
                                                <Button className="w-100 btn btn-primary btn-lg" loading={loading} />
                                            ) : (
                                                <input
                                                    type="submit"
                                                    name="subscribe"
                                                    id="mc-embedded-subscribe"
                                                    className="w-100 btn btn-primary btn-lg"
                                                    value="Book a demo"
                                                />
                                            )}
                                        </div>
                                    </div>
                                </form>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BookingForm;